import React from 'react';
import { useNotify, Loading } from 'react-admin';
import _get from 'lodash/get';
import qs from 'query-string';
import {
  BarCard,
  HeaderCard,
  Incomplete,
  Complete,
} from '@/components/Request';
import { getSmart, SaveData, showReq } from '@/actions';
import { ParseRequestDetail, ParseRequest } from '@/actions/ParseData';

import styles from './styles';

export default ({ location }) => {
  const cls = styles();
  const notify = useNotify();

  const [state, setState] = React.useState({
    loading: true,
    DATA: [],
    headerData: {},
    id: '',
  });

  React.useEffect(() => {
    let Fun = showReq;
    const { id, type } = qs.parse(_get(location, 'search', null));

    if (id) {
      if (type === 'smart') Fun = getSmart;
      Fun(id)
        .then((res) => {
          if (type === 'manual') res = [res];
          setState({
            DATA: ParseRequest(res),
            headerData: ParseRequestDetail(res[0]),
            loading: false,
            id,
          });
        })
        .catch((err) => {
          notify(err, 'warning');
          setState({
            DATA: [],
            loading: false,
            id,
          });
        });
    }
  }, [location, notify]);

  if (state.loading) return <Loading />;

  let filtered = {
    Incomplete: state.DATA.filter((i) => i.stat === 'In progress'),
    Complete: state.DATA.filter(
      (i) => i.stat === 'Complete' || i.stat === 'Cancled'
    ),
  };

  const Chat = (id, mode) =>
    SaveData({ Chat: { id, type: 'request', mode }, ChatItem: null });

  return (
    <div className={cls.content}>
      <div className={cls.headerBar}>
        <span className={cls.title}>My Requests/{state.id}</span>
      </div>

      <div className={cls.card}>
        <HeaderCard DATA={state.headerData} />

        <BarCard title="In progress" color="blue" white>
          {filtered.Incomplete.map((i) => (
            <Incomplete DATA={i} key={i.id} Chat={Chat} />
          ))}
        </BarCard>

        <BarCard title="Completed" color="green" white>
          {filtered.Complete.map((i) => (
            <Complete DATA={i} key={i.id} />
          ))}
        </BarCard>
      </div>
    </div>
  );
};
