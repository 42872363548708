import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: '#dfdfdf',
    margin: '5px 0 10px',
    maxHeight: '72%',
    zIndex: 9999999,
  },
  exportChassisSelect: {
    display: 'flex',
  },
  activeChassis: {
    backgroundColor: '#f8b100 !important',
    color: '#000 !important',
    '&:hover': {
      background: '#ffd616 !important',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'cneter',
    justifyContent: 'space-between',
  },
  rowRound: {
    overflow: 'hidden',
    width: '100%',
    height: 20,
    display: 'flex',
    flexDirection: 'row',
    borderRadius: 5,
    backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.27,
    color: '#9f9f9f',
    cursor: 'pointer',
    ['@media (max-width:450px)']: {
      marginLeft: 0,
      marginTop: 5,
      width: '100%',
    },
  },
  exportNumber: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 10px',
    background: ' #007fff',
    color: '#fff',
    fontSize: '14px',
    borderBottom: '1px solid #fff',
    alignItems: 'center',
    height: '28px',
  },
  chassisText: {
    textAlign: 'center',
    display: 'flex',
    color: '#000',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    padding: 5,
    width: '35px',
    '&:hover': {
      background: '#edecec',
    },
    '&:first-child': {
      borderRight: '2px solid #000000',
      borderRadius: '5px 0 0 5px',
    },
    '&:last-child': {
      borderRadius: '0 5px 5px 0',
    },
  },
  content: {
    padding: '10px 15px 5px',
  },
  row: {
    display: 'flex',
  },
  rowCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  but: {
    width: 80,
    height: 30,
    fontSize: 11,
    fontFamily: 'Averta Bold',
    lineHeight: 1.2,
    color: '#fff',
    fontWeight: 600,
    backgroundColor: '#44a570',
    borderRadius: 5,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#44a570',
    },
  },
  butCont: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginBottom: 10,
    animation: 'zoomIn 0.5s',
  },
  row2: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 10,
    fontFamily: 'Averta light',
    fontWeight: 300,
    color: '#303030',
    margin: '5px 0',
  },
  header: {
    fontSize: 12,
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    lineHeight: 1.3,
    color: '#303030',
    textAlign: 'center',
    marginBottom: 10,
  },
  title: {
    fontSize: 12,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.3,
    color: '#303030',
  },
  icon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    margin: '0 5px',
  },
  hr: {
    height: 2,
    backgroundColor: '#b6b1b1',
    borderRadius: 2,
    margin: 10,
  },
  loading: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.3)',
    backgroundColor: '#dfdfdf',
    margin: '5px 0 10px',
    minHeight: 150,
  },
  inputCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 15px 10px',
    animation: 'zoomIn 0.5s',
  },
  input: {
    marginLeft: 10,
    width: 50,
    height: 30,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      padding: '0 5px 0 10px',
      fontSize: 11,
    },
  },
  label: {
    marginBottom: '2px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 30,
    fontSize: 11,
    fontFamily: 'Averta Bold',
    lineHeight: 1.2,
    color: '#fff',
    fontWeight: 600,
    backgroundColor: '#f8b100',
    cursor: 'default',
    animation: 'zoomIn 0.5s',
    padding: '0 10px',
    boxSizing: 'border-box',
    textAlign: 'center',
  },
  green: {
    backgroundColor: '#44a570',
  },
  red: {
    backgroundColor: '#e4464b',
  },
  blue: {
    backgroundColor: '#007fff',
  },
  but2: {
    height: 30,
    fontSize: 10,
    fontFamily: 'Averta Bold',
    lineHeight: 1.2,
    color: '#fff',
    fontWeight: 600,
    backgroundColor: '#f8b100',
    borderRadius: 5,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#f8b100',
    },
  },

  redBut: {
    backgroundColor: '#e4464b',
    '&:hover': {
      backgroundColor: '#e4464b',
    },
  },

  selectedContainers: {
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.3)',
    background: '#ffffff',
    padding: '10px',
    boxSizing: 'border-box',
    height: '65%',
    // marginTop: '-10px',
    '@media (max-width:1000px)': {
      height: '53%',
    },
    '@media (max-width:500px)': {
      height: '57%',
    },
    '& button': {
      background: '#007FFF',
      color: '#ffffff',
      display: 'block',
      margin: '10px 15px 10px auto',
      '&:hover': {
        background: '#007FFF',
      },
    },
  },

  containersTableContainer: {
    width: '100%',
    minHeight: '100px',
    maxHeight: '200px',
    background: '#FFFFFF',
    overflow: 'auto',
    '@media (max-width:1000px)': {
      height: '100px',
    },
  },

  containersTable: {
    width: '100%',
    fontSize: '13px',
    fontWeight: 'normal',

    '& tr': {
      display: 'flex',
    },
    '& th': {
      color: '#777575',
    },
    '& td': {
      color: '#000000',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: '1px solid #dedede',
      height: '27px',

      '& label': {
        margin: 0,
      },
    },
  },

  checkbox: {
    '& svg': {
      fill: '#000000',
      width: 20,
      height: 20,
    },
    '&:hover': {
      background: '#f0efef !important',
      color: '#f0efef !important',
    },
  },
  editDiv: {
    width: '21px',
    height: '25px',
    marginTop: '-27px',
    position: 'relative',
  },
}));
