import React from 'react';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import mm from 'moment';
import {
  StatCard,
  OrderList,
  RequestList,
  Inventory,
} from '@/components/Report';

import DateInput from '@/components/DateInput';
import { getReportByDate } from '@/actions';
import { timeStamp } from '@/actions/Helper';
import styles from './styles';

export default (props) => {
  const cls = styles();

  const [state, setState] = React.useState({
    from: mm().startOf('month').format('MM/DD/YYYY'),
    to: mm().endOf('month').format('MM/DD/YYYY'),
    DATA: {},
    loading: true,
  });

  React.useEffect(() => {
    RefreshData();
  }, []);

  const RefreshData = () => {
    setState({ ...state, loading: true });
    let req = {
      start_date: timeStamp(state.from),
      end_date: timeStamp(state.to),
    };
    getReportByDate(req)
      .then((DATA) => {
        setState({ ...state, DATA, loading: false });
      })
      .catch(() => {
        setState({ ...state, DATA: {}, loading: false });
      });
  };

  const Search = () => {
    RefreshData();
  };

  return (
    <div className={cls.container}>
      <div className={cls.headerBar}>
        <span className={cls.title}>Report</span>
      </div>
      <div className={cls.content}>
        <StatCard />
        <Inventory />
        <Grid container spacing={2} className={cls.searchBar}>
          <Grid item xs={12} lg={4}>
            <span className={cls.title}>Choose report range</span>
          </Grid>
          <Grid item xs={12} sm={8} lg={6}>
            <div className={cls.form}>
              <DateInput
                placeholder="Start"
                className={cls.date}
                format="MM/dd/yyyy"
                maxDate={state.to}
                value={state.from}
                onChange={(from) => setState({ ...state, from })}
              />
              <span className={cls.spacer}>To</span>
              <DateInput
                placeholder="Finish"
                className={cls.date}
                format="MM/dd/yyyy"
                minDate={state.from || null}
                value={state.to}
                onChange={(to) => setState({ ...state, to })}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={4} lg={2}>
            <Button className={cls.but} onClick={Search}>
              Search Result
            </Button>
          </Grid>
        </Grid>
        {state.loading ? (
          <div className={cls.loading}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <RequestList data={state.DATA.req} />
            <OrderList data={state.DATA.ord} />
          </>
        )}
      </div>
    </div>
  );
};
