import React, { useEffect } from 'react';
import { Features, Offers, Landing, Steps, AppBar } from '@/components/Landing';
import HomeLayout from '@/components/HomeLayout';

import styles from './styles';

export default ({ history }) => {
  const cls = styles();
  return (
    <HomeLayout loginBut className={cls.list}>
      <Landing history={history} />
      <Offers history={history} />
      <Features />
      <Steps />
      <AppBar />
    </HomeLayout>
  );
};
