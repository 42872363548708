import React, { useEffect, useState } from 'react';
import { FormControlLabel, Checkbox, Button } from '@material-ui/core';
import styles from '../styles';
import { addChat } from '@/actions';

export default ({
  containers,
  orderID,
  selectedContainers,
  setSelectedContainers,
  setShowContainers,
  accepted_count,
  notify,
  id,
  containerNumers,
  setChat,
  parseData,
  user,
}) => {
  const cls = styles();
  const [activeContainers, setActiveContainers] = useState(containers);
  // console.log('activeContainers', activeContainers);
  const handleSelectContainer = (
    e,
    container_name,
    container_id,
    container_chases,
    own_chases
  ) => {
    if (!selectedContainers && e.target.checked) {
      notify(`You Can Select Just ${accepted_count} Containers`, 'warning');
    } else {
      if (e.target.checked) {
        setSelectedContainers((prev) => {
          return [
            ...prev,
            {
              name: container_name || 'Not Applicable',
              chases: container_chases || 'Not Applicable',
              own_chases: own_chases,
              id: container_id,
            },
          ];
        });
      } else {
        let adaptedContainers = selectedContainers.filter(
          (container) => container.id !== container_id
        );
        setSelectedContainers(adaptedContainers);
      }
    }
  };
  const handleShareContainers = () => {
    if (
      // selectedContainers.length <= accepted_count &&
      selectedContainers.length !== 0
    ) {
      // console.log(selectedContainers);
      let messageContainers = [`# - Container# - Chassis# - Chassis Type`];
      selectedContainers.forEach((container, index) => {
        messageContainers.push(
          `${index + 1} - ${container.name} - ${container.chases} - ${
            container.own_chases == 1
              ? 'Own'
              : container.own_chases == 0
              ? 'Pool'
              : ''
          }`
        );
      });
      setChat(({ DATA = [], otherPart }) => ({
        otherPart,
        DATA: DATA.concat(
          parseData(
            [
              {
                message: messageContainers.join(','),
                humans_time: 'Now',
                me: user.id,
                sender: {
                  name: user.name,
                  image: user.image,
                },
                user_id: user.id,
              },
            ],
            user
          )
        ),
      }));
      addChat(id, messageContainers.join(',')).then(() => {});
      setShowContainers(false);
      // let formContainers = selectedContainers.map((container) => {
      //   return container.id;
      // });
      localStorage.setItem(id, JSON.stringify(selectedContainers));
    } else if (accepted_count.length === 0) {
      notify(`Please select a minimum of one container.`, 'warning');
    } else {
      notify(`Please select a minimum of one container.`, 'warning');
    }
  };
  // const selectedContainersByID = selectedContainers?.map((item, index) => {
  //   let selectedContainerID = item.id;
  //   return [selectedContainerID];
  // });

  // console.log(selectedContainers, 'selectedContainers');

  // console.log(formContainers, 'ByID');

  const getActiveContainers = () => {
    let adaptedContainers = containers.filter((container) => {
      return container.status === 1;
    });

    setActiveContainers(adaptedContainers);
  };

  useEffect(() => {
    getActiveContainers();
  }, [containers]);

  return (
    <div className={cls.selectedContainers}>
      <div className={cls.containersTableContainer}>
        <table className={cls.containersTable}>
          <tr>
            <th style={{ flex: '0.3' }}>#</th>
            <th style={{ flex: '1' }}>Container#</th>
            <th style={{ flex: '1' }}>Chassis#</th>
            <th style={{ flex: '0.5' }}>Type</th>
            <th style={{ flex: '0.3' }}>Status</th>
          </tr>
          <tbody>
            {activeContainers.map((container, index) => {
              return (
                <tr key={index}>
                  <td style={{ flex: '0.3' }}>{index + 1}</td>
                  <td style={{ flex: '1' }}>
                    {container.name || 'Not Applicable'}
                  </td>
                  <td style={{ flex: '1' }}>
                    {container.chases || 'Not Applicable'}
                  </td>
                  <td style={{ flex: '0.4' }}>
                    {container.own_chases === 1 ? 'Own' : 'Pool'}
                  </td>
                  <td style={{ flex: '0.3' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            selectedContainers.find(
                              (selectedContainer) =>
                                selectedContainer.id == container.id
                            )
                              ? true
                              : false
                          }
                          className={cls.checkbox}
                          // disabled={selectedContainers.length === accepted_count}
                          onChange={(e) =>
                            handleSelectContainer(
                              e,
                              container.name,
                              container.id,
                              container.chases,
                              container.own_chases
                            )
                          }
                        />
                      }
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {containerNumers === 1 && (
        <Button onClick={() => handleShareContainers()}>Share</Button>
      )}
    </div>
  );
};
