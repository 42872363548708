import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useNotify } from 'react-admin';
import { CircularProgress } from '@material-ui/core';
import { getFirstMarket, Request } from '@/actions';
import { LoggedIn } from '@/actions/Helper';
import Card from './Card';
import styles from './styles';

const sliderSetting = {
  autoplay: true,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  arrows: true,
  slidesToScroll: 5,
  responsive: [
    {
      breakpoint: 1700,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
      },
    },
    {
      breakpoint: 1370,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1020,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 700,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export default ({ history }) => {
  const cls = styles();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(true);
  const [DATA, setData] = React.useState([]);
  const isMounted = React.useRef(true); // Create a ref to track whether the component is mounted

  React.useEffect(() => {
    return () => {
      // Set the isMounted flag to false when the component is unmounted
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    getFirstMarket()
      .then((res) => {
        // Check if the component is still mounted before updating state
        if (isMounted.current) {
          setData(res);
          setLoading(false);
        }
      })
      .catch((err) => {
        // Check if the component is still mounted before updating state
        if (isMounted.current) {
          setLoading(false);
        }
      });
  }, []);

  const req = ({ id, count }) => {
    if (LoggedIn())
      Request({
        inventory_id: id,
        count,
      })
        .then((res) => notify(res))
        .catch((err) => notify(err, 'warning'));
    else {
      notify('Please login', 'warning');
      history.push('/login');
    }
  };

  return (
    <>
      <div className={cls.container}>
        <div className={cls.title}>Latest Offers</div>
        {loading ? (
          <div className={cls.loading}>
            <CircularProgress />
          </div>
        ) : (
          <Slider {...sliderSetting}>
            {DATA.map((i, idx) => (
              <div key={idx}>
                <Card data={i} req={req}  />
              </div>
            ))}
          </Slider>
        )}
      </div>
    </>
  );
};
