import React from 'react';
import { Card, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { styles, ChartOptions } from './styles';

export default (props) => {
  const cls = styles();
  const [state, setState] = React.useState({
    labels: [],
    ordDATA: [],
    reqDATA: [],
    comDATA:[],
    loading: true,
  });

  const ChartData = {
    labels: state.labels,
    datasets: [
      {
        label: 'Accumulative Profit',
        barThickness: 30,
        backgroundColor: '#F8B100',
        // borderWidth: 2,
        // borderColor: '#f9bb05',
        // hoverBackgroundColor: '#49494955',
        data: state.ordDATA,
      },
      {
        label: 'Profit',
        barThickness: 40,
        backgroundColor: '#2BA672',
        data: state.reqDATA,
      },
    ],
  };

  // if (state.loading)
  //   return (
  //     <Card className={clsx(cls.card, cls.center)}>
  //       <CircularProgress />
  //     </Card>
  //   );

  return (
    <Card className={cls.card}>
      <div className={clsx(cls.row)}>
        <span className={cls.title}>Transaction Report</span>
        {/* <Link to="/report" className={cls.see}>
          ...
        </Link> */}
      </div>

      <div className={cls.chartCont}>
        <Bar data={ChartData} options={ChartOptions} />
      </div>
    </Card>
  );
};
