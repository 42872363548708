import React from 'react';
import { Grid } from '@material-ui/core';
import { useNotify, Loading } from 'react-admin';
import { useSelector } from 'react-redux';
import { Complete } from '@/components/Request';
import { Load, Filter } from '@/components/Global';
import { getReq, SaveData } from '@/actions';
import { ParseRequestComplete } from '@/actions/ParseData';

import styles from './styles';

const perPage = 8;

export default (props) => {
  const cls = styles();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(true);
  const [DATA, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });

  const [page, setPage] = React.useState(1);
  const disableLoad = () => {
    if (page * perPage < DATA.length) return false;
    else return true;
  };
  const RefreshPage = useSelector((state) => state.reducer.refreshPage || '');
  const RefreshData = React.useCallback(() => {
    getReq('complete')
      .then((res) => {
        let DATA = ParseRequestComplete(res);
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  }, [notify]);

  React.useEffect(() => {
    if (RefreshPage === 'request') {
      RefreshData();
      SaveData({ refreshPage: '' });
    }
  }, [RefreshPage, RefreshData]);

  React.useEffect(() => {
    RefreshData();
  }, [RefreshData]);

  const getData = React.useCallback(
    () => filter.DATA.slice(0, page * perPage),
    [filter.DATA, page]
  );

  return (
    <div className={cls.content}>
      <Filter
        title="My Requests/Completed"
        filter={filter}
        setFilter={setFilter}
        DATA={DATA}
      />

      <div className={cls.list}>
        {loading ? (
          <Loading />
        ) : (
          <Grid container justify="flex-start" spacing={2}>
            {getData().map((i, idx) => (
              <Grid item xs={12} sm={6} lg={3} xl="auto" key={idx}>
                <Complete RefreshData={RefreshData} DATA={i} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
      <Load onLoad={() => setPage(page + 1)} disable={disableLoad()} />
    </div>
  );
};
