import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 1,
    color: '#43424b',
  },
  headerBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '10px 40px 20px 40px',
  },

  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
  },

  searchBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '20px 10px 10px 40px',
    ['@media (max-width:1440px)']: {
      padding: '20px 10px 10px 10px',
    },
  },

  but: {
    width: 133,
    height: 40,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Bold',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.86,
    color: '#303030',
    padding: 0,
    // marginLeft: 40,
    marginLeft:'auto',
    display:'block',
    '&:hover': {
      backgroundColor: '#ffd616',
    },
    ['@media (max-width:1440px)']: {
      width: '100%',
    },
  },
  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 40,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    fontSize: 12,
    padding: '0 10px',
    border: 'solid 1px',
    '& input': {
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'Averta Light',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },
  spacer: {
    margin: '0 20px',
  },
  loading: {
    display: 'flex',
    height: 900,
    alignItems: 'center',
    justifyContent: 'center',
  },
  form:{
    display:'flex',
    alignItems:'center',
    justifyContent:'center'
  }
}));
