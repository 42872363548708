import React from 'react';
import { Send } from '@material-ui/icons';
import { CircularProgress } from '@material-ui/core';
import 'emoji-mart/css/emoji-mart.css';
import Emojies from 'emoji-mart/data/apple.json';
import { Picker } from 'emoji-mart';
import styles from './styles';
import { adminSendWhatsappMessage } from '@/actions';

export default ({ selectedUser, setChat, notify }) => {
  const [state, setState] = React.useState({
    msg: '',
    loading: false,
    emoji: false,
    file: null,
  });

  const cls = styles({ emoji: state.emoji });

  const handleSendMessage = (message) => {
    const apiData = {
      message,
      to: selectedUser,
      _method: 'put',
    };
    console.log(apiData);
    adminSendWhatsappMessage(apiData)
      .then(() => {
        setState({ loading: false });
      })
      .catch((err) => notify(err, 'warning'));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && e.target.value) {
      setState({ loading: true });
      setChat((prev) => {
        return {
          ...prev,
          DATA: [...prev.DATA, { message: e.target.value, is_me: 1 }],
        };
      });
      handleSendMessage(e.target.value);
      // fun(id, state.msg, (mode = '')).then(() => {
      //   setState((st) => ({ ...st, msg: '', loading: false }));
      //   // SaveData({ refresh: true });
      //   // SaveData({ refreshChat: true });
      // });
    }
  };
  const handlesendMsg = (e) => {
    const msgtext = document.getElementById('sendmsginput').value;
    console.log('msgtext', msgtext);
    setState({ loading: true });
    setChat((prev) => {
      return {
        ...prev,
        DATA: [...prev.DATA, { message: msgtext, is_me: 1 }],
      };
    });
    handleSendMessage(msgtext);
    // fun(id, state.msg, (mode = '')).then(() => {
    //   setState((st) => ({ ...st, msg: '', loading: false }));
    //   // SaveData({ refresh: true });
    //   // SaveData({ refreshChat: true });
    // });
  };

  return (
    <div className={cls.container}>
      <div className={cls.chat}>
        <Picker
          set="apple"
          data={Emojies}
          showPreview={false}
          showSkinTones={false}
          emojiSize={20}
          perLine={7}
          title="Emoji"
          sheetSize={32}
          include={['people', 'smileys', 'flags', 'activity', 'symbols']}
          onSelect={({ native }) =>
            setState({ ...state, msg: state.msg + native })
          }
          native
        />
      </div>

      {/* <SentimentSatisfiedRounded
        className={cls.emoji}
        onClick={() => setState({ ...state, emoji: !state.emoji })}
      /> */}
      {state.loading ? (
        <CircularProgress size={20} />
      ) : (
        <input
          id="sendmsginput"
          autoFocus
          className={cls.input}
          placeholder="Type your message"
          value={state.msg}
          onChange={(e) => setState({ ...state, msg: e.target.value })}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      )}
      <label className={cls.camera}>
        <Send onClick={(e) => handlesendMsg(e)} />
        {/* <ArrowForwardIosIcon className={cls.icon} /> */}
      </label>
    </div>
  );
};
