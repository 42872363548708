import React from 'react';
import { Avatar } from '@material-ui/core';
import _get from 'lodash/get';
import { fTimeDate } from '@/actions/Helper';
import { CheckRounded } from '@material-ui/icons';
import styles from './styles';

export default ({ DATA = {}, Open }) => {
  const cls = styles();
  console.log('DATAnotif', DATA);
  return (
    <div className={cls.card} onClick={() => Open(DATA)}>
      <div className={cls.row}>
        <Avatar
          className={cls.avatar}
          src={_get(DATA, 'data.user.profile', '')}
          alt="User"
        />

        <div className={cls.flex}>
          <div className={cls.row2}>
            <div className={cls.title}>{DATA.title}</div>
            <div className={cls.date}>{fTimeDate(DATA.created_at)}</div>
          </div>
          <div className={cls.des}>
            {DATA.body.length > 130
              ? DATA.body.slice(0, 85) + ' ...'
              : DATA.body}
          </div>
          {/* <div className={cls.des}>{DATA.body.slice(0, 85) + ' ...'}</div> */}
          {DATA.seen ? <CheckRounded className={cls.seen} /> : null}
          {DATA.type == 'chat' ? (
            <span className={cls.opponentName}>
              From : {DATA.data.opponent.name}
            </span>
          ) : DATA.type == 'whatsapp-messages' ? (
            <span className={cls.opponentName}>
              From : {DATA.data.message.from_name} / {DATA.data.message.from}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};
