import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  col1: {
    flex: 7.5,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
  },
  col2: {
    flex: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  row: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  updatePopup: {
    backgroundColor: '#000000aa',
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: '99999',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backdropFilter: 'blur(10px)',
  },
  popupCard: {
    width: '700px',
    background: '#ffffff',
    position: 'relative',
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxSizing: 'border-box',
    alignItems: 'center',
  },
  updateBtn: {
    background: '#3c845d',
    borderRadius: '5px',
    border: 'none',
    color: '#ffffff',
    fontSize: '13px',
    padding: '10px 15px',
    display: 'block',
    width: 200,
    margin: '15px auto',
    fontSize: 20,
  },
  updateText: {
    fontWeight: 'bold',
    fontSize: 36,
    color: '#000000',
    margin: 10,
  },
}));
