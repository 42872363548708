import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  card: {
    position: 'relative',
    padding: '25px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxHeight: '75%',
    // maxWidth: '100%',
    animation: 'zoomIn 0.7s',
    height: 'auto',
    width: 400,
  },

  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#e4464b',
  },

  inputContainer: {
    marginTop: '10px',
    marginBottom: '10px',
    '& label': {
      fontSize: '14px',
      marginBottom: '5px',
      display: 'block',
    },
  },

  form: {
    display: 'grid',
    gridGap: '0 14px',
    gap: '0 16px',
    gridTemplateColumns: 'auto auto',
    padding: '16px 0',
  },

  avatar: {
    marginRight: 'auto',
    marginLeft: 'auto',
    width: '90px',
    height: '90px',
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    boxShadow: '2px 6px 20px -3px #00000050',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },

  but: {
    background: '#f8b100',
    borderRadius: '3px',
    width: '140px',
    height: '31px',
    color: '#ffffff',
    '&:hover': {
      background: '#f8b100',
      color: '#ffffff',
    },
  },
  userAvatar: {
    display: 'none',
  },
  addAvatarLable: {
    borderRadius: '3px',
    color: '#008eff',
    padding: '5px 10px',
    fontSize: '14px',
    margin: '15px auto 0px auto',
    display: 'block',
    width: 'fit-content',
    cursor: 'pointer',
  },
}));
