import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 1.5vw',
    marginBottom: 30,
    '& * ': {
      cursor: 'default',
    },
  },
  card: {
    padding: '20px 15px',
    height: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  cardCont: {
    minWidth: '17vw',
    height: 120,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 5,
  },
  ml20: {
    marginLeft: 20,
  },
  hr: {
    height: 1,
    backgroundColor: '#9f9f9f',
    borderRadius: 2,
    marginTop: 4,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 10,
    fontFamily: 'Averta Bold',
    lineHeight: 1.5,
    color: '#303030',
    fontWeight: 'bold',
  },
  col: {
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  light: {
    fontFamily: 'Averta Light',
    fontWeight: '300',
    color: '#43424b',
  },
  blue: {
    color: '#007fff',
    lineHeight: 1.8,
  },
  green: {
    color: '#44a570',
    lineHeight: 1.8,
  },
  gray: {
    color: '#5e5e5e',
    lineHeight: 1.8,
  },
  title: {
    lineHeight: 1.8,
    fontSize: 30,
    fontFamily: 'Averta Bold',
    textAlign: 'center',
    color: '#303030',
    fontWeight: 'bold',
    '@media (max-width:600px)': {
      fontSize: '20px',
    },
  },
  bigText: {
    fontSize: 30,
    fontFamily: 'Averta Bold',
    fontWeight: 800,
    lineHeight: 1,
  },
  bigRow: {
    alignItems: 'flex-start',
  },
  img: {
    width: 120,
    height: 60,
    marginRight: 20,
    '@media (max-width:600px)': {
      width: 100,
      height: 50,
    },
  },
});
