import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  pagination: {
    display: 'flex',
    alignItems: 'cneter',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: 15,
    '& button': {
      '&:hover': {
        background: 'transparent',
      },
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      margin: '0 15px',
      height: '2rem',
      cursor: 'pointer',
      color: '#43424b',
    },
  },
  activePage: {
    background: '#000000',
    color: '#ffffff !important',
    minWidth: '2rem',
    padding: '0 6px',
  },
});
