import { adminGetReferredList, getAdminMembers } from '@/actions';
import React, { useState } from 'react';
import List from './List';
import styles from './styles';
import { useNotify, Loading } from 'react-admin';
import { ParseAdminMembers } from '@/actions/ParseData';
import Pagination from '@/components/pagination';

export default () => {
  const cls = styles();
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = React.useState({
    loading: false,
    DATA: [],
  });
  const [form, setForm] = React.useState(null);
  const [filter, setFilter] = React.useState(false);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pages: [1],
  });
  const notify = useNotify();

  const createArrayFromNumbers = (number) => {
    let numberArray = [];
    for (let i = 1; i <= number; i++) {
      numberArray.push(i);
    }

    return numberArray;
  };

  const getReferredCompany = () => {
    setCompanies((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    adminGetReferredList()
      .then((res) => {
        let DATA = res.data;
        setCompanies((prev) => {
          return {
            loading: false,
            DATA: DATA,
          };
        });
        setPagination((prev) => {
          return {
            ...prev,
            pages: createArrayFromNumbers(Math.ceil(res.total / res.per_page)),
          };
        });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  React.useEffect(() => {
    setLoading(true);
    getReferredCompany();
  }, [notify, pagination.currentPage]);

  return (
    <div className={cls.membersMainContainer}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <List
            filter={filter}
            setFilter={setFilter}
            setFilterForm={setForm}
            setDATA={setCompanies}
            getReferredCompany={getReferredCompany}
            DATA={companies.DATA}
            notify={notify}
            setLoading={setLoading}
          />
          <Pagination pagination={pagination} setPagination={setPagination} />
        </>
      )}
    </div>
  );
};
