import React from 'react';
import { Card, CardContent, Button, Avatar } from '@material-ui/core';
import { AccountCircle, HomeRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { fDate } from '@/actions/Helper';
import styles from './styles';

export default ({ DATA = {}, Cancel, Chat }) => {
  const cls = styles();

  return (
    <Card className={cls.card}>
      <div className={cls.header}>
        <span>{'ID: ' + DATA.id}</span>
        {/* <span>{'Status: ' + DATA.stat}</span> */}
        <span> {DATA.mode.charAt(0).toUpperCase() + DATA.mode.slice(1)}</span>
      </div>
      <CardContent className={cls.content}>
        <div className={clsx(cls.row, cls.bigRow)}>
          <span>Your request</span>
          <span className={cls.bigText}>{DATA.count}</span>
        </div>
        <div className={cls.row}>
          <span className={cls.light}>Date:</span>
          <span>{fDate(DATA.date)}</span>
        </div>
        <div className={cls.row}>
          <span className={cls.light}>Location:</span>
          <span>{DATA.loc}</span>
        </div>
        <div className={cls.row}>
          <span className={cls.light}>Size:</span>
          <span>{DATA.size}</span>
        </div>

        <div className={cls.row}>
          <span className={cls.light}>SSL:</span>
          <span>{DATA.ssl}</span>
        </div>
        <div className={cls.hr} />

        <div className={clsx(cls.row, cls.row2)}>
          <span>Accepted Number</span>
          <span>{DATA.num}</span>
        </div>

        <div className={cls.hr} />

        <div className={clsx(cls.row, cls.row3)}>
          <span>Company profile</span>
          <div>
            <div className={cls.row}>
              <Avatar
                className={cls.avatar}
                src={DATA.avatar}
                alt={DATA.user}
              />
              <div>
                <div className={cls.row}>
                  <AccountCircle className={cls.icon} />
                  <span className={cls.light2}>{DATA.user}</span>
                </div>
                <div className={cls.row}>
                  <HomeRounded className={cls.icon} />
                  <span className={cls.light2}>{DATA.company}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={cls.butCont}>
          <Button
            className={cls.but}
            onClick={() => {
              Chat(DATA.id, DATA.mode);
            }}>
            Chat
          </Button>
          <Button
            className={clsx(cls.but, cls.red)}
            onClick={() => Cancel(DATA.id, DATA.mode)}>
            Cancel
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
