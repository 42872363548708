import { makeStyles } from '@material-ui/core/styles';
import { Icons } from '@/assets';

export default makeStyles(({ breakpoints: BP }) => ({
  card: {
    height: 190,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    // backgroundColor: '#f8b100',
    background: 'url(' + Icons.welcome + ')',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    margin: '10px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 40px 0 80px',
    boxSizing: 'border-box',
  },
  title: {
    fontFamily: 'Averta Bold',
    fontSize: 20,
    fontWeight: 900,
    lineHeight: 1.92,
    color: '#fff',
    [BP.up('xl')]: {
      fontSize: 26,
    },
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },

  icon: {
    width: 350,
    height: 170,
    objectFit: 'contain',
  },
}));
