import { makeStyles } from '@material-ui/core/styles';

const COLORS = {
  yellow: '#f8b100',
  green: '#44a570',
  black: '#303030',
  blue: '#007fff',
};

export default makeStyles({
  content: {
    padding: '0 15px',
  },
  bar: {
    borderRadius: 5,
    boxShadow: '0 0 44px 0 rgba(49, 56, 150, 0.03)',
    backgroundColor: (props) => COLORS[props.color],
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
    padding: '0 70px',
  },
  title: {
    lineHeight: '50px',
    fontFamily: 'Averta Bold',
    fontSize: 20,
    fontWeight: 'bold',
    color: (props) => (props.white ? '#fff' : '#303030'),
    cursor: 'default',
  },
  icon: {
    height: 40,
    width: 40,
    color: (props) => (props.white ? '#fff' : '#303030'),
    cursor: 'pointer',
  },
});
