import React, { useEffect, useState } from 'react';
import styles from '../styles';
import { Tooltip, IconButton } from '@material-ui/core';
import { fDate } from '@/actions/Helper';
import { useNotify } from 'react-admin';
import { DeleteRounded } from '@material-ui/icons';
import { adminDelInventoryCompleted } from '@/actions';

export default ({ DATA = [], currentPage, getInventories, isCompleted }) => {
  const [showContainersModal, setShowContainersModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [state, setState] = React.useState({
    edit: null,
    file: null,
  });
  const cls = styles();
  const notify = useNotify();

  const closePopup = () => {
    setState({ ...state, edit: null });
  };

  const openPopUp = (item) => {
    setState({ ...state, edit: item });
  };

  const getActivContainers = (inventory, target) => {
    if (inventory) {
      let activeContainers = [];
      activeContainers = inventory.containers.filter((container) => {
        return container.status === 1;
      });

      if (activeContainers.length === inventory.count_all) {
        return (
          <input
            type="checkbox"
            className={`all ${cls.containerChecBox}`}
            checked={true}
          />
        );
      } else if (activeContainers.length === 0) {
        return (
          <input
            type="checkbox"
            className={`any ${cls.containerChecBox}`}
            checked={false}
          />
        );
      } else {
        return (
          <input
            type="checkbox"
            checked={true}
            className={`some ${cls.containerChecBox}`}
          />
        );
      }
    }
  };

  const handleDeleteInventoryCompleted = (id) => {
    adminDelInventoryCompleted(id)
      .then((data) => {
        getInventories();
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    let someCompletedCheckboxs = document.getElementsByClassName('some');
    let allCompletedCheckboxs = document.getElementsByClassName('all');
    let notCompletedCheckboxs = document.getElementsByClassName('any');
    for (let i = 0; i < someCompletedCheckboxs.length; i++) {
      someCompletedCheckboxs[i].indeterminate = true;
    }
    for (let i = 0; i < allCompletedCheckboxs.length; i++) {
      allCompletedCheckboxs[i].indeterminate = false;
    }
    for (let i = 0; i < notCompletedCheckboxs.length; i++) {
      notCompletedCheckboxs[i].indeterminate = false;
      notCompletedCheckboxs[i].checked = false;
    }
  }, [getActivContainers, getInventories]);

  return (
    <>
      <div className={cls.listContainer}>
        <table className={cls.list}>
          <thead className={cls.thead}>
            <th style={{ flex: 0.35 }}>#</th>
            <th style={{ flex: 1.5 }}>Seller Company</th>
            <th style={{ flex: 1.5 }}>Buyer Company</th>
            <th style={{ flex: 1 }}>Size</th>
            <th style={{ flex: 0.7 }}>SSL</th>
            <th style={{ flex: 0.7 }}>Number</th>
            <th style={{ flex: 1.2 }}>Location</th>
            <th style={{ flex: 1 }}>Created at Date</th>
            <th style={{ flex: 1 }}>Last Update</th>
            <th style={{ flex: 0.7 }}>Action</th>
          </thead>
          <tbody className={cls.tbody}>
            {DATA.map((i, idx) => (
              <tr className={cls.tableRow} key={idx}>
                <td style={{ flex: 0.35 }}>
                  {idx + 1 + (currentPage - 1) * 10}
                </td>
                <td style={{ flex: 1.5 }}>{i.sellerCompany}</td>
                <td style={{ flex: 1.5 }}>{i.buyerCompany}</td>
                <td style={{ flex: 1 }} className={cls.bold}>
                  {i.size}
                </td>
                <td style={{ flex: 0.7 }}>{i.ssl}</td>
                <td style={{ flex: 0.7 }}>{i.active}</td>
                <td style={{ flex: 1.2 }}>{i.loc}</td>
                <td style={{ flex: 1 }}>{fDate(i.date)}</td>
                {/* <td style={{ flex: 1 }}>{i.request}</td> */}
                <td style={{ flex: 1 }}>{fDate(i.up)}</td>
                <td style={{ flex: 0.7 }}>
                  <div className={cls.tableActions}>
                    <Tooltip arrow title="Containers" placement="top">
                      <IconButton
                        onClick={() => {
                          setShowContainersModal(!showContainersModal);
                          setSelectedRow(i);
                        }}>
                        {/* <img src={getActivContainers(i)} /> */}
                        {getActivContainers(i)}
                      </IconButton>
                    </Tooltip>
                    {/* <Tooltip arrow title="Delete" placement="top">
                      <IconButton
                        onClick={() => {
                          handleDeleteInventoryCompleted(i.id);
                        }}>
                        <DeleteRounded className={cls.delIcon} />
                      </IconButton>
                    </Tooltip> */}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};
