import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    display: 'flex',
    width: '100%',
    padding: '30px 10vw',
    boxSizing: 'border-box',
    height: 431,
    backgroundColor: '#5e5e5e',
    [BP.down('md')]: {
      padding: '30px 5vw',
    },
    [BP.down('sm')]: {
      padding: '20px 1rem',
      flexDirection: 'column',
      height: 'auto',
    },
  },
  row: {
    display: 'flex',
    [BP.down('sm')]: {
      justifyContent: 'space-between',
      margin: '20px 0',
    },
  },
  col: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: '0 20px',
    [BP.down('xs')]: {
      padding: 0,
    },
  },

  des: {
    fontSize: 24,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.5,
    color: '#fff',
    textAlign: 'left',
    [BP.down('sm')]: {
      textAlign: 'justify',
      marginTop: 20,
      fontSize: '1.2rem',
    },
  },

  icon: {
    '& div > svg': {
      height: 50,
      width: 150,
      [BP.down('xs')]: {
        height: 40,
        width: 'auto',
      },
    },
  },
  icon2: {
    '& div > svg': {
      height: 50,
      width: 150,
      marginLeft: 20,
      [BP.down('xs')]: {
        height: 40,
        width: 'auto',
        marginLeft: 0,
      },
    },
  },
  img: {
    height: 310,
    objectFit: 'contain',
  },
}));
