import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  content: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '40px 12vw 60px',
    [BP.down('md')]: {
      padding: '40px 20px',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 50,
    color: '#303030',
    borderBottom: 'solid 1px #303030',
    padding: '70px 0',
    [BP.down('md')]: {
      fontSize: 35,
      padding: '40px 0',
    },
  },
  card: {
    display: 'flex',
    flex: 1,
    borderRadius: 20,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    paddingRight: 30,
    alignItems: 'center',
    margin: '40px 0',
    [BP.down('md')]: {
      flexDirection: 'column',
      padding: '0 30px 20px',
    },
  },
  cardContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 30,
  },
  headerRow: {
    display: 'flex',
    marginBottom: 30,
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#a6adb4',
      height: 3,
      borderRadius: 2,
    },
    [BP.down('md')]: {
      flexDirection: 'column',
      '&::after': {
        content: 'none',
      },
    },
  },
  step: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    fontWeight: 300,
    fontSize: 20,
    color: '#303030',
    transition: 'all 0.5s ease-in-out',
    height: 70,
    cursor: 'pointer',
    position: 'relative',
    [BP.down('md')]: {
      flex: 0,
      height: 50,
      fontSize: 16,
      flexBasis: 'auto',
    },
  },

  stepActive: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    color: '#f8b100',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#f8b100',
      height: 3,
      borderRadius: 2,
      zIndex: 10,
      animation: 'zoomIn .5s',
    },
  },
  stepActive2: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    color: '#303030',
    '&::after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: '#303030',
      height: 3,
      borderRadius: 2,
      zIndex: 10,
      animation: 'zoomIn .5s',
    },
  },
  contentRow: {
    display: 'flex',
    transition: 'all .5s ease-in-out',
    color: '#303030',
    fontFamily: 'Averta Light',
    fontWeight: 300,
    fontSize: 14,
    '& > div': {
      transition: 'all .5s ease-in-out',
    },
    [BP.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
  },
  contentImg: {
    borderRadius: 10,
    marginRight: 40,
    maxWidth: 220,
    objectFit: 'contain',
    [BP.down('md')]: {
      height: 120,
      maxWidth: 'none',
      marginRight: 0,
      marginBottom: 20,
    },
  },
  imgCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderRadius: 10,
    backgroundColor: '#f8b100',
    color: '#303030',
    fontSize: 16,
    padding: '20px 60px',
    marginRight: 25,
    marginLeft: 25,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    '& svg': {
      height: 80,
    },
    [BP.down('md')]: {
      fontSize: 14,
      marginRight: 0,
      marginBottom: 20,
    },
  },
  imgCard2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    borderRadius: 10,
    backgroundColor: '#303030',
    color: '#fff',
    fontSize: 16,
    padding: '20px 60px',
    marginRight: 25,
    marginLeft: 25,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    '& svg': {
      height: 80,
    },
    [BP.down('md')]: {
      fontSize: 14,
      marginRight: 0,
      marginBottom: 20,
    },
  },
  title1: {
    fontFamily: 'Averta Light',
    fontWeight: 300,
    marginTop: 15,
  },
  title2: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    marginTop: 7,
  },
}));
