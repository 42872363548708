import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  typography: {
    fontFamily: [
      'Averta',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    button: {
      textTransform: 'none',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1440,
      xl: 1920,
    },
  },

  palette: {
    type: 'light',
  },
  overrides: {
    // targeting refresh button
    RaAppBar: {
      toolbar: {
        '& button': {
          '&:nth-child(7)': {
            display: 'none',
          },
        },
      },
    },
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
    },
    RaLayout: {
      appFrame: {
        marginTop: '70px !important',
      },
      root: {
        backgroundColor: '#f5f5f5',
      },
      content: {
        padding: '0 !important',
      },
    },
  },
});
