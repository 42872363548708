import React from 'react';
import { PlayCircleFilled } from '@material-ui/icons';
import Form from '../Form';
import styles from './styles';

export default ({ history }) => {
  const cls = styles();
  return (
    <div className={cls.container}>
      <div className={cls.flex6}>
        <div className={cls.h2}>Street turns are finally done "Smart"</div>
        <div className={cls.h1}>Find what you need!</div>
        <a className={cls.row} href="https://www.youtube.com/watch?v=WcvLZtnnQeQ">
          <PlayCircleFilled className={cls.icon} />
          <div className={cls.h3}>watch how it works</div>
        </a>
      </div>
      <div className={cls.flex4}>
        <Form history={history} />
      </div>
    </div>
  );
};
