import React from 'react';
import { ProgressBar } from '@/components/Global';
import clsx from 'clsx';
import { fDate, SizeIcons } from '@/actions/Helper';
import styles from './styles';
import {Grid} from '@material-ui/core'

export default ({ DATA = {} }) => {
  // console.log('data', DATA);
  const cls = styles();
  let progPercent = (DATA.acc / DATA.req) * 100;

  return (
    <Grid container spacing={3} className={cls.container}>
      <Grid item xs={12} lg={6}>
        <div className={cls.col}>
          <div className={cls.row}>
            <img
              src={SizeIcons(DATA.size)}
              className={cls.img}
              alt="container"
            />
            <span className={cls.title}>{'Request ' + DATA.id}</span>
          </div>
          <ProgressBar percent={progPercent} max={DATA.req} />
        </div>
      </Grid>

      {/* <div className={cls.row}> */}
      <Grid item xs={12} sm={6} lg={3}>
        <div className={cls.cardCont}>
          <div className={cls.card}>
            <div className={clsx(cls.row, cls.bigRow)}>
              <span>Your requests</span>
              <span className={cls.bigText}>{DATA.req}</span>
            </div>
            <div className={cls.row}>
              <span className={cls.light}>Date:</span>
              <span>{fDate(DATA.date)}</span>
            </div>
            <div className={cls.row}>
              <span className={cls.light}>Location:</span>
              <span>{DATA.loc}</span>
            </div>
            <div className={cls.row}>
              <span className={cls.light}>Size:</span>
              <span>{DATA.size}</span>
            </div>

            <div className={cls.row}>
              <span className={cls.light}>SSL:</span>
              <span>{DATA.ssl}</span>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <div className={clsx(cls.cardCont)}>
          <div className={cls.card}>
            <div className={clsx(cls.row, cls.blue)}>
              <span>Accepted Number</span>
              <span>{DATA.acc}</span>
            </div>
            <div className={cls.hr} />
            <div className={clsx(cls.row, cls.green)}>
              <span>Completed</span>
              <span>{DATA.com}</span>
            </div>
          </div>
        </div>
      </Grid>
      {/* </div> */}
    </Grid>
  );
};
