import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  profileCont: {
    display: 'flex',
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 8,
  },
  menuBtn: {
    fontSize: 15,
    fontWeight: 300,
    letterSpacing: 'normal',
    color: '#ffffff',
    padding: 5,
    fontFamily: 'Averta',
    textTransform: 'none',
    '@media (max-width:600px)': {
      fontSize: '15px',
    },
  },
  menu: {
    margin: '0px 16px',
    '@media (max-width:400px)': {
      display: 'none',
    },
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    display: 'flex',
    fontSize: '16px',
  },
}));
