import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    minWidth: 300,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 5,
    flex: 1,
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    transition: 'all ease 0.3s',
    '&:hover': {
      transition: 'all ease 0.3s',
      boxShadow: '0px 0px 20px 0 rgb(0 0 0 / 31%)',
      scale: '1.02',
    },
  },
  hasContainersIcon: {
    float: 'right',
    position: 'absolute',
    padding: '5px',
  },
  content: {
    padding: 15,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'column',
  },
  headerImg: {
    backgroundColor: '#d6d6d6',
    padding: '10px 0',
    minHeight: 140,
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
    },
    '& > div > svg': {
      height: 140,
      width: '80%',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 14,
    color: '#43424b',
    fontFamily: 'Averta Light',
    fontWeight: 300,
    marginBottom: 10,
  },
  bold: {
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    width: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign:'right'
  },
  capital: {
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    textTransform: 'uppercase',
  },
  but: {
    margin: '5px auto 0',
    width: 100,
    height: 32,
    fontSize: 15,
    fontFamily: 'Averta Light',
    color: '#fff',
    fontWeight: 300,
    backgroundColor: '#007fff',
    borderRadius: 5,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#007fff',
    },
  },
}));
