import { BaseIcon } from '@/Utils';
import React from 'react';
import styles from './styles';
import { MemberIcons as memberIcons } from '@/assets';
import ChatListCard from '@/components/Chat/ChatListCard';
import ChatUser from '@/components/Chat/Request/ChatUser';
import { CircularProgress } from '@material-ui/core';

export default ({ state, Chat, searchingChat, setSearchingChat }) => {
  const cls = styles();
  return !state.loading ? (
    <div className={cls.listContainer}>
      <div className={cls.searchBox}>
        <BaseIcon src={memberIcons.search} className={cls.searchIcon} />
        <input
          className={cls.searchInput}
          type="text"
          placeholder="Search..."
          onChange={(e) => setSearchingChat(e.target.value)}
        />
      </div>
      <div>
        {state.DATA.map((i, idx) => {
          if (
            !searchingChat ||
            (searchingChat &&
              i.user.toUpperCase().includes(searchingChat.toUpperCase()))
          ) {
            return <ChatListCard DATA={i} key={idx} Chat={Chat} />;
          }
        })}
      </div>
    </div>
  ) : (
    <div className={cls.loading}>
      <CircularProgress />
    </div>
  );
};
