import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    // height: 800,
    '@media (max-width:400px)': {
      width: '100%',
    },
  },
  card: {
    padding: '10px 2vw 10px 10px',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 7,
    '@media (max-width:400px)': {
      width: '100%',
      margin: '0',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& span': {
        marginBottom: '5px',
      },
    },
  },
  password: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '16px',
    '@media (max-width:500px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& span': {
        marginBottom: '5px',
        textAlign: 'start',
      },
    },
    '@media (max-width:400px)': {
      padding: '0 12px',
    },
  },
  rowMl: {
    display: 'flex',
    alignItems: 'center',
    // margin: '0 20px 15px 0',
    '@media (max-width:400px)': {
      width: '100%',
      margin: '0',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& span': {
        marginBottom: '5px',
      },
    },
  },
  row2: {
    display: 'flex',
    marginBottom: 25,
    '@media (max-width:400px)': {
      width: '100%',
      margin: '0',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& span': {
        marginBottom: '5px',
      },
      '& div': {
        width: '100%',
      },
    },
  },
  label: {
    fontSize: 15,
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    lineHeight: 1.67,
    color: '#43424b',
    minWidth: 200,
  },
  label2: {
    fontSize: 12,
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    lineHeight: 1.67,
    color: '#43424b',
    textAlign: 'center',
    width: '100%',
  },
  input: {
    width: 235,
    height: 32,
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    borderWidth: 0,
    outline: 'none',
    fontWeight: 300,
    '& input': {
      padding: '7px 12px',
      color: '#43424b',
      fontSize: 15,
      fontFamily: 'Averta Light',
    },
    '& p': {
      margin: '3px 0 0 5px',
      fontSize: 10,
    },
    '& fieldset': {
      borderColor: '#fff',
    },
    '@media (max-width:960px)': {
      flex: '1',
    },
    '@media (max-width:400px)': {
      width: '100%',
    },
  },
  areaCont: {
    flex: 1,
  },
  textarea: {
    width: '-webkit-fill-available',
    height: 100,
    padding: 10,
    resize: 'none',
    color: '#43424b',
    fontSize: 15,
    fontFamily: 'Averta Light',
  },
  butCont: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 35,
  },
  but: {
    width: 80,
    height: 32,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontSize: 12,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    color: '#fff',
    margin: '0 10px',
    '&:hover': {
      backgroundColor: '#f8b100',
    },
  },
  green: {
    backgroundColor: '#44a570',
    '&:hover': {
      backgroundColor: '#44a570',
    },
  },

  red: {
    width: 160,
    height: 32,
    backgroundColor: '#e4464b',
    margin: 0,
    '&:hover': {
      backgroundColor: '#e4464b',
    },
  },
  chasissBtn: {
    background: '#f8b100',
    color: '#ffffff',
    display: 'block',
    width: '100%',
    margin: '15px 12px',
    '&:hover': {
      background: '#f8b100',
    },
  },
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    // zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99999999999999999999999999999',
  },
  popupCard: {
    position: 'relative',
    padding: '15px 15px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxHeight: '75%',
    maxWidth: '80%',
    animation: 'zoomIn 0.7s',
    width: 310,
    overflow: 'hidden',
  },

  close: {
    width: '10px',
    height: '10px',
    position: 'absolute',
    top: 10,
    right: 15,
    cursor: 'pointer',
    fill: '#777575',
  },

  tableContainer: {
    overflow: 'auto',
    marginTop: 20,
    maxHeight: '700px',
  },

  icon: {
    width: 20,
    height: 20,
    fill: '#e4464b !important',
  },
  loadIcon: {
    width: '28px !important',
    height: '28px !important',
    color: '#ffffff !important',
  },
  chassisTable: {
    width: '100%',
    '& tr': {
      display: 'flex',
    },
    '& th': {
      color: '#777575',
      fontSize: '13px',
      padding: '10px 5px',
      textAlign: 'center',
    },
    '& td': {
      padding: '5px',
      textAlign: 'center',
      justifyContent: 'center',
      display: 'flex',
      alignItems: 'center',
      color: '#000000',
      fontSize: '13px',
      height: '27px',
      borderBottom: '1px solid #dedede',
      '& label': {
        margin: 0,
      },
      '& svg': {
        fill: '#000000',
      },
    },
  },

  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    boxShadow: '0 3px 6px 0 rgb(0 0 0 / 16%)',
    margin: 0,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    fontSize: 12,
    padding: '0 10px',
    '& input': {
      fontFamily: 'Averta Light',
      padding: 0,
      border: 'none !important',
      outline: 'none',
      width: '100%',
      height: '100%',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
    '& a': {
      top: 8,
    },
  },
  checkbox: {
    '& svg': {
      fill: '#f8b100',
    },
    '& hover': {
      background: '#f8b1001a',
    },
  },
  btnDiv: {
    display: 'flex',
    marginTop: '6px',
    justifyContent: 'flex-end',
  },
  optionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 13,
    borderBottom: '1px solid #dedede',
  },
  addBtn: {
    width: '100%',
    background: '#f8b100',
    color: '#ffffff',
    padding: 5,
    marginTop: '10px',
    margin: '2px',
    '&:hover': {
      background: '#f8b100',
      color: '#ffffff',
    },
  },
  widthFull: {
    width: '100%',
  },
  saveBtn: {
    color: '#ffffff',
    background: '#44a570',
    padding: 5,
    marginTop: '10px',
    margin: '2px',
    '&:hover': {
      background: '#44a570',
      color: '#ffffff',
    },
  },
  importBtn: {
    color: '#ffffff',
    background: '#007FFF',
    padding: 5,
    marginTop: '10px',
    margin: '2px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      background: '#007FFF',
      color: '#ffffff',
      opacity: '0.85',
    },
  },
  enabledContainer: {
    color: '#43424b ',
    marginBottom: -20,
    '& span': {
      fontSize: 15,
      fontWeight: '600',
      fontFamily: 'Averta Bold',
      color: '#303030 !important',
    },
  },
  disabledContainer: {
    color: '#d6d6d6',
    marginBottom: -20,
    '& span': {
      fontSize: 15,
      fontWeight: '600',
      fontFamily: 'Averta Bold',
    },
  },
  loader: {
    color: '#f8b100 !important',
    width: '27px !important',
    height: '27px !important',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
  },
  tableInput: {
    border: '1px solid #dedede',
    outline: 'none',
    width: '100%',
    padding: '4px 5px',
    textAlign: 'center',
  },
  notifTitle: {
    fontSize: 20,
  },
}));
