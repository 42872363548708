import React, { useEffect, useState } from 'react';
import { useNotify, Loading } from 'react-admin';
import { Button, TextField, Grid, Tooltip } from '@material-ui/core';
import { TrendingFlatRounded } from '@material-ui/icons';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import qs from 'query-string';
import { Card } from '@/components/Search';
import { Load, Filter } from '@/components/Global';
import { getMarket, getSavedSearches, Request } from '@/actions';
import {
  ParseInventoryList,
  ParseMarketPlace,
  ParseMarketPlaceForm,
} from '@/actions/ParseData';
import { locationOpt } from '@/actions/Helper';
import { List } from '@material-ui/icons';

import DateInput from '@/components/DateInput';
import styles from './styles';
import { data } from 'jquery';
import SavedSearches from './SavedSearches';
import { debounce } from 'lodash';

const perPage = 8;

export default ({ history }) => {
  const notify = useNotify();
  const [loading, setLoading] = React.useState(true);
  const [loadingCards, setLoadingCards] = React.useState([]);
  const selectOp = useSelector((state) => state.reducer);
  const [DATA, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });
  const [page, setPage] = React.useState(1);
  const [dataLastPage, setDataLastPage] = React.useState(null);
  const [showSavesSearches, setShowSavedSearches] = useState(false);
  const [savedSearches, setSavedSearches] = useState([]);

  const disableLoad = () => {
    if (page <= dataLastPage) return false;
    else return true;
  };

  const [form, setFormData] = React.useState({
    specification_id: null,
    company_id: null,
    count: null,
    date: new Date(),
    loc: null,
    //numberMode
    prefrence: false,
    chassis: {
      own: true,
      pool: true,
    },
  });

  const cls = styles({ prefrence: form.prefrence });

  const Detail = () => {
    for (let el in form) {
      if (el !== 'prefrence' && !form[el]) {
        notify('Error, Please check fields.', 'warning');
        return;
      }
      if (el === 'chassis' && !form[el].own && !form[el].pool) {
        notify('Error, Please Select Chassis.', 'warning');
        return;
      }
    }

    history.push(
      '/search-detail/empty?' + qs.stringify(ParseMarketPlaceForm(form))
    );
  };

  const getMarketData = () => {
    setLoading(true);
    getMarket(page)
      .then((res) => {
        let DATA = ParseMarketPlace(res.data);
        setFilter((prev) => {
          return {
            open: false,
            DATA: [...prev.DATA, ...DATA],
          };
        });
        setDataLastPage(res.last_page);
        setPage(page + 1);
        setData((prev) => {
          return [...prev, ...DATA];
        });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  //componentDidMount
  React.useEffect(() => {
    getMarketData();
  }, [notify]);

  const setForm = (it, val) => setFormData({ ...form, [it]: val });

  const removeCardLoading = (id) => {
    let adaptedLoadingCards = loadingCards.filter((cardId) => cardId !== id);
    setLoadingCards(adaptedLoadingCards);
  };

  const req = ({ id, num }) => {
    setLoadingCards((prev) => {
      return [...prev, id];
    });
    Request({
      inventory_id: id,
      count: num,
    })
      .then((res) => {
        notify(res);
        getMarketData();
        removeCardLoading(id);
      })
      .catch((err) => {
        removeCardLoading(id);
        notify(err, 'warning');
      });
  };

  const getData = React.useCallback(
    () => filter.DATA.slice(0, page * perPage),
    [filter.DATA, page]
  );

  const handleChangeNmuber = (number, index) => {
    setFilter((prev) => {
      return {
        ...prev,
        DATA: [
          ...prev.DATA.slice(0, index),
          {
            ...prev.DATA[index],
            num: String(number),
          },
          ...prev.DATA.slice(index + 1),
        ],
      };
    });
  };

  const fetchSavedSearches = () => {
    getSavedSearches().then((data) => {
      setSavedSearches(ParseInventoryList(data));
    });
  };

  useEffect(() => {
    fetchSavedSearches();
  }, []);

  return (
    <div className={cls.content}>
      {showSavesSearches && (
        <SavedSearches
          savedSearches={savedSearches}
          Close={() => setShowSavedSearches(false)}
          notify={notify}
          fetchSavedSearches={fetchSavedSearches}
        />
      )}
      <div className={cls.card}>
        {/* <div className={cls.searchActions}>
        </div> */}
        <>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <Select
                    placeholder="Size"
                    className={cls.select}
                    value={form.specification_id}
                    onChange={(item) => setForm('specification_id', item)}
                    options={selectOp.size}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Select
                    placeholder="SSL"
                    className={clsx(cls.select)}
                    value={form.company_id}
                    onChange={(item) => setForm('company_id', item)}
                    options={selectOp.ssl}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <AsyncSelect
                    placeholder="Location"
                    className={clsx(cls.input, cls.bigInput)}
                    classNamePrefix="react-select"
                    value={form.loc}
                    // inputValue={form.loc ? form.loc : ''}
                    // displayedValue ={form.loc ? form.loc : ''}
                    loadOptions={locationOpt}
                    onChange={(item) => {
                      setForm('loc', item);
                    }}
                    cacheOptions
                    defaultOptions
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={3}>
                  <TextField
                    type="number"
                    placeholder="Number"
                    className={cls.input}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ min: 1 }}
                    value={form.count}
                    onChange={(ev) => setForm('count', ev.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <DateInput
                    placeholder="Date"
                    className={cls.date}
                    format="MM/dd/yyyy"
                    value={form.date}
                    onChange={(item) => setForm('date', item)}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className={cls.actions}>
                    <div className={cls.rowRound}>
                      <span
                        onClick={() =>
                          setForm('chassis', {
                            ...form.chassis,
                            own: !form.chassis.own,
                          })
                        }
                        className={clsx(
                          cls.chassisText,
                          form.chassis.own && cls.activeChassis
                        )}>
                        Own Chassis
                      </span>
                      <span
                        onClick={() =>
                          setForm('chassis', {
                            ...form.chassis,
                            pool: !form.chassis.pool,
                          })
                        }
                        className={clsx(
                          cls.chassisText,
                          form.chassis.pool && cls.activeChassis
                        )}>
                        Pool Chassis
                      </span>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <div className={clsx(cls.row, cls.prefrence)}>
                    <div
                      className={cls.rowRound}
                      onClick={() => setForm('prefrence', !form.prefrence)}>
                      <span
                        className={clsx(
                          cls.text2,
                          !form.prefrence && cls.active
                        )}>
                        Location
                      </span>
                      <span
                        className={clsx(
                          cls.text2,
                          form.prefrence && cls.active
                        )}>
                        Number
                      </span>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: '15px' }}>
            <Grid item xs={12} sm={6} md={9}>
              <span style={{ marginBottom: '15px' }} className={cls.text}>
                WE WILL FIND YOU THE BEST RESULT
              </span>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <div className={cls.notifContainer}>
                <span
                  onClick={() => setShowSavedSearches(true)}
                  className={cls.notifList}>
                  <Tooltip arrow title="Saved Searches List" placement="top">
                    <List />
                  </Tooltip>
                </span>
                <Button
                  className={cls.find}
                  endIcon={<TrendingFlatRounded className={cls.findIcon} />}
                  onClick={Detail}>
                  Search
                </Button>
              </div>
            </Grid>
          </Grid>
        </>
      </div>
      <Filter
        title="Market place"
        filter={filter}
        setFilter={setFilter}
        DATA={DATA}
      />

      {loading ? (
        <Loading />
      ) : (
        <Grid container justify="flex-start" spacing={2}>
          {filter.DATA.map((i, idx) => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={idx}>
              <Card
                DATA={i}
                req={req}
                loadingCards={loadingCards}
                handleChangeNmuber={handleChangeNmuber}
                index={idx}
              />
            </Grid>
          ))}
        </Grid>
      )}

      <Load
        onLoad={() => {
          getMarketData();
        }}
        disable={disableLoad()}
      />
    </div>
  );
};
