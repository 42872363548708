import React, { useState } from 'react';
import Rewards from './Rewards/index';
import styles from './styles';
import { Button } from '@material-ui/core';
import Settings from './Settings';

export default () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const cls = styles();
  const referralTabItems = [
    {
      title: 'Programs',
      content: <Settings />,
    },
    {
      title: 'Rewards',
      content: <Rewards />,
    },
  ];
  return (
    <div className={cls.container}>
      <div className={cls.featureTabs}>
        {referralTabItems.map((item, index) => (
          <Button
            className={
              selectedTab === index
                ? cls.activeFeatureTabsBtn
                : cls.featureTabsBtn
            }
            onClick={() => setSelectedTab(index)}
            key={index}>
            {item.title}
          </Button>
        ))}
      </div>
      <div>{referralTabItems[selectedTab].content}</div>
    </div>
  );
};
