// in src/MyAppBar.js
import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import { Link } from 'react-router-dom';
import { HeaderIcons } from '@/assets';
import UserMenu from '../UserMenu';
import { SearchRounded } from '@material-ui/icons';
import {
  InputBase,
  InputAdornment,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import Notification from '@/components/Notification';
import styles from './styles';
import MenuIcon from '@material-ui/icons/Menu';
import LogOutButton from '../LogOutButton';
import { useSelector } from 'react-redux';
import { SaveData } from '@/actions';
import MobileMenu from '../MobileMenu';
import useMediaQuery from 'use-mediaquery';

export default (props) => {
  const cls = styles();
  const openUserMenu = useSelector(
    (state) => state.reducer.openUserMenu || null
  );
  const isSmallDevice = useMediaQuery('(max-width: 1000px)');

  const handleToggleMenu = () => {
    SaveData({ openUserMenu: !openUserMenu });
  };

  return (
    <>
      <AppBar className={cls.header} position="fixed">
        <Toolbar className={cls.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            className={cls.menuIcon}
            onClick={() => handleToggleMenu()}>
            <MenuIcon />
          </IconButton>
          <Link to="/">
            <img src={HeaderIcons.logo} alt="Logo" className={cls.logo} />
          </Link>
          {isSmallDevice ? (
            <MobileMenu />
          ) : (
            <div className={cls.linkCont}>
              <Link to="/" className={cls.link}>
                HOME
              </Link>
              <Link to="/how-it-works" className={cls.link}>
                HOW IT WORKS
              </Link>
              <Link to="/about" className={cls.link}>
                ABOUT
              </Link>
            </div>
          )}
          <span className={cls.spacer} />
          <InputBase
            id="searchBar"
            placeholder="Search Offer or ..."
            variant="outlined"
            margin="dense"
            className={cls.search}
            endAdornment={
              <InputAdornment position="end">
                <SearchRounded className={cls.searchIcon} />
              </InputAdornment>
            }
          />
          <Notification />
          <UserMenu>
            <LogOutButton />
          </UserMenu>
        </Toolbar>
      </AppBar>
    </>
  );
};
