import React from 'react';
import { Grid } from '@material-ui/core';
import { useNotify, Loading } from 'react-admin';
import { useSelector } from 'react-redux';
import { Incomplete } from '@/components/Request';
import { Load, Filter } from '@/components/Global';
import { getReq, delReq, SaveData, delExportReq } from '@/actions';
import { ParseRequestInComplete } from '@/actions/ParseData';
import { useHistory } from 'react-router-dom';

import styles from './styles';

const perPage = 8;

export default () => {
  const cls = styles();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(true);
  const [DATA, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });

  const history = useHistory();

  const [page, setPage] = React.useState(1);
  const disableLoad = () => {
    if (page * perPage < DATA.length) return false;
    else return true;
  };
  const RefreshPage = useSelector((state) => state.reducer.refreshPage || '');

  const RefreshData = React.useCallback(() => {
    setLoading(true);
    getReq('inprogress')
      .then((res) => {
        let DATA = ParseRequestInComplete(res);
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  }, [notify]);

  const getData = React.useCallback(
    () => filter.DATA.slice(0, page * perPage),
    [filter.DATA, page]
  );

  React.useEffect(() => {
    if (RefreshPage === 'request') {
      RefreshData();
      SaveData({ refreshPage: '' });
    }
  }, [RefreshPage, RefreshData]);

  React.useEffect(() => {
    RefreshData();
  }, [RefreshData]);

  const Cancel = (id, mode) => {
    switch (mode) {
      case 'empty':
        delReq(id)
          .then(({ data, msg }) => {
            notify(msg);
            history.push('/requests/complete');
          })
          .catch((err) => notify(err, 'warning'));
        break;
      case 'export':
        delExportReq(id)
          .then(({ data, msg }) => {
            notify(msg);
            history.push('/requests/complete');
          })
          .catch((err) => console.log(err, 'warning'));
      default:
        break;
    }
  };

  const Chat = (id, mode) =>
    SaveData({ Chat: { id, type: 'request', mode }, ChatItem: null });

  return (
    <div className={cls.content}>
      <Filter
        title="My Requests/In Progress"
        filter={filter}
        setFilter={setFilter}
        DATA={DATA}
      />

      <div className={cls.list}>
        {loading ? (
          <Loading />
        ) : (
          <Grid container justify="flex-start" spacing={2}>
            {getData().map((i, idx) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl="auto" key={idx}>
                <Incomplete DATA={i} Cancel={Cancel} Chat={Chat} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
      <Load onLoad={() => setPage(page + 1)} disable={disableLoad()} />
    </div>
  );
};
