import { makeStyles } from '@material-ui/core/styles';
import { WhatsappBg } from '@/assets';

export default makeStyles(({ breakpoints: BP }) => ({
  chatContainer: {
    display: 'flex',
    height: 'calc(100vh - 135px)',
    margin: '0 20px',
  },
  chatListContainer: {
    width: '335px',
    background: '#FFFFFF',
    border: '1px solid #FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    padding: '16px',
    overflow: 'auto',
    position: 'relative',
    '@media (max-width:1000px)': {
      flex: '1',
    },
  },
  input: {
    flex: '1',
    width: 215,
    fontSize: 12,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.6,
    color: '#ccc',
    outline: 'none',
    border: 'none',
    backgroundColor: 'transparent',
  },
  textContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  chatMessagesContainer: {
    padding: 0,
    backgroundImage: `url(${WhatsappBg})`,
    backgroundSize: "contain",
    border: '1px solid #FFFFFF',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: '10px',
    flex: '1',
    marginLeft: '16px',
    position: 'relative',
    overflow: 'auto',
    '@media (max-width:1000px)': {
      marginLeft: '0',
    },
  },
  searchBox: {
    background: '#FFFFFF',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    borderRadius: '10px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    padding: '20px',
    boxSizing: 'border-box',
  },
  searchIcon: {
    fill: 'rgba(0, 0, 0, 0.5)',
  },
  searchInput: {
    border: 'none',
    flex: '1',
    marginLeft: '10px',
    '&::placeholder': {
      color: 'rgba(0, 0, 0, 0.5)',
    },
    '&:focus-visible': {
      border: 'none !important',
      outline: 'none',
    },
  },
  inputContainer: {
    position: 'absolute',
    width: '100%',
    bottom: '10px',
  },
  chatBox: {
    height: '70vh',
    overflow: 'auto',
    padding: '5px 10px',
  },
  loading: {
    position: 'absolute',
    top: '0',
    right: '0',
    left: '0',
    bottom: '0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
