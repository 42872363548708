export const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const isValidPhone = (phone) => {
  return String(phone)
    .toLowerCase()
    .match(/^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im);
};

export const validateAdminAddMemberData = (reqData) => {
  const { name, email, phone, company, password, status, last_name } = reqData;
  if (email && !isValidEmail(email)) {
    return 'please enter a valid email';
  } else if (phone && !isValidPhone(phone)) {
    return 'please enter a valid phone';
  }
};
