import { makeStyles } from '@material-ui/core/styles';
import { LoginIcons } from '@/assets';

export default makeStyles({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: '0 20px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '23px',
  },
  card: {
    padding: '20px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    minHeight: 139,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 25,
  },
  formDescription: {
    fontSize: '16px',
  },
  select: {
    width: '-webkit-fill-available',
    fontFamily: 'Averta Light',
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& > div:first-child > div:first-child': {
      padding: '2px 8px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '15px',
  },
  loader: {
    width: '25 !important',
    height: '25 !important',
    color: '#f8b100',
  },
  saveBtn: {
    width: 'fit-content',
    color: '#ffffff',
    background: '#f8b100',
    '&:hover': {
      color: '#ffffff',
      background: '#f8b100',
    },
  },
  cityContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});
