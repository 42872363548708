import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    width: 'auto !important',
    height: '500px !important',
    position: 'relative !important',
    overflow: 'hidden',
    borderRadius: 15,
    margin: '0 10px',
    minWidth: '30vw',
    [BP.up('lg')]: {
      minWidth: 480,
    },
  },
  popUp: {
    flex: 1,
    display: 'flex',
    width: 'auto',
    minHeight: 130,
    borderRadius: 30,
    boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#f8b100',
    padding: '1rem 1.8rem',
    position: 'absolute',
    bottom: 20,
    right: '5%',
    left: '5%',
    boxSizing: 'border-box',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
    [BP.up('lg')]: {
      padding: '0.7rem 1.2rem',
    },
    '& h1': {
      fontFamily: 'Averta Bold',
      fontSize: 18,
      fontWeight: 'bold',
      color: '#43424b',
      margin: 0,
      [BP.up('lg')]: {
        fontSize: 16,
      },
    },
    '& h2': {
      fontFamily: 'Averta Bold',
      fontSize: 10,
      fontWeight: 'bold',
      color: '#303030',
      [BP.up('lg')]: {
        fontSize: 9,
      },
    },
  },
  cont: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  but: {
    margin: 'auto 0 auto 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '8px 12px',
    borderRadius: 10,
    boxShadow: '0 3px 15px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#303030',

    fontFamily: 'Averta Bold',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#f8b100',
    textDecoration: 'none',
    cursor: 'pointer',
    [BP.up('lg')]: {
      fontSize: '1rem',
    },
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 20,
    cursor: 'pointer',
  },
  mini: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    borderRadius: 10,
    boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.5)',
    backgroundColor: '#f8b100',
    padding: 5,
    position: 'absolute',
    bottom: 20,
    right: 55,
    boxSizing: 'border-box',
    opacity: 0.7,
    '&:hover': {
      opacity: 1,
    },
  },
  popupCont: {
    transition: 'all 1s ease-in',
  },
  launch: {
    height: 20,
  },
}));
