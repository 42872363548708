import React, { useState } from 'react';
import styles from './styles';
import { BaseIcon } from '@/Utils';
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import { MemberIcons as memberIcons, Icons } from '@/assets';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import EditMemberPopup from '@/components/Members/Edit';
import AddMemberPopup from '@/components/Members/Add';
import { DeleteRounded, CreateRounded, Email } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  adminAddMember,
  adminDeleteMember,
  adminEditMember,
  adminLoginToUserAccount,
  adminSendVerificationEmail,
  adminUpdateUserApp,
  SaveData,
} from '@/actions';
import DeleteMemberPopup from '@/components/DeletePopup';
import {
  Avatar,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core';
import Filter from '@/components/Members/Filter';
import { UrlField } from 'react-admin';
import DeletePopup from '@/components/DeletePopup';
import { validateAdminAddMemberData } from '@/actions/validations';
import { fTimeDate } from '@/actions/Helper';

export default ({ DATA = [], getUpdatedMembers, notify }) => {
  const cls = styles();

  const changeUserUpdate = (user) => {
    adminUpdateUserApp(user.id, {
      update: 1,
    })
      .then((res) => {
        notify('Successfully Updated', 'success');
        getUpdatedMembers();
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  return (
    <div className={cls.container}>
      <div className={cls.tableContainer}>
        <table className={cls.list}>
          <thead className={cls.thead}>
            <th style={{ flex: 1, textAlign: 'left' }}>
              <span style={{ paddingLeft: '70px' }}>Member</span>
            </th>
            <th style={{ flex: 1 }}>Email Address</th>
            <th style={{ flex: 1 }}>Company</th>
            <th style={{ flex: 1 }}>Update Date</th>
            <th style={{ flex: 0.5 }}>Actions</th>
          </thead>
          <tbody className={cls.tbody}>
            {DATA.map((i, idx) => {
              return (
                <tr className={cls.tableRow} key={idx}>
                  <td
                    style={{ flex: 1 }}
                    className={`${cls.bigText} ${cls.userName}`}>
                    <Avatar src={i.image} className={cls.avatar} />
                    <span style={{ cursor: 'pointer' }}>{`${i.name} ${
                      i.last_name || ''
                    }`}</span>
                  </td>
                  <td style={{ flex: 1 }}>{i.email}</td>
                  <td style={{ flex: 1 }}>{i.company}</td>
                  <td style={{ flex: 1 }}>{fTimeDate(i.updated_at)}</td>
                  <td style={{ flex: 0.5 }}>
                    <Tooltip arrow title="" placement="top">
                      <IconButton
                        onClick={() => {
                          changeUserUpdate(i);
                        }}>
                        <DeleteRounded className={cls.delIcon} />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
