import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import clsx from 'clsx';
import {
  TextField,
  FormControl,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import * as Y from 'yup';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { Header } from '@/components/Landing';
import { LoginIcons } from '@/assets';
import { SOCIAL_URL } from '@/actions/constants';
import { oauthLogin, shareLinkClick } from '@/actions';
import { IconButton } from '@material-ui/core';

import styles from './styles';

let RegisterSch = Y.object().shape({
  password: Y.string()
    .required('Password is required')
    .min(8, 'Your Password must be at least 8 characters'),
  password_confirmation: Y.string()
    .required('Password confirmation is required')
    .min(8, 'Password confirmation must be at least 8 characters')
    .oneOf([Y.ref('password'), null], 'Password confirmation does not match'),
  email: Y.string()
    .required('Email is required')
    .email('Your Email looks wrong.')
    .trim(),
  terms: Y.bool().oneOf([true], 'Please accept term and condition'),
});

let LoginSch = Y.object().shape({
  password: Y.string()
    .required('Password is required')
    .min(8, 'Your Password must be at least 8 characters'),
  email: Y.string()
    .required('Email is required')
    .email('Your Email looks wrong.')
    .trim(),
});

export default ({ history, ...rest }) => {
  const [errorMessage, setErrorMessage] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const notify = useNotify();
  React.useEffect(() => {
    let q = qs.parse(history.location.search);
    if (q.access_token)
      oauthLogin(q.access_token).then(() => {
        history.push('/dashboard');
        notify('You logged in successfully');
        window.location.reload();
        console.log('reloaded');
      });
  }, [history, notify]);

  const [errs, setErrs] = useState([]);
  const [loginMode, setLoginMode] = useState(true);
  const [form, setFormData] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    terms: false,
  });

  const setForm = (it, e) => setFormData({ ...form, [it]: e.target.value });

  const login = useLogin();
  const cls = styles({ loginMode });

  const toggleMode = () => {
    // $('#rep-pass').slideToggle();
    // $('#terms').slideToggle();
    // $('#forget').slideToggle();
    // $('#remember').slideToggle();
    // $('#tips').slideToggle();
    if (!loginMode) {
      setLoginMode(loginMode);
    }
    // setFormData({ ...form, password_confirmation: '' });
  };

  const Validate = () => {
    let Sch = loginMode ? LoginSch : RegisterSch;
    Sch.validate(form, { abortEarly: false })
      .then(handle)
      .catch(({ inner }) => setErrs(inner));
  };

  const handleShareLinkClick = (token) => {
    shareLinkClick(token)
      .then((res) => {
        if (res.message !== 'login' && res.message !== 'register') {
          history.push('/requests/active');
          localStorage.removeItem('share_token');
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handle = () => {
    login(form, '/dashboard')
      .then((data) => {
        setErrorMessage(false);
        const shareToken = localStorage.getItem('share_token');
        if (shareToken) {
          handleShareLinkClick(shareToken);
        }
        if (loginMode) notify('Logged In Successfully.');
        else notify('You are Registered Successfully.');
      })
      .catch((er) => {
        // if (er && er.message) {
        //   notify(er.message, 'warning');
        // } else {
        //   notify('Somthing went wrong', 'warning');
        // }
        if (er === 'The given data was invalid.') {
          setErrorMessage(true);
        }
      });
  };

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const checkError = (item) => {
    if (errs.length) {
      let a = errs.find((i) => i.path === item);
      return a ? a.message : false;
    } else return false;
  };

  return (
    <div className={cls.bg}>
      <Header height={70} logoWidth={190} />
      <Notification />

      <div className={cls.card}>
        <div className={cls.row}>
          <Button className={clsx(cls.but, cls.yellow)} onClick={toggleMode}>
            LOG IN
          </Button>
          <Link
            style={{ textDecoration: 'none' }}
            className={clsx(cls.but, cls.green)}
            to={'/Register'}>
            <Button className={clsx(cls.but, cls.green)} onClick={toggleMode}>
              SIGN UP
            </Button>
          </Link>

          <span className={cls.opLine} />
        </div>

        <TextField
          placeholder="Email*"
          variant="outlined"
          className={cls.input}
          value={form.email}
          onChange={(e) => setForm('email', e)}
          error={checkError('email')}
          helperText={checkError('email')}
        />

        <div className={cls.input}>
          <TextField
            type={passwordShown ? 'text' : 'password'}
            placeholder="Password*"
            variant="outlined"
            value={form.password}
            style={{ width: '100%' }}
            onChange={(e) => setForm('password', e)}
            error={checkError('password')}
            helperText={checkError('password')}
          />
          <IconButton className={cls.showBtn} onClick={togglePassword}>
            {passwordShown ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </div>

        <Link id="forget" className={cls.forget} to="/forget">
          FORGET YOUR PASSWORD?
        </Link>

        <FormControl id="rep-pass" style={{ display: 'none' }}>
          <TextField
            type="password"
            placeholder="Confirm"
            variant="outlined"
            className={cls.input}
            value={form.password_confirmation}
            onChange={(e) => setForm('password_confirmation', e)}
            error={checkError('password_confirmation')}
            helperText={checkError('password_confirmation')}
          />
        </FormControl>

        <FormControl className={'flip-card'}>
          <div className={clsx('flip-card-inner', loginMode && 'rot180')}>
            <Button
              className={clsx(cls.loginBut, 'flip-card-back')}
              fullWidth
              type="submit"
              color="primary"
              onClick={Validate}>
              LOG IN
            </Button>
            {/* <Button
              className={clsx(cls.loginBut, 'flip-card-front')}
              fullWidth
              type="submit"
              color="primary"
              disabled={true}
              onClick={Validate}>
              SIGN UP
            </Button> */}
          </div>
        </FormControl>

        <div id="terms" style={{ display: 'none' }}>
          <FormControlLabel
            className={cls.terms}
            control={
              <Checkbox
                color="secondary"
                icon={<CheckBoxOutlineBlank color="secondary" />}
                value={form.terms}
                onChange={(e) =>
                  setFormData({ ...form, terms: e.target.checked })
                }
              />
            }
            label="By signing up to smart-turn you agree to our Terms Of Use and Privacy Policy agreements"
          />
          {checkError('terms') && (
            <p className={cls.error}>Please accept terms and conditions</p>
          )}
        </div>

        <FormControlLabel
          className={cls.remember}
          id="remember"
          control={
            <Checkbox
              color="secondary"
              icon={<CheckBoxOutlineBlank color="secondary" />}
              defaultChecked
            />
          }
          label="Remember Me?"
        />

        <div className={cls.hr} />
        {/* <FormControl>
          <Button
            disabled={true}
            onClick={() => window.open(`${SOCIAL_URL}/facebook`, '_self')}
            className={cls.social}
            startIcon={
              <img alt="icon" className={cls.socIcon} src={LoginIcons.fb} />
            }>
            LOG IN WITH FACEBOOK
          </Button> */}
        {errorMessage && loginMode && (
          <p
            style={{ fontSize: '14px', lineHeight: '24px', color: '#FF0000' }}
            className={cls.remember}>
            Your account or password is incorrect. If you don't remember your
            password,{' '}
            <Link
              style={{ fontSize: '14px', color: '#007fff', margin: 0 }}
              id="forget"
              className={cls.forget}
              to="/forget">
              reset it now
            </Link>
            .
          </p>
        )}
        {/* <Button
            disabled={true}
            onClick={() => window.open(`${SOCIAL_URL}/google`, '_self')}
            className={cls.social}
            startIcon={
              <img alt="icon" className={cls.socIcon} src={LoginIcons.google} />
            }>
            LOG IN WITH GOOGLE
          </Button>
        </FormControl> */}
        <div id="tips" style={{ display: 'none' }}>
          <div className={cls.hr} />
          <div className={cls.already} onClick={() => toggleMode(true)}>
            ALREADY HAVE AN ACCOUNT
          </div>
        </div>
      </div>
    </div>
  );
};
