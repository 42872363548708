import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  filter: {
    background: '#FFFFFF',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    margin:'10px'
  },
  filterBtn: {
    padding: '10px 15px',
    border: '1px solid #000000',
    borderRadius: '5px',
    color: '#777575',
    marginLeft: 5,
    cursor: 'pointer',
    '&:hover': {
      color: '#ffffff',
      background: '#F8B100',
      borderColor: '#F8B100',
    },
  },
  activeFilter: {
    color: '#ffffff',
    background: '#F8B100',
    borderColor: '#F8B100',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dateInputs: {
    display: 'flex',
    alignItems: 'center',
  },
  dateContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 15px',
    border: '1px solid #000000',
    borderRadius: '5px',
    marginRight: 5,
    width: '100%',
    '& span': {
      marginLeft: 10,
      marginLeft: '22px',
      fontSize: '12px',
    },
  },
  date: {
    direction: 'rtl',
    '& input': {
      textAlign: 'end',
      marginLeft: 5,
      fontSize: 14,
    },
  },
  disabledDate: {
    color: 'rgba(0, 0, 0, 0.38)',
    borderColor: 'rgba(0, 0, 0, 0.38)',
  },
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  card: {
    position: 'relative',
    padding: '25px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxHeight: '75%',
    // maxWidth: '100%',
    animation: 'zoomIn 0.7s',
    height: 'auto',
    width: 400,
  },

  companyList: {
    height: '300px',
    overflow: 'auto',
    marginBottom: '1rem',
  },

  modalBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },

  okBtn: {
    background: '#007FFF',
    color: '#ffffff',
    '&:hover': {
      background: '#007FFF',
    },
  },

  resetBtn: {
    background: '#FF0000',
    color: '#ffffff',
    marginLeft: '0.5rem',
    '&:hover': {
      background: '#FF0000',
    },
  },
}));
