import React, { useEffect, useState } from 'react';
import {
  FormControlLabel,
  Checkbox,
  Button,
  CircularProgress,
} from '@material-ui/core';
import styles from '../styles';
import { addChat, getExportContainers } from '@/actions';
import { DeleteRounded, CreateRounded } from '@material-ui/icons';
import { useConditionalyOutsideAlerter } from '@/hocs';

export default ({
  setShowContainers,
  accepted_count,
  notify,
  id,
  mode,
  //   containerNumers,
  activeContainers,
  setActiveContainers,
  setChat,
  parseData,
  user,
}) => {
  const cls = styles();
  const [hasNewContainer, setHasNewContainer] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleSelectContainer = (
    e,
    container_name,
    container_id,
    container_chases,
    own_chases,
    is_new,
    edit
  ) => {
    setActiveContainers((prev) => {
      const adaptedContainers = prev.map((item, index) => {
        return {
          ...item,
          selected: item.id === container_id ? e.target.checked : item.selected,
        };
      });
      return adaptedContainers;
    });
  };
  const handleShareContainers = () => {
    const selectedContainers = activeContainers.filter(
      (container) => container.selected === true
    );
    if (selectedContainers.length !== 0) {
      let messageContainers = [`# - Container# - Chassis#`];
      selectedContainers.forEach((container, index) => {
        messageContainers.push(
          `${index + 1} - ${container.name} - ${container.chases}`
        );
      });
      setChat(({ DATA = [], otherPart }) => ({
        otherPart,
        DATA: DATA.concat(
          parseData(
            [
              {
                message: messageContainers.join(','),
                humans_time: 'Now',
                me: user.id,
                sender: {
                  name: user.name,
                  image: user.image,
                },
                user_id: user.id,
              },
            ],
            user
          )
        ),
      }));
      addChat(id, messageContainers.join(','), mode).then(() => {});
      setShowContainers(false);
      // let formContainers = selectedContainers.map((container) => {
      //   return container.id;
      // });
      // localStorage.setItem(id, JSON.stringify(selectedContainers));
    } else if (accepted_count.length === 0) {
      notify(`Please select a minimum of one container.`, 'warning');
    } else {
      notify(`Please select a minimum of one container.`, 'warning');
    }
  };

  const handleRemoveContainer = (id) => {
    setActiveContainers((prev) => {
      let adaptedContainers = [];
      prev.forEach((container) => {
        if (container.id !== id) {
          adaptedContainers.push(container);
        }
      });
      return adaptedContainers;
    });
  };

  useEffect(() => {
    let newContainers = activeContainers.filter(
      (container) => container.is_new === true
    );
    if (newContainers.length > 0) {
      setHasNewContainer(true);
    } else {
      setHasNewContainer(false);
    }
  }, [activeContainers]);

  // useEffect(() => {
  //   localStorage.setItem(id, JSON.stringify(selectedContainers));
  // }, [selectedContainers]);

  useEffect(() => {
    if (activeContainers.length > 0) {
      localStorage.setItem(`ex_${id}`, JSON.stringify(activeContainers));
    }
  }, [activeContainers, id]); // Include 'id' in the dependency array
  
  const handleAddNewContainer = () => {
    setActiveContainers((prev) => {
      const adapted = prev.map((item) => {
        return {
          ...item,
          edit: false,
        };
      });
      return [
        ...adapted,
        {
          name: '',
          id: Math.random(),
          chases: '',
          own_chases: 0,
          is_new: true,
          edit: true,
          selected: false,
        },
      ];
    });
  };

  const changeContainerInput = (id, name, value) => {
    setActiveContainers((prev) => {
      return prev.map((container) => {
        if (container.id === id) {
          return {
            ...container,
            [name]: value,
          };
        } else {
          return container;
        }
      });
    });
  };

  const getActiveContainers = () => {
    let selectedContainers = JSON.parse(localStorage.getItem(`ex_${id}`)) || [];
    selectedContainers = selectedContainers.map((item) => {
      return {
        ...item,
        edit: false,
      };
    });
    getExportContainers(id)
      .then((data) => {
        setLoading(false);
        let containers = [];
        data.containers.forEach((container) => {
          let el = selectedContainers.filter(
            (item) => container.id === item.id
          );
          if (!el[0]) {
            containers.push({ ...container, selected: false });
          }
        });
        setActiveContainers([...containers, ...selectedContainers]);
      })
      .catch((err) => {
        setLoading(false);
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    getActiveContainers();
  }, []);

  useEffect(() => {
    document.addEventListener('click', (event) => {
      console.log(event.target);
      if (
        (event.target instanceof HTMLInputElement &&
          event.target.getAttribute('type') !== 'checkbox') ||
        event.target.getAttribute('name') === 'edit' ||
        event.target.getAttribute('name') === 'add'
      ) {
      } else {
        setActiveContainers((prev) => {
          return prev.map((container) => {
            return {
              ...container,
              edit: false,
            };
          });
        });
      }
    });
  }, []);

  const wrapperRef = React.useRef(null);
  useConditionalyOutsideAlerter(
    wrapperRef,
    () => {
      setShowContainers(false);
    },
    (event) => {
      if (event.target.id === 'select') {
        return false;
      } else {
        return true;
      }
    }
  );

  useEffect(() => {
    let scroll_to_bottom = document.getElementById('list');
    if (scroll_to_bottom) {
      scroll_to_bottom.scrollTop = 2500;
    }
  }, [activeContainers]);

  return loading ? (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        background: '#ffffff',
        padding: '10px',
      }}>
      <CircularProgress size={20} color="#ffffff" />
    </div>
  ) : (
    <div
      style={{
        maxHeight: '75%',
        minHeight: '40%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
      ref={wrapperRef}
      className={cls.selectedContainers}>
      <div style={{ height: 'auto' }} className={cls.containersTableContainer}>
        <table
          style={{ tableLayout: 'fixed', borderCollapse: 'collapse' }}
          className={cls.containersTable}>
          <tr style={{ paddingRight: '18px', paddingBottom: '5px' }}>
            <th style={{ flex: '0.3' }}>#</th>
            <th style={{ flex: '1' }}>Container#</th>
            <th style={{ flex: '1' }}>Chassis#</th>
            {/* <th style={{ flex: '1' }}>Type</th> */}
            <th style={{ flex: '0.7' }}>Status</th>
          </tr>
          <tbody
            id="list"
            style={{
              display: 'block',
              overflowY: 'auto',
              minHeight: '20px',
              maxHeight: '150px',
            }}>
            {activeContainers.map((container, index) => {
              return (
                <tr
                  onClick={(e) => {
                    const el = document.getElementById(container.id);
                    // console.log(el);
                    // if (!el.contains(e.target)) {
                    // }
                  }}
                  id={container.id}
                  key={container.id}>
                  <td style={{ flex: '0.3' }}>{index + 1}</td>
                  <td style={{ flex: '1' }}>
                    {container.is_new && container.edit ? (
                      <input
                        defaultValue={container.name}
                        style={{ width: '100%', textAlign: 'center' }}
                        onChange={(e) =>
                          changeContainerInput(
                            container.id,
                            'name',
                            e.target.value
                          )
                        }
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            changeContainerInput(container.id, 'edit', false);
                          }
                        }}
                        id={`${container.id}_a`}
                        // onBlur={(e) => {
                        //   if (!isClicked) {
                        //     changeContainerInput(container.id, 'edit', false);
                        //   }
                        // }}
                        // onFocus={
                        //   (e) => setIsClicked(e.target.id)
                        //   // changeContainerInput(container.id, 'edit', true)
                        // }
                        type="text"
                      />
                    ) : (
                      container.name || 'Not Applicable'
                    )}
                  </td>
                  <td style={{ flex: '1' }}>
                    {container.is_new && container.edit ? (
                      <input
                        defaultValue={container.chases}
                        style={{ width: '100%', textAlign: 'center' }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            changeContainerInput(container.id, 'edit', false);
                          }
                        }}
                        id={`${container.id}_b`}
                        onChange={(e) =>
                          changeContainerInput(
                            container.id,
                            'chases',
                            e.target.value
                          )
                        }
                      />
                    ) : (
                      container.chases || 'Not Applicable'
                    )}
                  </td>
                  {/* <td style={{ flex: '1' }}>
                    {container.is_new ? (
                      <div className={cls.actions}>
                        <div className={cls.rowRound}>
                          <span
                            onClick={() =>
                              changeContainerInput(
                                container.id,
                                'own_chases',
                                1
                              )
                            }
                            className={clsx(
                              cls.chassisText,
                              container.own_chases === 1 && cls.activeChassis
                            )}>
                            Own
                          </span>
                          <span
                            onClick={() =>
                              changeContainerInput(
                                container.id,
                                'own_chases',
                                0
                              )
                            }
                            className={clsx(
                              cls.chassisText,
                              container.own_chases === 0 && cls.activeChassis
                            )}>
                            Pool
                          </span>
                        </div>
                      </div>
                    ) : container.own_chases === 1 ? (
                      'Own'
                    ) : (
                      'Pool'
                    )}
                  </td> */}
                  <td
                    style={{
                      flex: '0.7',
                      display: 'flex',
                      justifyContent: hasNewContainer
                        ? 'space-between'
                        : 'center',
                    }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={container.selected}
                          className={cls.checkbox}
                          // disabled={selectedContainers.length === accepted_count}
                          onChange={(e) =>
                            handleSelectContainer(
                              e,
                              container.name,
                              container.id,
                              container.chases,
                              container.own_chases,
                              container.is_new,
                              container.edit
                            )
                          }
                        />
                      }
                    />
                    {hasNewContainer && (
                      <div>
                        <CreateRounded
                          style={{
                            fill: container.is_new ? '#f8b100' : '#c1bfbf',
                            width: '20px',
                          }}
                        />
                        <div
                          name="edit"
                          onClick={() => {
                            container.is_new &&
                              // changeContainerInput(container.id, 'edit', true)
                              setActiveContainers((prev) => {
                                return prev.map((item) => {
                                  return {
                                    ...item,
                                    edit:
                                      container.id === item.id ? true : false,
                                  };
                                });
                              });
                          }}
                          className={cls.editDiv}></div>
                      </div>
                    )}
                    {hasNewContainer && (
                      <div
                        onClick={() =>
                          container.is_new &&
                          handleRemoveContainer(container.id)
                        }>
                        <DeleteRounded
                          style={{
                            fill: container.is_new ? '#e4464b' : '#c1bfbf',
                            width: '20px',
                            marginLeft: '5px',
                          }}
                        />
                      </div>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div style={{ display: 'flex', gap: '6px' }}>
        <Button
          style={{
            marginRight: 0,
            flex: '1',
            fontWeight: '600',
            fontFamily: 'Averta Bold',
            fontSize: '11px',
          }}
          onClick={() => handleAddNewContainer()}>
          <span
            name="add"
            style={{ width: '100%', height: '100%', display: 'block' }}>
            Add new Container
          </span>
        </Button>
        <Button
          style={{
            marginRight: 0,
            flex: '1',
            fontWeight: '600',
            fontFamily: 'Averta Bold',
            fontSize: '11px',
          }}
          onClick={() => handleShareContainers()}>
          Share
        </Button>
      </div>
    </div>
  );
};
