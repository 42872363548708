import React, { useState } from 'react';
import { Card, Button } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import { fDate, genSize, _get, SizeIcons } from '@/actions/Helper';
import styles from './styles';
import { Icons } from '@/assets';

const parseData = (data) => ({
  id: _get(data, 'id', 0),
  size: genSize(_get(data, 'cabin.specifications', {})),
  ssl: _get(data, 'cabin.company.name', ''),
  date: _get(data, 'updated_at', ''),
  loc: _get(data, 'address.city', 'Texas'),
  count: _get(data, 'count', 0),
  has_containers: _get(data, 'has_containers', ''),
  own_chases_count: _get(data, 'own_chases_count', ''),
  pool_chases_count: _get(data, 'pool_chases_count', ''),
  flip_available: _get(data, 'flip_available', ''),
  container_numbers: _get(data, 'container_numbers', ''),
});

export default ({ data, req }) => {
  const DATA = parseData(data);
  const cls = styles();
  const [hasContainerIcon, setHasContainerIcon] = useState();
  const hasContainer = () => {
    if (DATA.container_numbers) {
      setHasContainerIcon(Icons.has_containers);
    }
    // else if (DATA.has_containers == 1) {
    //   setHasContainerIcon(Icons.has_containers_half);
    // } else {
    //   setHasContainerIcon('');
    // }
  };
  React.useEffect(() => {
    hasContainer();
  }, []);

  return (
    <Card className={cls.card} style={{ margin: 10 }}>
      <ReactSVG src={SizeIcons(DATA.size)} className={cls.headerImg} />
      <div className={cls.hasContainersIcon}>
        {hasContainerIcon ? (
          <ReactSVG
            with="50px"
            src={hasContainerIcon}
            className={cls.hasContainersIcon}
          />
        ) : (
          ''
        )}
      </div>
      <div className={cls.content}>
        <div className={cls.row}>
          <span>SSL</span>
          <span className={cls.capital}>{DATA.ssl}</span>
        </div>
        <div className={cls.row}>
          <span>Number</span>
          <span className={cls.bold}>{DATA.count}</span>
        </div>
        <div className={cls.row}>
          <span>Location</span>
          <span className={cls.bold}>{DATA.loc}</span>
        </div>
        <div className={cls.row}>
          <span>Size</span>
          <span className={cls.bold}>{DATA.size}</span>
        </div>
        {/* <div className={cls.row}>
          <span>Last Update</span>
          <span className={cls.bold}>{fDate(DATA.date)}</span>
        </div>
        <div className={cls.row}>
          <span>Number of Own Chassis</span>
          <span className={cls.bold}>{DATA.own_available}</span>
        </div>{' '}
        <div className={cls.row}>
          <span>Number of Pool Chassis</span>
          <span className={cls.bold}>{DATA.pool_available}</span>
        </div>{' '}
        <div className={cls.row}>
          <span>Flip Available</span>
          <span className={cls.bold}>{DATA.flip_available ? 'Yes' : 'No'}</span>
        </div> */}
        <Button className={cls.but} onClick={() => req(DATA)}>
          Request
        </Button>
      </div>
    </Card>
  );
};
