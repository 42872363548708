import React, { useEffect, useState } from 'react';
import { CircularProgress } from '@material-ui/core';
import styles from './styles';
import Select from 'react-select';
import { adminChangeTmsSizeTypeModel } from '@/actions';

export default ({ setTablesData, tablesData, tmsName }) => {
  const cls = styles();
  const [selectedType, setSelectedType] = useState(null);
  const [loading, setLoading] = useState(false);
  let tableTypes = [
    // {
    //   value: 0,
    //   label: 'size-type',
    // },
    {
      value: 1,
      label: 'size-type-refer',
    },
    {
      value: 2,
      label: 'size-type/refer',
    },
    {
      value: 3,
      label: 'size/type-refer',
    },
    {
      value: 4,
      label: 'size/type/refer',
    },
  ];

  const getLabel = (value) => {
    let label = value !== 0 ? value : 1;
    switch (label) {
      case 1:
        return 'size-type-refer';
        break;
      case 2:
        return 'size-type/refer';
        break;
      case 3:
        return 'size/type-refer';
        break;
      case 4:
        return 'size/type/refer';
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (tablesData.size_type_model || tablesData.size_type_model === 0) {
      setSelectedType({
        value: tablesData.size_type_model,
        label: getLabel(tablesData.size_type_model),
      });
    }
  }, [tablesData]);

  const handleSelectSizeTypeModel = (sizeTypeModel) => {
    setLoading(true);
    setSelectedType(sizeTypeModel);
    adminChangeTmsSizeTypeModel(sizeTypeModel.value, tmsName.value).then(
      (data) => {
        setLoading(false);
        if (data) {
          setTablesData((prev) => {
            return {
              ...prev,
              ...data,
            };
          });
        }
      }
    );
  };

  const handleChangeSizeType = (name, value, key) => {
    let adaptedSizeType = tablesData.size_type;
    if (key) {
      adaptedSizeType[name][key] = value;
    } else {
      adaptedSizeType[name] = value;
    }
    setTablesData((prev) => {
      return {
        ...prev,
        size_type: adaptedSizeType,
      };
    });
  };

  const RenderTable = () => {
    switch (selectedType.value) {
      case 0:
        return (
          <table className={cls.list}>
            <thead>
              <tr>
                <th rowspan="2" style={{ flex: 0.7 }}>
                  SMART TURN
                </th>
                <th rowspan="2" style={{ flex: 1 }}>
                  SIZE-TYPE-REFER
                </th>
                <th colspan="2" style={{ flex: 0.7 }}>
                  USER
                </th>
              </tr>
              <tr>
                <th>SIZE-TYPE</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tablesData.size_type_default).map((key, index) => (
                <tr>
                  <td style={{ flex: 1 }}>{key}</td>
                  <td style={{ flex: 0.7 }}>
                    {tablesData.size_type_default[key]}
                  </td>
                  <td style={{ flex: 0.7 }}>
                    <input
                      type="text"
                      defaultValue={tablesData.size_type[key]}
                      value={tablesData.size_type[key]}
                      name={key}
                      onChange={(e) =>
                        handleChangeSizeType(e.target.name, e.target.value)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
        break;
      case 1:
        return (
          <table className={cls.list}>
            <thead>
              <tr>
                <th rowspan="2" style={{ flex: 0.7 }}>
                  SMART TURN
                </th>
                <th rowspan="2" style={{ flex: 1 }}>
                  SIZE-TYPE-REFER
                </th>
                <th colspan="1" style={{ flex: 0.7 }}>
                  USER
                </th>
              </tr>
              <tr>
                <th>SIZE-TYPE-REFER</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tablesData.size_type_default).map((key, index) => (
                <tr>
                  <td style={{ flex: 1 }}>{key}</td>
                  <td style={{ flex: 0.7 }}>
                    {tablesData.size_type_default[key]}
                  </td>
                  <td style={{ flex: 0.7 }}>
                    <input
                      type="text"
                      defaultValue={tablesData.size_type[key]}
                      value={tablesData.size_type[key]}
                      name={key}
                      onChange={(e) =>
                        handleChangeSizeType(e.target.name, e.target.value)
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
        break;
      case 2:
        return (
          <table className={cls.list}>
            <thead>
              <tr>
                <th rowspan="2" style={{ flex: 0.7 }}>
                  SMART TURN
                </th>
                <th rowspan="2" style={{ flex: 1 }}>
                  SIZE-TYPE-REFER
                </th>
                <th colspan="2" style={{ flex: 0.7 }}>
                  USER
                </th>
              </tr>
              <tr>
                <th>SIZE-TYPE</th>
                <th>REFER</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tablesData.size_type_default).map((key, index) => (
                <tr>
                  <td style={{ flex: 1 }}>{key}</td>
                  <td style={{ flex: 0.7 }}>
                    {tablesData.size_type_default[key]}
                  </td>
                  <td style={{ flex: 0.7 }}>
                    <input
                      type="text"
                      defaultValue={tablesData.size_type[key].st}
                      value={tablesData.size_type[key].st}
                      name={key}
                      onChange={(e) =>
                        handleChangeSizeType(
                          e.target.name,
                          e.target.value,
                          'st'
                        )
                      }
                    />
                  </td>
                  <td style={{ flex: 0.7 }}>
                    <input
                      type="text"
                      defaultValue={tablesData.size_type[key].r}
                      value={tablesData.size_type[key].r}
                      name={key}
                      onChange={(e) =>
                        handleChangeSizeType(e.target.name, e.target.value, 'r')
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
        break;
      case 3:
        return (
          <table className={cls.list}>
            <thead>
              <tr>
                <th rowspan="2" style={{ flex: 0.7 }}>
                  SMART TURN
                </th>
                <th rowspan="2" style={{ flex: 1 }}>
                  SIZE-TYPE-REFER
                </th>
                <th colspan="2" style={{ flex: 0.7 }}>
                  USER
                </th>
              </tr>
              <tr>
                <th>SIZE</th>
                <th>TYPE-REFER</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tablesData.size_type_default).map((key, index) => (
                <tr>
                  <td style={{ flex: 1 }}>{key}</td>
                  <td style={{ flex: 0.7 }}>
                    {tablesData.size_type_default[key]}
                  </td>
                  <td style={{ flex: 0.7 }}>
                    <input
                      type="text"
                      defaultValue={tablesData.size_type[key].s}
                      value={tablesData.size_type[key].s}
                      name={key}
                      onChange={(e) =>
                        handleChangeSizeType(e.target.name, e.target.value, 's')
                      }
                    />
                  </td>
                  <td style={{ flex: 0.7 }}>
                    <input
                      type="text"
                      defaultValue={tablesData.size_type[key].tr}
                      value={tablesData.size_type[key].tr}
                      name={key}
                      onChange={(e) =>
                        handleChangeSizeType(
                          e.target.name,
                          e.target.value,
                          'tr'
                        )
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
        break;
      case 4:
        return (
          <table className={cls.list}>
            <thead>
              <tr>
                <th rowspan="3" style={{ flex: 1 }}>
                  SMART TURN
                </th>
                <th rowspan="3" style={{ flex: 1 }}>
                  SIZE-TYPE-REFER
                </th>
                <th colspan="3" style={{ flex: 0.7 }}>
                  USER
                </th>
              </tr>
              <tr>
                <th>SIZE</th>
                <th>TYPE</th>
                <th>REFER</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(tablesData.size_type_default).map((key, index) => (
                <tr>
                  <td style={{ flex: 1 }}>{key}</td>
                  <td style={{ flex: 1 }}>
                    {tablesData.size_type_default[key]}
                  </td>
                  <td style={{ flex: 0.7 }}>
                    <input
                      type="text"
                      defaultValue={tablesData.size_type[key].s}
                      value={tablesData.size_type[key].s}
                      name={key}
                      onChange={(e) =>
                        handleChangeSizeType(e.target.name, e.target.value, 's')
                      }
                    />
                  </td>
                  <td style={{ flex: 0.7 }}>
                    <input
                      type="text"
                      defaultValue={tablesData.size_type[key].t}
                      value={tablesData.size_type[key].t}
                      name={key}
                      onChange={(e) =>
                        handleChangeSizeType(e.target.name, e.target.value, 't')
                      }
                    />
                  </td>
                  <td style={{ flex: 0.7 }}>
                    <input
                      type="text"
                      defaultValue={tablesData.size_type[key].r}
                      value={tablesData.size_type[key].r}
                      name={key}
                      onChange={(e) =>
                        handleChangeSizeType(e.target.name, e.target.value, 'r')
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        );
        break;
      default:
        break;
    }
  };

  return (
    <div>
      <div className={cls.header}>
        <h3>SIZE-TYPE-REFER NAMING</h3>
        <Select
          isClearable
          placeholder="Type"
          className={cls.select}
          defaultValue={selectedType}
          value={selectedType}
          onChange={(item) => {
            handleSelectSizeTypeModel(item);
          }}
          options={tableTypes}
        />
      </div>
      <div className={cls.listContainer}>
        {selectedType &&
          (loading ? (
            <div className={cls.loaderContainer}>
              <CircularProgress className={cls.loader} />
            </div>
          ) : (
            <>{RenderTable()}</>
          ))}
        {!selectedType && (
          <table className={cls.list}>
            <thead>
              <tr>
                <th rowspan="3" style={{ flex: 0.7 }}>
                  SMART TURN
                </th>
                <th colspan="3" style={{ flex: 0.7 }}>
                  USER
                </th>
                <th rowspan="3" style={{ flex: 1 }}>
                  size_type
                </th>
              </tr>
            </thead>
          </table>
        )}
      </div>
    </div>
  );
};
