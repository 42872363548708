import React from 'react';
import styles from './styles';
import { Tooltip, IconButton } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { useOutsideAlerter } from '@/hocs';
import { fTimeDate } from '@/actions/Helper';
import { DeleteRounded } from '@material-ui/icons';
import { removeSaveSearch } from '@/actions';

const SavedSearches = ({
  Close,
  savedSearches,
  notify,
  fetchSavedSearches,
}) => {
  const cls = styles();

  const handleDeleteSearch = (id) => {
    removeSaveSearch(id)
      .then((res) => {
        fetchSavedSearches();
        notify('Successfully Deleted', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    Close();
  });

  return (
    <div className={cls.popup}>
      <div ref={wrapperRef} className={cls.popupCard}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.close} onClick={Close} />
        </Tooltip>
        <div className={cls.tableContainer}>
          <table className={cls.searchesTable}>
            <thead>
              <tr>
                <th style={{ flex: '3' }}>Expiration Date</th>
                <th style={{ flex: '2' }}>Size</th>
                <th style={{ flex: '2.5' }}>SSL</th>
                <th style={{ flex: '5' }}>Location</th>
                <th style={{ flex: '0.5' }}>Number</th>
                <th style={{ flex: '2' }}>Chassis</th>
                <th style={{ flex: '1.5' }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {savedSearches.map((item, index) => (
                <tr key={index}>
                  <td style={{ flex: '3' }}>
                    <span>
                      {fTimeDate(
                        new Date(item.created).setDate(
                          new Date(item.created).getDate() + 1
                        )
                      )}
                    </span>
                  </td>
                  <td style={{ flex: '2' }}>{item.size}</td>
                  <td style={{ flex: '2.5' }}>{item.ssl}</td>
                  <td className={cls.doted} style={{ flex: '5' }}>
                    {item.address.split(',')[0] +
                      ',' +
                      item.address.split(',')[1]}
                  </td>
                  <td style={{ flex: '0.5' }}>{item.active}</td>
                  <td style={{ flex: '2' }}>
                    {item.chases === 'both' ? 'Own/Pool' : item.chases}
                  </td>
                  <td style={{ flex: '1.5' }}>
                    <Tooltip arrow title="Delete" placement="top">
                      <IconButton
                        onClick={() => handleDeleteSearch(item.id)}
                        aria-label="delete">
                        <DeleteRounded className={cls.delIcon} />
                      </IconButton>
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <button
          className={cls.closeBtn}
          onClick={() => {
            Close();
          }}>
          Close
        </button>
      </div>
    </div>
  );
};

export default SavedSearches;
