import { makeStyles } from '@material-ui/core/styles';
import { LandingIcon } from '@/assets';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 70px)',
    width: '100%',
    backgroundImage: `url(${LandingIcon.Bg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    padding: '0 40px',
    [BP.down('sm')]: {
      flexDirection: 'column',
      padding: '2rem',
      backgroundPosition: 'center',
      height: 'auto',
      boxSizing: 'border-box',
    },
  },

  flex4: {
    display: 'flex',
    flex: 4,
  },
  flex6: {
    display: 'flex',
    flexDirection: 'column',
    flex: 6,
    [BP.down('sm')]: {
      margin:'100px 0 50px 0'
    },
  },
  h1: {
    fontSize: 81,
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    lineHeight: 1.25,
    color: '#303030',
    textAlign: 'left',
    margin: '20px 0 40px 0',
    [BP.down('xs')]: {
      fontSize: '2rem',
      margin: 0,
      marginBottom: 5,
    },
    [BP.down('sm')]: {
      fontSize: 60,
    },
  },
  h2: {
    fontSize: 26,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.5,
    color: '#303030',
    textAlign: 'left',
    [BP.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  h3: {
    fontSize: 20,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.25,
    color: '#303030',
    textAlign: 'left',
    [BP.down('xs')]: {
      fontSize: '1rem',
    },
  },

  row: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    textDecorationColor: '#303030',
    [BP.down('xs')]: {
      flex: 0,
    },
  },
  icon: {
    color: '#303030',
    marginRight: 10,
    height: 25,
    width: 25,
  },
}));
