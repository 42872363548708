import React, { useState } from 'react';
import {
  Tooltip,
  Button,
  IconButton,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import styles from './styles';
import { changeNotifications, saveChassis } from '@/actions';
import { useNotify } from 'react-admin';
import { useOutsideAlerter } from '@/hocs';
import closeIcon from '../../../assets/img/closeIcon.svg';

export default ({
  Close,
  DATA,
  setDATA,
  setChassis,
  notification,
  setnotification,
}) => {
  const cls = styles();
  const notify = useNotify();

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    Close();
  });

  const handleSendData = () => {
    setnotification((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    changeNotifications(notification.DATA)
      .then((res) => {
        setnotification((prev) => {
          return {
            ...prev,
            loading: false,
            DATA: res.notification,
          };
        });
        notify('Notifications Successfully Updated!', 'success');
        Close();
      })
      .catch((err) => {
        setnotification((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
        notify(err, 'warning');
      });
  };

  const handleChangeNotification = (option, value) => {
    setnotification((prev) => {
      return {
        ...prev,
        DATA: {
          ...prev.DATA,
          [option]: value,
        },
      };
    });
  };

  return (
    <div className={cls.popup}>
      <div ref={wrapperRef} className={cls.popupCard}>
        <Tooltip arrow title="Close" placement="top">
          <div onClick={Close} className={cls.close}>
            <img alt="close_icon" src={closeIcon} />
          </div>
        </Tooltip>
        <div>
          <h3 className={cls.notifTitle}>Notification Options</h3>
          <div>
            <div className={cls.optionContainer}>
              <span>Email</span>
              <FormControlLabel
                style={{ margin: 0 }}
                className={`${
                  notification.DATA.email
                    ? cls.enabledContainer
                    : cls.disabledContainer
                }`}
                control={
                  <Checkbox
                    checked={notification.DATA.email ? true : false}
                    // onChange={(e) =>
                    //   handleChangeNotification('email', e.target.checked)
                    // }
                  />
                }
              />
            </div>
            <div className={cls.optionContainer}>
              <span>SMS</span>
              <FormControlLabel
                style={{ margin: 0 }}
                className={`${
                  notification.DATA.sms
                    ? cls.enabledContainer
                    : cls.disabledContainer
                }`}
                control={
                  <Checkbox
                    checked={notification.DATA.sms ? true : false}
                    onChange={(e) =>
                      handleChangeNotification('sms', e.target.checked)
                    }
                  />
                }
              />
            </div>
            <div className={cls.optionContainer}>
              <span>Whatsapp</span>
              <FormControlLabel
                style={{ margin: 0 }}
                className={`${
                  notification.DATA.whatsapp
                    ? cls.enabledContainer
                    : cls.disabledContainer
                }`}
                control={
                  <Checkbox
                    checked={notification.DATA.whatsapp ? true : false}
                    onChange={(e) =>
                      handleChangeNotification('whatsapp', e.target.checked)
                    }
                  />
                }
              />
            </div>
          </div>
        </div>
        <div className={cls.btnDiv}>
          <Button className={cls.saveBtn} onClick={() => handleSendData()}>
            {notification.loading ? (
              <CircularProgress className={cls.loadIcon} />
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
