import React from 'react';
import styles from './styles';
import { Avatar, IconButton } from '@material-ui/core';
import {ArrowForwardIos} from '@material-ui/icons'
import { SaveData } from '@/actions';

export default ({ avatar, name, lastSeen }) => {
  const cls = styles();
  return (
    <div className={cls.chatHeader}>
      <div className={cls.userInfo}>
        <Avatar src={avatar} className={cls.userAvatar} />
        <div>
          <span className={cls.userName}>{name}</span>
          <p className={cls.lastSeen}>{`Last Seen ${lastSeen}`}</p>
        </div>
      </div>
      <span
        onClick={() => {
          SaveData({ Chat: null });
        }}>
        <IconButton className={cls.backIcon}>
          <ArrowForwardIos/>
        </IconButton>
      </span>
    </div>
  );
};
