import React, { useEffect, useState } from 'react';
import { useMediaQuery, Grid } from '@material-ui/core';
import {
  StatCard,
  WelcomeCard,
  OrderList,
  RequestList,
  ReportCard,
  Inventory,
} from '@/components/Dashboard';
import styles from './styles';
import { usePermissions } from 'react-admin';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useNotify } from 'react-admin';
import { checkUpdate, updateSamrtApp } from '@/actions';
import UpdatePopup from '@/components/UpdatePopup';

export default (props) => {
  const isSmall = useMediaQuery('(max-width:1439px)');
  const history = useHistory();
  const cls = styles({ isSmall });
  const [updateApp, setUpdateApp] = useState(false);
  const [updateNotification, setUpdateNotification] = useState({
    title: 'We Have a New Update Available!',
    message: '',
  });
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const { permissions } = usePermissions();

  const updateNotif = useSelector((state) =>
    state.reducer.notif ? state.reducer.notif.update : null
  );
  const user = useSelector((state) => state.reducer.user);

  const notify = useNotify();

  let token = localStorage.getItem('token');

  const checkUpdateApp = () => {
    if (token) {
      checkUpdate().then((res) => {
        if (res.update === 1) {
          setUpdateApp(true);
        }
        if (res.notification) {
          setUpdateNotification(res.notification);
        }
      });
    }
  };

  const update = () => {
    setLoadingUpdate(true);
    updateSamrtApp()
      .then((res) => {
        setLoadingUpdate(false);
        notify('App Successfully Updated', 'success');
        window.location.reload(true);
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    checkUpdateApp();
  }, []);

  useEffect(() => {
    checkUpdateApp();
  }, [token]);

  useEffect(() => {
    if (updateNotif && token) {
      setUpdateApp(true);
      setUpdateNotification({
        title: updateNotif.title,
        message: updateNotif.body,
      });
    }
  }, [updateNotif]);

  useEffect(() => {
    if (permissions === 'admin') {
      history.push('/members');
    }
  }, [permissions]);

  return (
    <div className={cls.content}>
      {updateApp && (
        <UpdatePopup
          updateNotification={updateNotification}
          update={update}
          loadingUpdate={loadingUpdate}
        />
      )}
      {/* {isSmall && (
        <div className={cls.col}>
          {permissions === 'user' &&
            <WelcomeCard />
          }

          <ReportCard />
          <StatCard />
          <Inventory />
          <RequestList />
          <OrderList />
        </div>
      )} */}
      {permissions === 'user' && [
        <Grid container>
          <Grid item xs={12} lg={6}>
            <div className={cls.col1}>
              <WelcomeCard />
              <RequestList />
              <OrderList />
            </div>
          </Grid>
          <Grid item xs={12} lg={6}>
            <div className={cls.col2}>
              <ReportCard />
              <StatCard />
              <Inventory />
            </div>
          </Grid>
        </Grid>,
      ]}
    </div>
  );
};
