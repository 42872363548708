import React from 'react';
import { ReactSVG } from 'react-svg';
import { LandingIcon } from '@/assets';
import styles from './styles';

export default (props) => {
  const cls = styles();
  return (
    <div className={cls.container}>
      <div className={cls.col}>
        <img src={LandingIcon.App} alt="some" className={cls.img} />
      </div>
      <div className={cls.col}>
        <div className={cls.des}>
          "Smart-Turn is accessible over both Android and IOS devices. Use the
          below links to download the app and use the Smart-Turn platform
          anywhere, anytime"
        </div>
        <div className={cls.row}>
          <a
            className={cls.icon}
            href="/#"
            title="appstore"
            target="_blank"
            rel="noopener noreferrer">
            <ReactSVG src={LandingIcon.appstore} />
          </a>
          <a
            className={cls.icon2}
            href="/#"
            title="google"
            target="_blank"
            rel="noopener noreferrer">
            <ReactSVG src={LandingIcon.google} />
          </a>
        </div>
      </div>
    </div>
  );
};
