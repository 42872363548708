import React from 'react';
import { Tooltip } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import styles from './styles';
import closeIcon from '../../../assets/img/closeIcon.svg';
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { useOutsideAlerter } from '@/hocs';

export default ({ Close, DATA, open }) => {
  const cls = styles();
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    Close();
  });
  return (
    <>
      <div>
        <Modal open={open}>
          <div
            id="app_modal"
            className={cls.modal}
            onClick={
              (e) => console.log('hI')
              // e.target.id === 'app_modal' && setShowContainersModal(false)
            }
            style={{ width: '100%', height: '100%' }}>
            <Box className={cls.modalBox}>
              <div
                // onClick={() => setShowContainersModal(false)}
                className={cls.closeModal}>
                <img alt="close_icon" src={closeIcon} />
              </div>
              <h3 className={cls.modalTitle}>Own Chassis</h3>
              <div className={cls.containersTableContainer}>
                {/* {gatEditContainersContent()} */}
              </div>
              <button
                className={cls.updateContainersBtn}
                // onClick={() => setShowContainersModal(false)}
              >
                Close
              </button>
            </Box>
          </div>
        </Modal>
      </div>
      <div className={cls.popup}>
        <div ref={wrapperRef} className={cls.popupCard}>
          <Tooltip arrow title="Close" placement="top">
            <CloseRounded className={cls.close} onClick={Close} />
          </Tooltip>
          <h3 className={cls.modalTitle}>Own Chassis</h3>

          <div className={cls.tableContainer}>
            <table className={cls.chassisTable}>
              <thead>
                <tr>
                  <th style={{ flex: '0.6' }}>#</th>
                  <th style={{ flex: '1' }}>Chassis#</th>
                </tr>
              </thead>
              <tbody>
                {DATA.map((item, index) => (
                  <tr key={index}>
                    <td style={{ flex: '0.6' }}>{index + 1}</td>
                    <td style={{ flex: '1' }}>
                      <span>{item.name}</span>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};
