import React from 'react';
import { Loading } from 'react-admin';
import { Header, List, Map } from '@/components/Search';
import { _get, genSize } from '@/actions/Helper';
import styles from './styles';
import SavedSearches from '../Search/SavedSearches';
import { Grid, Button, Tooltip } from '@material-ui/core';
import { List as ListIcon } from '@material-ui/icons';

const parseData = (data) =>
  data.map((item) => ({
    //map
    name: _get(item, 'address.city', ''),
    pos: {
      lat: _get(item, 'address.lat', ''),
      lng: _get(item, 'address.long', ''),
    },

    // list
    id: _get(item, 'id', 0),
    size: genSize(_get(item, 'cabin.specifications', {})),
    pro: _get(item, 'cabin.company.name', ''),
    count: _get(item, 'count_available', 0),
    loc: _get(item, 'address.city', ''),
    date: _get(item, 'updated_at', ''),
    has_containers: _get(item, 'has_containers', ''),
    container_numbers: _get(item, 'container_numbers', ''),
    flip_available: _get(item, 'flip_available', ''),
    own_chases_count: _get(item, 'own_chases_count', ''),
    pool_chases_count: _get(item, 'pool_chases_count', ''),
    chase_type: _get(item, 'chase_type', ''),
    count_available: _get(item, 'count_available', 0),
    own_available: _get(item, 'own_available', 0),
    pool_available: _get(item, 'pool_available', 0),
  }));

export default ({
  showSavesSearches,
  savedSearches,
  setShowSavedSearches,
  notify,
  fetchSavedSearches,
  state,
  query,
  handleChangeNmuber,
  showNotify,
  activeNotify,
  smart,
  req,
  isExport,
}) => {
  const cls = styles();

  return (
    <div className={cls.container}>
      {showSavesSearches && (
        <SavedSearches
          savedSearches={savedSearches}
          Close={() => setShowSavedSearches(false)}
          notify={notify}
          fetchSavedSearches={fetchSavedSearches}
        />
      )}
      {state.DATA.length !== 0 && (
        <>
          <Header DATA={state.DATA} q={query} RemNum={state.RemNum} />
          <div className={cls.headerBar}>
            <span className={cls.title}>Available Containers</span>
          </div>
        </>
      )}

      {state.loading ? (
        <Loading />
      ) : (
        <>
          {state.DATA.length !== 0 && (
            <div className={cls.listContainer}>
              <List
                handleChangeNmuber={handleChangeNmuber}
                DATA={state.DATA}
                req={req}
                query={query}
                isExport={isExport}
              />
            </div>
          )}
          {showNotify && (
            <Grid container spacing={2} style={{ marginBottom: '15px' }}>
              <Grid item xs={12}>
                <div className={cls.card1} style={{ flex: 1 }}>
                  <div className={cls.col2}>
                    <h3>No Inventory has been found for this search!</h3>
                    <h3>
                      Click Notify, and we will notify you as soon as new
                      inventory becomes available that matches your search.
                    </h3>
                    <div className={cls.notifyContainer}>
                      <Button
                        onClick={() => activeNotify()}
                        to="search"
                        className={cls.notify}>
                        Notify
                      </Button>
                      <Button
                        onClick={() => setShowSavedSearches(true)}
                        className={cls.notifList}>
                        <Tooltip
                          arrow
                          title="Saved Searches List"
                          placement="top">
                          <ListIcon style={{ color: '#ffffff' }} />
                        </Tooltip>
                      </Button>
                    </div>
                  </div>
                </div>
              </Grid>
            </Grid>
          )}
          <Map DATA={state.DATA} smart={smart} />
        </>
      )}
    </div>
  );
};
