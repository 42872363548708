import React from 'react';
import clsx from 'clsx';
import styles from './styles';

export default (props) => {
  const cls = styles();
  return (
    <div className={cls.container}>
      <div className={cls.title}>HOW IT WORKS?</div>

      <div className={cls.listCont}>
        <div className={cls.list}>
          <div className={clsx(cls.circleCont)}>
            <span className={cls.circle}>1</span>
            <div className={cls.desCont}>
              <div className={cls.desTitle}>Sign Up</div>
              <div className={cls.des}>
                Join for free and make your operation easier
              </div>
            </div>
          </div>
          <div className={cls.circleContContainer}>
            <div className={cls.hrContainer}>
              <span className={cls.hr} />
            </div>
            <div className={cls.circleCont}>
              <span className={cls.circle}>2</span>
              <div className={cls.desCont}>
                <div className={cls.desTitle}>Smart Search</div>
                <div className={cls.des}>
                  Offer your available empties or find any empty you may need
                </div>
              </div>
            </div>
          </div>
          <div className={cls.circleContContainer}>
            <div className={cls.hrContainer}>
              <span className={cls.hr} />
            </div>
            <div className={cls.circleCont}>
              <span className={cls.circle}>3</span>
              <div className={cls.desCont}>
                <div className={cls.desTitle}>Make a Deal</div>
                <div className={cls.des}>
                  Chat with the other party using our platform and finalize your
                  deal
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
