import React, { useEffect, useMemo } from 'react';
import { Button, TextField, Tooltip, Grid } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import Select from 'react-select';
import clsx from 'clsx';
import $ from 'jquery';
import { MemberIcons as memberIcons, Icons } from '@/assets';
import styles from './styles';
import { BaseIcon } from '@/Utils';
import debounce from 'lodash.debounce';
import { getAdminMembers } from '@/actions';

const formInputs = [
  {
    name: 'name',
    type: 'text',
    placeholder: 'First Name',
  },
  {
    name: 'last_name',
    type: 'text',
    placeholder: 'last Name',
  },
  {
    name: 'email',
    type: 'email',
    placeholder: 'Email',
  },
  {
    name: 'phone',
    type: 'text',
    placeholder: 'Phone',
  },
  {
    name: 'company',
    type: 'text',
    placeholder: 'Company',
  },
];

export default ({
  DATA = [],
  filter = {},
  setFilter,
  setDATA,
  notify,
  setFilterForm,
}) => {
  const cls = styles();
  const statusOp = [
    {
      label: '1',
      value: '1',
    },
    {
      label: '2',
      value: '2',
    },
    {
      label: '3',
      value: '3',
    },
  ];

  const [form, setFormData] = React.useState({
    name: null,
    last_name: null,
    email: null,
    phone: null,
    company: '',
    status: '',
  });
  const setForm = (i, v) => setFormData({ ...form, [i]: v });

  React.useEffect(() => {
    if (filter) $('.filter-card').slideDown();
    else $('.filter-card').slideUp();
  }, [filter]);

  const Filter = () => {
    let arr = DATA;
    setFilterForm(form);
    // getAdminMembers(form)
    //   .then((res) => {
    //     let DATA = res.data;
    //     setDATA((prev) => {
    //       return {
    //         loading: false,
    //         DATA: DATA,
    //       };
    //     });
    //   })
    //   .catch((err) => {
    //     notify(err, 'warning');
    //   });
  };

  const handleSearch = (e) => {
    getAdminMembers({ s: e.target.value })
      .then((res) => {
        let DATA = res.data;
        setDATA((prev) => {
          return {
            loading: false,
            DATA: DATA,
          };
        });
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const debouncedResults = useMemo(() => {
    return debounce(handleSearch, 5000);
  }, []);

  useEffect(() => {
    return () => {
      debouncedResults.cancel();
    };
  });

  const Close = () => setFilter(false);
  const Toggle = () => setFilter(!filter);

  return (
    <div className={cls.filterContainer}>
      <div className={cls.search}>
        <div className={cls.searchBox}>
          <BaseIcon src={memberIcons.search} className={cls.searchIcon} />
          <input
            className={cls.searchInput}
            type="text"
            placeholder="Find Members"
            onChange={(e) => handleSearch(e)}
          />
        </div>
        <div onClick={Toggle} className={cls.filter}>
          <BaseIcon src={Icons.filter} className={cls.filterIcon} />
          <span className={cls.filterTitle}>Filter</span>
        </div>
      </div>
      <div
        className={clsx(cls.card, 'filter-card')}
        style={{ display: 'none' }}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.close} onClick={Close} />
        </Tooltip>
        <Grid container spacing={2}>
          {formInputs.map((item, index) => (
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                type={item.type}
                key={index}
                placeholder={item.placeholder}
                className={clsx(cls.input)}
                value={form.num}
                onChange={(e) => setForm(item.name, e.target.value)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ min: 0 }}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={3}>
            <Select
              isClearable
              placeholder="Status"
              className={clsx(cls.select)}
              value={form.status}
              onChange={(item) => setForm('status', item)}
              options={statusOp}
            />
          </Grid>
        </Grid>

        <div className={cls.row2}>
          <Button className={cls.but} onClick={Filter}>
            Filter
          </Button>
        </div>
      </div>
    </div>
  );
};
