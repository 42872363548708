import React from 'react';
import { Link } from 'react-router-dom';
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  Divider,
  IconButton,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import {
  MenuRounded,
  ChevronLeftRounded,
  MailRounded,
} from '@material-ui/icons';
import clsx from 'clsx';
import { HeaderIcons } from '@/assets';
import styles from './styles';
import { LoggedIn } from '@/actions/Helper';

export default function PersistentDrawerLeft() {
  const cls = styles();
  const [open, setOpen] = React.useState(false);
  const Item = ({ label, to, icon }) => (
    <ListItem button>
      <ListItemIcon>{icon}</ListItemIcon>
      <Link to={to}>{label}</Link>
    </ListItem>
  );

  return (
    <div className={cls.header}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(cls.appBar, open && cls.appBarShift)}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={() => setOpen(true)}
            edge="start"
            className={clsx(cls.menuButton, open && cls.hide)}>
            <MenuRounded />
          </IconButton>
          <div className={cls.headerCont}>
            <Link to="/">
              <img src={HeaderIcons.logo} alt="Logo" className={cls.logo} />
            </Link>
            {LoggedIn() ? (
              <Link
                to="/dashboard"
                className={cls.but}
                rel="noopener noreferrer">
                Dashboard
              </Link>
            ) : (
              <Link to="/login" className={cls.but} rel="noopener noreferrer">
                Login
              </Link>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Drawer
        className={cls.drawer}
        variant="persistent"
        anchor="left"
        open={open}
        classes={{ paper: cls.drawerPaper }}>
        <div className={cls.drawerHeader}>
          <IconButton onClick={() => setOpen(false)}>
            <ChevronLeftRounded />
          </IconButton>
        </div>
        <Divider />
        <List className={cls.list}>
          <Item to="/" label="HOME" icon={<MailRounded />} />
          <Item
            to="/how-it-works"
            label="HOW IT WORKS"
            icon={<MailRounded />}
          />
          <Item to="/about" label="ABOUT" icon={<MailRounded />} />
          <Item to="/contact" label="CONTACT US" icon={<MailRounded />} />
        </List>
      </Drawer>
    </div>
  );
}
