import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  card: {
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    margin: '10px',
    '& a': {
      textDecoration: 'none',
    },
    height: 463.2,
    padding: '20px 15px',
    boxSizing: 'border-box',
  },
  title: {
    fontSize: '1.4rem',
    fontFamily: 'Averta Bold',
    color: '#303030',
    fontWeight: 'bold',
    [BP.up('xl')]: {
      fontSize: 25,
    },
  },
  see: {
    fontSize: '0.7rem',
    fontFamily: 'Averta',
    color: '#303030',
    fontWeight: 600,
  },
  header: {
    '& tr': {
      background: '#000000',
    },
  },
  table: {
    width: '100%',
    '& th': {
      fontSize: '0.7rem',
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      color: '#ffffff',
      padding: 5,
      textAlign: 'center',
      border: 'none',
      backgroundColor: 'transparent',
      [BP.up('xl')]: {
        fontSize: 10,
      },
    },
    '& td': {
      fontSize: 9,
      fontFamily: 'Averta Light',
      fontWeight: 300,
      color: '#303030',
      padding: '10px 0',
      textAlign: 'center',
      border: 'none',
      [BP.up('xl')]: {
        fontSize: 10,
        padding: '15px 0',
      },
    },
    '& tr': {
      fontSize: 9,
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      color: '#303030',
      textAlign: 'center',
      [BP.up('xl')]: {
        fontSize: 10,
      },
    },
  },
  tableCont: {
    maxHeight: 380,
    overflowY: 'auto',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0 10px 10px 5px',
  },
  progressWrapper: {
    display: 'flex',
    height: 3,
    width: 80,
    [BP.up('xl')]: {
      width: 120,
    },
  },
  progressBar: {
    display: 'flex',
    borderRadius: 3,
    backgroundColor: '#dfdfdf',
    width: '100%',
  },
  progress: {
    height: 3,
    backgroundColor: '#f8b100',
    borderRadius: 3,
  },
  blue: {
    color: '#007fff',
  },
  green: {
    color: '#44a570',
  },
  black: {
    color: '#303030',
  },
  statCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    fontFamily: 'Averta Bold',
    fontSize: 9,
    fontWeight: 'bold',
    lineHeight: 1.8,
    [BP.up('xl')]: {
      fontSize: 10,
    },
  },
  separator: {
    height: 10,
  },
  launchIcon: {
    width: 15,
    height: 15,
    fill: '#303030',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}));
