import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: '0 4vw',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
}));
