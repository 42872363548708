import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  card: {
    height: 150,
    borderRadius: 5,
    boxShadow: '0 0 44px 0 rgba(49, 56, 150, 0.03)',
    margin: '10px',
  },

  content: {
    padding: '10px 20px',
    display: 'flex',
    flexDirection: 'column',
    height: '-webkit-fill-available',
    transition: 'all 0.7s ease-in',
  },
  yellow: {
    backgroundImage: 'linear-gradient(99deg, #f8b100 2%, #ffc27d)',
  },
  green: {
    backgroundImage: 'linear-gradient(99deg, #44a570 2%, #69ca95)',
  },
  black: {
    backgroundImage: 'linear-gradient(252deg, #5e5e5e 104%, #303030 -16%)',
  },
  red: {
    backgroundImage: 'linear-gradient(103deg, #e4464b 2%, #f56b9d 98%)',
  },

  title: {
    fontFamily: 'Averta Bold',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    lineHeight: 0.96,
    color: ({ white }) => (white ? '#fff' : '#303030'),
    [BP.up('xl')]: {
      fontSize: 25,
    },
  },
  des: {
    fontFamily: 'Averta Bold',
    fontSize: '0.7rem',
    fontWeight: 600,
    lineHeight: 1.67,
    color: ({ white }) => (white ? '#fff' : '#43424b'),
    [BP.up('xl')]: {
      fontSize: 15,
    },
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'center',
  },
  flex: {
    display: 'flex',
  },
  row: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  percent: {
    fontFamily: 'Averta Bold',
    fontSize: '2.3rem',
    fontWeight: 800,
    lineHeight: 0.8,
    color: ({ white }) => (white ? '#fff' : '#303030'),
    [BP.up('xl')]: {
      fontSize: 50,
    },
  },
  icon: {
    width: 25,
    height: 35,
    objectFit: 'contain',
    marginLeft: 5,
    transform: ({ neg }) => (neg ? 'rotate(180deg)' : ''),
    [BP.up('xl')]: {
      width: 32,
      height: 48,
    },
  },

  hr: {
    height: 2,
    width: 100,
    backgroundColor: ({ white }) => (white ? '#fff' : '#303030'),
    borderRadius: 2,
    margin: '0px 10px 0 -5px',
    [BP.up('xl')]: {
      width: 120,
    },
  },

  row2: {
    display: 'flex',
    alignItems: 'center',
  },
  circle: {
    display: 'block',
    width: 11,
    height: 11,
    backgroundColor: (props) => (props.white ? '#fff' : '#303030'),
    borderRadius: '50%',
  },

  // index style
  itemRow: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-evenly',
    flexWrap: 'wrap'
  },
  itemCol: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
}));
