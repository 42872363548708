import React from 'react';
import { ReactSVG } from 'react-svg';

export const BaseIcon = ({
  src,
  className = '',
  removeFill = true,
  ...rest
}) => (
  <ReactSVG
    src={src}
    beforeInjection={(svg) => {
      className
        .split(' ')
        .map((singleClassName) => svg.classList.add(singleClassName));
      if (removeFill) {
        svg.removeAttribute('fill');
        const children = [...svg.querySelectorAll('*')];
        children.forEach((child) => child.removeAttribute('fill'));
      }
    }}
    {...rest}
  />
);

