import React from 'react';
import { Grid } from '@material-ui/core';
import { ArrowDropDownRounded } from '@material-ui/icons';
import styles from './styles';
import $ from 'jquery';

export default ({ title, color = 'yellow', white, children }) => {
  const cls = styles({ white, color });

  return (
    <div className={cls.card}>
      <div
        className={cls.bar}
        onClick={() => $('#content-' + color).slideToggle()}>
        <span className={cls.title}>{title}</span>
        <ArrowDropDownRounded className={cls.icon} />
      </div>
      <div id={'content-' + color} className={cls.content}>
        <Grid container justify="flex-start" spacing={2}>
          {children.map((item, idx) => (
            <Grid item lg={4} key={idx}>
              {item}
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};
