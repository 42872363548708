import React, { useState } from 'react';
import {
  Tooltip,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import { CloseRounded, DeleteSharp } from '@material-ui/icons';
import styles from './styles';
import { importChassis, saveChassis } from '@/actions';
import { useNotify } from 'react-admin';
import { useOutsideAlerter } from '@/hocs';
import closeIcon from '../../../assets/img/closeIcon.svg';

export default ({ Close, DATA, setDATA, setChassis }) => {
  const [sendingChassis, setSendingChassis] = useState(null);
  const cls = styles();
  const notify = useNotify();

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    Close();
  });

  const handleChangeInput = (e, index) => {
    let adaptedData = [
      ...DATA.slice(0, index),
      {
        ...DATA[index],
        name: e.target.value,
      },
      ...DATA.slice(index + 1),
    ];
    setDATA(adaptedData);
  };

  const handleSendData = () => {
    setSendingChassis('send');
    let formData = DATA.map((item) => {
      return item.name;
    });
    saveChassis({ chases: formData })
      .then((res) => {
        setSendingChassis(null);
        setChassis((prev) => {
          return {
            ...prev,
            DATA: res.chases,
          };
        });
        notify('Chassis Successfully Updated!', 'success');
        Close();
      })
      .catch((err) => {
        setSendingChassis(null);
        notify(err, 'warning');
      });
  };

  const handleImportData = (file) => {
    setSendingChassis('import');
    let formData = new FormData();
    formData.append('document', file);
    importChassis(formData)
      .then((res) => {
        setSendingChassis(null);
        setChassis((prev) => {
          return {
            ...prev,
            DATA: res.data['own-chases'],
          };
        });
        setDATA(res.data['own-chases']);
        if (res.message) {
          notify(res.message, 'success');
        } else {
          notify('Chassis Successfully Updated!', 'success');
        }
      })
      .catch((err) => {
        setSendingChassis(null);
        notify(err, 'warning');
      });
  };

  const addNewChassis = () => {
    setDATA((prev) => {
      return [
        ...prev,
        {
          name: '',
        },
      ];
    });
  };

  const handleDeleteChassis = (index) => {
    let adaptedData = DATA.filter((item, i) => i !== index);
    setDATA(adaptedData);
  };

  return (
    <div className={cls.popup}>
      <div ref={wrapperRef} className={cls.popupCard}>
        <Tooltip arrow title="Close" placement="top">
          <div onClick={Close} className={cls.close}>
            <img alt="close_icon" src={closeIcon} />
          </div>
        </Tooltip>
        <div style={{ overflow: 'auto', marginTop: '20px' }}>
          <table className={cls.chassisTable}>
            <thead>
              <tr>
                <th style={{ flex: '0.2' }}>#</th>
                <th style={{ flex: '1' }}>Chassis#</th>
                <th style={{ flex: '0.3' }}></th>
              </tr>
            </thead>
            <tbody>
              {DATA.map((item, index) => (
                <tr key={index}>
                  <td style={{ flex: '0.2' }}>{index + 1}</td>
                  <td style={{ flex: '1' }}>
                    <input
                      onChange={(e) => handleChangeInput(e, index)}
                      type="text"
                      value={item.name}
                      className={cls.tableInput}
                    />
                  </td>
                  <td style={{ flex: '0.3' }}>
                    {
                      <IconButton
                        onClick={() => {
                          handleDeleteChassis(index);
                        }}>
                        <DeleteSharp className={cls.icon} />
                      </IconButton>
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <div className={cls.btnDiv}>
            <Button
              className={cls.addBtn}
              onClick={() => {
                addNewChassis();
              }}>
              Add New Chassis
            </Button>
            <label className={`${cls.importBtn} ${cls.widthFull}`}>
              {sendingChassis === 'import' ? (
                <CircularProgress className={cls.loadIcon} />
              ) : (
                <div>
                  <input
                    onChange={(e) => handleImportData(e.target.files[0])}
                    type="file"
                    style={{ display: 'none' }}
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                  <span>Import</span>
                </div>
              )}
            </label>
          </div>
          <Button
            className={`${cls.saveBtn} ${cls.widthFull}`}
            onClick={() => handleSendData()}>
            {sendingChassis === 'send' ? (
              <CircularProgress className={cls.loadIcon} />
            ) : (
              'Save'
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};
