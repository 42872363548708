import React, { useState } from 'react';
import { Tooltip, Avatar, TextField, Button } from '@material-ui/core';
import styles from '../styles';
import { CloseRounded } from '@material-ui/icons';
import { adminEditMember, SaveData } from '@/actions';
import { validateAdminAddMemberData } from '@/actions/validations';

const inputList = [
  {
    type: 'text',
    name: 'name',
    placeholder: 'Name*',
    label: 'Name',
  },
  {
    type: 'text',
    name: 'last_name',
    placeholder: 'Last Name*',
    label: 'Last Name',
  },
  {
    type: 'email',
    name: 'email',
    placeholder: 'Email Address*',
    label: 'Email',
  },
  {
    type: 'text',
    name: 'phone',
    placeholder: 'Phone*',
    label: 'Phone',
  },
  {
    type: 'text',
    name: 'company',
    placeholder: 'Company*',
    label: 'Company',
  },
  {
    type: 'password',
    name: 'password',
    placeholder: 'Password*',
    label: 'Password',
  },
  // {
  //   type: 'text',
  //   name: 'status',
  //   placeholder: 'Status*',
  // },
];

export default ({
  userAvatar,
  formData,
  setUserAvatar,
  Close,
  handleSendForm,
  handleChangeInput,
  button,
}) => {
  const cls = styles();
  const handleChangeUserAvatar = (e) => {
    let img = e.target.files[0];
    setUserAvatar({
      image: img,
      url: URL.createObjectURL(img),
    });
  };

  return (
    <div className={cls.card}>
      <Tooltip arrow title="Close" placement="top">
        <CloseRounded className={cls.close} onClick={Close} />
      </Tooltip>
      <div>
        <Avatar src={userAvatar.url && userAvatar.url} className={cls.avatar} />
        <input
          onChange={(e) => handleChangeUserAvatar(e)}
          className={cls.userAvatar}
          id="user_avatar"
          type="file"
        />
        <label for="user_avatar" className={cls.addAvatarLable}>
          {userAvatar.url ? 'Change Profile Photo' : 'Add Profile Photo'}
        </label>
        <div className={cls.form}>
          {inputList.map((item, index) => (
            <div key={index} className={cls.inputContainer}>
              <label>{item.label}</label>
              <TextField
                autoComplete="new-password"
                type={item.type}
                value={formData[item.name]}
                name={item.name}
                placeholder={item.placeholder}
                className={cls.input}
                onChange={(e) =>
                  handleChangeInput(e.target.name, e.target.value)
                }
                InputProps={{ disableUnderline: true }}
                inputProps={{ min: 0 }}
              />
            </div>
          ))}
        </div>
      </div>
      <Button onClick={() => handleSendForm()} className={cls.but}>
        {button}
      </Button>
    </div>
  );
};
