import React from 'react';
// import { Logout } from 'react-admin';
import { Link, useHistory } from 'react-router-dom';
import { ListItemIcon, MenuItem, makeStyles } from '@material-ui/core';
import { PersonRounded, LogoutIcon } from '@material-ui/icons';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import { SaveData } from '@/actions';

const useStyles = makeStyles(
  (theme) => ({
    icon: { minWidth: theme.spacing(5) },
    link: {
      textDecoration: 'none',
      color: theme.palette.text.secondary,
      display: 'flex',
    },
  }),
  { name: 'RaLogout' }
);

export default (props) => {
  const cls = useStyles(props);
  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    localStorage.removeItem('token_admin');
    history.push('/login');
    SaveData({ user: { name: '' } });
  };

  return (
    <>
      <MenuItem>
        <Link to="/profile" className={cls.link}>
          <ListItemIcon className={cls.icon}>
            <PersonRounded />
          </ListItemIcon>
          Profile
        </Link>
      </MenuItem>
      <MenuItem className={cls.link} onClick={() => handleLogout()}>
        <ListItemIcon className={cls.icon}>
          <ExitToAppOutlinedIcon />
        </ListItemIcon>
        Logout
      </MenuItem>
    </>
  );
};
