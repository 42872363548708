import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  listContainer: {
    overflow: 'auto',
  },
  list: {
    margin: '10px 0 10px 0',
    width: '100%',
    minWidth: 400,
    border: '1px solid #707070',
    '& th': {
      border: '1px solid #707070',
    },
    '& td': {
      border: '1px solid #707070',
      textAlign: 'center',
      fontSize: 15,
      '& input': {
        width: 150,
        fontSize: 14,
      },
    },
  },

  tableRow: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 61,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    margin: '10px 0',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',

    [BP.up('lg')]: {
      fontSize: 14,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  thead: {
    display: 'flex',
    fontFamily: 'Averta Bold',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#43424b',
    textAlign: 'center',
    alignItems: 'center',
    [BP.up('lg')]: {
      fontSize: 15,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  tbody: {
    // display: 'flex',
    // flexDirection: 'column',
    // width:'fit-content'
  },
  textInput: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    marginTop: 5,
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
}));
