import React, { useEffect, useState } from 'react';
import styles from '../styles';
import { useSelector } from 'react-redux';
import { getInventoryShareList, shareInventory } from '@/actions';
import { isValidEmail } from '@/actions/validations';
import {
  Avatar,
  IconButton,
  TextField,
  CircularProgress,
  Checkbox,
  Button,
} from '@material-ui/core';
import { PersonAdd, Close } from '@material-ui/icons';

export default ({ notify, setContent, shareModal, setShareModal }) => {
  const [emailInput, setEmailInput] = useState('');
  const [suggestions, setSuggestions] = useState({
    loading: false,
    DATA: [],
  });
  const [emails, setEmails] = useState([]);

  const cls = styles();

  const user = useSelector((state) => state.reducer.user);

  const handleShareInvite = () => {
    const data = {
      inventory: shareModal.id,
      emails: emails.map((email) => email.title),
    };
    shareInventory(data)
      .then((res) => {
        notify('Successfully invited', 'success');
        setContent(0);
        setShareModal(null);
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleAddEmailInput();
    }
  };

  const handleAddEmailInput = () => {
    if (!isValidEmail(emailInput)) {
      notify('Please enter a valid email', 'warning');
    } else {
      setEmails((prev) => {
        return [...prev, { title: emailInput }];
      });
      setEmailInput('');
    }
  };

  const handleAddEmail = (email) => {
    setEmails((prev) => {
      return [...prev, { title: email }];
    });
    setEmailInput('');
  };

  const handleRemoveEmailBadge = (index) => {
    setEmails((prev) => {
      return [...prev.slice(0, index), ...prev.slice(index + 1)];
    });
  };

  useEffect(() => {
    setSuggestions({
      loading: true,
      DATA: [],
    });
    getInventoryShareList()
      .then((res) => {
        const emails = res.emails.map((email) => {
          return { email: email.email };
        });
        setSuggestions({
          loading: false,
          DATA: emails,
        });
      })
      .catch((err) => {
        setSuggestions({ loading: false, DATA: [] });
        notify(err, 'warning');
      });
  }, []);

  const suggestionsContent = suggestions.DATA.filter((suggestion) =>
    suggestion.email.includes(emailInput)
  );

  return (
    <div className={cls.shareLinkContainer}>
      <h3 style={{ color: '#3c4043', marginTop: '0' }}>Add people</h3>
      <div className={cls.inviteIconContainer}>
        <div className={cls.inviteIcon}>
          <PersonAdd />
          <span>Share</span>
        </div>
      </div>
      <div className={cls.emailInputContainer}>
        {emails.map((email, index) => (
          <div className={cls.emailBadge}>
            <div className={cls.email}>
              <Avatar className={cls.emailAvatar} />
              <span>{email.title}</span>
            </div>
            <IconButton
              onClick={() => handleRemoveEmailBadge(index)}
              className={cls.removeEmail}>
              <Close className={cls.removeEmailIcon} />
            </IconButton>
          </div>
        ))}
        <TextField
          type="email"
          placeholder="Enter Email"
          style={{ flex: '1', minWidth: '100px' }}
          InputProps={{ disableUnderline: true }}
          value={emailInput}
          onBlur={() => handleAddEmailInput()}
          onChange={(e) => {
            setEmailInput(e.target.value);
          }}
          onKeyDown={(e) => handleKeyDown(e)}
        />
      </div>

      {(suggestions.DATA.length > 0 ||
        suggestions.loading ||
        isValidEmail(emailInput)) && (
        <div
          style={emails.length > 0 ? { height: '125px', overflow: 'auto' } : {}}
          className={cls.suggestionsContainer}>
          <h5 style={{ color: '#5f6368' }}>
            {emailInput ? 'Search Results' : 'Suggestions'}
          </h5>
          <div>
            {isValidEmail(emailInput) && (
              <div className={cls.suggestion}>
                <div className={cls.suggestionInfo}>
                  <Avatar className={cls.suggestionAvatar} />
                  <span>{emailInput}</span>
                </div>
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      handleAddEmail(emailInput);
                    }
                  }}
                  checked={
                    emails.filter((email) => email.title === emailInput)[0]
                      ? true
                      : false
                  }
                  className={
                    emails.filter((email) => email.title === emailInput)[0]
                      ? cls.emailCheckBox
                      : ''
                  }
                />
              </div>
            )}
          </div>
          <div>
            {suggestions.loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '100px',
                  alignItems: 'center',
                }}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {suggestionsContent.map((item, index) => (
                  <div className={cls.suggestion}>
                    <div className={cls.suggestionInfo}>
                      <Avatar className={cls.suggestionAvatar} />
                      <span>{item.email}</span>
                    </div>
                    <Checkbox
                      onChange={(e) =>
                        e.target.checked && handleAddEmail(item.email)
                      }
                      checked={
                        emails.filter((email) => email.title === item.email)[0]
                          ? true
                          : false
                      }
                      className={
                        emails.filter((email) => email.title === item.email)[0]
                          ? cls.emailCheckBox
                          : ''
                      }
                    />
                  </div>
                ))}
              </>
            )}
          </div>
        </div>
      )}
      {emails.length > 0 && (
        <div>
          <p
            style={{
              color: 'rgba(32,33,36,.67)',
              fontSize: '14px',
            }}>{`Sending email as ${user.name} ${user.last_name} `}</p>
          <p style={{ color: 'rgb(32,33,36)', fontSize: '14px' }}>
            {`${user.name} ${user.last_name} <${user.email}> share an inventory with you.`}
          </p>
        </div>
      )}
      {emails.length > 0 && (
        <Button
          onClick={() => handleShareInvite()}
          style={{ marginLeft: 'auto', marginTop: '20px' }}
          className={cls.ShareEmail}>
          Send Email
        </Button>
      )}
    </div>
  );
};
