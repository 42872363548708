import React, { useState } from 'react';
import { Loading } from 'react-admin';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import { Tooltip } from '@material-ui/core';
import { LaunchRounded, CloseRounded } from '@material-ui/icons';
import styles from './styles';

// const apiKey = 'AIzaSyCFM3CuJxSHXOUZQ79c2axVTPlO1vsL3-4';
const apiKey = 'AIzaSyCpZf-tnhdvT1n8MR7eF7UK8DFcDn_6zsY';

const MapContainer = ({ google, DATA = [], smart }) => {
  const cls = styles();

  const [popUp, setPopUp] = useState(true);
  const [state, setState] = useState({
    loading: true,
    Markers: [],
    Center: {
      lat: 40.854885,
      lng: -88.081807,
    },
  });

  React.useEffect(() => {
    if (DATA.length)
      setState({ Markers: DATA, Center: DATA[0].pos, loading: false });
    else setState((s) => ({ ...s, loading: false }));
  }, [DATA]);

  const toggle = () => setPopUp(!popUp);

  if (state.loading) return <Loading />;

  return (
    <Map
      google={google}
      className={cls.container}
      zoom={10}
      disableDefaultUI
      gestureHandling="cooperative"
      initialCenter={state.Center}>
      {state.Markers.map((item, index) => (
        <Marker title={item.name} position={item.pos} key={index} />
      ))}

      <div className={cls.popUpCont}>
        {popUp ? (
          <div className={cls.popUp}>
            <CloseRounded className={cls.close} onClick={toggle} />
            <div className={cls.cont}>
              <h1>Why smart search is better?</h1>
              <h2>
                Smart search improve your search and make sure you
                won't miss your target, check every offered container
                and fulfill your need.
              </h2>
            </div>

            <div onClick={smart} className={cls.but}>
              smart search
            </div>
          </div>
        ) : (
          <div className={cls.mini} onClick={toggle}>
            <Tooltip title="Smart search" placement="top" arrow>
              <LaunchRounded className={cls.launch} />
            </Tooltip>
          </div>
        )}
      </div>
    </Map>
  );
};

export default GoogleApiWrapper({ apiKey })(MapContainer);
