import {
  getAdminNewChatList,
  SaveData,
  seenChat,
  SupportseenChat,
  SupportseenChatByUser,
} from '@/actions';
import React, { useEffect, useState } from 'react';
import List from './List';
import Messages from './Messages';
import styles from './styles';
import { _get } from '@/actions/Helper';
import { useSelector } from 'react-redux';
import useMediaQuery from 'use-mediaquery';

const parseData = (data) => ({
  id: _get(data, 'order_id', ''),
  msg: _get(data, 'lastMessage.message', 'Click to Start Conversation.'),
  chat_id: _get(data, 'lastMessage.id', 0),
  update: _get(data, 'lastMessage.updated_at', new Date()),
  seen: _get(data, 'lastMessage.is_seen', 1),
  user: _get(data, 'withUser.name', ''),
  company: _get(data, 'withUser.company', ''),
  avatar: _get(data, 'withUser.image', 'A'),
  userId: _get(data, 'withUser.id', ''),
});

export default () => {
  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });
  const [searchingChat, setSearchingChat] = useState(null);
  const cls = styles();

  const isSmallDevice = useMediaQuery('(max-width: 1000px)');

  // const type = useSelector((state) => state.reducer.ChatItem || null);
  const ChatData = useSelector((state) => state.reducer.Chat || {});
  const refresh = useSelector((state) => state.reducer.refresh || null);

  const { id, userId } = ChatData;

  const RefreshData = React.useCallback(() => {
    setState({
      DATA: [],
      loading: true,
    });
    getAdminNewChatList()
      .then((res) =>
        setState({
          DATA: res.map((i) => parseData(i)),
          loading: false,
        })
      )
      .catch((err) => {
        // notify(err, 'warning');
        setState({ DATA: [], loading: false });
      });
  });

  const Chat = ({ chat_id, seen, userId, user }) => {
    SaveData({ Chat: { type: 'adminNewChat', userId, user } });
    if (!seen)
      seenChat(id, chat_id).then((res) => {
        setState((prev) => {
          const adaptedChats = prev.DATA.map((chat) => {
            if (chat.chat_id === chat_id) {
              return {
                ...chat,
                seen: 1,
              };
            } else {
              return chat;
            }
          });
          return {
            ...prev,
            DATA: adaptedChats,
          };
        });
      });
  };

  React.useEffect(() => {
    if (refresh) {
      RefreshData();
      SaveData({ refresh: null });
    }
  }, [refresh]);

  React.useEffect(() => {
    RefreshData();
  }, []);

  return (
    <div className={cls.chatContainer}>
      {(!isSmallDevice || (isSmallDevice && !userId)) && (
        <div className={cls.chatListContainer}>
          <List
            searchingChat={searchingChat}
            setSearchingChat={setSearchingChat}
            state={state}
            setState={setState}
            Chat={Chat}
          />
        </div>
      )}
      {(!isSmallDevice || (isSmallDevice && userId)) && (
        <div className={cls.chatMessagesContainer}>
          {
            <Messages
              setList={setState}
              refreshChatList={() => RefreshData()}
            />
          }
        </div>
      )}
    </div>
  );
};
