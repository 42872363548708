import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: 10,
    minWidth: '1200px',
  },

  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    margin: '10px 0',
    fontFamily: 'Averta Light',
    fontSize: 14,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',
    [BP.up('lg')]: {
      fontSize: 11,
    },
  },
  thead: {
    display: 'flex',
    fontFamily: 'Averta Light',
    fontSize: 14,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',
    '& > div': {
      display: 'flex',
      borderRight: '1px solid #43424b',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '8px 0',
    },
    [BP.up('lg')]: {
      fontSize: 11,
    },
  },
  tbody: {
    display: 'flex',
    flexDirection: 'column',
  },

  size: {
    flex: 0.8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Averta Bold',
    fontSize: 20,
    fontWeight: 600,
    lineHeight: 2.7,
    color: '#303030',
    '& > div > div': {
      display: 'flex',
    },
    '& svg': {
      width: 70,
      [BP.up('lg')]: {
        width: 60,
      },
    },
    '& > span': {
      marginRight: 15,
    },
    [BP.up('lg')]: {
      fontSize: 16,
    },
  },
  hasContainersIcon: {
    '& svg': {
      width: 40,
      marginRight: -15,
      [BP.up('lg')]: {
        width: 40,
      },
    },
  },
  bold: {
    fontFamily: 'Averta Bold',
    fontSize: 14,
    fontWeight: 600,
    lineHeight: 3.86,
    color: '#43424b',
    [BP.up('lg')]: {
      fontSize: 11,
    },
  },
  but: {
    flex: 0.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#007fff',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    color: '#fff',
    alignSelf: 'stretch',
    borderRadius: '0 5px 5px 0',
    padding: 0,
    '&:hover': {
      backgroundColor: '#007fff',
    },
    [BP.up('lg')]: {
      fontSize: 11,
    },
  },
  chassisContainer: {
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    '& span':{
      display:'block',
      margin:'0 10px'
    }
  }
}));
