import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  open: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    padding: '7px 0',
    borderRadius: 10,
    width: 90,
    margin: ' 1px auto',
    transition: 'all 0.3s ease-in',
    '&:hover': {
      backgroundColor: '#f8b100',
      '& svg': {
        fill: '#303030',
      },
      '& > span': {
        color: '#303030',
      },
    },
  },
  close: {
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    padding: '5px 0',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: 'transparent',
      '& svg': {
        fill: '#303030',
      },
    },
  },
  active: {
    backgroundColor: '#f8b100',
    '& svg': {
      fill: '#303030',
    },
    '& > span': {
      color: '#303030',
    },
    '& > div > div': {
      display: 'flex',
      animation: 'zoomIn 0.5s',
    },
  },
  listItemIcon: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 'auto',
  },
  icon: {
    height: 18,
    width: 18,
    objectFit: 'contain',
    fill: '#9f9f9f',
  },

  child: {
    display: 'none',
    backgroundColor: '#303030',
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
    flexDirection: 'column',
    width: '100%',
    marginTop: 7,
    marginBottom: -7,
  },
  item: {
    display: 'flex',
    justifyContent: 'center',
    margin: '2px 0',

    color: '#fff',
    fontFamily: 'Averta Bold',
    fontSize: 10,
    fontWeight: 600,
    textDecoration: 'none',
    transition: 'all 0.3s ease-in',
    '&:hover': {
      color: '#f8b100',
    },
  },
  itemActive: {
    color: '#f8b100',
  },
}));
