import React from 'react';
import { Link } from 'react-router-dom';
import {
  Twitter,
  Instagram,
  Facebook,
  KeyboardArrowUpRounded,
  LinkedIn,
} from '@material-ui/icons';
import { HeaderIcons } from '@/assets';
import styles from './styles';

export default (props) => {
  const cls = styles();
  let year = new Date().getFullYear();

  return (
    <div className={cls.footer}>
      <div
        className={cls.top}
        onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
        <KeyboardArrowUpRounded className={cls.icon} />
        To Top
      </div>
      <Link to="/" className={cls.logo}>
        <img src={HeaderIcons.logoWhite} alt="Logo" />
      </Link>
      <div className={cls.links}>
        <Link to="/">Home</Link>
        <Link to="/">FAQ</Link>
        <Link to="/privacy">PRIVACY POLICY</Link>
        <Link to="/privacy">TERM OF USE</Link>
      </div>

      <div className={cls.links}>
        <Link to="/">
          <Instagram />
        </Link>
        <Link to="/">
          <Twitter />
        </Link>
        <a href="https://www.facebook.com/profile.php?id=100085219720017">
          <Facebook />
        </a>
        <a href="https://www.linkedin.com/company/smart-turn/">
          <LinkedIn />
        </a>
      </div>
      <div className={cls.text}>@Smart-Turn {year} - All rights reserved</div>
    </div>
  );
};
