import React from 'react';
import { Notification } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Footer, Header } from '@/components/Landing';

const styles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    minHeight: '100vh',
  },
  content: {
    display: 'flex',
  },
});

export default ({ children, className, loginBut = true }) => {
  const cls = styles();
  return (
    <div className={cls.container}>
      <Notification />
      <Header loginBut={loginBut} />
      <div className={clsx(cls.content, className)}>{children}</div>
      <Footer />
    </div>
  );
};
