import React, { useState } from 'react';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import { AccountCircle, HomeRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { _get, genSize } from '@/actions/Helper';
import { finishOrder, finishOrderExport } from '@/actions';
import styles from './styles';
import EmptyContainers from './Containers/Empty';
import ExportContainers from './Containers/Export';

const parseData = (data) => ({
  id: _get(data, 'id', ''),
  count: _get(data, 'accepted_count', 0),
  max_count: _get(data, 'inventory.count', 0),
  loc: _get(data, 'address.city', ''),
  size: genSize(_get(data, 'cabin.specifications', {})),
  ssl: _get(data, 'cabin.company.name', ''),
  finOr: _get(data, 'finished_by_seller', false),
  finReq: _get(data, 'finished_by_buyer', false),
  stat: _get(data, 'status_string', false),
  user: _get(data, 'inventory.user.name', false),
  comp: _get(data, 'inventory.user.company', false),
});

export default ({
  type,
  data,
  Reject,
  notify,
  id,
  otherPart,
  setChat,
  parseChatData,
  user,
  mode,
}) => {
  const cls = styles();
  const [state, setState] = React.useState('');
  const [selectedContainers, setSelectedContainers] = React.useState([]);
  const [showContainers, setShowContainers] = useState(false);
  const [acceptedCount, setAcceptedCount] = useState('');
  const [headerData, setHeaderData] = useState(null);
  const [orderID, setOrderID] = useState();
  const [activeContainers, setActiveContainers] = useState([]);
  const [form, setForm] = React.useState({
    loading: false,
    DATA: {},
    count: 0,
  });
  React.useEffect(() => {
    if (data) {
      setHeaderData(data);
      setAcceptedCount(data.accepted_count);
      let DATA = parseData(data);
      setForm({ loading: false, DATA });
      checkState(DATA);
      setOrderID(data.id);
      if (localStorage.getItem(data.id)) {
        setSelectedContainers(JSON.parse(localStorage.getItem(data.id)));
      } else {
        localStorage.setItem(data.id, JSON.stringify(selectedContainers));
      }
    }
  }, [data]);

  const checkState = (DATA) => {
    let req = type === 'request';
    if (DATA.stat === 'Complete') setState('completed');
    else if (DATA.stat === 'Cancled') setState('rejected');
    else if (DATA.stat === 'Rejected') {
      setState('rejected');
      localStorage.removeItem(data.id);
    } else if (DATA.finReq && req) setState('finished');
    else if (DATA.finOr && !req) setState('finished');
    else if (DATA.finOr || DATA.finReq) setState('waiting');
    else if (DATA.stat === 'In progress') setState('waiting');
    else setState('but');
  };

  const handleFinish = () => {
    let formContainers;
    if (mode === 'empty') {
      if (type === 'order') {
        if (selectedContainers.length !== 0) {
          formContainers = selectedContainers.map((container) => {
            return container.id;
          });

          setForm(() => ({ ...form, loading: true }));
          finishOrder(
            DATA.id,
            type === 'order' ? { containers: formContainers } : null
          )
            .then((res) => {
              let DATA = parseData(res);
              setForm({ loading: false, DATA });
              checkState(DATA);
              notify(
                type === 'request' ? 'Request Finished.' : 'Offer Finished.'
              );
              localStorage.removeItem(DATA.id);
            })
            .catch((err) => notify(err, 'warning'));
        } else {
          notify('Please Select Container Numbers', 'warning');
        }
      } else {
        finishOrder(
          DATA.id,
          type === 'order' ? { containers: formContainers } : null
        )
          .then((res) => {
            let DATA = parseData(res);
            setForm({ loading: false, DATA });
            checkState(DATA);
            notify(
              type === 'request' ? 'Request Finished.' : 'Offer Finished.'
            );
            localStorage.removeItem(DATA.id);
          })
          .catch((err) => notify(err, 'warning'));
      }
    } else if (mode === 'export') {
      if (type === 'request') {
        const selectedContainers = activeContainers.filter(
          (container) => container.selected === true
        );
        if (selectedContainers.length !== 0) {
          formContainers = selectedContainers.map((container) => {
            if (!container.is_new) {
              return {
                id: container.id,
                name: container.name,
                chasis: container.chases,
              };
            } else {
              return {
                name: container.name,
                chasis: container.chases,
              };
            }
          });
          finishOrderExport(DATA.id, { containers: formContainers })
            .then((res) => {
              let DATA = parseData(res);
              setForm((prev) => {
                return {
                  loading: false,
                  DATA: {
                    ...prev.DATA,
                    count: DATA.count,
                  },
                };
              });
              checkState(DATA);
              notify(
                type === 'request' ? 'Request Finished.' : 'Offer Finished.'
              );
              localStorage.removeItem(DATA.id);
            })
            .catch((err) => notify(err, 'warning'));
        } else {
          notify('Please Select Container Numbers', 'warning');
        }
      }
      if (type === 'order') {
        finishOrderExport(DATA.id, {
          accepted_count: acceptedCount,
        })
          .then((res) => {
            let DATA = parseData(res);
            setForm((prev) => {
              return {
                loading: false,
                DATA: {
                  ...prev.DATA,
                  count: DATA.count,
                },
              };
            });
            checkState(DATA);
            notify(
              type === 'request' ? 'Request Finished.' : 'Offer Finished.'
            );
            localStorage.removeItem(DATA.id);
          })
          .catch((err) => notify(err, 'warning'));
      }
    }
  };

  if (form.loading)
    return (
      <div className={cls.loading}>
        <CircularProgress />
      </div>
    );

  const { DATA } = form;

  return (
    <>
      <div className={cls.card}>
        <div className={cls.content}>
          <span className={cls.title}>Request details</span>
          <div className={cls.row2}>
            <span>Size: {DATA.size}</span>
            <span>SSL: {DATA.ssl}</span>
            <span>Accepted: {DATA.count}</span>
            <span>Location: {DATA.loc ? DATA.loc.substring(0, 10) : ''}</span>
          </div>
          <div className={cls.hr} />

          <div className={cls.row2}>
            <span className={cls.title}>
              {type === 'order' ? 'Company Requested' : 'Company Offered'}
            </span>
            <div className={cls.row}>
              <div className={cls.rowCenter}>
                <AccountCircle className={cls.icon} />
                <span>{otherPart?.name}</span>
              </div>
              <div className={cls.rowCenter}>
                <HomeRounded className={cls.icon} />
                <span>{otherPart?.company}</span>
              </div>
            </div>
          </div>
        </div>

        {state === 'finished' && (
          <div className={cls.label}>Waiting for other side to confirm</div>
        )}
        {state === 'completed' && (
          <div className={clsx(cls.label, cls.green)}>Completed</div>
        )}
        {state === 'rejected' && (
          <div className={clsx(cls.label, cls.red)}>Rejected</div>
        )}
        {state === 'input' && (
          <div className={cls.inputCont}>
            <div>
              <span className={cls.title}>Deal Number</span>
              <TextField
                type="number"
                placeholder=""
                className={cls.input}
                InputProps={{ disableUnderline: true }}
                inputProps={{ min: 1 }}
                value={form.count || DATA.count}
                onChange={(e) => setForm({ ...form, count: e.target.value })}
              />
            </div>
            <Button className={cls.but2} onClick={() => handleFinish()}>
              Done
            </Button>
            <Button
              className={clsx(cls.but2, cls.redBut)}
              onClick={() => Reject(DATA.id)}>
              Reject
            </Button>
          </div>
        )}

        {(state === 'but' || state === 'waiting') && (
          <>
            <div className={cls.butCont}>
              <Button onClick={() => handleFinish()} className={cls.but}>
                Finish Deal
              </Button>
              <Button
                className={clsx(cls.but, cls.redBut)}
                onClick={() => Reject(DATA.id)}>
                Reject
              </Button>
            </div>
            {type === 'request' && mode === 'export' && (
              <>
                <div
                  id="select"
                  onClick={() => setShowContainers(!showContainers)}
                  className={clsx(cls.label, cls.blue)}>
                  Select Conainter Numbers
                </div>
                {showContainers && headerData && (
                  <ExportContainers
                    orderId={orderID}
                    selectedContainers={selectedContainers}
                    setSelectedContainers={setSelectedContainers}
                    setShowContainers={setShowContainers}
                    // containerNumers={headerData.inventory.container_numbers}
                    accepted_count={headerData.accepted_count}
                    handleFinish={handleFinish}
                    notify={notify}
                    id={id}
                    mode={mode}
                    setChat={setChat}
                    parseData={parseChatData}
                    user={user}
                    activeContainers={activeContainers}
                    setActiveContainers={setActiveContainers}
                  />
                )}
              </>
            )}
            {type === 'order' && mode === 'export' && (
              <div className={cls.exportNumber}>
                <span
                  style={{ width: 'auto', height: 'auto' }}
                  className={clsx(cls.label, cls.blue)}>
                  Export Numbers
                </span>
                <TextField
                  style={{
                    height: '20px',
                    width: '40px',
                    fontSize: '11px',
                    marginRight: '10px',
                  }}
                  type="number"
                  placeholder=""
                  className={cls.input}
                  onChange={(e) => setAcceptedCount(e.target.value)}
                  value={acceptedCount}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{
                    min: 1,
                    max: headerData.inventory.count + headerData.accepted_count,
                  }}
                  defaultValue={headerData.accepted_count}
                />
              </div>
            )}
            {type === 'order' && mode === 'empty' && (
              <>
                <div
                  id="select"
                  style={{ padding: '0 10px' }}
                  onClick={() => setShowContainers(!showContainers)}
                  className={clsx(cls.label, cls.blue)}>
                  Select Conainter Numbers
                </div>
                {showContainers && headerData && (
                  <>
                    <EmptyContainers
                      orderId={orderID}
                      selectedContainers={selectedContainers}
                      setSelectedContainers={setSelectedContainers}
                      setShowContainers={setShowContainers}
                      containers={headerData.inventory.containers}
                      containerNumers={headerData.inventory.container_numbers}
                      accepted_count={headerData.accepted_count}
                      handleFinish={handleFinish}
                      notify={notify}
                      id={id}
                      setChat={setChat}
                      parseData={parseChatData}
                      user={user}
                    />
                  </>
                )}
              </>
            )}
            <div
              style={{ minHeight: '30px', padding: '0 10px', height: 'auto' }}
              className={clsx(cls.label, cls.blue)}>
              The other party has confirmed the deal, Please either finish or
              reject the deal
            </div>
          </>
        )}
        {/* {state === 'waiting' && (
          <div className={clsx(cls.label, cls.blue)}>
            The other party has confirmed the deal, Please either finish or
            reject the deal
          </div>
        )} */}
      </div>
    </>
  );
};
