import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    margin: '0 20px',
  },
  featureTabs: {
    background: '#ffffff',
    marginBottom: '20px',
    padding: '10px 20px',
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    borderRadius: '10px',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
  },
  backBtn: {
    width: '140px',
    position: 'fixed',
    zIndex: '999',
    bottom: '30px',
    left: '15px',
    width: 'fit-content',
    background: '#f8b100',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    '&:hover': {
      background: '#f8b100',
    },
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: 'gray',
    width: 15,
  },
  select: {
    width: '100%',
    marginTop: 10,
    fontFamily: 'Averta Light',
    position: 'relative',
    zIndex: '99',
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  inputLabel: {
    fontSize: 16,
    marginBottom: 10,
    display: 'block',
  },
  featureTabsBtn: {
    border: '1px solid #e5e5e5',
    color: '#777575',
    background: '#e5e5e5',
    width: 100,
  },
  activeFeatureTabsBtn: {
    background: '#f8b100',
    border: '1px solid #f8b100',
    color: '#ffffff',
    width: 100,
    '&:hover': {
      background: '#f8b100',
    },
  },
  saveSettingsBtn: {
    background: '#f8b100',
    border: '1px solid #f8b100',
    color: '#ffffff',
    width: 100,
    '&:hover': {
      background: '#f8b100',
    },
  },
  previewBtn: {
    background: '#f8b100',
    border: '1px solid #f8b100',
    color: '#ffffff',
    width: 100,
    display: 'block',
    '&:hover': {
      background: '#f8b100',
    },
  },
  card: {
    padding: '15px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    // height: 160,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 25,
  },
  date: {
    boxSizing: 'border-box',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    border: 'solid 1px #707070',
    padding: '0 10px',
    '& input': {
      width: '100%',
      padding: 0,
      cursor: 'pointer',
      fontFamily: 'Averta Light',
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
  },
  priceInput: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  price: {
    flex: '1',
  },
  dollar: {
    width: '40px',
    background: '#e5e5e5',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 'bold',
  },
  textInput: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  actionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 6,
    marginTop: 20,
  },
  editor: {
    paddingTop: '16px',
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  referInputContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    margin: '25px 0',
    '& span': {
      fontWeight: 'bold',
      fontSize: 15,
      display: 'block',
      width: 200,
    },
    '@media (max-width:500px)': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  referBtn: {
    width: '100%',
    background: '#0CA549',
    color: '#ffffff',
    '&:hover': {
      background: '#0CA549',
      color: '#ffffff',
    },
  },
  referBtn2: {
    marginTop: 60,
    width: '100%',
    background: '#007FFF',
    color: '#ffffff',
    '&:hover': {
      background: '#007FFF',
      color: '#ffffff',
    },
  },
  referListTitle: {
    fontWeight: 'bold',
    fontFamily: 'Averta bold',
    paddingBottom: 5,
    display: 'block',
  },
  referListItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  referListItemTitle: {
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
  },
  referListBigText: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  referListSmallText: {
    fontSize: 12,
    color: 'gray',
  },
  referListSmallBoldText: {
    fontSize: 12,
    color: '#000000',
    fontWeight: 'bold',
    fontFamily: 'Averta bold',
  },
  referListBottomBorder: {
    borderBottom: '1.5px solid #80808042',
    padding: '10px 12px',
  },
  referInfoBottomBorder: {
    borderBottom: '1.5px solid #80808042',
    padding: '12px 0',
  },
  card1: {
    background: '#ffffff',
    flex: '1',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '5px',
    padding: '15px 20px',
    height: 268,
    marginTop: 20,
  },
  card2: {
    background: '#ffffff',
    flex: '1',
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: '5px',
    padding: '15px 20px',
    '& p': {
      lineHeight: 2.5,
    },
  },
  referContainer: {
    margin: '20px 0px',
    '@media (max-width:600px)': {
      margin: '20px',
    },
  },
  popup: {
    // position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',
  },
  popupCard: {
    width: '700px',
    position: 'relative',
    padding: 40,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxHeight: '70vh',
    overflow: 'auto',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    paddingLeft: 70,
    height: '100%',
    maxWidth: 450,
    width: '100%',
    '@media (max-width:960px)': {
      paddingLeft: 0,
      maxWidth: '100%',
    },
  },
  referCard: {
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderRadius: 10,
    background: '#f9c03180',
    padding: '15px 20px',
    marginBottom: 20,
    '& h2': {
      fontFamily: 'Averta bold',
      marginTop: 0,
    },
    '& p': {
      fontFamily: 'Averta bold',
      marginBottom: 0,
      paddingBottom: 6,
      lineHeight: 2,
    },
  },
}));
