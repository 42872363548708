import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: ({ AI, is_me }) => (AI || is_me ? 'row-reverse' : 'row'),
    alignItems: ({ AI, is_me }) => (AI || is_me ? 'flex-end' : 'flex-start'),
    margin: '10px 0',
  },
  modal: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  modalBox: {
    width: '350px',
    background: '#FFFFFF',
    borderRadius: '5px',
    padding: '10px 15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  actionContainer: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  yesBtn: {
    color: '#ffffff',
    padding: '10px 15px',
    background: '#007FFF',
    border: 'none',
    borderRadius: '5px',
    width: 80,
    '&:hover': {
      color: '#ffffff',
      background: '#007FFF',
    },
  },
  noBtn: {
    color: '#ffffff',
    padding: '10px 15px',
    background: '#FF0000',
    marginRight: 10,
    border: 'none',
    borderRadius: '5px',
    width: 80,
    '&:hover': {
      color: '#ffffff',
      background: '#FF0000',
    },
  },
  input: {
    fontFamily: 'Averta Light',
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    padding: '1rem',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      fontSize: 13,
    },
  },
  chat: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 10px',
    justifyContent: 'space-between',
    width: 241,
    '@media (min-width:1060px)': {
      width: "auto",
      maxWidth: 360,
    },

    boxShadow: "0 0 5px 0px #00000026",
    
    background: ({ status, AI, is_me }) =>
      !AI && !is_me
        ? '#ffff'
        : !AI && is_me
        ? '#dddd'
        : status === 0
        ? 'linear-gradient(287deg, rgb(255 200 200) 100%, rgba(69, 69, 69, 0.5))'
        : status === 1
        ? '#dbdbdb'
        : status === 2
        ? 'linear-gradient(287deg, rgb(43 43 43 / 75%) 100%, rgb(69, 69, 69))'
        : '#d8ffd9',
        // ? 'linear-gradient(287deg, rgb(43 43 43 / 75%) 100%, rgb(69, 69, 69))'
        // : 'linear-gradient(287deg, #2b2b2b 100%, #454545)',


    borderRadius: ({ AI, is_me }) =>
      AI || is_me ? '20px 10px 0 10px' : '0 10px 10px 10px',
  },

  title: {
    fontSize: 12,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.6,
    // wordBreak: 'break-all',
    color: ({ status, is_me }) =>
      status || is_me === 1 ? '#000000' : '#00000',
  },
  chatLineLink: {
    wordBreak: 'break-all !important',
  },
  time: {
    fontSize: 9,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.3,
    color: '#ccc',
  },

  avatar: {
    height: 35,
    width: 35,
    margin: ({ AI, is_me }) => (AI || is_me ? '0 0 0 5px' : '0 5px 0 0'),
  },
  img: {
    height: 200,
    borderRadius: 20,
    marginBottom: 10,
    objectFit: 'contain',
  },
  chatLine: {
    display: 'block',
  },
  chatContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 8,
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
  },
  tickIcon: {
    background: 'green',
    borderRadius: '12px',
    color: 'white',
    width: '2rem',
    height: '2rem',
    marginTop: '0.5rem',
    '&:hover': {
      background: 'green !important',
    },
  },
  closeIcon: {
    background: '#FF0000',
    borderRadius: '12px',
    color: 'white',
    width: '2rem',
    height: '2rem',
    marginTop: '0.5rem',
    '&:hover': {
      background: '#FF0000 !important',
    },
  },
  editIcon: {
    background: '#007FFF',
    borderRadius: '12px',
    color: 'white',
    width: '2rem',
    height: '2rem',
    marginTop: '0.5rem',
    '&:hover': {
      background: '#007FFF !important',
    },
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 16,
    fontSize: 10,
    color: '#9b9b9b',
  },
}));
