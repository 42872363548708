import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    alignSelf: 'stretch',
    margin: '0 20px',
  },

  icon: {
    fontSize: '2rem',
    color: '#303030',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in',
    '&:hover': {
      transform: 'scale(1.3)',
      color: '#f8b100',
    },
  },

  title: {
    fontSize: 20,
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    lineHeight: 1.6,
    color: '#303030',
    textTransform: 'capitalize',
  },
}));
