import { getAdminNewChat, SaveData, seenChat } from '@/actions';
import AdminChatFooter from '@/components/Chat/AdminChatFooter';
import AdminChatHeader from '@/components/Chat/AdminChatHeader';
import React, { useEffect } from 'react';
import styles from './styles';
import { useSelector } from 'react-redux';
import { pushClient, _get, fTimeDate } from '@/actions/Helper';
import Chat from '../../components/Chat/Chat';
import { CircularProgress } from '@material-ui/core';
import Input from '../../components/Chat/Input';

const parseData = (data, user) =>
  data.map((i) => ({
    message: i.message,
    time: i.humans_time,
    me: i.user_id === user.id,
    name: _get(i, 'sender.name', ''),
    avatar: _get(i, 'sender.image', ''),
  }));

export default ({ chatData, setList }) => {
  const [state, setState] = React.useState({
    headerData: {},
    user: {},
    DATA: [],
    otherPart: {},
    loading: true,
    popUp: false,
  });

  const user = useSelector((state) => state.reducer.user);
  const [pusher, setPusher] = React.useState(null);

  const ChatData = useSelector((state) => state.reducer.Chat || {});
  const refresh = useSelector((state) => state.reducer.refreshChat || null);

  const cls = styles();

  const listRef = React.useRef();

  const { id, userId, chatId } = ChatData;

  const handlePush = React.useCallback(
    (res) => {
      const { conversation_id, id, user_id } = res;
      setList((prev) => {
        let adaptedChats = prev.DATA.map((chat) => {
          if (ChatData && user_id === chat.userId) {
            return {
              ...chat,
              msg: res.message,
            };
          } else {
            return chat;
          }
        });

        return {
          ...prev,
          DATA: adaptedChats,
        };
      });
      if (id)
        seenChat(conversation_id, id).then((res) => {
          setState((prev) => {
            let adaptedChats = prev.DATA.map((chat) => {
              if (ChatData && user_id === chat.userId) {
                return {
                  ...chat,
                  seen: 1,
                };
              } else {
                return chat;
              }
            });
            return {
              ...prev,
              DATA: adaptedChats,
            };
          });
        });
      if (ChatData && res.user_id === ChatData.userId) {
        setState((prev) => ({
          ...prev,
          DATA: prev.DATA.concat(parseData([res], user)) || [],
        }));
      }
    },
    [user, ChatData, setList]
  );

  const RefreshData = () => {
    getAdminNewChat(userId).then(({ ls, chn, headerData, user }) => {
      setPusher(pushClient(chn, handlePush));
      setState(() => ({
        headerData,
        user,
        DATA: parseData(ls, user),
        loading: false,
      }));
    });
  };

  React.useEffect(() => () => pusher && pusher.disconnect(), [pusher]);

  React.useEffect(() => {
    if (id || userId || chatId) RefreshData();
  }, [userId]);

  React.useEffect(() => {
    if (refresh) {
      RefreshData();
      SaveData({ refreshChat: null });
    }
  }, [refresh]);

  React.useEffect(() => {
    const { current } = listRef;
    if (current) current.scrollTop = current.scrollHeight;
    // let scroll_to_bottom = document.getElementById('chatslist');
    if (current) {
      current.scrollTop = current.scrollHeight;
    }
  }, [state.DATA]);

  return (
    <div className={cls.messagesContainer}>
      {userId ? (
        <>
          {state.loading ? (
            <div className={cls.loading}>
              <CircularProgress />
            </div>
          ) : (
            <>
              {state.user && (
                <AdminChatHeader
                  avatar={state.user.image || ''}
                  name={state.user.name + (state.user.last_name || '')}
                  lastSeen={fTimeDate(state.user.updated_at)}
                />
              )}
              <div>
                <div ref={listRef} id="chatslist" className={cls.chatBox}>
                  {state.DATA.map((i, idx) => (
                    <Chat DATA={i} key={idx} />
                  ))}
                </div>
              </div>
              <div className={cls.inputContainer}>
                <Input
                  type={'adminNewChat'}
                  id={ChatData.userId}
                  notify={() => {}}
                  setChat={setState}
                  user={user}
                  parseData={parseData}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <div className={cls.textContainer}>Select a Chat for start...</div>
      )}
    </div>
  );
};
