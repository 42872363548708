import React from 'react';
import styles from './styles';
import { Tooltip, IconButton } from '@material-ui/core';
import { DeleteRounded } from '@material-ui/icons';
import { Avatar } from '@material-ui/core';
import { fTimeDate } from '@/actions/Helper';
import { adminUpdateUserApp } from '@/actions';

export default ({ DATA = [], getLogs, notify }) => {
  const cls = styles();

  return (
    <div className={cls.container}>
      <div className={cls.tableContainer}>
        <table className={cls.list}>
          <thead className={cls.thead}>
            <th style={{ flex: 1.5, textAlign: 'left' }}>
              <span style={{ paddingLeft: '45px' }}>Member</span>
            </th>
            <th style={{ flex: 2 }}>Company</th>
            <th style={{ flex: 1 }}>Integration</th>
            <th style={{ flex: 1 }}>Naming</th>
            <th style={{ flex: 2 }}>File</th>
            <th style={{ flex: 1 }}>Method</th>
            <th style={{ flex: 3 }}>Message</th>
            <th style={{ flex: 1 }}>Date</th>
            <th style={{ flex: 1 }}>Status</th>
          </thead>
          <tbody className={cls.tbody}>
            {DATA.map((i, idx) => {
              return (
                <tr className={cls.tableRow} key={idx}>
                  <td
                    style={{ flex: 1.5 }}
                    className={`${cls.bigText} ${cls.userName}`}>
                    <div style={{ marginLeft: 10 }}>{i.id}</div>
                    {/* <Avatar src={i.image} className={cls.avatar} /> */}
                    <span style={{ cursor: 'pointer' }}>{`${i.user.name} ${
                      i.user.last_name || ''
                    }`}</span>
                  </td>
                  <td style={{ flex: 2 }}>{i.user.company}</td>
                  <td style={{ flex: 1 }}>
                    {' '}
                    {i.user.active_excel_mode === 1 && 'TMS/FTP'}
                    {i.user.active_excel_mode === 2 && 'TMS/Email'}
                    {i.user.active_excel_mode === 3 && 'Excel And GoogleSheet'}
                    {i.user.active_excel_mode === 4 && 'Email'}
                    {i.user.active_excel_mode === 5 && 'Application'}
                  </td>
                  <td style={{ flex: 1 }}>
                    {(i.user.active_excel_mode === 1 ||
                      i.user.active_excel_mode === 2) &&
                      i.tms.name}
                    {(i.user.active_excel_mode === 3 ||
                      i.user.active_excel_mode === 4 ||
                      i.user.active_excel_mode === 5) &&
                      'Manual'}
                  </td>
                  <td style={{ flex: 2 }}>{i.file}</td>
                  <td style={{ flex: 1 }}>{i.method}</td>
                  <td style={{ flex: 3, height: '85%', overflow: 'auto' }}>
                    {i.message}
                  </td>
                  <td style={{ flex: 1 }}>
                    {i.created_at ? fTimeDate(i.created_at) : ''}
                  </td>
                  <td style={{ flex: 1 }}>
                    {i.status === 0 ? (
                      <span style={{ color: '#0CA549' }}>Normal</span>
                    ) : i.status === 1 ? (
                      <span style={{ color: '#f8b100' }}>Warning</span>
                    ) : i.status === 2 ? (
                      <span style={{ color: '#FF0000' }}>Danger</span>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
