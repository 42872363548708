import React from 'react';
import { getAdminCompareReport } from '@/actions';
import StatCard from './Card';
import styles from './styles';
import { Grid } from '@material-ui/core';

export default () => {
  const cls = styles();
  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });

  React.useEffect(() => {
    getAdminCompareReport()
      .then((DATA) => {
        setState({
          loading: false,
          DATA: DATA,
        });
      })
      .catch(() => setState((st) => ({ ...st, loading: false })));
  }, []);

  return (
    <div className={cls.itemRow} spacing={2}>
      <Grid container>
        <Grid item xs={12} md={6}>
          <StatCard
            title="Total requests"
            color="yellow"
            data={state.DATA.req}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatCard
            title="Total Offers"
            color="black"
            white
            data={state.DATA.ord}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatCard
            title="Completed Deals"
            color="green"
            data={state.DATA.com}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StatCard
            title="Rejected Deals"
            color="red"
            white
            data={state.DATA.rej}
          />
        </Grid>
      </Grid>
    </div>
  );
};
