import React from 'react';
import {
  Card,
  TableCell,
  TableHead,
  Table,
  TableRow,
  TableBody,
  Grid,
} from '@material-ui/core';
// import { AccountCircle, HomeRounded, PrintRounded } from '@material-ui/icons';
import { AccountCircle, HomeRounded } from '@material-ui/icons';
import { _get, fDate, genSize } from '@/actions/Helper';
import styles from './styles';

const parseData = (data) =>
  data.map((i) => ({
    id: _get(i, 'id', ''),
    stat: _get(i, 'status_string', 0),
    up: _get(i, 'updated_at', ''),
    ssl: _get(i, 'inventory.cabin.company.name', ''),
    loc: _get(i, 'inventory.address.city', ''),
    user: _get(i, 'inventory.user.name', ''),
    company: _get(i, 'inventory.user.company', ''),
    acc: _get(i, 'accepted_count', 0),
    in: _get(i, 'inprogress_count', 0),
    tot: _get(i, 'requested_count', 0),
    size: genSize(_get(i, 'inventory.cabin.specifications', {})),
  }));

const genDetail = (i) => `${i.tot}-${i.ssl}-${i.size}-${i.loc}`;

export default ({ data = {} }) => {
  const cls = styles();
  const [DATA, setData] = React.useState([]);

  React.useEffect(() => {
    if (data && data.orders) setData(parseData(data.orders));
  }, [data]);

  return (
    <Grid container spacing={3} className={cls.container}>
      <Grid item xs={12} lg={3}>
        <div className={cls.detailCard}>
          <div className={cls.detailHeader}>Offers</div>
          <div className={cls.detailContent}>
            <div className={cls.detailItem}>
              <div>Total</div>
              <div>{data.total_count}</div>
            </div>
            <div className={cls.detailItem}>
              <div>In Progress</div>
              <div>{data.inprogress_count}</div>
            </div>
            <div className={cls.detailItem}>
              <div>Completed</div>
              <div>{data.completed_count}</div>
            </div>
            <div className={cls.detailItem}>
              <div>Rejected</div>
              <div>{data.rejected_count}</div>
            </div>
            <div className={cls.detailItem}>
              <div>Total container dealt</div>
              <div>{data.dealt_cointainer}</div>
            </div>
            <div className={cls.exportRow}>
              <span>Export</span>
              <div className={cls.blueRow}>
                <a href={data.excel_dl_link} download rel="noopener noreferrer">
                  XLSX
                </a>
                {/* <PrintRounded /> */}
              </div>
            </div>
          </div>
        </div>
      </Grid>

      <Grid item xs={12} lg={9}>
        <Card className={cls.card}>
          <Table className={cls.table} border={0} cellSpacing={0}>
            <TableHead className={cls.header}>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Company Offered</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Accepted Number</TableCell>
                <TableCell>Date</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              <TableRow className={cls.separator} />
              {DATA.map((i, idx) => (
                <TableRow key={idx}>
                  <TableCell>{i.id}</TableCell>
                  <TableCell>{genDetail(i)}</TableCell>
                  <TableCell>
                    <div className={cls.detailRow}>
                      <AccountCircle />
                      <span>{i.user}</span>
                      <HomeRounded />
                      <span>{i.company}</span>
                    </div>
                  </TableCell>
                  <TableCell>{i.stat}</TableCell>
                  <TableCell>{i.acc}</TableCell>
                  <TableCell>{fDate(i.up)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
      </Grid>
    </Grid>
  );
};
