import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  popup: {
    // position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'fixed',

  },

  card: {
    position: 'relative',
    padding: '35px 30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
  },
  loading: {
    height: '100%',
    margin: 0,
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  ml: {
    marginLeft: 20,
    width: 150,
  },
  mb: {
    display: 'block',
    marginBottom: 5,
  },
  butCont: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 25,
  },
  but: {
    width: 100,
    height: 38,
    borderRadius: 5,
    backgroundColor: '#007fff',
    fontFamily: 'Averta Light',
    fontSize: 14,
    fontWeight: 300,
    color: '#fff',
    padding: 0,

    '&:hover': {
      backgroundColor: '#007fff',
    },
  },
  cancel: {
    backgroundColor: '#e4464b',
    '&:hover': {
      backgroundColor: '#e4464b',
    },
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#e4464b',
  },
}));
