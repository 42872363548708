import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  mainContainer: {
    margin: '0 10px',
  },
  col1: {
    flex: 7.5,
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
  },
  col2: {
    flex: 6,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));
