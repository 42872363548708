import React from 'react';
import {
  Card,
  TableCell,
  TableHead,
  Table,
  TableRow,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import { LaunchRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { _get, fDate, fTimeDate, genSize } from '@/actions/Helper';
import { getOrderList } from '@/actions';
import styles from './styles';

const parseData = (data) =>
  data.map((i) => ({
    id: _get(i, 'id', ''),
    stat: _get(i, 'status_string', 0),
    up: _get(i, 'updated_at', ''),
    ssl: _get(i, 'inventory_with_out_user.cabin.company.name', ''),
    loc: _get(i, 'inventory_with_out_user.address.city', ''),
    acc: _get(i, 'accepted_count', 0),
    size: genSize(_get(i, 'inventory_with_out_user.cabin.specifications', {})),
  }));

const genDetail = (i) => `${i.acc}-${i.ssl}-${i.size}-${i.loc}`;

const linkGroups = {
  'In progress': 'inprogress',
  Complete: 'complete',
  Open: 'active',
};

export default (props) => {
  const cls = styles();
  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });

  React.useEffect(() => {
    getOrderList()
      .then((res) =>
        setState({ DATA: parseData(res.slice(0, 7)), loading: false })
      )
      .catch(() => setState({ DATA: [], loading: false }));
  }, []);

  const statClasses = {
    'In progress': cls.blue,
    Complete: cls.green,
    Open: cls.yellow,
  };

  if (state.loading)
    return (
      <Card className={clsx(cls.card, cls.center)}>
        <CircularProgress />
      </Card>
    );

  return (
    <Card className={cls.card}>
      <div className={clsx(cls.row)}>
        <span className={cls.title}>Latest Offers</span>
        <Link to="/orders" className={cls.see}>
          See More
        </Link>
      </div>

      <div className={cls.tableCont}>
        <Table stickyHeader className={cls.table} border={0} cellSpacing={0}>
          <TableHead className={cls.header}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Detail</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Last Inventory Update</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Manage</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow className={cls.separator} />
            {state.DATA.map((it, idx) => (
              <TableRow key={idx}>
                <TableCell>{it.id}</TableCell>
                <TableCell>{genDetail(it).substring(0, 25)}</TableCell>
                <TableCell className={statClasses[it.stat]}>
                  {it.stat}
                </TableCell>
                <TableCell>{fTimeDate(it.up)}</TableCell>
                <TableCell>{fDate(it.up)}</TableCell>
                <TableCell>
                  <Link to={`/orders/${linkGroups[it.stat]}`}>
                    <LaunchRounded className={cls.launchIcon} />
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
};
