import React from 'react';
import { TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import { SearchRounded } from '@material-ui/icons';
import { useNotify } from 'react-admin';
import { useSelector } from 'react-redux';
import clsx from 'clsx';

import { _get } from '@/actions/Helper';
import {
  SupportseenChatByUser,
  getSupportChatList,
  getAdminNewChatList,
  getChatList,
  SaveData,
  seenChat,
  SupportseenChat,
} from '@/actions';
import Request from '../Request';
import ChatUser from '../Request/ChatUser';
import Header from '../Header';
import styles from './styles';
import { useHistory } from 'react-router-dom';

const parseData = (data) => ({
  id: _get(data, 'order_id', ''),
  mode: _get(data, 'mode', ''),
  msg: _get(data, 'lastMessage.message', 'Click to Start Conversation.'),
  chat_id: _get(data, 'lastMessage.id', 0),
  update: _get(data, 'lastMessage.updated_at', new Date()),
  seen: _get(data, 'lastMessage.is_seen', 1),
  user: _get(data, 'withUser.name', ''),
  company: _get(data, 'withUser.company', ''),
  avatar: _get(data, 'withUser.image', 'A'),
  userId: _get(data, 'withUser.id', ''),
});

export default ({ setOtherPart }) => {
  const cls = styles();
  const notify = useNotify();
  const ChatCard = useSelector((state) => state.reducer.Chat || null);
  const type = useSelector((state) => state.reducer.ChatItem || null);
  const refresh = useSelector((state) => state.reducer.refresh || null);

  const history = useHistory();

  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });

  const RefreshData = React.useCallback(() => {
    setState({ loading: true });
    if (type === 'adminNewChat') {
      getAdminNewChatList()
        .then((res) =>
          setState({ DATA: res.map((i) => parseData(i)), loading: false })
        )
        .catch((err) => {
          notify(err, 'warning');
          setState({ loading: false });
        });
    } else if (type === 'support') {
      getSupportChatList()
        .then((res) =>
          setState({ DATA: res.map((i) => parseData(i)), loading: false })
        )
        .catch((err) => {
          notify(err, 'warning');
          setState({ loading: false });
        });
    } else {
      getChatList(type)
        .then((res) =>
          setState({ DATA: res.map((i) => parseData(i)), loading: false })
        )
        .catch((err) => {
          if (err === 'Complete your profile please.') {
            history.push('/profile');
          }
          notify(err, 'warning');
          setState({ loading: false });
        });
    }
  }, [notify, type]);

  React.useEffect(() => {
    if (type && !ChatCard) RefreshData();
  }, [type, ChatCard, RefreshData]);

  React.useEffect(() => {
    if (refresh) {
      RefreshData();
      SaveData({ refresh: null });
    }
  }, [refresh, RefreshData]);

  const Chat = ({ id, chat_id, seen, userId, user, company, mode }) => {
    setOtherPart({
      user,
      company,
    });
    if (type === 'adminNewChat') {
      SaveData({ Chat: { type, userId, user, mode } });
      if (!seen) SupportseenChat(chat_id);
    } else if (type === 'support') {
      SaveData({ Chat: { type, userId, user, mode } });
      if (!seen) SupportseenChatByUser(chat_id);
    } else {
      SaveData({ Chat: { type, id, mode } });
      if (!seen) seenChat(id, chat_id);
    }
  };
  // const requestTitle = type == Request ? 'Request' : '';
  return (
    <div className={cls.card} id="ChatDialog">
      {state.loading ? (
        <div className={cls.loading}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div className={cls.header}>
            <Header
              title={
                type === 'adminNewChat'
                  ? 'New'
                  : type === 'Offer'
                  ? 'Order'
                  : type
              }
            />
            <div className={cls.row}>
              <span>Newest</span>
              <span>&uarr; ID</span>
              <span>&darr; ID</span>
              <span className={cls.noBorder}>All</span>
            </div>

            <TextField
              fullWidth
              className={cls.search}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchRounded className={cls.icon} />
                  </InputAdornment>
                ),
              }}
            />
          </div>
          <div className={clsx('scroll-bar', cls.content)}>
            {state.DATA &&
              state.DATA.map((i, idx) =>
                type === 'adminNewChat' || type === 'support' ? (
                  <ChatUser DATA={i} key={idx} Chat={Chat} />
                ) : (
                  <Request DATA={i} key={idx} Chat={Chat} />
                )
              )}
          </div>
        </>
      )}
    </div>
  );
};
