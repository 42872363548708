import Welcome from '@/components/Finance/Welcome';
import DealsList from '@/components/Finance/DealsList';
import Filter from '@/components/Finance/Filter';
import OrderList from '@/components/Finance/OrderList';
import Report from '@/components/Finance/Report';
import RequestList from '@/components/Finance/RequestList';
import TransactionReport from '@/components/Finance/TransactionReport';
import UsersReport from '@/components/Finance/UsersReport';
import React, { useEffect, useState } from 'react';
import styles from './styles';
import Stat from '@/components/Finance/Stat';
import { Grid } from '@material-ui/core';
import {
  getAdminFinanceCustomReport,
  getAdminFinanceDailyReport,
  getAdminFinanceMonthlyReport,
  getAdminFinanceRequests,
  getAdminFinanceTrackingCompanies,
  getAdminFinanceYearlyReport,
} from '@/actions';

export default () => {
  const [trackingCompanies, setTrackingCompanies] = useState([]);
  const [tablesData, setTablesData] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState({
    name: null,
    reportData: {
      requests: {
        label: [],
        data: [],
      },
      completed: {
        label: [],
        data: [],
      },
      rejected: {
        label: [],
        data: [],
      },
      orders: {
        label: [],
        data: [],
      },
      users: {
        total: {
          label: [],
          data: [],
        },
        active: {
          label: [],
          data: [],
        },
        deactivated: {
          label: [],
          data: [],
        },
      },
    },
    title: '',
    trackingCompany: null,
    loading: false,
    date: {
      from: new Date(),
      to: new Date(),
    },
  });
  const cls = styles();

  const getDateFormDateObject = (dateObject) => {
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    return `${year}-${month}-${day}`;
  };

  const handleSaveReportResponse = (data) => {
    let chartData = {
      requests: {
        label: Object.keys(data.requests),
        data: Object.values(data.requests),
      },
      completed: {
        label: Object.keys(data.completed),
        data: Object.values(data.completed),
      },
    };

    if (data.orders) {
      chartData.orders = {
        label: Object.keys(data.orders),
        data: Object.values(data.orders),
      };
    }

    if (data.rejected) {
      chartData.rejected = {
        label: Object.keys(data.rejected),
        data: Object.values(data.rejected),
      };
    }

    if (data.users) {
      chartData.users = {
        total: {
          label: Object.keys(data.users.total),
          data: Object.values(data.users.total),
        },
        active: {
          label: Object.keys(data.users.active),
          data: Object.values(data.users.active),
        },
        deactivated: {
          label: Object.keys(data.users.deactivated),
          data: Object.values(data.users.deactivated),
        },
      };
    }

    setSelectedFilter((prev) => {
      return {
        ...prev,
        loading: false,
        reportData: chartData,
      };
    });
  };

  const getSelectedFilterData = () => {
    setSelectedFilter((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    let company = selectedFilter.trackingCompany
      ? selectedFilter.trackingCompany
      : null;
    switch (selectedFilter.name) {
      case 'day':
        getAdminFinanceDailyReport(company).then((data) => {
          handleSaveReportResponse(data);
        });
        break;
      case 'month':
        getAdminFinanceMonthlyReport(company).then((data) => {
          handleSaveReportResponse(data);
        });
        break;
      case 'year':
        getAdminFinanceYearlyReport(company).then((data) => {
          handleSaveReportResponse(data);
        });
        break;
      case 'custom':
        getAdminFinanceCustomReport(
          {
            from: getDateFormDateObject(selectedFilter.date.from),
            to: getDateFormDateObject(selectedFilter.date.to),
          },
          company
        ).then((data) => {
          handleSaveReportResponse(data);
        });
        break;
      default:
        break;
    }
  };

  const getFilterTitle = (name) => {
    switch (name) {
      case 'day':
        return 'Daily Report';
        break;
      case 'month':
        return 'Monthly Report';
        break;
      case 'year':
        return 'Yearly Report';
      case 'custom':
        return 'Custom Report';
        break;
      default:
        break;
    }
  };

  const changeFilter = (name) => {
    const title = getFilterTitle(name);
    setSelectedFilter((prev) => {
      return {
        ...prev,
        name,
        title,
      };
    });
  };

  const createTableData = (data) => {
    let adaptedData = [];
    data.forEach((item) => {
      adaptedData.push({
        id: item.id,
        company: item.inventory ? item.inventory.cabin.company.name : '',
        status: item.status_string,
        user: item.user,
        inventory: item.inventory,
        accepted_count: item.accepted_count,
        inprogress_count: item.inprogress_count,
        requested_count: item.requested_count,
        date: item.updated_at.split('T')[0],
        by: item.user ? item.user.name : '',
      });
    });
    return adaptedData;
  };

  const getTablesData = () => {
    getAdminFinanceRequests(
      selectedFilter.trackingCompany ? selectedFilter.trackingCompany : null
    ).then((data) => {
      let tables = {
        requests: createTableData(data.requests),
        completed: createTableData(data.completed),
      };
      if (data.orders) {
        tables.orders = createTableData(data.orders);
      }
      if (data.rejected) {
        tables.rejected = createTableData(data.rejected);
      }

      setTablesData(tables);
    });
  };

  useEffect(() => {
    if (!selectedFilter.name) {
      changeFilter('day');
    }
    getAdminFinanceTrackingCompanies().then((data) => {
      setTrackingCompanies(data);
    });
    getTablesData();
  }, []);

  useEffect(() => {
    getSelectedFilterData();
    getTablesData();
  }, [
    selectedFilter.name,
    selectedFilter.date,
    selectedFilter.trackingCompany,
  ]);

  return (
    <div className={cls.mainContainer}>
      <Filter
        setSelectedFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
        changeFilter={changeFilter}
        trackingCompanies={trackingCompanies}
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <div className={cls.col1}>
            <Welcome />
            {tablesData && (
              <>
                <RequestList data={tablesData.requests} />
                <OrderList
                  title={
                    tablesData.rejected
                      ? 'Latest Rejectd'
                      : tablesData.orders
                      ? 'Latest Offers'
                      : ''
                  }
                  data={
                    tablesData.rejected
                      ? tablesData.rejected
                      : tablesData.orders
                      ? tablesData.orders
                      : []
                  }
                />
                <DealsList data={tablesData.completed} />
              </>
            )}
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <div className={cls.col2}>
            <Report
              reportData={selectedFilter.reportData}
              selectedFilter={selectedFilter}
            />
            <Stat />
            <TransactionReport />
            <UsersReport users={selectedFilter.reportData.users} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
