import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: ({ me }) => (me ? 'row-reverse' : 'row'),
    alignItems: ({ me }) => (me ? 'flex-end' : 'flex-start'),
    margin: '10px 0',
  },
  chat: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px 10px',
    justifyContent: 'space-between',
    width: 241,
    backgroundImage: ({ me }) =>
      me
        ? 'linear-gradient(255deg, #007ef4, #2a75bc)'
        : 'linear-gradient(287deg, #2b2b2b 100%, #454545)',

    borderRadius: ({ me }) => (me ? '20px 20px 0 20px' : '0 20px 20px 20px'),
  },

  title: {
    fontSize: 12,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.6,
    color: '#fff',
    marginBottom: 12,
    wordBreak: 'break-all',
  },

  time: {
    fontSize: 9,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.3,
    color: '#ccc',
  },

  avatar: {
    height: 35,
    width: 35,
    margin: ({ me }) => (me ? '0 0 0 5px' : '0 5px 0 0'),
  },
  img: {
    height: 200,
    borderRadius: 20,
    marginBottom: 10,
    objectFit: 'contain',
  },
  chatLine: {
    display: 'block'
  }
}));
