import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles(({ breakpoints: BP }) => ({
  card: {
    height: 420,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    margin: '10px',
    '& a': {
      textDecoration: 'none',
    },
    position: 'relative',
    padding: '25px 20px',
    boxSizing: 'border-box',
    [BP.up('xl')]: {
      padding: '30px 20px',
    },
  },
  title: {
    fontSize: '1.4rem',
    fontFamily: 'Averta Bold',
    color: '#303030',
    fontWeight: 'bold',
    lineHeight: 1.9,
    [BP.up('xl')]: {
      fontSize: 25,
    },
  },
  see: {
    fontSize: 40,
    fontFamily: 'Averta Bold',
    color: '#43424b',
    fontWeight: 900,
    position: 'absolute',
    right: 20,
    top: 0,
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  chartCont: {
    display: 'flex',
    height: 300,
    paddingTop: 15,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}));

const ChartOptions = {
  maintainAspectRatio: false,
  scales: {
    xAxes: [
      {
        gridLines: { display: false },
        ticks: {
          fontFamily: 'Averta Light',
          fontSize: 11,
          color: '#a6a9b7',
          padding: 0,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          display: true,
          borderDash: [10],
          drawBorder: false,
        },
        ticks: {
          fontFamily: 'Averta Light',
          fontSize: 11,
          color: '#a6a9b7',
          padding: 15,
          suggestedMin: 0,
        },
      },
    ],
  },
  legend: {
    display: true,
    align: 'end',
    boxWidth: 20,
    padding: 45,
    fullWidth: true,
    labels: {
      fontFamily: 'Averta Light',
      fontSize: 12,
      fontColor: '#43424b',
      usePointStyle: true,
      pointStyle: 'cross',
      padding: 10,
    },
  },
};

export { styles, ChartOptions };
