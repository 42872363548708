import {
  adminAddNewTms,
  adminDeleteTms,
  adminGetSingleTmsType,
  adminGetTmsList,
  adminSaveTmsExcelConfigs,
} from '@/actions';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styles from './styles';
import { Grid, Button, TextField, CircularProgress } from '@material-ui/core';
import SSLTable from '@/components/Integration/TMS/SSlTable';
import SizeTable from '@/components/Integration/TMS/SizeTable';
import ColumnsTable from '@/components/Integration/TMS/ColumnTable';
import { useNotify } from 'react-admin';
import DeletePopup from '@/components/DeletePopup';
import CityTable from '@/components/Integration/TMS/CityTable';

export default () => {
  const [tmsName, setTmsName] = useState(null);
  const [tmsList, setTmsList] = useState(null);
  const [newTms, setNewTms] = useState('');
  const [loadingTables, setLoadingTables] = useState('list');
  const [tablesData, setTablesData] = useState([]);
  const [deleteTmsPopup, setDeleteTmsPopup] = useState(false);
  const [tableCities, setTableCities] = useState([]);

  const cls = styles();
  const notify = useNotify();

  const getTmsList = () => {
    adminGetTmsList()
      .then((data) => {
        let options = data.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setTmsList(options);
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  useEffect(() => {
    getTmsList();
  }, []);

  const handleDeleteTms = () => {
    setDeleteTmsPopup(false);
    if (tmsName) {
      setLoadingTables('delete');
      adminDeleteTms(tmsName.value, tmsName.name).then(() => {
        setLoadingTables(null);
        setTmsName(null);
        getTmsList();
        notify('successfully');
      });
    }
  };

  const handleSelectTmsType = (tmsType) => {
    setLoadingTables('list');
    setTmsName(tmsType);
    adminGetSingleTmsType(tmsType.value)
      .then((data) => {
        let adaptedCities = [];
        Object.keys(data.cities).forEach((key, index) => {
          adaptedCities.push({
            user: key,
            smart: {
              value: data.cities[key],
              label: data.cities[key].city,
            },
          });
        });
        setTableCities(adaptedCities);
        setTablesData(data);
        setLoadingTables(null);
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleSaveExcelConfig = () => {
    setLoadingTables('update');
    let cities = {};
    tableCities.forEach((item) => {
      if (item.user && item.smart) {
        cities[item.user] = item.smart.value;
      }
    });
    let apiData = {
      ssl: tablesData.ssl,
      size_type: tablesData.size_type,
      columns: tablesData.columns,
      cities,
      _method: 'patch',
    };
    adminSaveTmsExcelConfigs(apiData, tmsName.value)
      .then((data) => {
        notify('Successfully Updated ');
        setLoadingTables(null);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoadingTables(null);
      });
  };

  const handleAddNewTms = () => {
    adminAddNewTms(newTms)
      .then((data) => {
        notify('Successfully Added');
        let options = data.tms_types.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setTmsList(options);
        setNewTms('');
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  return (
    <div className={cls.content}>
      {deleteTmsPopup && (
        <DeletePopup
          handleDelete={() => handleDeleteTms()}
          closePopup={() => setDeleteTmsPopup(false)}
        />
      )}
      <div className={cls.card}>
        <h2 className={cls.title}>Add New TMS</h2>
        <div>
          <TextField
            defaultValue={newTms}
            value={newTms}
            onChange={(e) => setNewTms(e.target.value)}
            type="text"
            className={cls.input}
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0 }}
          />
          <div className={cls.btnContainer}>
            <Button onClick={() => handleAddNewTms()}>Add</Button>
          </div>
        </div>
      </div>
      <div className={cls.card}>
        <h2 className={cls.title}>List Of TMS</h2>
        <Select
          isClearable
          placeholder="List of TMS"
          className={cls.select}
          value={tmsName}
          onChange={(item) => handleSelectTmsType(item)}
          options={tmsList}
        />
      </div>
      {tmsName && (
        <>
          {loadingTables === 'list' ? (
            <div className={cls.loaderContainer}>
              <CircularProgress className={cls.loader} />
            </div>
          ) : (
            <>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <SSLTable
                    tablesData={tablesData}
                    setTablesData={setTablesData}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SizeTable
                    tmsName={tmsName}
                    tablesData={tablesData}
                    setTablesData={setTablesData}
                  />
                  <CityTable
                    // selectedCompany={selectedCompany}
                    tableCities={tableCities}
                    setTableCities={setTableCities}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <ColumnsTable
                    tablesData={tablesData}
                    setTablesData={setTablesData}
                  />
                </Grid>
              </Grid>

              <div className={cls.actionConatiner}>
                {loadingTables === 'update' ? (
                  <CircularProgress className={cls.loader} />
                ) : (
                  <Button
                    className={cls.saveBtn}
                    onClick={() => handleSaveExcelConfig()}>
                    Save
                  </Button>
                )}
                {loadingTables === 'delete' ? (
                  <CircularProgress className={cls.loader} />
                ) : (
                  <Button
                    className={cls.deleteBtn}
                    onClick={() => setDeleteTmsPopup(true)}>
                    Delete TMS
                  </Button>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
