import React from 'react';
import { CloseRounded } from '@material-ui/icons';
import { SaveData } from '@/actions';
import styles from './styles';

export default ({ title }) => {
  const cls = styles();

  return (
    <div className={cls.container}>
      <span className={cls.title}>{title}</span>
      <CloseRounded
        className={cls.icon}
        onClick={() => SaveData({ ChatItem: null, Chat: null })}
      />
    </div>
  );
};
