import React, { useState } from 'react';
import { Grid, TextField, Button } from '@material-ui/core';
import DateInput from '@/components/DateInput';
import styles from './styles';
import TextEditor from '@/components/TextEditor';
import Select from 'react-select';
import Preview from './Preview';

export default ({
  content1,
  setContent1,
  content2,
  setContent2,
  showPreview,
  setShowPreview,
  previewType,
  formData,
  setFormData,
  handleSendForm,
  btnText,
}) => {
  const [selectedSection, setSelectedSection] = useState(null);
  const cls = styles();

  const changeFormData = (key, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  return (
    <div>
      {showPreview ? (
        <Preview content1={content1} content2={content2} />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <span className={cls.inputLabel}>Start date</span>
              <DateInput
                placeholder="Start date"
                className={cls.date}
                format="MM/dd/yyyy"
                value={formData.start_date}
                onChange={(item) => changeFormData('start_date', item)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className={cls.inputLabel}>End date</span>
              <DateInput
                placeholder="Available date"
                className={cls.date}
                format="MM/dd/yyyy"
                value={formData.end_date}
                onChange={(item) => changeFormData('end_date', item)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className={cls.inputLabel}>Price</span>
              <div className={`${cls.textInput} ${cls.priceInput}`}>
                <span className={cls.dollar}>$</span>
                <TextField
                  type="number"
                  placeholder="Price"
                  className={cls.price}
                  value={formData.price}
                  onChange={(e) => changeFormData('price', e.target.value)}
                  InputProps={{ disableUnderline: true }}
                  inputProps={{ min: 0 }}
                />
              </div>
            </Grid>
            <Grid item xs={12} sm={6}>
              <span className={cls.inputLabel}>Name</span>
              <TextField
                type="text"
                placeholder="Name"
                className={cls.textInput}
                value={formData.name}
                onChange={(e) => changeFormData('name', e.target.value)}
                InputProps={{ disableUnderline: true }}
                inputProps={{ min: 0 }}
              />
            </Grid>
            <Grid item xs={12}>
              <span className={cls.inputLabel}>ٍEditor</span>
              <Select
                placeholder="Editor"
                className={cls.select}
                value={selectedSection}
                onChange={(item) => setSelectedSection(item)}
                options={[
                  {
                    label: 'Section 1',
                    value: 1,
                  },
                  {
                    label: 'Section 2',
                    value: 2,
                  },
                ]}
              />
              {selectedSection && (
                <div className={cls.editor}>
                  {selectedSection.value === 1 && (
                    <TextEditor
                      editorState={content1}
                      setEditorState={setContent1}
                    />
                  )}
                  {selectedSection.value === 2 && (
                    <TextEditor
                      editorState={content2}
                      setEditorState={setContent2}
                    />
                  )}
                </div>
              )}
            </Grid>
          </Grid>
          <div className={cls.actionContainer}>
            <Button
              onClick={() => setShowPreview(previewType)}
              className={cls.previewBtn}>
              Preview
            </Button>
            <Button onClick={handleSendForm} className={cls.saveSettingsBtn}>
              {btnText}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
