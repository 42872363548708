import React, { useEffect, useState } from 'react';
import styles from './styles';
import {
  TextField,
  Button,
  CircularProgress,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { adminChangeXlsUrl } from '@/actions';

export default ({ companies, notify, state, handleSelectCompany }) => {
  const [loadingForm, setLoadingForm] = useState(false);
  const [url, setUrl] = useState('');
  const [tmsAlias, setTmsAlias] = useState('');
  const cls = styles();

  const handleChangeXlsUrl = () => {
    setLoadingForm(true);
    let data = {
      user_id: state.DATA.id,
      xls_url: url,
    };
    tmsAlias && (data.tms_manual_alias = tmsAlias);
    adminChangeXlsUrl(data)
      .then((data) => {
        setLoadingForm(false);
        notify('successfully updated', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoadingForm(false);
      });
  };

  useEffect(() => {
    setUrl(state.DATA.xls_url);
    state.DATA.tms_manual_alias && setTmsAlias(state.DATA.tms_manual_alias);
  }, [state]);

  return (
    <div className={cls.card}>
      {state.loading ? (
        <div className={cls.loaderContainer}>
          <CircularProgress className={cls.loader} />
        </div>
      ) : (
        <>
          <div className={cls.urlContainer}>
            <span>Excel URL:</span>
            <TextField
              type="url"
              placeholder="Excel URL:"
              className={cls.input}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
              InputProps={{ disableUnderline: true }}
              inputProps={{ min: 0 }}
            />
          </div>
          <div style={{ marginTop: '20px' }}>
            <div className={cls.optionContainer}>
              <span>TMS Alias:</span>
            </div>
            <div className={cls.urlContainer}>
              <TextField
                type="text"
                placeholder="TMS Alias"
                className={cls.input}
                InputProps={{ disableUnderline: true }}
                value={tmsAlias}
                onChange={(e) => setTmsAlias(e.target.value)}
              />
            </div>
          </div>
          <div className={cls.actions}>
            {loadingForm ? (
              <div className={cls.loaderContainer}>
                <CircularProgress className={cls.loader} />
              </div>
            ) : (
              <div className={cls.btnContainer}>
                <Button
                  disabled={Object.keys(state.DATA).length < 0 ? true : false}
                  onClick={() => handleChangeXlsUrl()}>
                  Save
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
