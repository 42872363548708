import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: '0 2vw',
  },
  card: {
    borderRadius: 5,
    backgroundColor: '#fff',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    padding: '50px 70px',
    '@media (max-width:600px)': {
      padding: '30px',
    },
    '@media (max-width:400px)': {
      padding: '20px',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filter: {
    height: 25,
    width: 25,
    objectFit: 'contain',
    fill: '#9f9f9f',
    marginLeft: 20,
  },
  title: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 1,
    color: '#43424b',
  },
  headerBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '30px 40px 20px 40px',
  },
}));
