import React, { useEffect } from 'react';
import { Card, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { styles, ChartOptions } from './styles';

export default ({ reportData, selectedFilter }) => {
  const cls = styles();
  const [state, setState] = React.useState({
    labels: [],
    rejDATA: [],
    reqDATA: [],
    comDATA: [],
    ordDATA: [],
    loading: true,
  });

  useEffect(() => {
    let labels = [];
    Object.keys(reportData).forEach((item) => {
      if (item.label) {
        labels.push(...item.label);
      }
    });

    let chartObj = {
      reqDATA: reportData.requests.data,
      comDATA: reportData.completed.data,
    };

    if (reportData.rejected) {
      chartObj.rejDATA = reportData.rejected.data;
    }

    if (reportData.orders) {
      chartObj.ordDATA = reportData.orders.data;
    }

    setState({
      ...chartObj,
      labels,
      loading: false,
    });
  }, [reportData]);

  const ChartData = {
    labels: state.labels,
    datasets: [
      {
        label: reportData.Rejected ? 'Rejected' : 'Offers',
        barThickness: 30,
        backgroundColor: '#D9D9D9',
        // borderWidth: 2,
        // borderColor: '#f9bb05',
        // hoverBackgroundColor: '#49494955',
        data: reportData.Rejected ? state.rejDATA : state.ordDATA,
      },
      {
        label: 'Requests',
        barThickness: 40,
        backgroundColor: '#F8B100',
        data: state.reqDATA,
      },
      {
        label: 'Completed',
        barThickness: 20,
        backgroundColor: '#2BA672',
        data: state.comDATA,
      },
    ],
  };

  // if (state.loading)
  //   return (
  //     <Card className={clsx(cls.card, cls.center)}>
  //       <CircularProgress />
  //     </Card>
  //   );

  return (
    <Card className={cls.card}>
      <div className={clsx(cls.row)}>
        <span className={cls.title}>{selectedFilter.title}</span>
        <Link to="/report" className={cls.see}>
          ...
        </Link>
      </div>

      <div className={cls.chartCont}>
        <Bar data={ChartData} options={ChartOptions} />
      </div>
    </Card>
  );
};
