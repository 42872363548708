import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    width: '100%',
    padding: '0 2vw',
    boxSizing: 'border-box',
    [BP.down('xs')]: {
      padding: '0 1.8rem',
    },
  },

  title: {
    fontSize: 58,
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    lineHeight: 1,
    color: '#303030',
    textAlign: 'center',
    marginTop: 90,
    [BP.down('xs')]: {
      fontSize: '2.5rem',
      marginTop: 70,
    },
  },
  des: {
    fontSize: 24,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.5,
    color: '#303030',
    textAlign: 'center',
    marginTop: 30,
    textTransform: 'uppercase',
    [BP.down('xs')]: {
      fontSize: '1.6rem',
      marginTop: 20,
      lineHeight: 1.2,
    },
  },
  row: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 70,
    [BP.down('xs')]: {
      flexDirection: 'column',
      marginTop: 50,
    },
  },
  col: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '0 10px',
  },
  col1: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    margin: '0 10px',
    [BP.down('xs')]: {
      flexDirection: 'column-reverse',
    },
  },
  item: {
    height: '50vh',
    borderRadius: 15,
    backgroundColor: '#303030',
    color: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px 0',
    transition: 'all 0.5s ease-in-out',
    padding: '0 20%',
    textAlign: 'center',
    boxSizing: 'border-box',
    border: 'solid 2px #303030',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#303030',
    },
    [BP.down('md')]: {
      padding: '0 10%',
    },
    [BP.down('xs')]: {
      margin: '10px 0',
      padding: '4rem 1rem',
      height: 'auto',
    },
   
  },
  itemTitle: {
    fontSize: 31,
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    lineHeight: 1.52,
    [BP.down('xs')]: {
      fontSize: '1.5rem',
    },
  },
  itemDes: {
    fontSize: 20,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.3,
    [BP.down('xs')]: {
      fontSize: '1rem',
    },
  },
  white: {
    backgroundColor: '#fff',
    color: '#303030',
    border: 'none',
    '&:hover': {
      backgroundColor: '#303030',
      color: '#fff',
    },
  },
}));
