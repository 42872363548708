import React, { useState } from 'react';
import styles from './styles';
import { BaseIcon } from '@/Utils';
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import { MemberIcons as memberIcons, Icons } from '@/assets';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import EditMemberPopup from '@/components/Members/Edit';
import AddMemberPopup from '@/components/Members/Add';
import { DeleteRounded, CreateRounded, Email } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { fDate, fTime, fTimeDate } from '@/actions/Helper';
import {
  adminAddMember,
  adminChangeExport,
  adminDeleteMember,
  adminEditMember,
  adminLoginToUserAccount,
  adminSendVerificationEmail,
  SaveData,
} from '@/actions';
import DeleteMemberPopup from '@/components/DeletePopup';
import {
  Avatar,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
} from '@material-ui/core';
import Filter from '@/components/Members/Filter';
import { UrlField } from 'react-admin';
import DeletePopup from '@/components/DeletePopup';
import { validateAdminAddMemberData } from '@/actions/validations';
import { now } from 'lodash';
import { date } from 'yup';
import { tr } from 'date-fns/locale';

export default ({
  DATA = [],
  notify,
  getMembers,
  filter,
  setFilter,
  setDATA,
  setLoading,
  setFilterForm,
}) => {
  const cls = styles();
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    last_name: '',
    email: '',
    phone: '',
    company: '',
    password: '',
    // status: DATA.status,
  });
  console.log('members', DATA);
  const [loginLoading, setLoginLoading] = useState(null);
  const [userAvatar, setUserAvatar] = useState({
    url: null,
    image: null,
  });
  const editpopUp = useSelector(
    (state) => state.reducer.editMemberPopup || null
  );
  const addpopUp = useSelector((state) => state.reducer.addMemberPopup || null);
  const deletepopUp = useSelector(
    (state) => state.reducer.deleteMemberPopup || null
  );

  let history = useHistory();

  const [addMember, setAddMember] = useState({
    showForm: false,
    formValues: {
      name: {
        name: 'name',
        value: '',
        type: 'text',
        placeholder: 'First Name*',
        flex: 0.9,
      },
      last_name: {
        name: 'last_name',
        value: '',
        type: 'text',
        placeholder: 'Last Name*',
        flex: 0.9,
      },
      email: {
        name: 'email',
        value: '',
        type: 'email',
        placeholder: 'Email Address*',
        flex: 1,
      },
      phone: {
        name: 'phone',
        value: '',
        type: 'text',
        placeholder: 'Phone*',
        flex: 0.7,
      },
      company: {
        name: 'company',
        value: '',
        type: 'text',
        placeholder: 'Company*',
        flex: 0.7,
      },
      password: {
        name: 'password',
        value: '',
        type: 'password',
        placeholder: 'Password*',
        flex: 0.7,
      },
    },
  });

  const renderTableActions = (member) => {
    let actionItems = [
      {
        icon: <CreateRounded className={cls.editIcon} />,
        tooltip: 'Edit',
        onClick: () => {
          SaveData({ editMemberPopup: member });
        },
      },
      {
        icon: (
          <Email src={memberIcons.verifyMember} className={cls.maileIcon} />
        ),
        tooltip: 'Verify',
        onClick: () => {
          adminSendVerificationEmail(member.id).then((response) => {
            if (response.status === 'success') {
              notify('Verification email resent.', { type: 'success' });
            }
          });
        },
      },
      {
        icon: <DeleteRounded className={cls.delIcon} />,
        tooltip: 'Delete',
        onClick: () => {
          SaveData({ deleteMemberPopup: member.id });
        },
      },
    ];
    return (
      <div className={cls.tableActionsContainer}>
        {actionItems.map((item, index) => (
          <Tooltip arrow title={item.tooltip} placement="top">
            <IconButton onClick={item.onClick}>{item.icon}</IconButton>
          </Tooltip>
        ))}
      </div>
    );
  };

  const handleChangeStatus = (id, status) => {
    adminEditMember({ status, id, isEditStatus: true })
      .then(() => {
        notify('successfully updated', 'success');
        getMembers();
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleChangeExport = (id, enable) => {
    adminChangeExport({ user: id, enable: enable === 1 ? true : false })
      .then(() => {
        notify('successfully updated', 'success');
        getMembers();
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleChangeInput = (name, value) => {
    setAddMember((prev) => {
      return {
        ...prev,
        formValues: {
          ...prev.formValues,
          [name]: {
            ...prev.formValues[name],
            value,
          },
        },
      };
    });
  };

  const handleUploadUserAvatar = (e) => {
    let img = e.target.files[0];
    setUserAvatar({
      image: img,
      url: URL.createObjectURL(img),
    });
  };

  const handleDeleteMember = (member) => {
    adminDeleteMember(member).then((res) => {
      if (res.status === 'success') {
        getMembers();
        notify('member successfully deleted', { type: 'success' });
      }
    });
    SaveData({ deleteMemberPopup: false });
  };

  const handleLoginUser = (user_id) => {
    setLoginLoading(user_id);
    let adminToken = localStorage.getItem('token');
    adminLoginToUserAccount(user_id)
      .then((data) => {
        localStorage.setItem('token', data.access_token);
        localStorage.setItem('permissions', 'user');
        localStorage.setItem('token_admin', adminToken);
        history.push('/dashboard');
        window.location.reload();
        notify('Redirect to User Account', 'success');
        setLoginLoading(null);
      })
      .then((err) => {
        notify(err, 'warning');
        setLoginLoading(null);
      });
  };

  const updateMembers = (data) => {
    adminEditMember(data)
      .then(() => {
        SaveData({ editMemberPopup: false });
        notify('successfully updated', 'success');
        getMembers();
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const resetAddMemberValues = () => {
    setFormData({
      id: '',
      name: '',
      last_name: '',
      email: '',
      phone: '',
      company: '',
      password: '',
    });
    setUserAvatar({
      image: '',
      url: '',
    });
  };

  const handleAdd = () => {
    let data = formData;

    if (userAvatar.image) {
      data.image = userAvatar.image;
    }

    let error = validateAdminAddMemberData(data);
    if (error) {
      notify(error, { type: 'warning' });
    } else {
      adminAddMember(formData)
        .then((res) => {
          if (res.status === 'success') {
            notify(res.message, { type: 'success' });
            getMembers();
            resetAddMemberValues();
          }
        })
        .catch((res) => {
          if (res.errors) {
            Object.keys(res.errors).map((message) => {
              notify(res.errors[message][0], { type: 'warning' });
            });
          } else {
            notify(res, { type: 'warning' });
          }
        });
    }
  };

  var todayDate = new Date();
  var todayTimeMinez1 = new Date();

  todayTimeMinez1.setHours(todayTimeMinez1.getHours() - 1);
  // console.log('today', fTime(today));
  console.log(fDate(todayDate));
  // console.log(fTime(today) > fTime(todayNow));
  // console.log(fDate(today) < fDate(todayNow));

  const isExpiredTime = (time) => {
    let datetime = new Date(time);
    datetime.setMinutes(datetime.getMinutes() + 90);
    if (new Date() > datetime) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={cls.container}>
      {editpopUp && (
        <EditMemberPopup
          update={updateMembers}
          DATA={editpopUp}
          notify={notify}
        />
      )}
      {addpopUp && (
        <AddMemberPopup
          handleAdd={handleAdd}
          formData={formData}
          setFormData={setFormData}
          userAvatar={userAvatar}
          setUserAvatar={setUserAvatar}
        />
      )}
      {deletepopUp && (
        <DeleteMemberPopup
          handleDelete={() => handleDeleteMember(deletepopUp)}
          closePopup={() => SaveData({ deleteMemberPopup: false })}
        />
      )}
      <Filter
        title="Members"
        filter={filter}
        setFilter={setFilter}
        setFilterForm={setFilterForm}
        DATA={DATA}
        setLoading={setLoading}
        notify={notify}
        setDATA={setDATA}></Filter>
      <div className={cls.tableContainer}>
        <table className={cls.list}>
          <thead className={cls.thead}>
            <th style={{ flex: 1.3, textAlign: 'left' }}>
              <span style={{ paddingLeft: '70px' }}>Member</span>
            </th>
            {/* <th style={{ flex: 0.35 }}>First Name</th>
          <th style={{ flex: 0.9 }}>Last Name</th> */}
            <th style={{ flex: 1.5 }}>Email Address</th>
            <th style={{ flex: 0.7 }}>Phone</th>
            <th style={{ flex: 0.7 }}>Company</th>
            <th style={{ flex: 0.7 }}>Integration</th>
            <th style={{ flex: 0.7 }}>Naming</th>
            <th style={{ flex: 0.7 }}>Last update</th>
            <th style={{ flex: 0.7 }}>Export</th>
            <th style={{ flex: 0.7 }}>Status</th>
            {/* <th style={{ flex: 0.7 }}>Status</th> */}
            <th style={{ flex: 0.7 }}>Actions</th>
          </thead>
          <tbody className={cls.tbody}>
            <tr className={cls.tableRow}>
              <td className={cls.addAvatar}>
                {/* <div
                className={clsx(
                  cls.addMemberIconContainer,
                  addMember.showForm && cls.activeIconContainer
                )}>
                {addMember.showForm ? (
                  <>
                    <input
                      className={cls.addAvatarInput}
                      id="member_icon"
                      type="file"
                      onChange={(e) => handleUploadUserAvatar(e)}
                    />
                    <label for="member_icon">
                      {userAvatar.url ? (
                        <img className={cls.userAvatar} src={userAvatar.url} />
                      ) : (
                        <BaseIcon
                          src={memberIcons.addMember}
                          onClick={() => {
                            setAddMember({
                              ...addMember,
                              showForm: true,
                            });
                          }}
                          className={clsx(cls.icon, cls.activeIcon)}
                        />
                      )}
                    </label>
                  </>
                ) : ( */}
                <BaseIcon
                  src={memberIcons.addMember}
                  // onClick={() => {
                  //   setAddMember({
                  //     ...addMember,
                  //     showForm: true,
                  //   });
                  // }}
                  onClick={() => {
                    SaveData({ addMemberPopup: true });
                  }}
                  className={clsx(cls.icon)}
                />
                {/* )} */}
                {/* </div> */}
              </td>
              <span
                onClick={() => {
                  SaveData({ addMemberPopup: true });
                }}
                className={cls.addMemberText}>
                Add Member
              </span>

              {/* {addMember.showForm && (
              <>
                {Object.keys(addMember.formValues).map((input, index) => (
                  <div
                    style={{ flex: addMember.formValues[input].flex }}
                    className={cls.bold}>
                    <TextInput
                      value={addMember.formValues[input].value}
                      type={addMember.formValues[input].type}
                      name={addMember.formValues[input].name}
                      placeholder={addMember.formValues[input].placeholder}
                      onChange={(e) =>
                        handleChangeInput(e.target.name, e.target.value)
                      }
                    />
                  </div>
                ))}
                <div style={{ flex: 0.7 }}>
                  {
                    <div className={cls.actions}>
                      <button
                        className={cls.addBtn}
                        onClick={() => handleAddMember()}>
                        Add
                      </button>
                      <button
                        className={cls.cancleBtn}
                        onClick={() => {
                          resetAddMemberValues();
                        }}>
                        Cancle
                      </button>
                    </div>
                  }
                </div>
              </>
            )} */}
            </tr>
            {DATA.map((i, idx) => {
              return (
                <tr className={cls.tableRow} key={idx}>
                  <td
                    style={{ flex: 1.3 }}
                    className={`${cls.bigText} ${cls.userName}`}>
                    <div style={{ marginLeft: 10, width: 20 }}>{i.id}</div>

                    <Avatar src={i.image} className={cls.avatar} />
                    <Tooltip
                      arrow
                      title="Login to User Account"
                      placement="top">
                      <span
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleLoginUser(i.id)}>{`${i.name} ${
                        i.last_name || ''
                      }`}</span>
                    </Tooltip>
                    {loginLoading && loginLoading === i.id && (
                      <CircularProgress className={cls.loginLoader} />
                    )}
                  </td>
                  <td style={{ flex: 1.5 }}>{i.email}</td>
                  <td style={{ flex: 0.7 }}>{i.phone}</td>
                  <td style={{ flex: 0.7 }}>{i.company}</td>
                  <td style={{ flex: 0.7 }}>
                    {i.active_excel_mode === 1 && 'TMS/FTP'}
                    {i.active_excel_mode === 2 && 'TMS/Email'}
                    {i.active_excel_mode === 3 && 'Excel And GoogleSheet'}
                    {i.active_excel_mode === 4 && 'Email'}
                    {i.active_excel_mode === 5 && 'Application'}
                  </td>
                  <td id="LasupdateDate" style={{ flex: 0.7 }}>
                    {(i.active_excel_mode === 1 || i.active_excel_mode === 2) &&
                      i.tms.name}
                    {(i.active_excel_mode === 3 ||
                      i.active_excel_mode === 4 ||
                      i.active_excel_mode === 5) &&
                      `Manual / ${
                        i.tms_manual_alias ? i.tms_manual_alias : '-'
                      }`}
                  </td>
                  <td
                    style={{ flex: 0.7 }}
                    className={
                      i.last_inventory_update
                        ? isExpiredTime(i.last_inventory_update.updated_at)
                          ? cls.LasupdateDate
                          : ''
                        : ''
                    }>
                    {i.last_inventory_update
                      ? fTimeDate(i.last_inventory_update.updated_at)
                      : ''}
                    {/* {i.last_inventory_update
                      ? fTimeDate(today) >
                          fTimeDate(i.last_inventory_update.updated_at) ===
                        false
                        ? console.log(
                            'vaziyat',
                            fTimeDate(i.last_inventory_update.updated_at) >
                              fTimeDate(today)
                          )
                        : '--kochike'
                      : '--nadare'} */}
                    {/* -- */}
                    {/* {i.last_inventory_update
                      ? fTimeDate(
                          fTimeDate(Date.now()) -
                            fTimeDate(i.last_inventory_update.updated_at)
                        )
                      : ''}
                    --
                    {i.last_inventory_update
                      ? fTimeDate(i.last_inventory_update.updated_at)
                      : ''}
                    --{fTimeDate(todayNow)} */}
                  </td>
                  <td style={{ flex: 0.7 }}>
                    {
                      <FormControl>
                        <Select
                          displayEmpty
                          disableUnderline={true}
                          value={i.enable_export}
                          // className={cls.selectBox}
                          onChange={(e) =>
                            handleChangeExport(i.id, e.target.value)
                          }
                          defaultValue={1}
                          inputProps={{ 'aria-label': 'Without label' }}>
                          <MenuItem selected disabled value="">
                            Export
                          </MenuItem>
                          <MenuItem key={1} value={1}>
                            Active
                          </MenuItem>
                          <MenuItem key={1} value={0}>
                            Deactive
                          </MenuItem>
                        </Select>
                      </FormControl>
                    }
                  </td>
                  <td style={{ flex: 0.7 }}>
                    {
                      <FormControl>
                        <Select
                          displayEmpty
                          disableUnderline={true}
                          value={i.status}
                          // className={cls.selectBox}
                          onChange={(e) =>
                            handleChangeStatus(i.id, e.target.value)
                          }
                          inputProps={{ 'aria-label': 'Without label' }}>
                          <MenuItem selected disabled value="">
                            Status
                          </MenuItem>
                          <MenuItem key={1} value={1}>
                            Registered
                          </MenuItem>
                          <MenuItem key={2} value={2}>
                            Active
                          </MenuItem>
                          <MenuItem key={3} value={3}>
                            Deactive
                          </MenuItem>
                          <MenuItem key={0} value={0}>
                            Suspended
                          </MenuItem>
                          {/* <MenuItem key={3} value={3}>
                          {3}
                        </MenuItem> */}
                        </Select>
                      </FormControl>
                    }
                  </td>
                  {/* <td style={{ flex: 0.7 }}>{i.status}</td> */}
                  <td style={{ flex: 0.7 }}>{renderTableActions(i)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
