import { makeStyles } from '@material-ui/core/styles';
import { LoginIcons } from '@/assets';

export default makeStyles({
  bg: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    fontFamily: 'Averta',
    backgroundImage: `url(${LoginIcons.bg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
  },
  card: {
    display: 'flex',
    maxWidth: 400,
    minHeight: 250,
    width: 400,
    backgroundColor: '#fff',
    borderRadius: 15,
    margin: 'auto',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '25px 40px 25px 40px',
    boxSizing: 'border-box',
  },

  loading: {
    display: 'flex',
    maxWidth: 400,
    minHeight: 250,
    width: 400,
    backgroundColor: '#fff',
    borderRadius: 15,
    margin: 'auto',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '25px 40px 25px 40px',
    boxSizing: 'border-box',
  },

  inp: {
    backgroundColor: '#fff',
    borderRadius: 5,
    marginTop: 12,
    fontFamily: 'Averta',
    borderWidth: 5,
    boxShadow: '0px 3px 10px -2px rgba(0, 0, 0, 0.2)',
  },

  but: {
    color: '#fff',
    height: 50,
    marginTop: 25,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    '&:hover': {
      backgroundColor: '#ffd616',
    },
  },
  signIn: {
    fontFamily: 'Averta Light',
    color: '#44a570',
    fontSize: 16,
    margin: '10px 0 0 10px',
    cursor: 'pointer',
    textAlign: 'center',
    marginTop: 20,
  },
});
