import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    // minWidth: 260,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#303030',
    margin: '10px',
    width: '100%',
  },
  header: {
    height: 33,
    backgroundColor: '#f8b100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
    color: '#000',
    fontSize: 13,
  },

  content: {
    padding: '20px',
  },
  avText2: {
    fontFamily: 'Averta Light',
    fontSize: 13,
    fontWeight: 300,
  },
  flex: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  hr: {
    height: 1,
    backgroundColor: '#9f9f9f',
    borderRadius: 2,
    margin: '15px 0',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 10,
    fontFamily: 'Averta Light',
    lineHeight: 1.5,
    color: '#fff',
    fontWeight: 300,
    padding: '0 10px',
  },
  but: {
    height: 25,
    fontSize: 9,
    fontFamily: 'Averta Bold',
    lineHeight: 1.2,
    color: '#fff',
    fontWeight: 600,
    backgroundColor: '#f8b100',
    borderRadius: 5,
    textTransform: 'none',
    '& .MuiButton-endIcon': {
      marginLeft: 4,
      height: 15,
      width: 15,
      objectFit: 'contain',
    },
  },
  stat: {
    lineHeight: 1.8,
    fontSize: 10,
    fontFamily: 'Averta Bold',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 'bold',
    marginBottom: 5,
  },
  statText: {
    lineHeight: 1.5,
    fontSize: 10,
    fontFamily: 'Averta light',
    color: '#007fff',
    fontWeight: 500,
    marginBottom: 5,
  },
  but2: {
    backgroundColor: '#e4464b',
  },
  bigText: {
    fontSize: 30,
    fontFamily: 'Averta Bold',
    fontWeight: 800,
    lineHeight: 1,
    marginRight: 3,
  },
  launchIcon: {
    display: 'none',
  },
  text: {
    lineHeight: 1.5,
    fontSize: 10,
    fontFamily: 'Averta light',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 300,
  },
  margin: {
    marginBottom: 15,
  },
  input: {
    width: 50,
    height: 25,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    marginLeft: '5px',
    '& input': {
      padding: '0 5px 0 10px',
    },
  },
  light: {
    fontFamily: 'Averta light',
    fontWeight: 300,
  },
  bold: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
  },
  avatar: {
    height: 35,
    width: 35,
  },

  icon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    margin: '0 5px',
  },
  light2: {
    fontFamily: 'Averta Light',
    fontWeight: 300,
    color: '#fff',
    width: '60%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  row2: {
    display: 'flex',
    alignItems: 'center',
  },
}));
