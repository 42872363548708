import React from 'react';
import styles from './styles';
import { Grid, TextField, Button } from '@material-ui/core';

export default ({ content1, content2, setShowPreview }) => {
  const cls = styles();

  const formInputs = [
    {
      label: 'First Name',
      type: 'text',
    },
    {
      label: 'Last Name',
      type: 'text',
    },
    {
      label: 'Company',
      type: 'text',
    },
    {
      label: 'Email',
      type: 'email',
    },
    {
      label: 'Phone Number',
      type: 'text',
    },
    {
      label: 'Send Invite',
      type: 'submit',
    },
  ];

  return (
    <div className={cls.referContainer}>
      <Button onClick={() => setShowPreview(false)} className={cls.backBtn}>
        Back to Settings
      </Button>
      <div className={cls.referCard}>
        <div
          dangerouslySetInnerHTML={{
            __html: content1,
          }}
        />
        {/* <h2>Spread the word and earn rewards</h2>
        <p>
          When someone signs up with your unique referal link,both you and the
          refereed user will receive $100 after
        </p> */}
      </div>
      <Grid container>
        <Grid item xs={12} md={6}>
          <div>
            {formInputs.map((item, index) => {
              return item.type !== 'submit' ? (
                <div className={cls.referInputContainer}>
                  <span>{item.label}</span>
                  <TextField
                    type={item.type}
                    placeholder=""
                    className={cls.input}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ min: 1 }}
                    //   value={form.count}
                    //   onChange={(ev) => setForm('count', ev.target.value)}
                  />
                </div>
              ) : (
                <div className={cls.referInputContainer}>
                  <span />
                  <Button className={cls.referBtn}>{item.label}</Button>
                </div>
              );
            })}
          </div>
          <div>
            <div className={cls.referListBottomBorder}>
              <span className={cls.referListTitle}>Referrals</span>
            </div>
            <div
              className={`${cls.referListItem} ${cls.referListBottomBorder}`}>
              <div className={cls.referListItemTitle}>
                <span className={cls.referListBigText}>Zowie</span>
                <span className={cls.referListSmallText}>Pending</span>
              </div>
              <div className={cls.referListSmallText}>$100</div>
            </div>
            <div
              className={`${cls.referListItem} ${cls.referListBottomBorder}`}>
              <div className={cls.referListItemTitle}>
                <span className={cls.referListBigText}>Kai</span>
                <span className={cls.referListSmallText}>Pending</span>
              </div>
              <div className={cls.referListSmallText}>$100</div>
            </div>
            <div
              className={`${cls.referListItem} ${cls.referListBottomBorder}`}>
              <div className={cls.referListItemTitle}>
                <span className={cls.referListBigText}>Vitaly</span>
                <span className={cls.referListSmallText}>Pending</span>
              </div>
              <div className={cls.referListSmallText}>$100</div>
            </div>
            <div
              className={`${cls.referListItem} ${cls.referListBottomBorder}`}>
              <div className={cls.referListItemTitle}>
                <span className={cls.referListBigText}>John</span>
                <span className={cls.referListSmallText}>Completed</span>
              </div>
              <div className={cls.referListSmallText}>$100</div>
            </div>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className={cls.cardContainer}>
            <div className={cls.card1}>
              <span className={cls.referListTitle}>Referrals</span>
              <div
                className={`${cls.referListItem} ${cls.referInfoBottomBorder}`}>
                <div className={cls.referListItemTitle}>
                  <span className={cls.referListBigText}>Invite</span>
                </div>
                <div className={cls.referListSmallBoldText}>12</div>
              </div>
              <div
                className={`${cls.referListItem} ${cls.referInfoBottomBorder}`}>
                <div className={cls.referListItemTitle}>
                  <span className={cls.referListBigText}>
                    Potential rewards
                  </span>
                </div>
                <div className={cls.referListSmallBoldText}>$100</div>
              </div>
              <div
                className={`${cls.referListItem} ${cls.referInfoBottomBorder}`}>
                <div className={cls.referListItemTitle}>
                  <span className={cls.referListBigText}>You have earned</span>
                </div>
                <div className={cls.referListSmallBoldText}>$1200</div>
              </div>
              <Button className={cls.referBtn2}>View Rewards</Button>
            </div>
            <div className={cls.card2}>
              <div
                dangerouslySetInnerHTML={{
                  __html: content2,
                }}
              />
              {/* <span className={cls.referListTitle}>Your $100 reward</span>
              <p>
                When someone signs up with your unique referral link, both you
                and the refereed user will receive $100 after
              </p> */}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
