import React from 'react';
import styles from './styles';

export default ({ tablesData, setTablesData, pageContent }) => {
  const cls = styles();

  const handleChangeColumn = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setTablesData((prev) => {
      return {
        ...prev,
        columns: {
          ...prev.columns,
          [name]: value,
        },
      };
    });
  };

  return (
    <div>
      <h3 className={cls.header}>COLUMNS</h3>
      <div className={cls.listContainer}>
        <table className={cls.list}>
          <thead>
            <th style={{ flex: 1 }}>COLUMN NAME</th>
            <th style={{ flex: 0.7 }}>XL COLUMN NAME</th>
          </thead>
          <tbody>
            {tablesData.columns &&
              Object.keys(tablesData.columns_default).map((key, index) => (
                <tr>
                  <td style={{ flex: 1 }}>{key.toUpperCase()}</td>
                  <td style={{ flex: 0.7 }}>
                    {pageContent.value === 1 || pageContent.value === 2 ? (
                      <>{tablesData.columns[key]}</>
                    ) : (
                      <input
                        onChange={(e) => handleChangeColumn(e)}
                        type="text"
                        defaultValue={tablesData.columns[key]}
                        value={tablesData.columns[key]}
                        name={key}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
