import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  card: {
    position: 'relative',
    padding: '15px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 20,
  },
  enabledContainer: {
    color: '#43424b ',
    marginBottom: -20,
    '& span': {
      fontSize: 15,
      fontWeight: '600',
      fontFamily: 'Averta Bold',
      color: '#f8b100 !important',
    },
  },
  mondayCheck: {
    fontSize: '1rem',
    fontFamily:
      'Averta,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
    fontWeight: '400',
    lineHeight: '1.5',
  },
  disabledContainer: {
    color: '#d6d6d6',
    marginBottom: -20,
    '& span': {
      fontSize: 15,
      fontWeight: '600',
      fontFamily: 'Averta Bold',
    },
  },
  mailCheck: {
    display: 'flex',
    gap: '2',
    flexDirection: 'row',
  },
  checkbox: {
    '& svg': {
      fill: '#f8b100',
    },
    '& hover': {
      background: '#f8b1001a',
    },
  },
  select: {
    fontFamily: 'Averta Light',
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& > div:first-child > div > div': {
      padding: '8px 4px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    marginBottom: 10,
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    marginTop: '0.5rem',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      fontSize: 13,
    },
  },
  optionContainer: {
    marginTop: '20px',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& button': {
      color: '#ffffff',
      background: '#f8b100',
      '&:hover': { color: '#ffffff', background: '#f8b100' },
    },
  },
});
