import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: ({ height }) => (height ? height : 70),
    backgroundColor: '#f8b100',
    justifyContent: 'center',
    padding: '0 4vw',
    [BP.down('md')]: {
      padding: '0 2vw',
    },
  },
  logo: {
    marginTop: -7,
    width: ({ logoWidth }) => (logoWidth ? logoWidth : '175px'),
    [BP.down('md')]: {
      width: '100px !important',
    },
  },
  spacer: {
    flex: 1,
  },
  linkCont: {
    [BP.down('sm')]: {
      display: 'none',
    },
    marginLeft: 40,
    [BP.down('md')]: {
      marginLeft: 20,
    },
  },
  link: {
    fontFamily: 'Averta light',
    textDecoration: 'none',
    fontSize: 18,
    fontWeight: 300,
    lineHeight: 2.7,
    color: '#303030',
    transition: 'all .2s ease-in-out',
    marginLeft: 15,
    '&:hover': {
      // fontWeight: 700,
      // fontSize: 22,
      // lineHeight: 2.3,
      color: "gray",
    },
    [BP.down('md')]: {
      fontSize: 12,
      marginLeft: 5,
      '&:hover': {
        fontSize: 13,
      },
    },
  },
  but: {
    width: '8vw',
    height: 50,
    borderRadius: 10,
    backgroundColor: '#303030',
    fontFamily: 'Averta Bold',
    textDecoration: 'none',
    fontSize: 20,
    fontWeight: 'Bold',
    lineHeight: 1.2,
    color: '#f8b100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    boxSizing: 'border-box',
    marginLeft: 25,

    '&:hover': {
      backgroundColor: '#f8b100',
      color: '#303030',
      border: 'solid 2px #303030',
    },
    [BP.down('md')]: {
      fontSize: 10,
      width: '6vw',
      marginLeft: 5,
      height: 35,
      padding: '0 5px',
    },
  },
  headerItem: {
    [BP.down('sm')]: {
      display: 'none',
    },
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Averta Bold',
    textDecoration: 'none',
    fontSize: 15,
    fontWeight: 600,
    lineHeight: 1.67,
    color: '#43424b',
    marginRight: 15,

    '& > svg': {
      height: 30,
      width: 30,
      fill: '#43424b',
      marginRight: 10,
      [BP.down('md')]: {
        height: 20,
        width: 20,
        marginRight: 5,
      },
    },
    [BP.down('md')]: {
      fontSize: 12,
      marginRight: 5,
    },
  },
}));
