import React, { useEffect, useState } from 'react';
import { Drawer, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import { SaveData, SaveNotif } from '@/actions';
import { ChatBarIcons } from '@/assets';
import PopupCard from '../PopUpCard';
import ChatCard from '../ChatCard';
import styles from './styles';
import { usePermissions } from 'react-admin';
import useMediaQuery from 'use-mediaquery';

function useOutsideAlerter(ref, func) {
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default (props) => {
  const popUp = useSelector((state) => state.reducer.ChatItem || null);
  const Chat = useSelector((state) => state.reducer.Chat || null);
  const notif = useSelector((state) => state.reducer.notif || {});
  const [otherPart, setOtherPart] = useState(null);
  const cls = styles({ notif });
  const [showMobileMebuItems, setShowMobileMenuItems] = useState(false);
  const matches = useMediaQuery('(max-width: 1000px)');

  const openPopup = (item) => {
    SaveData({ ChatItem: item, Chat: null });
    if (notif[item]) SaveNotif({ [item]: null });
    if (notif.support) SaveNotif({ support: null });
  };

  const openSupport = () => {
    SaveData({ ChatItem: 'support', Chat: null });
    if (notif.support) SaveNotif({ support: null });
  };

  const { permissions } = usePermissions();
  //for close chat when click outside of box
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    SaveData({ ChatItem: null, Chat: null });
    setShowMobileMenuItems(false);
  });

  const renderDesktopChatBar = () => {
    return (
      <Drawer
        variant="permanent"
        className={cls.container}
        open
        id="chat_bar"
        PaperProps={{ className: cls.drawerPaper }}
        anchor="right">
        <span className={cls.title}>Chat</span>
        {permissions === 'user' && [
          <div
            className={clsx(cls.item, cls.request)}
            onClick={() => openPopup('request')}>
            <ReactSVG className={cls.icon} src={ChatBarIcons.chat} />
            <div className={clsx(cls.redDot, cls.requestDot)} />
            <span>Request</span>
          </div>,

          <div
            className={clsx(cls.item, cls.order)}
            onClick={() => openPopup('order')}>
            <ReactSVG className={cls.icon} src={ChatBarIcons.chat} />
            <div className={clsx(cls.redDot, cls.orderDot)} />
            <span>Offer</span>
          </div>,

          <div
            className={clsx(cls.item, cls.archive)}
            onClick={() => openPopup('archive')}>
            <ReactSVG src={ChatBarIcons.archive} className={cls.icon} />
            <div className={clsx(cls.redDot, cls.archiveDot)} />
            <span>Archive</span>
          </div>,

          <div className={clsx(cls.item, cls.support)} onClick={openSupport}>
            <ReactSVG src={ChatBarIcons.support} className={cls.icon} />
            <div className={clsx(cls.redDot, cls.supportDot)} />
            <span>Support</span>
          </div>,
        ]}
      </Drawer>
    );
  };

  const renderMobileChatBar = () => {
    return (
      <div id="chat_bar_mobile">
        {showMobileMebuItems && (
          <div className={cls.mobileChatBarItems}>
            {permissions === 'admin' && [
              <div
                className={clsx(cls.item, cls.request)}
                onClick={() => {
                  openPopup('adminNewChat');
                }}>
                <ReactSVG className={cls.icon} src={ChatBarIcons.chat} />
                <div className={clsx(cls.redDot, cls.supportDot)} />
                <span>New</span>
              </div>,
            ]}
            {permissions === 'user' && [
              <div
                className={clsx(cls.item, cls.request)}
                onClick={() => {
                  openPopup('request');
                }}>
                <ReactSVG className={cls.icon} src={ChatBarIcons.chat} />
                <div className={clsx(cls.redDot, cls.requestDot)} />
                <span>Request</span>
              </div>,

              <div
                className={clsx(cls.item, cls.order)}
                onClick={() => {
                  openPopup('order');
                }}>
                <ReactSVG className={cls.icon} src={ChatBarIcons.chat} />
                <div className={clsx(cls.redDot, cls.orderDot)} />
                <span>Offer</span>
              </div>,

              <div
                className={clsx(cls.item, cls.archive)}
                onClick={() => {
                  openPopup('archive');
                }}>
                <ReactSVG src={ChatBarIcons.archive} className={cls.icon} />
                <div className={clsx(cls.redDot, cls.archiveDot)} />
                <span>Archive</span>
              </div>,

              <div
                className={clsx(cls.item, cls.support)}
                onClick={() => {
                  openSupport();
                }}>
                <ReactSVG src={ChatBarIcons.support} className={cls.icon} />
                <div className={clsx(cls.redDot, cls.supportDot)} />
                <span>Support</span>
              </div>,
            ]}
          </div>
        )}
        <Button
          onClick={() => setShowMobileMenuItems(!showMobileMebuItems)}
          className={cls.mobileChatBtn}>
          Chat
        </Button>
      </div>
    );
  };

  return (
    <div ref={wrapperRef}>
      {(popUp || Chat) && (
        <div className={cls.card}>
          {popUp && <PopupCard setOtherPart={setOtherPart} />}
          {Chat && <ChatCard otherPart={otherPart} />}
        </div>
      )}
      {matches ? renderMobileChatBar() : renderDesktopChatBar()}
    </div>
  );
};
