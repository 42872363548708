import React, { forwardRef, useCallback } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { MenuItem, ListItemIcon, Tooltip } from '@material-ui/core';
import useStyles from './styles';

const NavLinkRef = forwardRef((props, ref) => (
  <NavLink innerRef={ref} {...props} />
));

export default forwardRef(
  (
    {
      classes: classesOverride,
      className,
      primaryText,
      leftIcon,
      onClick,
      sidebarIsOpen,
      ...props
    },
    ref
  ) => {
    const cls = useStyles();
    const handleMenuTap = useCallback((e) => onClick && onClick(e), [onClick]);

    const renderMenuItem = () => (
      <MenuItem
        className={clsx(sidebarIsOpen ? cls.open : cls.close, className)}
        activeClassName={cls.active}
        component={NavLinkRef}
        ref={ref}
        {...props}
        onClick={handleMenuTap}>
        {leftIcon && (
          <ListItemIcon className={cls.listItemIcon}>{leftIcon}</ListItemIcon>
        )}
        <span className="DashboardItemText">{primaryText}</span>
      </MenuItem>
    );

    if (sidebarIsOpen) return renderMenuItem();

    return (
      <Tooltip arrow title={primaryText} placement="right">
        {renderMenuItem()}
      </Tooltip>
    );
  }
);
