import React from 'react';
import { Tooltip } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  card: {
    position: 'relative',
    padding: '30px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxHeight: '75%',
    maxWidth: '80%',
    animation: 'zoomIn 0.7s',
  },

  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#e4464b',
  },
  img: {
    height: '-webkit-fill-available',
    objectFit: 'contain',
  },
});

export default ({ data, Close }) => {
  const cls = styles();

  return (
    <div className={cls.popup}>
      <div className={cls.card}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.close} onClick={Close} />
        </Tooltip>
        <img src={data} className={cls.img} alt="Chat" />
      </div>
    </div>
  );
};
