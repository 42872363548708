import { create } from 'apisauce';
const MapBoxToken = process.env.REACT_APP_MAPBOX_API_KEY;
const MapBox = 'https://api.mapbox.com/geocoding/v5/mapbox.places';
const MapBoxAPI = create({ baseURL: MapBox });

const SOCIAL_URL = 'api.smart-turn.com/redirect';

const API_URL = 'https://api.smart-turn.com/api/';

export { API_URL, SOCIAL_URL, MapBoxToken, MapBoxAPI };
