import React, { useEffect, useState } from 'react';
import styles from '../styles';
import {
  adminExportAvalableInventories,
  adminGetAvalableInventories,
  adminGetEmptyListForWhatsapp,
} from '@/actions';
import { Button, CircularProgress } from '@material-ui/core';
import { useNotify } from 'react-admin';
import moment from 'moment';

const AvalableInventories = () => {
  const [table, setTable] = useState({
    loading: true,
    DATA: [],
  });
  const [loadingList, setLoadingList] = useState(false);
  const cls = styles();
  const notify = useNotify();

  const sizeIdToLetter = {
    2: 'b',
    3: 'c',
    4: 'd',
    5: 'e',
    6: 'f',
    7: 'g',
    8: 'h',
    9: 'i',
    10: 'j',
    11: 'k',
    12: 'l',
    13: 'm',
  };

  const sslIdToLetter = {
    1: 'a',
    2: 'b',
    3: 'c',
    4: 'd',
    5: 'e',
    6: 'f',
    7: 'g',
    8: 'h',
    9: 'i',
    10: 'j',
    11: 'k',
    12: 'l',
    13: 'm',
    14: 'n',
    15: 'o',
    16: 'p',
    17: 'q',
    18: 'r',
    19: 's',
    20: 't',
    21: 'u',
  };
  const sizezzzz = 20;
  const getEmptyList = () => {
    setLoadingList(true);
    adminGetEmptyListForWhatsapp()
      .then((data) => {
        setLoadingList(false);
        let adaptedData = Object.keys(data).map((key) => {
          return `${data[key].company.alias} ${
            (data[key].specification.size + data[key].specification.name ==
              '45') |
            (data[key].specification.size + data[key].specification.name ==
              '20')
              ? data[key].specification.size +
                data[key].specification.name +
                'FT'
              : data[key].specification.size + data[key].specification.name
          } x${data[key].count} \n ${
            process.env.REACT_APP_PUBLIC_URL_Without_Https
          }/#/e/${sizeIdToLetter[data[key].specification.id]}${
            sslIdToLetter[data[key].company.id]
          } \n`;
        });
        adaptedData[0] = `*Available Empties for Street Turn* \n* ***${moment().format(
          'LL'
        )}*** * \n`;
        navigator.clipboard.writeText(adaptedData.join(' '));
        notify('copied!', 'success');
      })
      .catch((err) => {
        setLoadingList(false);
        notify(err, 'warning');
      });
  };

  const handleExportInventories = () => {
    adminExportAvalableInventories().then((data) => {
      const uint8Array = new Uint8Array(data);
      // Create a Blob object from the Uint8Array
      const blob = new Blob([data], {
        type: 'application/octet-stream',
      });

      // Generate a download link for the Blob
      const url = URL.createObjectURL(blob);

      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.download = 'excel_file.csv'; // Set the desired file name

      // Trigger a click event on the link element to start the download
      link.click();

      // Clean up the URL object
      URL.revokeObjectURL(url);
    });
  };

  useEffect(() => {
    setTable({
      loading: true,
      DATA: [],
    });
    adminGetAvalableInventories()
      .then((data) => {
        let tableData = Object.keys(data).map((key) => {
          return {
            company: data[key].company,
            C20: data[key].C20,
            C40: data[key].C40,
            C45: data[key].C45,
          };
        });
        setTable({
          loading: false,
          DATA: tableData,
        });
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  }, []);

  return (
    <>
      {table.loading ? (
        <div className={cls.loaderContainer}>
          <CircularProgress className={cls.loader} />
        </div>
      ) : (
        <>
          <div
            style={{ marginTop: '30px' }}
            className={cls.availableListContainer}>
            <table className={cls.avalableList}>
              <thead className={cls.availableThead}>
                <th style={{ flex: 1 }}>SSL</th>
                <th style={{ flex: 1 }}>20" Containers</th>
                <th style={{ flex: 1 }}>40" Containers</th>
                <th style={{ flex: 1 }}>45" Containers</th>
              </thead>
              <tbody className={cls.availableTbody}>
                {table.DATA.map((item, index) => (
                  <tr
                    key={index}
                    style={{ minHeight: '35px', margin: '3px 0' }}
                    className={cls.tableRow}>
                    <td style={{ flex: 1 }}>{item.company}</td>
                    <td style={{ flex: 1 }}>{item.C20}</td>
                    <td style={{ flex: 1 }}>{item.C40}</td>
                    <td style={{ flex: 1 }}>{item.C45}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              gap: '6px',
              paddingRight: '10px',
            }}>
            <a
              className={cls.linkBtn}
              target="_blank"
              download
              href={`${process.env.REACT_APP_API_URL}download-all-available-inventories/917a52bfc1786c174d943d9cb0d416646387a34b`}>
              Excel
            </a>
            <Button className={cls.linkBtn} onClick={() => getEmptyList()}>
              {loadingList ? (
                <CircularProgress size={20} color="#ffffff" />
              ) : (
                <>WhatsApp</>
              )}
            </Button>
          </div>
        </>
      )}
    </>
  );
};

export default AvalableInventories;
