import React from 'react';
import { Card } from '@material-ui/core';
import styles from './styles';

export default (props) => {
  const cls = styles();

  return (
    <Card className={cls.card}>
      <div className={cls.col}>
        <span className={cls.title}>Welcome to Your</span>
        <span className={cls.title}>Smart Turn Finance</span>
      </div>
    </Card>
  );
};
