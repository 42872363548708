import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    width: '100%',
    padding: '0 5rem',
    boxSizing: 'border-box',
    marginBottom: 50,
    [BP.down('lg')]: {
      padding: '0 1rem',
    },
    [BP.down('xs')]: {
      padding: '0 2rem',
    },
  },

  title: {
    fontSize: 58,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1,
    color: '#303030',
    textAlign: 'center',
    marginTop: 90,
    [BP.down('xs')]: {
      fontSize: '2.2rem',
      marginTop: 60,
    },
  },
  listCont: {
    margin: '50px 0',
  },
  list: {
    display: 'flex',
    flex: 1,
    height: '100%',
    [BP.down('sm')]: {
      flexDirection: 'column',
    },
    // [BP.down('xs')]: {
    //   minWidth: 660,
    //   padding: '0 5rem',
    // },
  },

  flex0: {
    flex: '0 !important',
  },
  circleContContainer: {
    position: 'relative',
    flex: '1',
  },
  circleCont: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    padding: 10,
    zIndex: '2',
    [BP.down('sm')]: {
      alignItems: 'flex-start',
    },
    // [BP.down('xs')]: {
    //   height: 55,
    // },
  },
  circle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 90,
    width: 90,
    borderRadius: '50%',
    border: 'solid 2px #303030',
    backgroundColor: '#fff',
    transition: 'all .5s ease-in-out',

    fontSize: 36,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 0.72,
    color: '#f8b100',
    '&:hover': {
      color: '#303030',
      backgroundColor: '#f8b100',
      fontWeight: 'bold',
    },
    [BP.down('lg')]: {
      height: 80,
      width: 80,
    },
    [BP.down('lg')]: {
      height: 70,
      width: 70,
    },
    [BP.down('xs')]: {
      height: 50,
      width: 50,
      fontSize: '1.2rem',
    },
  },
  hrContainer: {
    position: 'absolute',
    left: 'calc(-50% + 32px)',
    right: 'calc(50% + 20px)',
    top: '50px',
    flex: 1,
    [BP.down('sm')]: {
      display: 'none',
    },
  },
  hr: {
    height: 2,
    backgroundColor: '#303030',
    display: 'flex',

    margin: '0 -1.5px',
    [BP.down('xs')]: {
      margin: '0 -1px',
    },
  },
  desCont: {
    // position: 'absolute',
    // top: 120,
    // right: -140,
    // width: 380,
    boxSizing: 'border-box',
    wordBreak: 'break-word',
    // padding:10,
    [BP.down('sm')]: {
      paddingLeft: '35px',
      marginLeft: '35px',
      borderLeft: '2px solid',
      marginTop:'20px'
    },
    [BP.down('xs')]: {
      paddingLeft: '25px',
      marginLeft: '23px',
    },
  },
  des: {
    fontSize: 20,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.3,
    color: '#303030',
    textAlign: 'center',
    [BP.down('lg')]: {
      fontSize: 14,
    },
    [BP.down('sm')]: {
      textAlign: 'start',
    },
    [BP.down('xs')]: {
      fontSize: '0.9rem',
    },
  },
  desTitle: {
    fontSize: 36,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.5,
    color: '#303030',
    textAlign: 'center',
    marginBottom: 20,
    marginTop: 10,
    textTransform: 'uppercase',
    [BP.down('lg')]: {
      fontSize: '28px',
    },
    [BP.down('md')]: {
      fontSize: '25px',
    },
    [BP.down('sm')]: {
      textAlign: 'start',
    },
    [BP.down('xs')]: {
      fontSize: '1.2rem',
    },
  },
}));
