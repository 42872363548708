import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    position: 'relative',
    borderRadius: 20,
    boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: ({ black, gray }) =>
      black ? '#303030' : gray ? '#e1dfdf' : '#fff',
    padding: '10px',
    boxSizing: 'border-box',
    margin: '10px 0',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  row2: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 3,
  },
  f1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 10,
  },
  f2: {
    flex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginLeft: 5,
  },

  title: {
    fontSize: 10,
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    lineHeight: 1.3,
    color: ({ black }) => (black ? '#fff' : '#303030'),
  },
  light: {
    fontSize: 10,
    fontFamily: 'Averta light',
    fontWeight: 300,
    color: ({ black }) => (black ? '#fff' : '#43424b'),
    wordBreak: 'break-all',
    margin: '0 0 3px 3px',
  },

  avatar: {
    height: 50,
    width: 50,
  },
  icon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    margin: '0 5px 0 0',
  },
  circle: {
    display: 'block',
    width: 10,
    height: 10,
    background: 'red',
    borderRadius: '50%',
  },

  seen: {
    display: 'flex',
    fontSize: 10,
    fontFamily: 'Averta light',
    fontWeight: 300,
    color: ({ black }) => (black ? '#fff' : '#43424b'),
    justifyContent: 'flex-end',
  },

  more: {
    position: 'absolute',
    right: 10,
    top: 10,
    height: 25,
    width: 20,
    color: ({ black }) => (black ? '#fff' : '#303030'),
    cursor: 'pointer',
  },
}));
