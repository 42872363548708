import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  card1: {
    flex: 2,
    height: 210,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    padding: '35px 15px',
    margin: '0 10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: '#303030',
    whiteSpace: 'nowrap',
    '& h1': {
      fontSize: 30,
      color: '#f8b100',
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      lineHeight: 1.23,
      margin: 0,
    },
    '& h2': {
      fontSize: 20,
      color: '#f8b100',
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      lineHeight: 1.25,
      margin: 0,
    },
    '& h3': {
      fontSize: 16,
      color: '#fff',
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      lineHeight: 1.23,
      margin: 0,
    },
    ['@media (max-width:420px)']: {
      flexDirection: 'column',
      gap: 10,
      '& h1': {
        marginBottom: 10,
      },
      '& hr': {
        display: 'none',
      },
    },
  },

  card2: {
    flex: 1.5,
    minWidth: 300,
    height: 210,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 10,
    padding: '35px 15px',
    margin: '0 10px',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: '#007fff',
    whiteSpace: 'nowrap',
    '& h1': {
      fontSize: 30,
      color: '#303030',
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      lineHeight: 1.23,
      margin: 0,
    },
    '& h2': {
      fontSize: 20,
      color: '#303030',
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      lineHeight: 1.25,
      margin: 0,
    },
    '& h3': {
      fontSize: 16,
      color: '#303030',
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      lineHeight: 1.23,
      margin: 0,
    },
    ['@media (max-width:420px)']: {
      flexDirection: 'column',
      gap: 10,
      height:'auto',
      '& h1': {
        marginBottom: 10,
      },
      '& hr': {
        display: 'none',
      },
    },
  },

  col: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  col1: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
  },
  col2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    '& a': { textDecoration: 'none' },
    '& h3': {
      whiteSpace: 'break-spaces',
      textAlign: 'center',
      lineHeight: '30px',
    },
  },
  divider: {
    height: '100%',
    width: 2,
    margin: '0 10px',
    backgroundColor: '#dfdfdf',
    borderRadius: 5,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    '& > h1': {
      fontSize: 50,
      color: '#303030',
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      lineHeight: 1.23,
      margin: '0 10px 0 0',
    },
  },

  but: {
    height: 40.3,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Bold',
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.2,
    color: '#303030',
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fill: '#303030',
  },
}));
