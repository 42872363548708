import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  card: {
    // minWidth: 280,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    borderRadius: 5,
    flex: 1,
    padding: '15px 20px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'all ease 0.3s',
    [BP.up('xl')]: {
      padding: '30px 25px',
    },
    '&:hover': {
      transition: 'all ease 0.3s',
      boxShadow: '0px 0px 20px 0 rgb(0 0 0 / 31%)',
      scale: '1.02',
    },
  },
  hasContainersIcon: {
    float: 'right',
    position: 'absolute',
  },
  headerImg: {
    margin: '0 auto 15px',
    height: 75,
    '& > div': {
      display: 'flex',
      justifyContent: 'center',
      height: 75,
    },
    '& > div > svg': {
      width: '80%',
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 11,
    color: '#43424b',
    fontFamily: 'Averta Light',
    fontWeight: 300,
    [BP.up('xl')]: {
      fontSize: 14,
    },
  },
  bold: {
    fontFamily: 'Averta Bold',
    fontWeight: 600,
  },
  but: {
    margin: '15px auto 0',
    width: 90,
    fontSize: 12,
    fontFamily: 'Averta Light',
    color: '#fff',
    fontWeight: 300,
    backgroundColor: '#007fff',
    borderRadius: 5,
    textTransform: 'none',
    transition: 'all ease 0.3s',

    '&:hover': {
      backgroundColor: '#005ab5',
      transition: 'all ease 0.3s',
    },
    [BP.up('xl')]: {
      width: 100,
      height: 32,
      fontSize: 15,
    },
  },
  loader: {
    width: '20px !important',
    height: '20px !important',
    color: '#ffffff',
  },
  numInputContainer: {
    marginRight: '-2px',
  },
  numInput: {
    maxWidth: '32px',
    direction: 'rtl',
    border: '1px solid transparent',
    borderRadius: '4px',
    transition: 'all ease 0.3s',
    fontSize: '11px',
    fontFamily: 'Averta Bold',
    color: '#43424b',
    padding: '0 2px 0 0',
    '&:hover': {
      border: '1px solid black',
      transition: 'all ease 0.3s',
    },
  },
  chassisContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  progressIcon: {
    width: 15,
    height: 15,
    margin: '0 5px',
  },
}));
