import { Tooltip, Avatar, TextField, Button } from '@material-ui/core';
import React, { useState } from 'react';
import styles from '../styles';
import { CloseRounded } from '@material-ui/icons';
import { adminEditMember, SaveData } from '@/actions';
import { validateAdminAddMemberData } from '@/actions/validations';
import Form from '../Form';

export default ({ DATA, notify, update }) => {
  const cls = styles();
  const [userAvatar, setUserAvatar] = useState({
    image: DATA.image || '',
    url: DATA.image,
  });

  const Close = () => SaveData({ editMemberPopup: false });

  const [formData, setFormData] = useState({
    id: DATA.id,
    name: DATA.name,
    last_name: DATA.last_name,
    email: DATA.email,
    phone: DATA.phone,
    company: DATA.company,
    password: DATA.password,
    // status: DATA.status,
  });

  const handleChangeInput = (name, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const handleUpdateMember = () => {
    const error = validateAdminAddMemberData(formData);
    if (error) {
      notify(error, { type: 'warning' });
    } else {
      let data = formData;
      if (userAvatar.url && userAvatar.url.includes('https://')) {
        delete data.image;
      } else {
        data.image = userAvatar.image;
      }
      update(data)
    }
  };

  return (
    <div className={cls.popup}>
      <Form
        userAvatar={userAvatar}
        formData={formData}
        Close={Close}
        setUserAvatar={setUserAvatar}
        handleSendForm={handleUpdateMember}
        handleChangeInput={handleChangeInput}
        button="Update Member"
      />
    </div>
  );
};
