import React, { useEffect, useState } from 'react';
import styles from './styles';
import { CircularProgress } from '@material-ui/core';
import { adminGetLogs } from '@/actions';
import { useNotify } from 'react-admin';
import Pagination from '@/components/pagination';
import List from './List';

const Logs = () => {
  const [logs, setLogs] = React.useState({
    loading: false,
    DATA: [],
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pages: [],
  });

  const notify = useNotify();

  const cls = styles();

  const createArrayFromNumbers = (number) => {
    let numberArray = [];
    for (let i = 1; i <= number; i++) {
      numberArray.push(i);
    }

    return numberArray;
  };

  const getLogs = () => {
    setLogs((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    adminGetLogs({ page: pagination.currentPage })
      .then((res) => {
        setLogs((prev) => {
          return {
            loading: false,
            DATA: res.data,
          };
        });
        setPagination((prev) => {
          return {
            ...prev,
            pages: createArrayFromNumbers(Math.ceil(res.total / res.per_page)),
          };
        });
      })
      .catch((err) => {
        notify(err, 'warning');
        setLogs((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
  };

  useEffect(() => {
    getLogs();
  }, []);

  useEffect(() => {
    getLogs();
  }, [notify, pagination.currentPage]);

  return (
    <div className={cls.content}>
      <h2 style={{ padding: '0 10px' }} className={cls.title}>
        Logs
      </h2>
      {logs.loading ? (
        <div className={cls.loading}>
          <CircularProgress className={cls.loader} />
        </div>
      ) : (
        <div style={{ overflow: 'auto', width: '100%' }}>
          <List getLogs={getLogs} DATA={logs.DATA} notify={notify} />
        </div>
      )}
      <Pagination pagination={pagination} setPagination={setPagination} />
      {/* <div className={cls.pagination}>
        <Button
          disabled={pagination.currentPage === 1}
          onClick={() =>
            setPagination((prev) => {
              return {
                ...prev,
                currentPage: prev.currentPage - 1,
              };
            })
          }>
          <ArrowBackIos />
          <span>Prev</span>
        </Button>
        {pagination.pages.map((page, index) => {
          return (
            (page === pagination.pages[pagination.pages.length - 1] ||
              page === pagination.currentPage ||
              page === pagination.currentPage - 1 ||
              page === pagination.currentPage + 1 ||
              page === pagination.currentPage - 2 ||
              page === pagination.currentPage + 2 ||
              page === 1) && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {page === pagination.pages[pagination.pages.length - 1] &&
                  pagination.currentPage <=
                    pagination.pages[pagination.pages.length - 1] - 4 && (
                    <span>...</span>
                  )}
                <span
                  className={pagination.currentPage === page && cls.activePage}
                  onClick={() =>
                    setPagination((prev) => {
                      return {
                        ...prev,
                        currentPage: page,
                      };
                    })
                  }>
                  {page}
                </span>
                {page === 1 && pagination.currentPage >= 5 && <span>...</span>}
              </div>
            )
          );
        })}
        <Button
          disabled={
            pagination.currentPage ===
            pagination.pages[pagination.pages.length - 1]
          }
          onClick={() =>
            setPagination((prev) => {
              return {
                ...prev,
                currentPage: prev.currentPage + 1,
              };
            })
          }>
          <span>Next</span>
          <ArrowForwardIos />
        </Button>
      </div> */}
    </div>
  );
};

export default Logs;
