import React from 'react';
import styles from './styles';
import { Button } from '@material-ui/core';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

const Pagination = ({ pagination, setPagination }) => {
  const cls = styles();
  return (
    pagination.pages.length > 1 && (
      <div className={cls.pagination}>
        <Button
          disabled={pagination.currentPage === 1}
          onClick={() =>
            setPagination((prev) => {
              return {
                ...prev,
                currentPage: prev.currentPage - 1,
              };
            })
          }>
          <ArrowBackIos />
          <span>Prev</span>
        </Button>
        {pagination.pages.map((page, index) => {
          return (
            (page === pagination.pages[pagination.pages.length - 1] ||
              page === pagination.currentPage ||
              page === pagination.currentPage - 1 ||
              page === pagination.currentPage + 1 ||
              page === pagination.currentPage - 2 ||
              page === pagination.currentPage + 2 ||
              page === 1) && (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                {page === pagination.pages[pagination.pages.length - 1] &&
                  pagination.currentPage <=
                    pagination.pages[pagination.pages.length - 1] - 4 && (
                    <span>...</span>
                  )}
                <span
                  className={pagination.currentPage === page && cls.activePage}
                  onClick={() =>
                    setPagination((prev) => {
                      return {
                        ...prev,
                        currentPage: page,
                      };
                    })
                  }>
                  {page}
                </span>
                {page === 1 && pagination.currentPage >= 5 && <span>...</span>}
              </div>
            )
          );
        })}
        <Button
          disabled={
            pagination.currentPage ===
            pagination.pages[pagination.pages.length - 1]
          }
          onClick={() =>
            setPagination((prev) => {
              return {
                ...prev,
                currentPage: prev.currentPage + 1,
              };
            })
          }>
          <span>Next</span>
          <ArrowForwardIos />
        </Button>
      </div>
    )
  );
};

export default Pagination;
