import React, { useEffect } from 'react';
import { Card, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { styles, ChartOptions } from './styles';

export default ({ users }) => {
  const cls = styles();
  const [state, setState] = React.useState({
    labels: [],
    activeDATA: [],
    deactivatedDATA: [],
    totalDATA: [],
    loading: true,
  });

  useEffect(() => {
    let labels = [];
    Object.keys(users).forEach((item) => {
      if (item.label) {
        labels.push(...item.label);
      }
    });

    let chartObj = {
      totalDATA: users.total.data,
      activeDATA: users.active.data,
      deactivatedDATA: users.deactivated.data,
    };

    setState({
      ...chartObj,
      labels,
      loading: false,
    });
  }, [users]);

  // const ChartData = {
  //   labels: state.labels,
  //   datasets: [
  //     {
  //       label: reportData.Rejected ? 'Rejected' : 'Orders',
  //       barThickness: 30,
  //       backgroundColor: '#D9D9D9',
  //       // borderWidth: 2,
  //       // borderColor: '#f9bb05',
  //       // hoverBackgroundColor: '#49494955',
  //       data: reportData.Rejected ? state.rejDATA : state.ordDATA,
  //     },
  //     {
  //       label: 'Requests',
  //       barThickness: 40,
  //       backgroundColor: '#F8B100',
  //       data: state.reqDATA,
  //     },
  //     {
  //       label: 'Completed',
  //       barThickness: 20,
  //       backgroundColor: '#2BA672',
  //       data: state.comDATA,
  //     },
  //   ],
  // };

  const ChartData = {
    labels: state.labels,
    datasets: [
      {
        label: 'Deactivated Users',
        barThickness: 30,
        backgroundColor: '#FF0000',
        // borderWidth: 2,
        // borderColor: '#f9bb05',
        // hoverBackgroundColor: '#49494955',
        data: state.activeDATA,
      },
      {
        label: 'Total Users',
        barThickness: 40,
        backgroundColor: '#F8B100',
        data: state.totalDATA,
      },
      {
        label: 'Active Users',
        barThickness: 20,
        backgroundColor: '#2BA672',
        data: state.deactivatedDATA,
      },
    ],
  };

  // if (state.loading)
  //   return (
  //     <Card className={clsx(cls.card, cls.center)}>
  //       <CircularProgress />
  //     </Card>
  //   );

  return (
    <Card className={cls.card}>
      <div className={clsx(cls.row)}>
        <span className={cls.title}>Users Report</span>
        {/* <Link to="/report" className={cls.see}>
          ...
        </Link> */}
      </div>

      <div className={cls.chartCont}>
        <Bar data={ChartData} options={ChartOptions} />
      </div>
    </Card>
  );
};
