import React from 'react';
import { Card } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import clsx from 'clsx';
import { DashboardIcons as Icons } from '@/assets';
import styles from './styles';

const calc = ({ lastMonth = 0, thisMonth = 0 }) => {
  let c = (thisMonth - lastMonth) / (thisMonth + lastMonth);
  return {
    percent: isNaN(c) ? 0 : Math.abs(c * 100).toFixed(0),
    neg: lastMonth > thisMonth,
  };
};

export default ({ title, color, white, data = {} }) => {
  const { percent, neg } = calc(data);
  const cls = styles({ white, neg });

  let COLORS = {
    yellow: cls.yellow,
    green: cls.green,
    red: cls.red,
    black: cls.black,
  };

  return (
    <Card className={clsx(cls.card, COLORS[color])}>
      <div className={cls.content}>
        <div className={cls.col}>
          <span className={cls.title}>{title}</span>
          <span className={cls.des}>Compare to Last Month</span>
        </div>
        <div className={cls.row}>
          <div className={cls.row2}>
            <span className={cls.circle} />
            <div className={cls.hr} />
          </div>
          <div className={cls.flex}>
            <span className={cls.percent}>{percent}%</span>
            <ReactSVG
              src={white ? Icons.arrowWhite : Icons.arrowBlack}
              className={cls.icon}
            />
          </div>
        </div>
      </div>
    </Card>
  );
};
