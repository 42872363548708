import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: 'gray',
    width: 15,
  },
  card: {
    position: 'relative',
    padding: '10px 0',
    boxSizing: 'border-box',
    width: 380,
    height: 180,
    boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    // position: 'absolute',
    // right: '9vw',
    zIndex: 10,
    // top: 110,
    '@media (max-width:500px)': {
      width: '100%',
    },
    '& p': {
      margin: '35px 0',
    },
  },
  actionContainer: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  yesBtn: {
    color: '#ffffff',
    padding: '10px 15px',
    background: '#007FFF',
    border: 'none',
    borderRadius: '5px',
    width: 80,
    '&:hover': {
      color: '#ffffff',
      background: '#007FFF',
    },
  },
  noBtn: {
    color: '#ffffff',
    padding: '10px 15px',
    background: '#FF0000',
    marginRight: 10,
    border: 'none',
    borderRadius: '5px',
    width: 80,
    '&:hover': {
      color: '#ffffff',
      background: '#FF0000',
    },
  },
}));
