import React from 'react';
import { Button, Tooltip, Slider } from '@material-ui/core';
import AvatarEditor from 'react-avatar-editor';
import { useNotify } from 'react-admin';
import clsx from 'clsx';
import { CloseRounded } from '@material-ui/icons';
import {} from './';

import styles from './styles';

export default ({ Close, Upload }) => {
  const cls = styles();
  const editorRef = React.useRef();
  const fileInputRef = React.useRef();
  const notify = useNotify();

  const [state, setState] = React.useState({
    image: null,
    scale: 1.2,
    rotate: 0,
  });

  React.useEffect(() => {
    fileInputRef.current.click();
  }, []);

  const submit = () => {
    if (state.image)
      editorRef.current
        .getImageScaledToCanvas()
        .toBlob((blob) => Upload(blob), 'image/jpeg');
    else notify('Please Select Image !!', 'warning');
  };

  return (
    <div className={cls.popup}>
      <div className={cls.card}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.close} onClick={Close} />
        </Tooltip>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={(e) => setState({ ...state, image: e.target.files[0] })}
        />
        <div className={cls.row}>
          <AvatarEditor
            ref={editorRef}
            image={state.image}
            width={360}
            height={360}
            border={20}
            borderRadius={180}
            scale={state.scale}
            rotate={state.rotate}
            crossOrigin="anonymous"
          />
          <div className={cls.ml}>
            <span className={cls.mb}>Scale:</span>
            <Slider
              value={state.scale}
              onChange={(e, scale) => setState({ ...state, scale })}
              min={1}
              max={2}
              step={0.01}
            />
            <span className={cls.mb}>Rotate:</span>
            <div className={cls.row}>
              <button
                onClick={() =>
                  setState({ ...state, rotate: state.rotate - 90 })
                }>
                Left
              </button>
              <button
                onClick={() =>
                  setState({ ...state, rotate: state.rotate + 90 })
                }>
                Right
              </button>
            </div>
          </div>
        </div>
        <div className={cls.butCont}>
          <Button className={cls.but} onClick={submit}>
            Upload
          </Button>
          <Button
            className={clsx(cls.but, cls.cancel)}
            onClick={() => fileInputRef.current.click()}>
            Select Image
          </Button>
        </div>
      </div>
    </div>
  );
};
