import React, { useEffect, useState } from 'react';
import Add from './Add';
import List from './List';
import { useNotify, Loading } from 'react-admin';
import {
  adminExportInventory,
  adminImportInventory,
  getAdminFinanceTrackingCompanies,
  getAdminInventory,
} from '@/actions';
import { ParseInventoryList } from '@/actions/ParseData';
import styles from '../styles';
import { Filter } from '@/components/Global';
import XlPopup from './XlPopup';
import {
  Button,
  CircularProgress,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import { FormatListBulleted } from '@material-ui/icons';
import { fDate } from '@/actions/Helper';
import Pagination from '@/components/pagination';
import AvalableInventories from './AvalableInventories';

export default () => {
  const notify = useNotify();
  const [state, setState] = React.useState({
    edit: null,
    file: null,
  });
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    pages: [1],
  });
  const [form, setForm] = useState(null);
  const [importingFile, setImportingFile] = useState(false);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [popUp, setPopup] = React.useState(null);
  const [DATA, setData] = React.useState([]);
  const [excelFile, setExcelFile] = useState(null);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });

  const cls = styles();

  const createArrayFromNumbers = (number) => {
    let numberArray = [];
    for (let i = 1; i <= number; i++) {
      numberArray.push(i);
    }

    return numberArray;
  };

  const getInventories = () => {
    setLoading(true);
    getAdminInventory(
      form
        ? { ...form, page: pagination.currentPage }
        : { page: pagination.currentPage }
    )
      .then((res) => {
        setPagination((prev) => {
          return {
            ...prev,
            pages: createArrayFromNumbers(res.last_page),
          };
        });
        let DATA = ParseInventoryList(res.data);
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const getCompanies = () => {
    getAdminFinanceTrackingCompanies()
      .then((res) => {
        let DATA = res.map((company) => {
          return {
            label: company.company,
            value: company.id,
          };
        });
        setUsers(DATA);
      })
      .catch((err) => {
        notify('err', 'warning');
      });
  };

  const filterWithApi = (filterData) => {
    let form = {};
    if (filterData.company) {
      form.user_company = filterData.company.value;
    }
    if (filterData.date) {
      form.available_at = fDate(filterData.date);
    }
    if (filterData.loc) {
      form.address = filterData.loc.label;
    }
    if (filterData.num) {
      form.count = filterData.num;
    }
    if (filterData.size) {
      form.cabin = filterData.size.value;
    }
    if (filterData.ssl) {
      form.ssl = filterData.ssl.value;
    }

    form.page = pagination.currentPage;
    setForm(form);
  };

  const handleExportInventory = () => {
    adminExportInventory(selectedCompany.value)
      .then((res) => {
        window.open(res.data.link, '_blank');
        setSelectedCompany(null);
        setPopup(null);
      })
      .catch((err) => {
        notify(err, 'warning');
        setPopup(null);
      });
  };

  const handleImportInventory = (file) => {
    setImportingFile(true);
    adminImportInventory(selectedCompany.value, file)
      .then((data) => {
        getInventories();
        setSelectedCompany(null);
        setPopup(null);
        setImportingFile(false);
        notify('Successfully Updated', 'success');
      })
      .catch((err) => {
        notify(err, 'warning');
        setPopup(null);
        setImportingFile(false);
      });
  };

  useEffect(() => {
    getCompanies();
  }, []);

  React.useEffect(() => {
    getInventories();
  }, [notify]);

  React.useEffect(() => {
    setLoading(true);
    getInventories();
  }, [notify, pagination.currentPage, form]);

  return (
    <div className={cls.mainContainer}>
      {popUp && (
        <XlPopup
          selectedCompany={selectedCompany}
          setSelectedCompany={setSelectedCompany}
          companies={users}
          showCompanies={popUp !== 'avalable' ? true : false}
          Close={() => setPopup(null)}>
          {popUp === 'import' && (
            <>
              <label className={cls.import}>
                <input
                  type="file"
                  disabled={!selectedCompany ? true : false}
                  style={{ display: 'none' }}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={(e) => {
                    setExcelFile(e.target.files[0]);
                    e.target.value = null;
                  }}
                />
                <span className={cls.xlSpan}>Select File</span>
              </label>
              {excelFile && (
                <span className={cls.fileName}>{excelFile.name}</span>
              )}
              <Button
                disabled={!selectedCompany ? true : false}
                onClick={() => {
                  if (excelFile) {
                    handleImportInventory(excelFile);
                  }
                }}
                className={cls.xlBtn}>
                {importingFile ? (
                  <CircularProgress className={cls.fileLoader} />
                ) : (
                  <span>Add</span>
                )}
              </Button>
            </>
          )}
          {popUp === 'export' && (
            <Button
              disabled={!selectedCompany ? true : false}
              onClick={() => handleExportInventory()}
              className={cls.xlBtn}>
              Export
            </Button>
          )}
          {popUp === 'avalable' && (
            <AvalableInventories Close={() => setPopup(null)} />
          )}
        </XlPopup>
      )}

      <Add
        title="Add Inventory"
        users={users}
        setPopup={setPopup}
        getInventories={getInventories}
      />
      <Filter
        title="All Inventories"
        companies={users}
        filter={filter}
        setFilter={setFilter}
        filterWithApi={filterWithApi}
        withDateFilter={true}
        DATA={DATA}>
        <div onClick={() => setPopup('export')} className={cls.filter}>
          <span className={cls.export}>Export</span>
          <span className={cls.xlsx}>XLSX</span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className={cls.popupTableTitle}>Available Inventories</span>
          <IconButton onClick={() => setPopup('avalable')}>
            <FormatListBulleted />
          </IconButton>
        </div>
      </Filter>
      {loading ? (
        <Loading />
      ) : (
        <List
          getInventories={getInventories}
          title="All Inventories"
          DATA={filter.DATA}
          currentPage={pagination.currentPage}
        />
      )}
      <Pagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
};
