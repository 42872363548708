import React from 'react';
import clsx from 'clsx';
import styles from './styles';

export default (props) => {
  const cls = styles();
  return (
    <div className={cls.container}>
      <div className={cls.title}>FEATURES</div>
      <div className={cls.des}>MAKE A DIFFERENCE BY USING OUR SERVICES</div>
      <div className={cls.row}>
        <div className={cls.col}>
          <div className={cls.item}>
            <div className={cls.itemTitle}>SAVE MONEY</div>
            <div className={cls.itemDes}>
              Avoid unnecessary trips to the ports and save money on empty
              returns, yard pulls and port congestions
            </div>
          </div>
          <div className={clsx(cls.item, cls.white)}>
            <div className={cls.itemTitle}>OVERCOME CHASSIS SHORTAGE</div>
            <div className={cls.itemDes}>
              Take over an empty sitting on a chassis and worry less about the
              extreme chassis shortage
            </div>
          </div>
        </div>
        <div className={cls.col1}>
          <div className={clsx(cls.item, cls.white)}>
            <div className={cls.itemTitle}>SAVE TIME</div>
            <div className={cls.itemDes}>
              Avoid the congested terminals and keep your drivers on the road
            </div>
          </div>
          <div className={cls.item}>
            <div className={cls.itemTitle}>SAVE THE PLANET</div>
            <div className={cls.itemDes}>
              Lets help our environment by saving the many avoidable trips to
              the terminals
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
