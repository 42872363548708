import { create } from 'apisauce';

// TODO

export const BASE_URL = process.env.REACT_APP_API_URL;

export const FTP_URL = process.env.REACT_APP_FTP_URL;

// define the api
export const API = create({
  baseURL: BASE_URL,
  headers: {
    accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// add Authorization token to header
API.addRequestTransform((req) => {
  req.headers.Authorization = `Bearer ${localStorage.token || ''}`;
});

// if status === 401 redirect to homepage
API.addResponseTransform((res) => {
  if (!res.ok) console.error('err => ', res);

  if (res.status === 401 && window.location.hash !== '') {
    localStorage.removeItem('token');
    window.location.hash = '';
  }
});
