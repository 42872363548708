import React, { useState } from 'react';
import styles from './styles';
import { fTimeDate } from '@/actions/Helper';
import { Tooltip, IconButton } from '@material-ui/core';
import { DeleteRounded, CreateRounded, Visibility } from '@material-ui/icons';
import { adminDeleteReferralProgram } from '@/actions';
import Preview from '../Preview';

export default ({
  DATA = [],
  notify,
  getReferralPrograms,
  setEditPopup,
  showPreview,
  setShowPreview,
  setEditingContent1,
  setEditingContent2,
}) => {
  const cls = styles();

  const handleDeleteReferral = (id) => {
    adminDeleteReferralProgram(id)
      .then((res) => {
        getReferralPrograms();
        notify('successfully deleted');
      })
      .catch((err) => {
        notify(err);
      });
  };

  return (
    <div>
      {console.log('preee', showPreview)}
      {/* {showPreview && (
        <Preview
          content1={showPreview.content1}
          content2={showPreview.content2}
        />
      )} */}
      <h2 style={{ padding: '0 10px' }} className={cls.title}>
        Promotions
      </h2>
      <div className={cls.tableContainer}>
        <table className={cls.list}>
          <thead className={cls.thead}>
            <th style={{ flex: 1, textAlign: 'left' }}>
              <span style={{ paddingLeft: '45px' }}>Name</span>
            </th>
            <th style={{ flex: 1 }}>Reward</th>
            <th style={{ flex: 1 }}>Start Date</th>
            <th style={{ flex: 1 }}>End Date</th>
            <th style={{ flex: 1 }}>Actions</th>
          </thead>
          <tbody className={cls.tbody}>
            {DATA.map((i, idx) => {
              return (
                <tr className={cls.tableRow} key={idx}>
                  <td
                    style={{ flex: 1 }}
                    className={`${cls.bigText} ${cls.userName}`}>
                    <div style={{ marginLeft: 10 }}>{i.id}</div>
                    <span style={{ cursor: 'pointer' }}>{i.name}</span>
                  </td>
                  <td style={{ flex: 1 }}>{`$  ${i.price}`}</td>
                  <td style={{ flex: 1 }}>
                    {i.start_date ? i.start_date_string : ''}
                  </td>
                  <td style={{ flex: 1 }}>
                    {i.end_date ? i.end_date_string : ''}
                  </td>
                  <td style={{ flex: 1 }}>
                    <div className={cls.tableActionsContainer}>
                      <Tooltip arrow title="Edit" placement="top">
                        <IconButton
                          style={i.id === 1 ? { marginRight: '3rem' } : {}}
                          onClick={() => setEditPopup(i)}>
                          <CreateRounded className={cls.editIcon} />
                        </IconButton>
                      </Tooltip>
                      {i.id !== 1 && (
                        <Tooltip arrow title="Delete" placement="top">
                          <IconButton
                            onClick={() => handleDeleteReferral(i.id)}>
                            <DeleteRounded className={cls.delIcon} />
                          </IconButton>
                        </Tooltip>
                      )}
                      <Tooltip arrow title="previwe" placement="top">
                        <IconButton
                          onClick={() => {
                            setShowPreview('edit');
                            setEditingContent1(i.content1);
                            setEditingContent2(i.content2);
                          }}>
                          <Visibility className={cls.previweIcon} />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
