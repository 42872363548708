import React from 'react';
import {
  Card,
  CardContent,
  Button,
  Avatar,
  CircularProgress,
} from '@material-ui/core';
import { LaunchRounded, HomeRounded, AccountCircle } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { fDate } from '@/actions/Helper';

import styles from './styles';

export default ({ DATA = {}, Stop, loadingCards }) => {
  let progress = DATA.acc / DATA.num;
  const cls = styles({ progress });

  return (
    <Card className={cls.card}>
      <div className={cls.header}>
        <span>ID: {DATA.id}</span>
        {/* <span>Search Type: {DATA.type}</span> */}
        <span> {DATA.mode.charAt(0).toUpperCase() + DATA.mode.slice(1)}</span>
      </div>
      <CardContent className={cls.content}>
        <div className={clsx(cls.row, cls.bigRow)}>
          <span>Your requests</span>
          <span className={cls.bigText}>{DATA.num}</span>
        </div>
        <div className={cls.row}>
          <span className={cls.light}>Date:</span>
          <span>{fDate(DATA.date)}</span>
        </div>
        <div className={cls.row}>
          <span className={cls.light}>Location:</span>
          <span>{DATA.loc}</span>
        </div>
        <div className={cls.row}>
          <span className={cls.light}>Size:</span>
          <span>{DATA.size}</span>
        </div>

        <div className={cls.row}>
          <span className={cls.light}>SSL:</span>
          <span>{DATA.ssl}</span>
        </div>
        <div className={cls.hr} />

        <div className={clsx(cls.row, cls.stat)}>
          <span>Status</span>
          <span>{DATA.stat}</span>
        </div>

        <div className={cls.hr} />

        {DATA.type === 'manual' &&
          (DATA.stat === 'Open' ? (
            <div className={cls.acc2}>
              <span>Accepted Number:</span>
              <span>waiting</span>
            </div>
          ) : (
            <>
              <div className={clsx(cls.row, cls.acc)}>
                <span>Accepted Number:</span>
                <span>{DATA.acc}</span>
              </div>
              <div className={cls.avatarCont}>
                <Avatar
                  className={cls.avatar}
                  src={DATA.avatar}
                  alt={DATA.user}
                />
                <div className={cls.row2}>
                  <div className={cls.avatarRow}>
                    <AccountCircle />
                    <span>{DATA.user}</span>
                  </div>
                  <div className={cls.avatarRow}>
                    <HomeRounded />
                    <span>{DATA.company}</span>
                  </div>
                </div>
              </div>
            </>
          ))}
        {DATA.type === 'smart' && (
          <>
            <div className={clsx(cls.row, cls.blue)}>
              <span>In progress:</span>
              <span>{DATA.in}</span>
            </div>

            <div className={cls.hr} />

            <div className={clsx(cls.row, cls.green)}>
              <span>Completed:</span>
              <span>{DATA.acc}</span>
            </div>
          </>
        )}

        <div className={cls.progressWrapper}>
          <span>0</span>
          <div className={cls.progressBar}>
            <div className={cls.progress} />
          </div>
          <span>{DATA.num}</span>
        </div>

        <div className={cls.butCont}>
          <Link
            to={`/request-detail?id=${DATA.id}&type=${DATA.type}`}
            className={cls.link}>
            <Button
              className={cls.but}
              endIcon={<LaunchRounded className={cls.launchIcon} />}>
              Manage Request
            </Button>
          </Link>
          <Button
            className={cls.but2}
            onClick={() => Stop(DATA.id, DATA.type, DATA.mode)}>
            {loadingCards.indexOf(DATA.id) !== -1 ? (
              <CircularProgress className={cls.loader} />
            ) : DATA.type === 'manual' ? (
              'Reject'
            ) : (
              'Stop'
            )}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
