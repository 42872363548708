import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Icons } from '@/assets';
import { ReactSVG } from 'react-svg';

const styles = makeStyles({
  load: {
    display: ({ disable }) => (disable ? 'none' : 'flex'),
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: 135,
    height: 135,
    backgroundColor: '#dfdfdf',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'all .2s ease-in-out 0s',
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    '&:hover': {
      boxShadow: '0px 0px 25px -3px rgba(0, 0, 0, 0.7)',
    },
    margin: ' 0 auto',
    marginTop: 50,
  },
  truck: {
    width: 38.9,
    height: 37,
    objectFit: 'contain',
    fill: '#303030',
  },
  title: {
    fontSize: 15,
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    color: '#303030',
    lineHeight: 2.5,
    cursor: 'default',
  },
});

export default ({ onLoad, disable = false }) => {
  const cls = styles({ disable });
  return (
    <div className={cls.load} onClick={onLoad}>
      <ReactSVG src={Icons.truck} className={cls.truck} />
      <span className={cls.title}>Load More</span>
    </div>
  );
};
