import { makeStyles } from '@material-ui/core/styles';
import { LoginIcons } from '@/assets';

export default makeStyles({
  bg: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    fontFamily: 'Averta',
    backgroundImage: `url(${LoginIcons.bg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
  },
  card: {
    display: 'flex',
    maxWidth: 800,
    width: 800,
    backgroundColor: '#fff',
    borderRadius: 15,
    margin: '30px 0',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '25px 40px 25px 40px',
    boxSizing: 'border-box',
    ['@media (max-width:850px)']: {
      width: '400px',
    },
    ['@media (max-width:420px)']: {
      width: '100%',
    },
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    position: 'relative',
    marginBottom: '25px',
  },
  input: {
    margin: '12px 0',
    height: 40,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    borderWidth: 0,
    outline: 'none',
    fontWeight: 300,
    borderRadius: 5,
    backgroundColor: '#fff',

    '& input': {
      padding: '7px 12px',
      color: '#43424b',
      fontSize: 12,
      fontFamily: 'Averta Light',
      height: 26,
    },
    '& p': {
      margin: '3px 0 0 5px',
      fontSize: 10,
      fontFamily: 'Averta Light',
    },
    '& fieldset': {
      borderColor: '#fff',
    },
  },
  but: {
    display: 'flex',
    flex: 1,
    backgroundColor: 'transparent',
    fontSize: 18,
    height: 35,
    padding: 0,
    fontFamily: 'Averta',
    '& active': {
      color: '#f8b100',
    },
  },
  loginBut: {
    color: '#fff',
    height: 50,
    marginTop: 25,
    borderRadius: 5,
    fontFamily: 'Averta',
    backgroundColor: ({ loginMode }) => (loginMode ? '#f8b100' : '#44a570'),
    '&:hover': {
      backgroundColor: ({ loginMode }) => (loginMode ? '#ffd616' : '#5aca8d'),
    },
  },
  terms: {
    color: '#44a570',
    marginRight: 0,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    '& > span': {
      fontSize: 10,
      fontFamily: 'Averta Light',
      // padding: 0,
    },
  },
  forget: {
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    color: '#44a570',
    fontSize: 10,
    margin: '20px 0 0 10px',
    cursor: 'pointer',
  },
  remember: {
    color: '#44a570',
    marginRight: 0,
    fontFamily: 'Averta Light',
    fontWeight: 500,
    '& > span': {
      fontSize: 12,
      fontFamily: 'Averta Light',
      // padding: 0,
    },
  },
  hr: {
    height: 2,
    borderRadius: 2,
    backgroundColor: '#dfdfdf',
    marginBottom: 20,
    width: '100%',
  },
  opLine: {
    position: 'absolute',
    bottom: 0,
    height: 4,
    width: 100,
    borderRadius: 5,
    backgroundColor: ({ loginMode }) => (loginMode ? '#f8b100' : '#44a570'),
    right: ({ loginMode }) => (loginMode ? 190 : 130),
    transition: 'all .2s ease 0s',
    ['@media (max-width:850px)']: {
      right: ({ loginMode }) => (loginMode ? 135 : '10%'),
    },
    ['@media (max-width:370px)']: {
      right: ({ loginMode }) => (loginMode ? 135 : '7%'),
    },
    ['@media (max-width:350px)']: {
      right: ({ loginMode }) => (loginMode ? 135 : '0'),
    },
  },
  yellow: {
    color: '#f8b100',
  },
  green: {
    color: '#44a570',
  },
  socIcon: {
    width: 20,
    height: 20,
    objectFit: 'contain',
  },
  social: {
    fontFamily: 'Averta',
    color: '#fff',
    fontSize: 12,
    backgroundColor: '#5e5e5e',
    borderRadius: 25,
    padding: '10px 20px',
    margin: '7px 0 15px 0',
    width: 250,
    alignSelf: 'center',
    '&:hover': {
      backgroundColor: '#5e5e5e',
    },
  },
  already: {
    textAlign: 'center',
    color: '#5e5e5e',
    fontSize: 14,
    cursor: 'pointer',
  },
  error: {
    fontSize: 10,
    fontFamily: 'Averta Light',
    color: '#f44336',
    textAlign: 'center',
  },
});
