import React, { useState } from 'react';
import styles from './styles';
import { Help } from '@material-ui/icons';
import close from '../../../assets/img/close.svg';
import { IconButton, Tooltip } from '@material-ui/core';

export default ({
  tableSeprations,
  setTableSeprations,
  pageContent,
  selectedCompany,
}) => {
  const cls = styles();

  const handleChangeSeprations = (name, value, index) => {
    const adaptedSeprations = [
      ...tableSeprations.slice(0, index),
      {
        ...tableSeprations[index],
        [name]: value,
      },
      ...tableSeprations.slice(index + 1),
    ];
    setTableSeprations(adaptedSeprations);
  };

  const handleAddNewSeparation = () => {
    setTableSeprations((prev) => {
      return [
        ...prev,
        {
          split_by: '',
          column: '',
          template: '',
        },
      ];
    });
  };

  const handleRemoveSeprations = (index) => {
    const adaptedSeprations = [
      ...tableSeprations.slice(0, index),
      ...tableSeprations.slice(index + 1),
    ];

    setTableSeprations(adaptedSeprations);
  };

  return (
    <div className={cls.separetionTable}>
      <h3 className={cls.header}>SEPARATION</h3>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <div className={cls.listContainer}>
          <table className={cls.list}>
            <thead>
              <th style={{ width: '25%' }}>COLUMNS</th>
              <th style={{ width: '20%' }}>SPLIT BY</th>
              <th style={{ width: '40%' }}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    justifyContent: 'center',
                  }}>
                  <span>TEMPLATE</span>
                  <Tooltip arrow title="Type,SSL,Size" placement="top">
                    <Help className={cls.close} />
                  </Tooltip>
                </div>
              </th>
              {pageContent.value !== 1 && pageContent.value !== 2 && (
                <th style={{ flex: 0.3 }}>Remove</th>
              )}
            </thead>
            <tbody>
              {tableSeprations.map((item, index) => (
                <tr>
                  <td style={{ flex: 1 }}>
                    {pageContent.value !== 1 && pageContent.value !== 2 ? (
                      <input
                        onChange={(e) =>
                          handleChangeSeprations(
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }
                        type="text"
                        defaultValue={item.column}
                        value={item.column}
                        name="column"
                        style={{ textAlign: 'center' }}
                      />
                    ) : (
                      <span>{item.column}</span>
                    )}
                  </td>
                  <td style={{ flex: 1 }}>
                    {pageContent.value !== 1 && pageContent.value !== 2 ? (
                      <input
                        onChange={(e) =>
                          handleChangeSeprations(
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }
                        type="text"
                        defaultValue={item.split_by}
                        value={item.split_by}
                        name="split_by"
                        style={{ textAlign: 'center' }}
                      />
                    ) : (
                      <span>{item.split_by}</span>
                    )}
                  </td>
                  <td style={{ flex: 1 }}>
                    {pageContent.value !== 1 && pageContent.value !== 2 ? (
                      <input
                        onChange={(e) =>
                          handleChangeSeprations(
                            e.target.name,
                            e.target.value,
                            index
                          )
                        }
                        type="text"
                        defaultValue={item.template}
                        value={item.template}
                        name="template"
                        style={{ textAlign: 'center', width: '80%' }}
                      />
                    ) : (
                      <span>{item.template}</span>
                    )}
                  </td>
                  {pageContent.value !== 1 && pageContent.value !== 2 && (
                    <td style={{ flex: 0.3 }}>
                      <IconButton
                        onClick={() => {
                          handleRemoveSeprations(index);
                        }}>
                        <img alt="close_icon" src={close} />
                      </IconButton>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {pageContent.value !== 1 && pageContent.value !== 2 && (
            <button
              disabled={!selectedCompany}
              className={cls.addRowBtn}
              onClick={() => handleAddNewSeparation()}>
              Add New Row
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
