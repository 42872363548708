import React from 'react';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import { HowIcons, HowSteps } from '@/assets';
import HomeLayout from '@/components/HomeLayout';
import { AppBar } from '@/components/Landing';
import styles from './styles';

const CustomerSteps = [
  {
    title: 'Sign up',
    des: 'You can sign up free of any charges with your Facebook or Google account.',
    img: HowSteps.sign,
  },
  {
    title: 'Search inventory',
    des: 'You can use the Smart Search feature or manually search the empties listed in the market place. Choose your container size, desired SSL, location, and the request date. In addition, you can select from items provided in the drop down window to narrow down your search result. You can also prioritize your preference by selecting either the location or the number of containers needed.',
    img: HowSteps.search,
  },
  {
    title: 'Request a container',
    des: 'Select the containers that meet your needs and simply book them. In this step, you can work out the details of your deal with the other party directly or using our chat platform.',
    img: HowSteps.req,
  },
  {
    title: 'Finalize the deal',
    des: 'By selecting the "Finish Deal" icon, you have agreed on the deal terms and conditions. You can arrange the rest directly with the other party.',
    img: HowSteps.final,
  },
];

const ProviderSteps = [
  {
    title: 'Sign up',
    des: 'Create a Smart-Turn account for free by using your email address.',
    img: HowSteps.sign,
  },
  {
    title: 'Add inventory',
    des: 'Add your available empty containers and their details including size, SSL, location and the number of containers available. You can do this either by uploading the list in an excel format, by selecting the containers using the drop down menu or automatically by uploading your inventory from your TMS, without any extra effort. Smart-Turn will then send your empty containers to customers requesting that specific empty.',
    img: HowSteps.inv,
  },
  {
    title: 'Approve a request',
    des: 'Evaluate the incoming requests and work out the details with other trucking companies using our chat platform. You can accept a request completely or partially based on your inventory or the number of containers you are willing to release.',
    img: HowSteps.req,
  },
  {
    title: 'Finalize the deal',
    des: 'By selecting the "Finish Deal" icon on the chat, you have agreed on the deal terms and conditions. You can arrange the rest directly with the other party.',
    img: HowSteps.final,
  },
];

export default (props) => {
  const cls = styles();
  const [active, setActive] = React.useState(0);

  const [active2, setActive2] = React.useState(0);

  return (
    <HomeLayout className={cls.container}>
      <div className={cls.header}>How it works</div>
      <div className={cls.content}>
        <div className={cls.card}>
          <div className={cls.imgCard}>
            <ReactSVG src={HowIcons.custom} className={cls.cardImage} />
            <div className={cls.title1}>To Look For</div>
            <div className={cls.title2}>An Empty Container</div>
          </div>
          <div className={cls.cardContent}>
            <div className={cls.headerRow}>
              {CustomerSteps.map((i, idx) => (
                <div
                  className={clsx(cls.step, idx === active && cls.stepActive)}
                  onClick={() => setActive(idx)}>
                  {i.title}
                </div>
              ))}
            </div>
            <div className={cls.contentRow}>
              <img
                className={cls.contentImg}
                src={CustomerSteps[active].img}
                alt="sdf"
              />
              <div>{CustomerSteps[active].des}</div>
            </div>
          </div>
        </div>

        <div className={cls.card}>
          <div className={cls.imgCard2}>
            <ReactSVG src={HowIcons.container} className={cls.cardImage} />
            <div className={cls.title1}>To Offer</div>
            <div className={cls.title2}>An Empty Container</div>
          </div>
          <div className={cls.cardContent}>
            <div className={cls.headerRow}>
              {ProviderSteps.map((i, idx) => (
                <div
                  className={clsx(cls.step, idx === active2 && cls.stepActive2)}
                  onClick={() => setActive2(idx)}>
                  {i.title}
                </div>
              ))}
            </div>
            <div className={cls.contentRow}>
              <img
                className={cls.contentImg}
                src={ProviderSteps[active2].img}
                alt="sdf"
              />
              <div>{ProviderSteps[active2].des}</div>
            </div>
          </div>
        </div>
      </div>
      <AppBar />
    </HomeLayout>
  );
};
