import React from 'react';
import {
  Card,
  TableCell,
  TableHead,
  Table,
  TableRow,
  TableBody,
  CircularProgress,
} from '@material-ui/core';
import clsx from 'clsx';
import { _get, genSize } from '@/actions/Helper';
import styles from './styles';

const parseData = (data) =>
  data.map((i) => ({
    id: _get(i, 'id', ''),
    stat: _get(i, 'status', 0),
    up: _get(i, 'date', ''),
    user: _get(i, 'user.name', ''),
    ssl: _get(i, 'inventory.cabin.company.name', ''),
    loc: _get(i, 'inventory.address.city', ''),
    acc: _get(i, 'accepted_count', 0),
    in: _get(i, 'inprogress_count', 0),
    tot: _get(i, 'requested_count', 0),
    size: genSize(_get(i, 'inventory.cabin.specifications', {})),
  }));

const getDetail = (i) => `${i.tot}-${i.ssl}-${i.size}-${i.loc}`;

const linkGroups = {
  'In progress': 'inprogress',
  Complete: 'complete',
  Open: 'active',
};

export default ({ data }) => {
  const cls = styles();
  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });

  const getTableData = () => {
    let tables = parseData(data);
    setState((prev) => {
      return {
        DATA: tables,
        loading: false,
      };
    });
  };

  React.useEffect(() => {
    if (data) {
      getTableData();
    }
  }, [data]);

  if (state.loading)
    return (
      <Card className={clsx(cls.card, cls.center)}>
        <CircularProgress />
      </Card>
    );

  return (
    <Card className={cls.card}>
      <div className={clsx(cls.row)}>
        <span className={cls.title}>Latest Requests</span>
        {/* <Link to="/requests" className={cls.see}>
          See More
        </Link> */}
      </div>

      <div className={cls.tableCont}>
        <Table stickyHeader className={cls.table} border={0} cellSpacing={0}>
          <TableHead className={cls.header}>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Detail</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>By</TableCell>
              <TableCell>Manage</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow className={cls.separator} />
            {state.DATA.map((it, idx) => (
              <TableRow>
                <TableCell>{it.id}</TableCell>
                <TableCell>{getDetail(it)}</TableCell>
                <TableCell className={cls.statCont}>
                  {it.stat === 'In progress' ? (
                    <div>
                      <span className={cls.blue}>{it.acc}</span>
                      <span className={cls.blue}> In Progress - </span>
                      <span className={cls.green}>0 Completed</span>
                    </div>
                  ) : (
                    <div>
                      <span className={cls.blue}>0 In Progress - </span>
                      <span className={cls.green}>{it.acc}</span>
                      <span className={cls.green}> Completed</span>
                    </div>
                  )}
                  <div className={cls.progressWrapper}>
                    <div className={cls.progressBar}>
                      <div
                        style={{ width: (it.acc / it.tot) * 100 + '%' }}
                        className={cls.progress}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell>{it.up}</TableCell>
                <TableCell>{it.user}</TableCell>
                <TableCell>manage</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </Card>
  );
};
