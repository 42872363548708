import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  profileCont: {
    display: 'flex',
  },
  avatar: {
    width: 30,
    height: 30,
    marginRight: 8,
    '@media (max-width:800px)': {
      marginRight: '0',
    },
  },
  userName: {
    fontSize: 15,
    fontWeight: 300,
    letterSpacing: 'normal',
    color: '#ffffff',
    padding: 5,
    fontFamily: 'Averta',
    textTransform: 'none',
  },
  name: {
    '@media (max-width:800px)': {
      display: 'none',
    },
  },
});
