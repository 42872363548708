import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import HubspotForm from 'react-hubspot-form';
import styles from './styles';

export default ({ data, notify }) => {
  const cls = styles();
  const [form, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  const setForm = (e, item) =>
    setFormData({
      ...form,
      [item]: e.target.value,
    });

  return (
    // <div className={cls.card}>
    //   <div className={cls.title}>Contact Us</div>

    //   <div className={cls.col}>
    //     <span className={cls.label}>Name</span>
    //     <input
    //       placeholder="Name"
    //       className={cls.input}
    //       value={form.name}
    //       onChange={(e) => setForm(e, 'name')}
    //     />

    //     <span className={cls.label}>Last Name</span>
    //     <input
    //       placeholder="Last Name"
    //       className={cls.input}
    //       value={form.email}
    //       onChange={(e) => setForm(e, 'email')}
    //     />

    //     <span className={cls.label}>Email</span>
    //     <input
    //       placeholder="Email"
    //       className={cls.input}
    //       value={form.email}
    //       onChange={(e) => setForm(e, 'email')}
    //     />

    //     <span className={cls.label}>Company</span>
    //     <input
    //       placeholder="Your company"
    //       className={cls.input}
    //       value={form.subject}
    //       onChange={(e) => setForm(e, 'subject')}
    //     />

    //     <span className={cls.label}>Insert your message</span>
    //     <textarea
    //       className={clsx(cls.input, cls.textarea)}
    //       value={form.message}
    //       onChange={(e) => setForm(e, 'message')}
    //     />
    //   </div>

    //   <div className={cls.butCont}>
    //     <Button className={cls.but}>Send</Button>
    //     {/* <Link className={cls.link}>Link to Information</Link> */}
    //   </div>
    // </div>
    <div className={cls.card}>
      <div className={cls.title}>Contact Us</div>
      <div className={cls.hubspotContact}>
        <HubspotForm
          portalId="22601622"
          formId="5e3f932f-1c5a-4563-9ac0-5e6c566afbd3"
          onSubmit={() => console.log('Submit!')}
          onReady={(form) => console.log('Form ready!')}
          loading={<div>Loading...</div>}
        />
      </div>
    </div>
  );
};
