import React from 'react';
import styles from './styles';
import { CircularProgress, Tooltip } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';

const UpdatePopup = ({
  updateNotification,
  update,
  loadingUpdate,
  disabledUpdate,
  showClose,
  handleClose,
}) => {
  const cls = styles();
  return (
    <div className={`updatePopup ${cls.updatePopup}`}>
      <div className={cls.popupCard}>
        {showClose && (
          <Tooltip arrow title="Close" placement="top">
            <CloseRounded className={cls.close} onClick={handleClose} />
          </Tooltip>
        )}
        <p className={cls.updateText}>{updateNotification.title}</p>
        <div>
          {
            <div
              dangerouslySetInnerHTML={{
                __html: updateNotification.message,
              }}
            />
          }
        </div>
        <button
          onClick={() => !disabledUpdate && update()}
          className={cls.updateBtn}>
          {loadingUpdate ? (
            <CircularProgress size={20} color="#ffffff" />
          ) : (
            'Update Now'
          )}
        </button>
      </div>
    </div>
  );
};

export default UpdatePopup;
