import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  chatHeader: {
    padding: '0 2px',
    borderBottom: '1px solid #000000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& span': {
      padding: '12px',
      cursor: 'pointer',
    },
  },
  userInfo: {
    display: 'flex',
    padding: '12px',
    '& p': {
      margin: '5px 0',
    },
  },
  userAvatar: {
    marginRight: '10px',
    width: '41px',
    height: '41px',
  },
  userName: { fontWeight: '400', fontSize: '14px', lineHeight: '16px' },
  lastSeen: { fontWeight: '400', fontSize: '12px', lineHeight: '14px' },
  backIcon: {
    width: '20px',
    height: '20px',
  },
}));
