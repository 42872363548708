import React from 'react';
import {
  Card,
  CardContent,
  Button,
  Avatar,
  TextField,
} from '@material-ui/core';
import { AccountCircle, HomeRounded } from '@material-ui/icons';

import clsx from 'clsx';
import { fDate, fTimeDate } from '@/actions/Helper';
import styles from './styles';

export default ({ DATA, Accept, Reject }) => {
  const cls = styles();
  const [state, setState] = React.useState(0);

  React.useEffect(() => {
    setState(DATA.req);
  }, [DATA]);

  return (
    <Card className={cls.card}>
      <div className={cls.header}>
        <span className={cls.bold}>
          {DATA.mode.charAt(0).toUpperCase() + DATA.mode.slice(1)}
        </span>
        <span className={cls.light}>ID: {DATA.id}</span>
      </div>
      <CardContent className={cls.content}>
        <div className={clsx(cls.row, cls.margin)}>
          <div className={cls.row2}>
            <Avatar className={cls.avatar} src={DATA.avatar} alt={DATA.user} />
            <div>
              <div className={cls.row2}>
                <AccountCircle className={cls.icon} />
                <span className={cls.light2}>{DATA.user}</span>
              </div>
              <div className={cls.row2}>
                <HomeRounded className={cls.icon} />
                <span className={cls.light2}>{DATA.company}</span>
              </div>
            </div>
          </div>

          <div className={cls.flex}>
            <div className={cls.bigText}>{DATA.requested_count}</div>
            <div className={cls.avText2}>Nu</div>
          </div>
        </div>
        <div className={cls.row}>
          <span>Date:</span>
          <span>{fDate(DATA.date)}</span>
        </div>
        <div className={cls.row}>
          <span>Location:</span>
          <span>{DATA.loc}</span>
        </div>
        <div className={cls.row}>
          <span>Size:</span>
          <span>{DATA.size}</span>
        </div>

        <div className={cls.row}>
          <span>SSL:</span>
          <span>{DATA.ssl}</span>
        </div>

        <div className={cls.row}>
          <span>Chassis Type:</span>
          <span>
            {/* {`${DATA.own_available ? 'Own/' : ''} ${
            DATA.pool_available ? 'Pool' : ''
          }`} */}
            {DATA.chase_type == 'normal'
              ? 'Own/Pool'
              : DATA.chase_type == 'own'
              ? 'Own'
              : DATA.chase_type == 'pool'
              ? 'Pool'
              : ''}
          </span>
        </div>

        <div className={cls.hr} />

        <div className={cls.row}>
          <span className={cls.stat}>Status</span>
          {/* <span className={cls.statText}>{DATA.stat}</span> */}
          <span className={cls.statText}>New</span>
        </div>

        <div className={cls.text}>Your Last Inventory Update</div>

        <div className={cls.text}>{fTimeDate(DATA.update)}</div>

        <div className={cls.hr} />

        <div className={clsx(cls.row, cls.margin)}>
          <span className={cls.stat}>Number you have now</span>
          <TextField
            type="number"
            placeholder=""
            className={cls.input}
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 1, max: DATA.req || 0 }}
            value={state}
            onChange={(ev) => setState(ev.target.value)}
          />
        </div>

        <div className={cls.row}>
          <Button className={cls.but} onClick={() => Accept(DATA, state)}>
            Accept Offer
          </Button>
          <Button
            className={clsx(cls.but, cls.but2)}
            onClick={() => Reject(DATA.id)}>
            Reject
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
