import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import logo from '../../assets/img/logo.png';
import styles from './styles';
import { fDate2 } from '@/actions/Helper';
import qs from 'query-string';

export default () => {
  const params = useParams();
  const history = useHistory();

  const cls = styles();

  const sizeLetterToId = {
    b: 2,
    c: 3,
    d: 4,
    e: 5,
    f: 6,
    g: 7,
    h: 8,
    i: 9,
    j: 10,
    k: 11,
    l: 12,
    m: 13,
  };

  const sslLetterToId = {
    a: 1,
    b: 2,
    c: 3,
    d: 4,
    e: 5,
    f: 6,
    g: 7,
    h: 8,
    i: 9,
    j: 10,
    k: 11,
    l: 12,
    m: 13,
    n: 14,
    o: 15,
    p: 16,
    q: 17,
    r: 18,
    s: 19,
    t: 20,
    u: 21,
  };

  const sizeIdToName = {
    2: '20--Dry',
    3: '40-ST-Dry',
    4: '40-HC-Dry',
    5: '45--Dry',
    6: '20--Reefer',
    7: '40-ST-Reefer',
    8: '40-HC-Reefer',
    9: '45--Reefer',
    10: '20--Opentop',
    11: '40-ST-Opentop',
    12: '40-HC-Opentop',
    13: '45--Opentop',
  };

  const sslIdToName = {
    1: 'MAERSK',
    2: 'MSC',
    3: 'CMA-CGM',
    4: 'COSCO',
    5: 'HAPAG-LLOYD',
    6: 'ONE',
    7: 'YANG MING',
    8: 'PIL',
    9: 'OOCL',
    10: 'HYUNDAI',
    11: 'EVERGREEN',
    12: 'SM LINE',
    13: 'ZIM',
    14: 'WAN HAI',
    15: 'MATSON',
    16: 'APL',
    17: 'ANL',
    18: 'HAMBURG SUD',
    19: 'SEALAND',
    20: 'SAF MARINE',
    21: 'BAL',
  };

  const handleShareLinkClick = () => {
    const user = localStorage.getItem('userID');
    const token = localStorage.getItem('token');

    if (user && token) {
      const search = {
        address: 'Los Angeles, California, United States',
        city: 'Los Angeles',
        count: '1',
        count_available: '1',
        date: fDate2(Date.now()),
        lat: '34.053691',
        long: '-118.242766',
        pre: 'false',
        size: sizeLetterToId[params.empty.split('')[0]],
        ssl: sslLetterToId[params.empty.split('')[1]],
      };
      history.push('/search-detail/empty?' + qs.stringify(search));
    } else if (user && !token) {
      history.push('/login');
    } else if (!user && !token) {
      window.location.replace(
        `?ssl_spacification_name=${
          sizeIdToName[sizeLetterToId[params.empty.split('')[0]]]
        }&ssl_company_name=${
          sslIdToName[sslLetterToId[params.empty.split('')[1]]]
        }#/register`
      )
    }
  };

  useEffect(() => {
    handleShareLinkClick();
  }, [handleShareLinkClick]); 
  
  return (
    <div className={cls.container}>
      <div className={cls.loaderContainer}>
        <img alt="" src={logo} />
        <CircularProgress className={cls.loader} />
        <div className={cls.text}>please wait..</div>
      </div>
    </div>
  );
};
