import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    maxWidth: '800px',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    padding: '2vh 4vw',
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  icon: {
    width: 28,
    height: 28,
    fill: '#f8b100',
    marginRight: 15,
  },

  row: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 20,
    fontFamily: 'Averta Bold',
    lineHeight: 1.2,
    color: '#303030',
    fontWeight: 'bold',
    marginBottom: 20,
    '@media (max-width:900px)': {
      fontSize: '16px',
    },
  },
  bio: {
    fontSize: 16,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.25,
    color: '#303030',
    paddingTop: 25,
  },
  avatarCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
    cursor: 'pointer',
  },
  avatar: {
    height: 130,
    width: 130,
  },
  avatarText: {
    fontSize: 18,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 2.33,
    color: '#007fff',
    marginTop: 15,
  },
  checkbox: {
    padding: '10px 15px',
    '& svg': {
      fill: '#f8b100',
    },
    '& hover': {
      background: '#f8b1001a',
    },
  },
  enabledContainer: {
    color: '#000000 ',
    '& span': {
      fontSize: 20,
      fontWeight: 'bold',
      fontFamily: 'Averta Bold',
      color: '#727272 !important',
      '&:first-child': {
        paddingLeft: '7px',
        paddingRight: '8px',
      },
    },
  },
  disabledContainer: {
    color: '#d6d6d6',
    '& span': {
      fontSize: 20,
      fontWeight: 'bold',
      fontFamily: 'Averta Bold',
      '&:first-child': {
        paddingLeft: '7px',
        paddingRight: '8px',
      },
    },
  },
  chasissBtn: {
    background: '#f8b100',
    color: '#ffffff',
    display: 'block',
    width: '100%',
    margin: '15px 0px 5px',
    '&:hover': {
      background: '#f8b100',
    },
  },
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    // zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99999999999999999999999999999',
  },
  popupCard: {
    position: 'relative',
    padding: '15px 15px',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxHeight: '75%',
    maxWidth: '80%',
    animation: 'zoomIn 0.7s',
    height: 400,
    width: 250,
    justifyContent: 'flex-start',
    overflow: 'auto',
  },

  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#777575',
  },

  tableContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  pTitle: { color: '#727272' },
  chassisTable: {
    width: '230px',
    '& tr': {
      display: 'flex',
    },
    '& th': {
      color: '#777575',
      fontSize: '13px',
      padding: '10px',
    },
    '& td': {
      padding: '0 10px',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#000000',
      fontSize: '13px',
      height: '27px',
      borderBottom: '1px solid #dedede',
      '& label': {
        margin: 0,
      },
      '& svg': {
        fill: '#000000',
      },
    },
  },
}));
