import React, { useState } from 'react';
import styles from './styles';
import { Grid, Button } from '@material-ui/core';
import carIcon from '../../../assets/img/car.svg';
import DateInput from '@/components/DateInput';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';

export default ({
  changeFilter,
  selectedFilter,
  setSelectedFilter,
  trackingCompanies,
}) => {
  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const cls = styles();

  const changeFilterDate = (date, key) => {
    const dateObject = {
      to: date,
    };
    if (key === 'from') {
      dateObject.from = date;
    } else {
      dateObject.from = selectedFilter.date.from;
    }
    setSelectedFilter((prev) => {
      return {
        ...prev,
        date: dateObject,
      };
    });
  };

  const handleSelectTrackingCompany = () => {
    if (trackingCompanies.length > 0) {
      setShowCompanyModal(true);
    } else {
      //
    }
  };

  return (
    <div className={cls.filter}>
      {showCompanyModal && (
        <div className={cls.popup}>
          <div className={cls.card}>
            <FormControl className={cls.companyList}>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={selectedFilter.trackingCompany}
                onChange={(e) => {
                  setSelectedFilter((prev) => {
                    return {
                      ...prev,
                      trackingCompany: Number(e.target.value),
                    };
                  });
                  console.log(selectedFilter.trackingCompany);
                }}>
                {trackingCompanies.map((item, index) => (
                  <FormControlLabel
                    value={item.id}
                    control={<Radio />}
                    label={item.company}
                  />
                ))}
              </RadioGroup>
            </FormControl>
            <div className={cls.modalBtnContainer}>
              <Button
                className={cls.okBtn}
                onClick={() => {
                  setShowCompanyModal(false);
                }}>
                Ok
              </Button>
              <Button
                className={cls.resetBtn}
                onClick={() => {
                  setSelectedFilter((prev) => {
                    return {
                      ...prev,
                      trackingCompany: null,
                    };
                  });
                }}>
                Reset
              </Button>
            </div>
          </div>
        </div>
      )}
      <Grid container alignItems="center" spacing={2}>
        <Grid item md={7} lg={6}>
          <Grid container spacing={2}>
            <Grid item>
              <div
                onClick={() => {
                  handleSelectTrackingCompany();
                }}
                className={`${cls.filterBtn} ${
                  selectedFilter.trackingCompany ? cls.activeFilter : ''
                }`}>
                <img src={carIcon} alt='' />
                <span>Tracking Company</span>
              </div>
            </Grid>
            <Grid item className={cls.row}>
              <div
                onClick={() => changeFilter('day')}
                className={`${cls.filterBtn} ${
                  selectedFilter.name === 'day' ? cls.activeFilter : ''
                }`}>
                Day
              </div>
              <div
                onClick={() => changeFilter('month')}
                className={`${cls.filterBtn} ${
                  selectedFilter.name === 'month' ? cls.activeFilter : ''
                }`}>
                Month
              </div>
              <div
                onClick={() => changeFilter('year')}
                className={`${cls.filterBtn} ${
                  selectedFilter.name === 'year' ? cls.activeFilter : ''
                }`}>
                Year
              </div>
              <div
                onClick={() => changeFilter('custom')}
                className={`${cls.filterBtn} ${
                  selectedFilter.name === 'custom' ? cls.activeFilter : ''
                }`}>
                Custom
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={5} lg={6}>
          <div className={cls.dateInputs}>
            <div
              className={`${cls.dateContainer} ${
                selectedFilter.name !== 'custom' ? cls.disabledDate : ''
              }`}>
              <span>Check- in</span>
              <DateInput
                disabled={selectedFilter.name !== 'custom'}
                className={`${cls.date}`}
                value={selectedFilter.date.from}
                onChange={(date) => changeFilterDate(date, 'from')}
              />
            </div>
            <div
              className={`${cls.dateContainer} ${
                selectedFilter.name !== 'custom' ? cls.disabledDate : ''
              }`}>
              <span>Check- in</span>
              <DateInput
                disabled={selectedFilter.name !== 'custom'}
                className={`${cls.date}`}
                value={selectedFilter.date.to}
                onChange={(date) => changeFilterDate(date, 'to')}
                minDate={selectedFilter.date.from}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
