import React, { useState } from 'react';
import List from './List';
import { useNotify, Loading } from 'react-admin';
import {
  getAdminInventory,
  getAdminInventoryCompleted,
  getInventory,
} from '@/actions';
import {
  ParseCompletedInventoryList,
  ParseInventoryList,
} from '@/actions/ParseData';
import styles from '../styles';
import { Filter } from '@/components/Global';
import { BaseIcon } from '@/Utils';
import { MemberIcons } from '@/assets';
import { fDate } from '@/actions/Helper';
import Pagination from '@/components/pagination';

export default () => {
  const notify = useNotify();
  const [state, setState] = React.useState({
    edit: null,
    file: null,
  });
  const [pagination, setPagination] = React.useState({
    currentPage: 1,
    pages: [1],
  });
  const [form, setForm] = React.useState(null);
  const [loading, setLoading] = React.useState(true);
  const [DATA, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });

  const cls = styles();

  const createArrayFromNumbers = (number) => {
    let numberArray = [];
    for (let i = 1; i <= number; i++) {
      numberArray.push(i);
    }

    return numberArray;
  };

  const getInventories = () => {
    setLoading(true);
    getAdminInventoryCompleted(
      form
        ? { ...form, page: pagination.currentPage }
        : { page: pagination.currentPage }
    )
      .then((res) => {
        let DATA = ParseCompletedInventoryList(res.data);
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
        setPagination((prev) => {
          return {
            ...prev,
            pages: createArrayFromNumbers(Math.ceil(res.total / res.per_page)),
          };
        });
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const filterWithApi = (filterData) => {
    let form = {};
    if (filterData.company) {
      form.user_company = filterData.company.value;
    }
    if (filterData.date) {
      form.available_at = fDate(filterData.date);
    }
    if (filterData.loc) {
      form.address = filterData.loc.label;
    }
    if (filterData.num) {
      form.count = filterData.num;
    }
    if (filterData.size) {
      form.cabin = filterData.size.value;
    }
    if (filterData.ssl) {
      form.ssl = filterData.ssl.value;
    }

    form.page = pagination.currentPage;
    setForm(form);
  };

  React.useEffect(() => {
    getInventories();
  }, [notify]);

  React.useEffect(() => {
    setLoading(true);
    getInventories();
  }, [notify, pagination.currentPage, form]);

  return (
    <div className={cls.mainContainer}>
      <Filter
        title="Completed"
        filter={filter}
        setFilter={setFilter}
        filterWithApi={filterWithApi}
        withDateFilter={true}
        DATA={DATA}>
        <div className={cls.filter}>
          <span className={cls.export}>Export</span>
          <span className={cls.xlsx}>XLSX</span>
        </div>
      </Filter>
      {loading ? (
        <Loading />
      ) : (
        <List
          getInventories={getInventories}
          title="Completed"
          DATA={filter.DATA}
          isCompleted={true}
          currentPage={pagination.currentPage}
        />
      )}
      <Pagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
};
