import React from 'react';
import { Card, Button, Avatar } from '@material-ui/core';
import { AccountCircle, HomeRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { fTimeDate } from '@/actions/Helper';
import styles from './styles';

export default ({ DATA = {}, Reject, Chat }) => {
  const cls = styles();

  return (
    <Card className={cls.card}>
      <div className={cls.header}>
        <span className={cls.bold}>
          {DATA.mode.charAt(0).toUpperCase() + DATA.mode.slice(1)}
        </span>
        <span className={cls.light}>ID: {DATA.id}</span>
      </div>
      <div className={cls.content}>
        <div className={clsx(cls.row, cls.margin)}>
          <div className={cls.row2}>
            <Avatar className={cls.avatar} src={DATA.avatar} alt={DATA.user} />
            <div>
              <div className={cls.row2}>
                <AccountCircle className={cls.icon} />
                <span className={cls.light2}>{DATA.user}</span>
              </div>
              <div className={cls.row2}>
                <HomeRounded className={cls.icon} />
                <span className={cls.light2}>{DATA.company}</span>
              </div>
            </div>
          </div>

          <div className={cls.flex}>
            <div className={cls.bigText}>{DATA.count}</div>
            <div className={cls.avText2}>Nu</div>
          </div>
        </div>

        <div className={cls.row}>
          <span>Date :</span>
          <span>{fTimeDate(DATA.date)}</span>
        </div>
        <div className={cls.row}>
          <span>Location :</span>
          <span>{DATA.loc}</span>
        </div>
        <div className={cls.row}>
          <span>Size :</span>
          <span>{DATA.size}</span>
        </div>

        <div className={cls.row}>
          <span>SSL :</span>
          <span>{DATA.ssl}</span>
        </div>
        <div className={cls.row}>
          <span>Chassis Type:</span>
          <span>
            {/* {`${DATA.own_available ? 'Own/' : ''} ${
            DATA.pool_available ? 'Pool' : ''
          }`} */}
            {DATA.chase_type == 'normal'
              ? 'Own/Pool'
              : DATA.chase_type == 'own'
              ? 'Own'
              : DATA.chase_type == 'pool'
              ? 'Pool'
              : ''}
          </span>
        </div>
        <div className={cls.hr} />

        <div className={clsx(cls.row, cls.margin)}>
          <span className={cls.stat}>Status</span>
          <span className={cls.statText}>{DATA.stat}</span>
        </div>

        <div className={cls.text}>Your Last Inventory Update</div>

        <div className={cls.text}>{fTimeDate(DATA.update)}</div>

        <div className={clsx(cls.hr, cls.margin)} />

        <div className={clsx(cls.row, cls.margin)}>
          <span className={cls.stat}>Offered Number</span>
          <span className={cls.numText}>{DATA.acc}</span>
        </div>

        <div className={cls.row}>
          <Button className={cls.but} onClick={() => Chat(DATA.id, DATA.mode)}>
            Chat
          </Button>
          <Button
            className={clsx(cls.but, cls.but2)}
            onClick={() => Reject(DATA.id, DATA.mode)}>
            Reject
          </Button>
        </div>
      </div>
    </Card>
  );
};
