import { API } from '@/actions/API';
import { getInit } from '@/actions';
import { askNotificationPerm } from '@/components/push-notification';
import _get from 'lodash/get';

export default {
  login: ({ email, password, password_confirmation }) => {
    // login
    let url = '/login';
    let req = { email, password };
    if (password_confirmation) {
      // register
      url = '/register';
      req = {
        email,
        password,
        password_confirmation,
        name: 'user',
        phone: '',
        image: '',
        company: '',
      };
    }

    return API.post(url, req).then(({ data, ok }) => {
      if (ok) {
        let token = _get(data, 'data.access_token', null);
        let x = atob(token.split('.')[1]);
        let userId = JSON.parse(x).sub;
        if (token) {
          localStorage.token = token;
          localStorage.userID = userId;
        }
        let role = _get(data, 'data.roles', null);
        if (role) localStorage.permissions = role;
        getInit();
        askNotificationPerm();
        return Promise.resolve();
      } else {
        localStorage.removeItem('token');
        return Promise.reject(data.message);
      }
    });
  },
  logout: () => {
    localStorage.removeItem('token');
    localStorage.removeItem('permissions');
    return Promise.resolve();
  },
  checkError: ({ status }) =>
    status === 401 || status === 403 ? Promise.reject() : Promise.resolve(),
  checkAuth: () => (localStorage.token ? Promise.resolve() : Promise.reject()),
  getPermissions: () => {
    const role = localStorage.getItem('permissions') ?? 'user';
    return role ? Promise.resolve(role) : Promise.reject();
  },
};
