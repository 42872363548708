import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#d6d6d6',
    position: 'relative',
  },
  list: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    backgroundColor: '#fff',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filter: {
    height: 25,
    width: 25,
    objectFit: 'contain',
    fill: '#9f9f9f',
    marginLeft: 20,
  },
  title: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 24,
    lineHeight: 1,
    color: '#43424b',
  },
  headerBar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    margin: '30px 40px 20px 40px',
  },
});
