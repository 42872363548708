import React, { useState, version } from 'react';
import Profile from './Profile';
import styles from './styles';
import { Button } from '@material-ui/core';
import Refer from './Refer';

export default () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const cls = styles();
  const appVersion = 'v1.9.1';
  const profileTabItems = [
    {
      title: 'Profile',
      content: <Profile />,
    },
    {
      title: 'Referral',
      content: <Refer setSelectedTab={setSelectedTab} />,
    },
  ];
  return (
    <div>
      <div className={cls.profileTabs}>
        <span className={cls.profileBtnsSpan}>
          {profileTabItems.map((item, index) => (
            <Button
              className={
                selectedTab === index
                  ? cls.activeProfileTabsBtn
                  : cls.profileTabsBtn
              }
              onClick={() => setSelectedTab(index)}
              key={index}>
              {item.title}
            </Button>
          ))}
        </span>
        <span>
          <a
            href={`https://smart-turn.com/app/download/SmartTurnSetup-${appVersion}.exe`}>
            <Button className={cls.winAppDownload}>Download Windows App</Button>
          </a>
        </span>
      </div>
      <div>{profileTabItems[selectedTab].content}</div>
    </div>
  );
};
