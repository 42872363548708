import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    padding: '40px 60px',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#303030',
    boxSizing: 'border-box',
    [BP.down('xs')]: {
      padding: '1rem 1.5rem',
      marginTop: 30,
    },
  },

  list: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 10,
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 20,
  },
  rowCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 30,
    [BP.down('xs')]: {
      marginTop: '1rem',
    },
  },

  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Averta Light',
    fontWeight: 300,
    fontSize: 42,
    lineHeight: 1.24,
    color: '#e8eff7',
    textAlign: 'center',
    [BP.down('xs')]: {
      fontSize: '1.8rem',
    },
  },
  title2: {
    fontFamily: 'Averta Light',
    fontWeight: 300,
    fontSize: 15,
    lineHeight: 1.24,
    color: '#e8eff7',
    textAlign: 'center',
    margin: '20px 0',
    [BP.down('xs')]: {
      fontSize: '0.8rem',
      margin: '10px 0 0',
    },
  },

  select: {
    width: 160,
    fontFamily: 'Averta Light',
    '& div': {
      borderWidth: 0,
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& svg': {
      fill: '#f8b100',
    },
    [BP.down('xs')]: {
      width: '33vw',
      fontSize: '.7rem',
    },
  },
  date: {
    boxSizing: 'border-box',
    width: 160,
    height: 38,
    marginLeft: 10,
    backgroundColor: '#fff',
    borderRadius: 4,
    justifyContent: 'center',
    fontFamily: 'Averta Light',
    fontSize: 12,
    padding: '0 10px',
    '& input': {
      fontFamily: 'Averta Light',
      padding: 0,
    },
    '& svg': {
      fill: '#f8b100',
    },
    '& button': {
      padding: 8,
    },
    [BP.down('xs')]: {
      width: '33vw',
      '& input': {
        fontSize: '.7rem',
      },
    },
  },
  ml10: {
    marginLeft: 10,
  },
  input: {
    fontFamily: 'Averta Light',
    width: 160,
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    '& input': {
      fontFamily: 'Averta Light',
      padding: '5px 10px',
    },
    [BP.down('xs')]: {
      width: '33vw',
      '& input': {
        padding: '3px 6px',
        fontSize: '.7rem',
      },
    },
  },
  bigInput: {
    width: '100%',
  },
  text: {
    fontFamily: 'Averta Light',
    fontSize: 16,
    fontWeight: 300,
    lineHeight: 1.25,
    color: '#fff',
    [BP.down('xs')]: {
      fontSize: '0.8rem',
    },
  },
  find: {
    width: 133,
    height: 40.3,
    borderRadius: 5,
    backgroundColor: '#f8b100',
    fontFamily: 'Averta Bold',
    fontSize: 21,
    fontWeight: 'bold',
    lineHeight: 1.86,
    color: '#303030',
    padding: 0,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffd616',
    },
    [BP.down('xs')]: {
      fontSize: '1rem',
      width: '33vw',
      height: 30,
      '& > .MuiButton-label': {
        height: 30,
      },
    },
  },
  findIcon: {
    width: 28,
    height: 40,
    fill: '#303030',
  },
  rowRound: {
    width: 180,
    height: 40,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    borderRadius: 20,
    backgroundColor: '#fff',
    fontFamily: 'Averta Light',
    fontSize: 15,
    fontWeight: 300,
    lineHeight: 1.27,
    color: '#9f9f9f',
    marginLeft: 25,
    cursor: 'pointer',
    [BP.down('xs')]: {
      width: '33vw',
      height: 30,
      marginLeft: 10,
      fontSize: '0.8rem',
    },
  },
  active: {
    borderRadius: 20,
    backgroundColor: '#f8b100',
    color: '#fff',
    animation: (props) =>
      props.prefrence ? 'slideInLeft 0.5s' : 'slideInRight 0.5s',
  },
  text2: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all 0.2s ease-in',
  },
}));
