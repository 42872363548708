import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    // zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: '99999999999999999999999999999',
  },

  card: {
    position: 'relative',
    padding: '25px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    maxWidth: '80%',
    animation: 'zoomIn 0.7s',
    height: 'auto',
    width: 400,
  },

  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: '#e4464b',
  },

  icon: {
    width: 28,
    height: 28,
    fill: '#f8b100',
    marginRight: 15,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 16,
    fontFamily: 'Averta Light',
    lineHeight: 1.2,
    color: '#303030',
    fontWeight: 'bold',
    marginBottom: 10,
  },

  avatarCont: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 30,
  },
  avatar: {
    height: 130,
    width: 130,
  },
  avatarText: {
    fontSize: 18,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 2.33,
    color: '#007fff',
    marginTop: 15,
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    color: '#222',
    marginBottom: 20,
  },
  transfer: {
    backgroundColor: '#007fff',
    color: '#ffffff',
    width: '100%',
    marginBottom: 5,
    '& a': {
      textDecoration: 'none',
    },
    '&:hover': {
      backgroundColor: '#007fff',
      color: '#ffffff',
    },
    '& a': {
      textDecoration: 'none',
      color: '#ffffff',
    },
  },
}));
