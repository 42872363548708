import * as firebase from 'firebase/app';
import '@firebase/analytics';
import '@firebase/messaging';
import { HeaderIcons } from '@/assets';
import {
  setFirebaseToken,
  SaveNotif,
  SaveData,
  AddData,
} from '@/actions';

const PUBLIC_URL = process.env.PUBLIC_URL;

const firebaseUrl = `${PUBLIC_URL}/firebase-messaging-sw.js`;

export const InitFirebase = () => {
  const firebaseConfig = {
    // -------- Amirbios Gmail -------
    // apiKey: 'AIzaSyAFpH67wkO3zon1Crpzndmr5TxNUd5qlE0',
    // authDomain: 'smartturn-cb4f9.firebaseapp.com',
    // databaseURL: 'https://smartturn-cb4f9-default-rtdb.firebaseio.com',
    // projectId: 'smartturn-cb4f9',
    // storageBucket: 'smartturn-cb4f9.appspot.com',
    // messagingSenderId: '763192511885',
    // appId: '1:763192511885:web:8eee285a6f1927434041c4',
    // measurementId: 'G-T6VGKSR21E',

    // ---- info@Smart-turn.com Google Account ----
    apiKey: 'AIzaSyDpZNp1y71q8P01FBE4ML9uQ9Sivw8QBGc',
    authDomain: 'smart-turn-365119.firebaseapp.com',
    databaseURL: 'https://smart-turn-365119-default-rtdb.firebaseio.com',
    projectId: 'smart-turn-365119',
    storageBucket: 'smart-turn-365119.appspot.com',
    messagingSenderId: '522604794402',
    appId: '1:522604794402:web:c891d9f299815b631c307b',
    measurementId: 'G-MT6T53QDV0',

    //---- info@Smart-turn.com Google Account .env ----
    // apiKey: process.env.REACT_APP_PUSH_NOTIF_JS_API_KEEEY,
    // authDomain: process.env.REACT_APP_PUSH_NOTIF_JS_AUTH_DOMAIN,
    // databaseURL: process.env.REACT_APP_PUSH_NOTIF_JS_DATABASE_URL,
    // projectId: process.env.REACT_APP_PUSH_NOTIF_JS_PROJECT_ID,
    // storageBucket: process.env.REACT_APP_PUSH_NOTIF_JS_STORAGE_BUCKET,
    // messagingSenderId: process.env.REACT_APP_PUSH_NOTIF_JS_MESSAGING_SENDER_ID,
    // appId: process.env.REACT_APP_PUSH_NOTIF_JS_APP_ID,
    // measurementId: process.env.REACT_APP_PUSH_NOTIF_JS_MEASUREMENTID,
  };

  // const notif = useSelector((state) => state.reducer.notif || {});

  // const openPopup = (item) => {
  //   SaveData({ ChatItem: item, Chat: null });
  //   if (notif[item]) SaveNotif({ [item]: null });
  //   if (notif.support) SaveNotif({ support: null });
  // };
  // Project Settings => Add Firebase to your web app
  firebase.initializeApp(firebaseConfig);

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(firebaseUrl, {
        scope: `${PUBLIC_URL}/firebase-cloud-messaging-push-scope`,
        updateViaCache: 'none',
      })
      .then((registration) => {
        firebase.analytics();

        const msg = firebase.messaging();

        msg.useServiceWorker(registration);

        msg.onMessage(({ data = {}, notification, ...rest }) => {
          let { type = '', status } = data;
          if (type === 'appUpdate') {
            SaveNotif({
              update: notification,
            });
          } else if (type === 'ContactInfo') {
            SaveData({
              ContactInfoPopup: {
                ...JSON.parse(data.user),
                transferLink: JSON.parse(data['transfer-links']),
              },
            });
            // window.push('/orders/compated');
          } else if (status === 'complete') {
            SaveNotif({ chat: true });
          } else if (status === 'archive') {
            SaveNotif({ archive: true });
          } else if (type === 'order' && status === 'active') {
            SaveNotif({ chat: true });
          } else if (type === 'request' && status === 'inprogress') {
            SaveNotif({ chat: true });
          } 
          else if (notification.title === 'Whatsapp Messages') {
            SaveNotif({ 'whatsapp-messages': true });
            AddData({
              key: 'whatsappNewMessages',
              value: data,
            });
          } 
          else if (type === 'request' && status === 'archive') {
            SaveNotif({ chat: true });
            SaveData({
              Chat: {
                id: data.id,
                type: data.status,
                chatId: data.id,
                mode: data.mode,
              },
            });
            // console.log('request archive');
          } else if (type === 'chat') {
            const permissions = localStorage.getItem('permissions');
            if (permissions !== 'admin') {
              SaveNotif({ [status]: true });
              SaveData({
                Chat: {
                  id: data.order_id,
                  type: data.status,
                  chatId: data.id,
                  messageId: data.message_id,
                  orderId: data.order_id,
                  mode: data.mode,
                },
              });
            }
          } else SaveNotif({ [type]: true });

          // SaveData({ refresh: true, refreshChat: true, refreshPage: type });

          const { body, title } = notification;

          const options = {
            data,
            body,
            click_action: '/',
            image: HeaderIcons.logoWhite,
            actions: [
              {
                action: `/#/${type}s/${status}`,
                title: 'Open',
              },
              {
                action: 'dismiss',
                title: 'Dismiss',
              },
            ],
          };
          registration.showNotification(title, options);
        });
      })
      .catch((er) => console.error('error => ', er));
  }
};

const sendTokenToServer = (token) => {
  if (token) {
    setFirebaseToken(token);
    localStorage.setItem('notification', 'allow');
    console.log('notification token allow');
  } else {
    localStorage.setItem('notification', 'notallow');
  }
};
export const askNotificationPerm = async () => {
  try {
    const msg = firebase.messaging();
    await msg.requestPermission();
    msg.getToken().then((t) => sendTokenToServer(t));
    msg.onTokenRefresh(() => msg.getToken().then((t) => sendTokenToServer(t)));
    // localStorage.setItem("notification" , "allow")
  } catch (error) {
    // localStorage.setItem("notification" , "allow")
    console.error(error);
    console.log('notification token not allow');
    localStorage.setItem('notification', 'notallow');
  }
};
