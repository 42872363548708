import React from 'react';
import { useNotify } from 'react-admin';
import { Button, TextField } from '@material-ui/core';
import { TrendingFlatRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import qs from 'query-string';
import _get from 'lodash/get';

import { fDate2, locationOpt, LoggedIn } from '@/actions/Helper';
import DateInput from '@/components/DateInput';
import styles from './styles';

export default ({ history }) => {
  const selectOp = useSelector((state) => state.reducer);
  const notify = useNotify();
  const [form, setFormData] = React.useState({
    specification_id: null,
    company_id: null,
    count: '',
    date: new Date(),
    loc: null,
    //numberMode
    prefrence: false,
  });

  const cls = styles({ prefrence: form.prefrence });

  const setForm = (it, value) => setFormData({ ...form, [it]: value });

  const parseForm = () => ({
    count: _get(form, 'count', ''),
    date: fDate2(form.date),
    pre: _get(form, 'prefrence', ''),
    lat: _get(form, 'loc.value.lat', ''),
    long: _get(form, 'loc.value.lng', ''),
    address: _get(form, 'loc.value.address', ''),
    size: _get(form, 'specification_id.value', ''),
    ssl: _get(form, 'company_id.value', ''),
  });

  const Detail = () => {
    if (!LoggedIn()) {
      notify('Please login', 'warning');
      history.push('/login');
      return;
    }
    for (let el in form) {
      if (el !== 'prefrence' && !form[el]) {
        notify('Error, Please check fields.', 'warning');
        return;
      }
    }
    history.push('/search-detail?' + qs.stringify(parseForm()));
  };

  return (
    <div className={cls.card}>
      <span className={cls.title}>TRY IT FOR FREE</span>
      <span className={cls.title2}>WHAT ARE YOU SEARCHING FOR?</span>
      <div className={cls.row}>
        <Select
          placeholder="Size"
          className={cls.select}
          value={form.specification_id}
          onChange={(item) => setForm('specification_id', item)}
          options={selectOp.size}
        />

        <Select
          placeholder="SSL"
          className={clsx(cls.select, cls.ml10)}
          options={selectOp.ssl}
          onChange={(item) => setForm('company_id', item)}
        />
      </div>
      <div className={cls.row}>
        <TextField
          type="number"
          placeholder="Number"
          className={cls.input}
          InputProps={{ disableUnderline: true }}
          inputProps={{ min: 0 }}
          value={form.count}
          onChange={(ev) => setForm('count', ev.target.value)}
        />

        <DateInput
          placeholder="Date"
          className={cls.date}
          format="MM/dd/yyyy"
          value={form.date}
          onChange={(item) => setForm('date', item)}
        />
      </div>

      <div className={cls.row}>
        <AsyncSelect
          placeholder="Location"
          loadOptions={(e) => locationOpt(e)}
          cacheOptions
          defaultOptions
          onChange={(item) => setForm('loc', item)}
          className={clsx(cls.input, cls.bigInput)}
        />
      </div>

      <div className={cls.rowCenter}>
        <span className={cls.text}>Preference</span>
        <div
          className={cls.rowRound}
          onClick={() => setForm('prefrence', !form.prefrence)}>
          <span className={clsx(cls.text2, !form.prefrence && cls.active)}>
            Location
          </span>
          <span className={clsx(cls.text2, form.prefrence && cls.active)}>
            Number
          </span>
        </div>
      </div>

      <div className={cls.rowCenter}>
        <span className={cls.text}>WE WILL FIND YOU THE BEST RESULT</span>
      </div>
      <div className={cls.rowCenter}>
        <Button
          className={cls.find}
          endIcon={<TrendingFlatRounded className={cls.findIcon} />}
          onClick={Detail}>
          Search
        </Button>
      </div>
    </div>
  );
};
