import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    padding: '10px 0',
    boxSizing: 'border-box',
    width: 360,
    height: '100%',
    boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 10,
    // position: 'absolute',
    // right: '9vw',
    zIndex: 10,
    // top: 110,
    '@media (max-width:500px)': {
      width: '100%',
    },
  },
  header: {
    margin: '0 20px',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 20px',
    overflowY: 'auto',
    height: '100%',
  },
  search: {
    marginBottom: 10,
  },
  icon: {
    color: '#9f9f9f',
    transform: 'rotate(90deg)',
  },
  row: {
    margin: '10px 0',
    display: 'flex',
    fontSize: 14,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1.6,
    color: '#9f9f9f',
    '& > span': {
      borderRight: '1px solid #9f9f9f',
      padding: '0 5px',
      cursor: 'pointer',
    },
  },
  noBorder: {
    borderRight: 'none !important',
  },
  loading: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
