import React, { useEffect, useState } from 'react';
import styles from './styles';
import {
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { adminSetTmsMailInfo } from '@/actions';
import { FormControl, Radio, RadioGroup } from '@material-ui/core';

export default ({ notify, selectedCompany, DATA }) => {
  const cls = styles();
  const [tmsAlias, setTmsAlias] = useState({
    checked: false,
    value: '',
  });
  const [state, setState] = useState({
    tms_mail_subject: '',
    tms_mail_subject_contain: null,
    tsm_mail_address: '',
    tms_manual_alias: '',
    plain_mail: false,
    mail_pattern: '',
  });

  const handleSendForm = () => {
    let apiData = {
      tms_mail_subject: state.tms_mail_subject,
      tsm_mail_address: state.tsm_mail_address,
    };
    if (state.tms_mail_subject_contain) {
      apiData.tms_mail_subject_contain =
        state.tms_mail_subject_contain === 'Contain' ? 1 : 0;
    }
    if (state.plain_mail) {
      apiData.plain_mail = state.plain_mail;
    }
    if (state.mail_pattern) {
      apiData.mail_pattern = state.mail_pattern;
    }
    if (state.tms_manual_alias) {
      apiData.tms_manual_alias = state.tms_manual_alias;
    }
    adminSetTmsMailInfo(apiData, selectedCompany.value).then((data) => {
      notify('Successfully Saved');
    });
  };

  const handleChangeInput = (name, value) => {
    setState((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (DATA) {
      setState({
        tms_mail_subject: DATA.tms_mail_subject,
        tsm_mail_address: DATA.tsm_mail_address,
        plain_mail: DATA.plain_mail,
        mail_pattern: DATA.mail_pattern,
        tms_manual_alias: DATA.tms_manual_alias,
        tms_mail_subject_contain:
          DATA.tms_mail_subject_contain === 0 ||
          DATA.tms_mail_subject_contain === 1
            ? DATA.tms_mail_subject_contain === 1
              ? 'Contain'
              : 'Exact'
            : null,
      });
    }
  }, [DATA]);

  return (
    <div className={cls.card}>
      <div className={cls.popup}>
        <div className={cls.popupCard}>
          <div className={cls.inputContainer}>
            <span>From:</span>
            <TextField
              type="text"
              placeholder="From"
              className={cls.input}
              InputProps={{ disableUnderline: true }}
              inputProps={{ min: 0 }}
              value={state.tsm_mail_address}
              onChange={(e) =>
                handleChangeInput('tsm_mail_address', e.target.value)
              }
            />
          </div>
          <div className={cls.inputContainer}>
            <span>Subject:</span>
            <TextField
              type="text"
              placeholder="Subject"
              className={cls.input}
              InputProps={{ disableUnderline: true }}
              inputProps={{ min: 0 }}
              value={state.tms_mail_subject}
              onChange={(e) =>
                handleChangeInput('tms_mail_subject', e.target.value)
              }
            />
          </div>
          <div>
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={state.tms_mail_subject_contain}
                className={cls.mailCheck}
                onChange={(e) => {
                  setState((prev) => {
                    return {
                      ...prev,
                      tms_mail_subject_contain: e.target.value,
                    };
                  });
                }}>
                <FormControlLabel
                  value={'Contain'}
                  control={<Radio />}
                  label={'Contain'}
                />
                <FormControlLabel
                  value={'Exact'}
                  control={<Radio />}
                  label={'Exact'}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div>
            <div style={{ marginTop: '20px' }}>
              <div className={cls.inputContainer}>
                <span>TMS Alias:</span>
                <div className={cls.inputContainer}>
                  <TextField
                    type="text"
                    placeholder="TMS Alias"
                    className={cls.input}
                    InputProps={{ disableUnderline: true }}
                    defaultValue={state.tms_manual_alias}
                    value={state.tms_manual_alias}
                    onChange={(e) =>
                      handleChangeInput('tms_manual_alias', e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
            <div className={cls.optionContainer}>
              <span className={cls.mondayCheck}>Monday Emails:</span>
              <FormControlLabel
                style={{ margin: 0 }}
                className={`${
                  state.plain_mail
                    ? cls.enabledContainer
                    : cls.disabledContainer
                }`}
                control={
                  <Checkbox
                    checked={state.plain_mail ? true : false}
                    onChange={(e) =>
                      setState((prev) => {
                        return {
                          ...prev,
                          plain_mail: e.target.checked,
                        };
                      })
                    }
                  />
                }
              />
            </div>
            <div className={cls.inputContainer}>
              <span>Monday Email Pattern:</span>
              <TextField
                type="text"
                placeholder="Monday Email Pattern"
                className={cls.input}
                InputProps={{ disableUnderline: true }}
                inputProps={{ min: 0 }}
                value={state.mail_pattern}
                onChange={(e) =>
                  handleChangeInput('mail_pattern', e.target.value)
                }
              />
            </div>
          </div>
          <div className={cls.btnContainer}>
            <Button onClick={() => handleSendForm()}>Save</Button>
          </div>
        </div>
      </div>
    </div>
  );
};
