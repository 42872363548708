import React, { useState } from 'react';
import { useNotify, Loading } from 'react-admin';
import { Profile, Form, AvatarInput } from '@/components/Profile';
import { noScroll, removeNoScroll } from '@/actions/Helper';
import {
  getProfile,
  upProfile,
  addAvatar,
  SaveData,
  changePassword,
  getChassis,
} from '@/actions';
import styles from './styles';
import { Button } from '@material-ui/core';

export default (props) => {
  const cls = styles();
  const [state, setState] = React.useState({
    DATA: {},
    loading: true,
    popUp: false,
  });
  const [chassis, setChassis] = useState({
    DATA: [],
    loading: true,
    popUp: false,
  });
  const [pageContent, setPageContent] = useState('profileCard');

  const notify = useNotify();

  React.useEffect(() => {
    getProfile()
      .then((DATA) => setState({ DATA, loading: false }))
      .catch((err) => notify(err, 'warning'));
    getChassis()
      .then((DATA) => setChassis({ DATA, loading: false }))
      .catch((err) => notify(err, 'warning'));
  }, [notify]);

  const ChangePass = ({ old_password, new_password }) => {
    setState({ loading: true });
    let req = {
      old_password,
      new_password,
      new_password_confirmation: new_password,
    };
    changePassword(req)
      .then((data) => {
        setState({ ...state, loading: false });
        notify('Your profile password updated successfully.');
      })
      .catch((msg) => {
        setState({ ...state, loading: false });
        notify(msg, 'warning');
      });
  };

  const Update = (form) => {
    setState({ loading: true });
    let t = {
      ...form,
      yard_start: form.yard_start
        ? `${form.yard_start.hour()}:${form.yard_start.minutes()}`
        : '',
      yard_end: form.yard_end
        ? `${form.yard_end.hour()}:${form.yard_end.minutes()}`
        : '',
    };
    upProfile(t)
      .then((DATA) => {
        setState({ DATA, loading: false });
        SaveData({ user: DATA });
        notify('Profile updated successfully.');
      })
      .catch((msg) => {
        setState({ ...state, loading: false });
        notify(msg, 'warning');
      });
  };

  const Upload = (image) => {
    setState({ loading: true, popUp: false });
    addAvatar(image)
      .then((DATA) => {
        setState({ DATA, loading: false });
        notify('Profile updated successfully.');
      })
      .catch((msg) => {
        setState({ ...state, loading: false });
        notify(msg, 'warning');
      });
  };

  const openPopUp = () => {
    noScroll();
    setState({ ...state, popUp: true });
  };

  const closePopup = () => {
    removeNoScroll();
    setState({ ...state, popUp: false });
  };

  if (state.loading) return <Loading />;

  return (
    <div className={cls.content}>
      {state.popUp && (
        <AvatarInput Close={closePopup} Upload={Upload} data={state.DATA} />
      )}
      <div className={cls.pageContent}>
        <div className={cls.title}>
          User profile{' '}
          {pageContent === 'profileCard' ? (
            <Button className={cls.btn} onClick={() => setPageContent('edit')}>
              {'Edit'}
            </Button>
          ) : (
            <Button
              className={cls.btn}
              onClick={() => setPageContent('profileCard')}>
              {'Profile'}
            </Button>
          )}
        </div>
        {pageContent === 'profileCard' && (
          <div className={cls.flex3}>
            <Profile
              chassis={chassis}
              setChassis={setChassis}
              notify={notify}
              data={state.DATA}
              openPopUp={openPopUp}
            />
          </div>
        )}
        {pageContent === 'edit' && (
          <div className={cls.flex4}>
            <Form
              chassis={chassis}
              setChassis={setChassis}
              data={state.DATA}
              Update={Update}
              ChangePass={ChangePass}
            />
          </div>
        )}
      </div>
    </div>
  );
};
