import React from 'react';
import { useNotify } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Form from './Form';
import HomeLayout from '@/components/HomeLayout';
import { ContactBg } from '@/assets';
import RouteChangeTracker from '@/components/RouteChangeTracker';

const styles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f5f5f5',
    flex: 1,
  },
  flex3: {
    display: 'flex',
    flex: 3,
  },
  flex2: {
    display: 'flex',
    flex: 2,
    backgroundImage: `url(${ContactBg})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    ['@media (max-width:960px)']: {
      display: 'none',
    },
  },
});

export default (props) => {
  const cls = styles();

  const notify = useNotify();

  return (
    <HomeLayout className={cls.content}>
      <RouteChangeTracker />
      <div className={cls.flex2} />
      <div className={cls.flex3}>
        <Form notify={notify} />
      </div>
    </HomeLayout>
  );
};
