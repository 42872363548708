import React, { useEffect, useState } from 'react';
import { Card, Button, Avatar, CircularProgress } from '@material-ui/core';
import { AccountCircle, HomeRounded } from '@material-ui/icons';
import { CloseRounded } from '@material-ui/icons';
import clsx from 'clsx';
import { fTimeDate } from '@/actions/Helper';
import styles from './styles';
import { Tooltip } from '@material-ui/core';
import {
  exportOrderApprove,
  ExportOrderConfirm,
  orderApprove,
  orderConfirm,
  SaveData,
} from '@/actions';
import { useNotify } from 'react-admin';

export default ({ DATA = {}, RefreshData, Chat }) => {
  const cls = styles();
  const [file, setFile] = useState(null);
  const [modal, setModal] = useState(null);
  const [status, setStatus] = useState('success');
  const [showView, setsShowView] = useState(false);
  const [loading, setLoading] = useState(false);

  const notify = useNotify();

  const handleUploadFile = (uploadedFile, mode) => {
    // setModal('upload');
    setFile(uploadedFile);
    uploadFile(uploadedFile, mode);
  };

  const openPopup = (mode) => {
    SaveData({ Chat: { id: DATA.id, type: 'archive', mode }, ChatItem: null });
  };

  const uploadFile = (uploadedFile, mode) => {
    setLoading(true);
    let formData = new FormData();
    formData.append('document', uploadedFile);
    if (mode === 'empty') {
      orderApprove(formData, DATA.id)
        .then((res) => {
          if (res) {
            setsShowView(true);
            setLoading(false);
            notify('file successfully uploaded', 'success');
          }
        })
        .catch((err) => {
          notify(err);
          setLoading(false);
        });
    } else if (mode === 'export') {
      exportOrderApprove(formData, DATA.id)
        .then((res) => {
          if (res) {
            setsShowView(true);
            setLoading(false);
            notify('file successfully uploaded', 'success');
          }
        })
        .catch((err) => {
          notify(err);
          setLoading(false);
        });
    }
  };

  const handleConfirm = () => {
    setModal('Confirm');
  };

  const handleDispute = () => {
    setModal('Dispute');
  };

  const confirm = (isConfirm) => {
    if (DATA.mode === 'empty') {
      orderConfirm(DATA.id, { accept: isConfirm }).then((res) => {
        if (res) {
          notify(
            `successfully ${isConfirm ? 'confirmed' : 'disputed'} `,
            'success'
          );
          isConfirm && setStatus('success');
          !isConfirm && setStatus('error');
          setModal(null);
          RefreshData();
        }
      });
    } else if (DATA.mode === 'export') {
      ExportOrderConfirm(DATA.id, { accept: isConfirm }).then((res) => {
        if (res) {
          notify(
            `successfully ${isConfirm ? 'confirmed' : 'disputed'} `,
            'success'
          );
          isConfirm && setStatus('success');
          !isConfirm && setStatus('error');
          setModal(null);
          RefreshData();
        }
      });
    }
  };

  useEffect(() => {
    if (DATA.seller_confirm === '1' && DATA.buyer_confirm === '1') {
      setStatus('success');
    }
    if (DATA.seller_confirm === '0' || DATA.buyer_confirm === '0') {
      setStatus('error');
    } else {
      setStatus('success');
    }
  }, [DATA]);

  return (
    <Card className={cls.card}>
      {modal && (
        <div className={cls.popup}>
          <div className={cls.popUpCard}>
            <Tooltip arrow title="Close" placement="top">
              <CloseRounded
                className={cls.close}
                onClick={() => setModal(null)}
              />
            </Tooltip>
            <div>
              {modal === 'Dispute' && (
                <div>
                  <p>Are you sure that you want to Dispute it?</p>
                  <div className={cls.actionContainer}>
                    <button
                      onClick={() => {
                        setModal(null);
                        setStatus('error');
                      }}
                      className={cls.noBtn}>
                      No
                    </button>
                    <button
                      onClick={() => {
                        confirm(false);
                      }}
                      className={cls.yesBtn}>
                      Yes
                    </button>
                  </div>
                </div>
              )}
              {modal === 'Confirm' && (
                <div>
                  <div>
                    <p>Are you sure that you want to Confirm it?</p>
                    <div className={cls.actionContainer}>
                      <button
                        onClick={() => {
                          setModal(null);
                        }}
                        className={cls.noBtn}>
                        No
                      </button>
                      <button
                        onClick={() => {
                          setModal(null);
                          confirm(true);
                        }}
                        className={cls.yesBtn}>
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {modal === 'upload' && (
                <div>
                  <div>
                    <p>Are you sure that you want to upload this file?</p>
                    <a
                      className={cls.file}
                      target="_blank"
                      href={URL.createObjectURL(file)}>
                      See Uploaded Document
                    </a>
                    <div className={cls.actionContainer}>
                      <button
                        onClick={() => {
                          setModal(null);
                          setFile(null);
                        }}
                        className={cls.noBtn}>
                        No
                      </button>
                      <button
                        onClick={() => {
                          setModal(null);
                          uploadFile();
                        }}
                        className={cls.yesBtn}>
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          background: status === 'error' ? '#e4464b' : '#44a570',
        }}
        className={cls.header}>
        <span className={cls.bold}>
          {DATA.mode.charAt(0).toUpperCase() + DATA.mode.slice(1)}
        </span>
        <span className={cls.light}>ID: {DATA.id}</span>
      </div>
      <div className={cls.content}>
        <div className={cls.cardData}>
          <div className={clsx(cls.row, cls.margin)}>
            <div className={clsx(cls.row, cls.margin)}>
              <div className={cls.row2}>
                <Avatar
                  className={cls.avatar}
                  src={DATA.avatar}
                  alt={DATA.user}
                />
                <div>
                  <div className={cls.row2}>
                    <AccountCircle className={cls.icon} />
                    <span className={cls.light2}>{DATA.user}</span>
                  </div>
                  <div className={cls.row2}>
                    <HomeRounded className={cls.icon} />
                    <span className={cls.light2}>{DATA.company}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={cls.flex}>
              <div className={cls.bigText}>{DATA.acc}</div>
              <div className={cls.avText2}>Nu</div>
            </div>
          </div>

          <div className={cls.row}>
            <span>Date:</span>
            <span>{fTimeDate(DATA.date)}</span>
          </div>
          <div className={cls.row}>
            <span>Location:</span>
            <span>{DATA.loc}</span>
          </div>
          <div className={cls.row}>
            <span>Size:</span>
            <span>{DATA.size}</span>
          </div>

          <div className={cls.row}>
            <span>SSL:</span>
            <span>{DATA.ssl}</span>
          </div>

          <div className={clsx(cls.hr, cls.margin)} />

          <div className={clsx(cls.row, cls.margin)}>
            <span className={cls.stat}>Offered Number</span>
            <span className={cls.numText}>{DATA.req}</span>
          </div>

          <div className={clsx(cls.hr, cls.margin)} />

          <div className={cls.approvalDiv}>
            <span className={cls.stat}>Approval</span>
            <div className={clsx(cls.row, cls.margin)}>
              <span>Upload Agreement</span>
              <span className={clsx(cls.numText, cls.uploadBtn)}>
                {(DATA.buyer_confirm !== '0' ||
                  DATA.seller_confirm !== '0') && (
                  <>
                    <input
                      onChange={(e) =>
                        handleUploadFile(e.target.files[0], DATA.mode)
                      }
                      type="file"
                      accept="image/png, image/jpeg, application/pdf, application/msword"
                      id={DATA.id}
                    />
                    <label className={cls.uploadLabel} htmlFor={DATA.id}>
                      {loading ? (
                        <CircularProgress className={cls.loader} />
                      ) : (
                        'Upload'
                      )}
                    </label>
                  </>
                )}

                <Button disabled={!DATA.seller_approve && !file}>
                  {DATA.seller_approve || file ? (
                    <a
                      style={{ width: '100%', height: '100%' }}
                      href={DATA.seller_approve || URL.createObjectURL(file)}
                      target="_blank">
                      View
                    </a>
                  ) : (
                    <span>View</span>
                  )}
                </Button>
              </span>
            </div>

            <div className={clsx(cls.row, cls.margin)}>
              <span>View Other Party's Agreement</span>
              <span className={cls.numText}>
                <Button disabled={DATA.buyer_approve ? false : true}>
                  {DATA.buyer_approve ? (
                    <a
                      style={{ width: '100%', height: '100%' }}
                      href={DATA.buyer_approve}
                      target="_blank">
                      View
                    </a>
                  ) : (
                    <>View</>
                  )}
                </Button>
              </span>
            </div>
          </div>
          <div className={clsx(cls.hr, cls.margin)} />
        </div>

        <div className={cls.statusContainer}>
          {DATA.seller_confirm !== '0' && DATA.buyer_confirm !== '0' && (
            <div className={cls.butCont}>
              <Button
                className={clsx(cls.but, cls.but2)}
                onClick={() => confirm(false)}>
                Dispute
              </Button>
              <Button
                className={clsx(cls.but, cls.but3)}
                onClick={() => openPopup(DATA.mode)}>
                Chat
              </Button>

              {/* <div className={cls.confirm_container}>
                <Button
                  className={clsx(cls.but, cls.but3)}
                  onClick={() => handleConfirm()}>
                  Confirm
                </Button>
              </div> */}
            </div>
          )}
          {/* {DATA.seller_confirm === '1' && DATA.buyer_confirm === '1' && (
            <p
              style={{
                textAlign: 'center',
                color: 'white',
                marginTop: 5,
                background: 'rgb(68, 165, 112)',
              }}>
              Confirmed
            </p>
          )}
          {DATA.seller_confirm === '1' && DATA.buyer_confirm === null && (
            <p
              style={{
                textAlign: 'center',
                color: 'blak',
                marginTop: 5,
                background: '#cacaca',
              }}>
              Waiting for other side to confirm
            </p>
          )}
          {DATA.buyer_confirm === '1' && DATA.seller_confirm === null && (
            <p
              style={{
                textAlign: 'center',
                color: 'blak',
                background: '#cacaca',
              }}>
              The other party has confirmed the deal. Please either Confirm or
              Dispute the deal.
            </p>
          )} */}
          {(DATA.seller_confirm === '0' || DATA.buyer_confirm === '0') && (
            <div className={cls.butCont}>
              <p
                style={{
                  textAlign: 'center',
                  color: 'white',
                  background: 'rgb(228, 70, 75)',
                  height: 30,
                  fontSize: 10,
                  padding: 0,
                  fontFamily: 'Averta',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: 6,
                  flex: '1',
                }}>
                Disputed
              </p>
              <Button
                className={clsx(cls.but, cls.but3)}
                onClick={() => openPopup()}>
                Chat
              </Button>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};
