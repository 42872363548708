import React from 'react';
import { Card, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import { genSize, _get, SizeIcons } from '@/actions/Helper';
import { getInventory } from '@/actions';

import styles from './styles';

const parseData = (data) =>
  data.map((i) => ({
    count: i.count,
    size: genSize(_get(i, 'cabin.specifications', {})),
  }));

const getTotal = (data) => data.reduce((p, n) => n.count + p, 0);

// const Types = ['Today', 'Week', 'Month', 'Year'];

export default (props) => {
  const cls = styles();

  // const [active, setActive] = React.useState(0);
  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });

  React.useEffect(() => {
    getInventory()
      .then((res) => {
        setState({ DATA: parseData(res.slice(0, 4)), loading: false });
      })
      .catch(() => setState({ DATA: [], loading: false }));
  }, []);

  if (state.loading)
    return (
      <Card className={clsx(cls.card, cls.center)}>
        <CircularProgress />
      </Card>
    );

  return (
    <Card className={cls.card}>
      {/* <div className={cls.typeWrapper}>
        {Types.map((i, idx) => (
          <div
            key={idx}
            onClick={() => setActive(idx)}
            className={clsx(cls.type, active === idx ? cls.typeActive : '')}>
            {i}
          </div>
        ))}
      </div> */}

      <div className={cls.content}>
        <div className={cls.title}>
          <span>Inventories</span>
          <span className={cls.title2}>Total {getTotal(state.DATA)}</span>
        </div>

        <div className={cls.row}>
          {state.DATA.map((it, idx) => (
            <div className={cls.item} key={idx}>
              <span className={cls.itemTitle}>{it.size}</span>
              <ReactSVG src={SizeIcons(it.size)} className={cls.icon} />
              <span className={cls.itemDes}>{it.count}</span>
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};
