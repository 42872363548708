import React, { useEffect, useState } from 'react';
import styles from './styles';
import Promitions from './Promitions';
import {
  adminAddReferralProgram,
  adminEditReferralProgram,
  adminGetReferralPrograms,
} from '@/actions';
import Preview from './Preview';
import SettingsForm from './SettingsForm';
import { CloseRounded } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { Loading, useNotify } from 'react-admin';

export default () => {
  const [editPopup, setEditPopup] = useState(null);
  const [programs, setPRograms] = useState({
    DATA: [],
    loading: false,
  });
  const [content1, setContent1] = useState('');
  const [content2, setContent2] = useState('');
  const [editingContent1, setEditingContent1] = useState('');
  const [editingContent2, setEditingContent2] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [editingFormData, setEditingFormData] = useState({
    start_date: new Date(),
    end_date: new Date(),
    price: '',
    name: '',
  });
  const [formData, setFormData] = useState({
    start_date: new Date(),
    end_date: new Date(),
    price: '',
    name: '',
  });

  const cls = styles();

  const notify = useNotify();

  const getReferralPrograms = () => {
    setPRograms((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    adminGetReferralPrograms()
      .then((response) => {
        setPRograms((prev) => {
          return {
            loading: false,
            DATA: response.data,
          };
        });
      })
      .catch((err) => {
        setPRograms((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
  };

  useEffect(() => {
    getReferralPrograms();
  }, []);

  useEffect(() => {
    if (editPopup) {
      setEditingContent1(editPopup.content1);
      setEditingContent2(editPopup.content2);
      setEditingFormData({
        start_date: editPopup.start_date,
        end_date: editPopup.end_date,
        price: editPopup.price,
        name: editPopup.name,
      });
    }
  }, [editPopup]);

  const handleAddReferral = () => {
    const data = {
      start_date: formData.start_date,
      end_date: formData.end_date,
      price: formData.price,
      name: formData.name,
      content1,
      content2,
    };
    adminAddReferralProgram(data)
      .then((res) => {
        getReferralPrograms();
        notify('saccessfully added');
      })
      .catch((err) => {
        notify(err);
      });
  };

  const handleEditReferral = () => {
    console.log(editingFormData);
    const data = {
      start_date: editingFormData.start_date,
      end_date: editingFormData.end_date,
      price: editingFormData.price,
      name: editingFormData.name,
      content1: editingContent1,
      content2: editingContent2,
      _method: 'patch',
    };
    adminEditReferralProgram(editPopup.id, data)
      .then((res) => {
        getReferralPrograms();
        notify('saccessfully updated');
        setEditPopup(null);
      })
      .catch((err) => {
        notify(err);
      });
  };

  return (
    <div>
      {showPreview ? (
        <Preview
          content1={showPreview === 'add' ? content1 : editingContent1}
          content2={showPreview === 'add' ? content2 : editingContent2}
          setShowPreview={setShowPreview}
        />
      ) : (
        <>
          {' '}
          {editPopup && (
            <div className={cls.popup}>
              <div className={cls.popupCard}>
                <Tooltip arrow title="Close" placement="top">
                  <CloseRounded
                    className={cls.close}
                    onClick={() => setEditPopup(null)}
                  />
                </Tooltip>
                <SettingsForm
                  content1={editingContent1}
                  setContent1={setEditingContent1}
                  content2={editingContent2}
                  setContent2={setEditingContent2}
                  showPreview={showPreview}
                  setShowPreview={setShowPreview}
                  formData={editingFormData}
                  setFormData={setEditingFormData}
                  handleSendForm={handleEditReferral}
                  previewType="edit"
                  btnText="Save"
                />
              </div>
            </div>
          )}
          <div className={cls.card}>
            <SettingsForm
              content1={content1}
              setContent1={setContent1}
              content2={content2}
              setContent2={setContent2}
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              formData={formData}
              setFormData={setFormData}
              handleSendForm={handleAddReferral}
              previewType="add"
              btnText="Add"
            />
          </div>
          {programs.loading ? (
            <Loading />
          ) : (
            <Promitions
              DATA={programs.DATA}
              setEditPopup={setEditPopup}
              getReferralPrograms={getReferralPrograms}
              notify={notify}
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              setEditingContent1={setEditingContent1}
              setEditingContent2={setEditingContent2}
            />
          )}
        </>
      )}
    </div>
  );
};
