import { getShareTokenInfo, shareLinkClick } from '@/actions';
import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import logo from '../../assets/img/logo.png';
import styles from './styles';

export default () => {
  const params = useParams();
  const history = useHistory();

  const cls = styles();

  const handleShareLinkClick = (token) => {
    const user = localStorage.getItem('userID');
    shareLinkClick(token)
      .then((res) => {
        if (res.message !== 'login' && res.message !== 'register') {
          history.push('/requests/active');
        } else if (
          res.message === 'login' ||
          (res.message === 'register' && user)
        ) {
          history.push('/login');
          localStorage.setItem('share_token', token);
        } else if (res.message === 'register' && !user) {
          getShareTokenInfo(token).then((res) => {
            const data = res.data;
            window.location.replace(
              `/?shared_company=${data.shared_company}&shared_city=${data.shared_city}&shared_number=${data.shared_number}&shared_size_type=${data.shared_size_type}&shared_ssl=${data.shared_ssl}&shared_date_hour=${data.shared_date_hour}&shared_container_number=${data.shared_container_number}&email=${data.email}&shared_token=${data.shared_token}#/register`
            );
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const token = params.token;
    handleShareLinkClick(token);
  }, []);

  return (
    <div className={cls.container}>
      <div className={cls.loaderContainer}>
        <img alt="" src={logo} />
        <CircularProgress className={cls.loader} />
        <div className={cls.text}>please wait..</div>
      </div>
    </div>
  );
};
