import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  chatFooter: {
    width: '100%',
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    borderTop: '1px solid #000000',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '14px',
    boxSizing: 'border-box',
    '& input': {
      border: 'none',
      background: 'transparent',
      flex: '1',
      '&:focus-visible': {
        border: 'none !important',
        outline: 'none',
      },
    },
  },
  actions: {
    display: 'flex',
  },
  sendBtn: {
      background: '#F9C031',
      width: '12px',
      height: '12px',
      padding: '4px',
      borderRadius: '2px',
  },
  emoji: {
      width: '16px',
      height: '14.98px',
      margin: '5px',
  },
  file: {
      width: '9.25px',
      height: '16.5px',
      margin: '5px 8px',
  },
}));
