import React, { useEffect, useState } from 'react';
import styles from '../styles';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import DateInput from '@/components/DateInput';
import clsx from 'clsx';
import { Grid, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { locationOpt } from '@/actions/Helper';
import { adminAddInventory } from '@/actions';
import { Loading, useNotify } from 'react-admin';

export default ({ title, getInventories, users, setPopup }) => {
  const cls = styles();
  const selectOp = useSelector((state) => state.reducer);
  const [showContainers, setShowContainers] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    date: new Date(),
    count: '',
    loc: null,
    // request: '',
    // order: '',
    containers: [],
    user_id: '',
  });

  const setForm = (it, val) => setFormData({ ...form, [it]: val });

  const Add = () => {
    for (let el in form) {
      if (!form[el]) {
        notify('Can not add, Please check fields.', 'warning');
        return;
      }
    }
    setLoading(true);

    adminAddInventory(form)
      .then((res) => {
        notify('Added Successfully.');
        getInventories();
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  };

  const setFormContainers = () => {
    let containers = [];
    for (let i = 0; i < form.count; i++) {
      if (form.containers[i]) {
        containers.push(form.containers[i]);
      } else {
        containers.push('');
      }
    }
    setForm('containers', containers);
  };

  const handleResetForm = () => {
    setFormData((prev) => {
      return {
        size: null,
        ssl: null,
        date: new Date(),
        count: '',
        loc: null,
        containers: [],
        user_id: '',
      };
    });
  };

  useEffect(() => {
    setFormContainers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.count]);

  return loading ? (
    <Loading />
  ) : (
    <>
      <div>
        <div className={cls.titleContainer}>
          <h2 className={cls.title}>{title}</h2>
          <div onClick={() => setPopup('import')} className={cls.importSection}>
            <span className={cls.import}>Import</span>
            <span className={cls.xlsxTitle}>XLSX</span>
          </div>
        </div>
        <div className={cls.card}>
          <Grid container spacing={2} className={cls.row}>
            <Grid item xs={12} sm={4} lg={2}>
              <Select
                placeholder="Size"
                className={cls.select}
                value={form.size}
                onChange={(item) => setForm('size', item)}
                options={selectOp.size}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={2}>
              <Select
                placeholder="SSL"
                className={clsx(cls.select, cls.ml15)}
                value={form.ssl}
                onChange={(item) => setForm('ssl', item)}
                options={selectOp.ssl}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={2}>
              <TextField
                type="number"
                placeholder="Number"
                className={cls.textInput}
                InputProps={{ disableUnderline: true }}
                inputProps={{ min: 1 }}
                value={form.count}
                onChange={(e) => setForm('count', e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <AsyncSelect
                placeholder="Location"
                className={cls.loc}
                value={form.loc}
                loadOptions={(e) => locationOpt(e)}
                onChange={(item) => setForm('loc', item)}
                cacheOptions
                defaultOptions
              />
            </Grid>

            <Grid item xs={12} sm={6} lg={3}>
              <DateInput
                placeholder="Available date"
                className={cls.date}
                format="MM/dd/yyyy"
                value={form.date}
                onChange={(item) => setForm('date', item)}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={2}>
              <Select
                placeholder="Company"
                className={clsx(cls.select, cls.ml15)}
                value={form.user_id}
                onChange={(item) => setForm('user_id', item)}
                options={users}
              />
            </Grid>

            {/* <Grid item xs={12} sm={4} lg={2}>
            <Select
              placeholder="Request"
              className={clsx(cls.select, cls.ml15)}
              value={form.request}
              onChange={(item) => setForm('request', item)}
              // options={selectOp.ssl}
            />
          </Grid>
          <Grid item xs={12} sm={4} lg={2}>
            <Select
              placeholder="Order"
              className={clsx(cls.select, cls.ml15)}
              value={form.order}
              onChange={(item) => setForm('order', item)}
              // options={selectOp.ssl}
            />
          </Grid> */}

            <Grid item xs={12}>
              <Grid xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      className={cls.checkbox}
                      onChange={() => setShowContainers(!showContainers)}
                    />
                  }
                  label="Show Containers"
                />
              </Grid>
              <Grid container spacing={2}>
                {showContainers &&
                  form.containers.map((item, i) => (
                    <Grid key={i} item xs={12} sm={6} lg={3}>
                      <TextField
                        type="text"
                        placeholder={`Container ${i + 1}`}
                        className={cls.textInput}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ min: 1 }}
                        value={form.containers[i].name}
                        onChange={(e) => {
                          setForm('containers', [
                            ...form.containers.slice(0, i),
                            {
                              ...form.containers[i],
                              name: e.target.value,
                            },
                            ...form.containers.slice(i + 1),
                          ]);
                        }}
                      />
                      <TextField
                        type="text"
                        placeholder={`Chassis ${i + 1}`}
                        className={cls.textInput}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ min: 1 }}
                        value={form.containers[i].chases}
                        onChange={(e) => {
                          setForm('containers', [
                            ...form.containers.slice(0, i),
                            {
                              ...form.containers[i],
                              chases: e.target.value,
                            },
                            ...form.containers.slice(i + 1),
                          ]);
                        }}
                      />
                    </Grid>
                  ))}
              </Grid>
            </Grid>
            <div className={cls.actions}>
              <button
                onClick={() => handleResetForm()}
                className={cls.resetBtn}>
                Reset
              </button>
              <button onClick={() => Add()} className={cls.addBtn}>
                Add
              </button>
            </div>
          </Grid>
        </div>
      </div>
    </>
  );
};
