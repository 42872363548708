import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '25px 10px',
  },
  loading: {
    display: 'flex',
    height: 450,
    alignItems: 'center',
    justifyContent: 'center',
  },
  card: {
    flex: 1,
    display: 'flex',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    height: 450,
    overflow: 'auto',
    boxSizing: 'border-box',
    width:'100%',
  },

  header: {
    '& tr': {
      background: 'linear-gradient(99deg, #f8b100 2%, #ffc27d)',
    },
  },
  table: {
    minWidth:700,
    '& th': {
      fontSize: '0.7rem',
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      color: '#303030',
      padding: '8px 5px',
      textAlign: 'center',
      border: 'none',
      backgroundColor: 'transparent',
      [BP.up('xl')]: {
        fontSize: 10,
      },
    },
    '& td': {
      fontSize: 10,
      fontFamily: 'Averta Light',
      fontWeight: 300,
      color: '#303030',
      padding: '10px 0',
      textAlign: 'center',
      border: 'none',
      [BP.up('xl')]: {
        fontSize: 10,
        padding: '15px 0',
      },
    },
    '& tr': {
      fontSize: 10,
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      color: '#303030',
      textAlign: 'center',
      [BP.up('xl')]: {
        fontSize: 10,
      },
    },
  },
  detailRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > svg': {
      height: 20,
      width: 20,
      fill: '#f8b100',
      margin: '0 6px',
    },
  },

  separator: {
    height: 10,
  },

  detailCard: {
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    boxSizing: 'border-box',
    backgroundColor: '#fff',
  },
  detailHeader: {
    display: 'flex',
    background: 'linear-gradient(99deg, #f8b100 2%, #ffc27d)',
    alignItems: 'center',
    justifyContent: 'center',
    height: 40,
    borderRadius: '5px 5px 0 0',
    fontFamily: 'Averta Bold',
    fontSize: 18,
    fontWeight: 'bold',
  },
  detailContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: '30px 40px',
  },
  detailItem: {
    color: '#303030',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Averta Bold',
    fontSize: 14,
    fontWeight: 'bold',
    margin: '8px 0',
    minWidth: 220,
  },
  exportRow: {
    display: 'flex',
    color: '#303030',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    fontSize: 20,
    marginTop: 25,
  },

  blueRow: {
    color: '#007fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& svg': {
      marginLeft: 15,
      cursor: 'pointer',
    },
    '& a': {
      color: '#007fff',
      textDecoration: 'none',
    },
  },
}));
