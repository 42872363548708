import React from 'react';
import { Card, CircularProgress } from '@material-ui/core';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { ReactSVG } from 'react-svg';
import { genSize, _get, SizeIcons } from '@/actions/Helper';
import { getInventory } from '@/actions';

import styles from './styles';

const parseData = (data) =>
  data.map((i) => ({
    count: i.count,
    size: genSize(_get(i, 'cabin.specifications', {})),
  }));

export default (props) => {
  const cls = styles();
  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });

  React.useEffect(() => {
    getInventory()
      .then((res) => {
        setState({ DATA: parseData(res.slice(0, 4)), loading: false });
      })
      .catch(() => setState({ DATA: [], loading: false }));
  }, []);

  if (state.loading)
    return (
      <Card className={clsx(cls.card, cls.center)}>
        <CircularProgress />
      </Card>
    );

  return (
    <Card className={cls.card}>
      <div className={clsx(cls.row)}>
        <span className={cls.title}>Inventories</span>
        <Link to="/inventory" className={cls.see}>
          See More
        </Link>
      </div>

      <div className={cls.row2}>
        {state.DATA.map((it, idx) => (
          <div className={cls.item} key={idx}>
            <span className={cls.itemTitle}>{it.size}</span>
            <ReactSVG src={SizeIcons(it.size)} className={cls.icon} />
            <span className={cls.itemDes}>{it.count}</span>
          </div>
        ))}
      </div>
    </Card>
  );
};
