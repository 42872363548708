import React, { useEffect, useState } from 'react';
import { Tooltip, Avatar, Button } from '@material-ui/core';
import {
  AccountCircle,
  RoomRounded,
  CallRounded,
  HomeRounded,
  MailRounded,
  CloseRounded,
} from '@material-ui/icons';
import _get from 'lodash/get';
import { noScroll, removeNoScroll } from '@/actions/Helper';
import { SaveData } from '@/actions';
import styles from './styles';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isValidEmail } from '@/actions/validations';

export default ({ data = {} }) => {
  const cls = styles();
  const [DATA, setData] = React.useState({
    avatar: null,
    name: '',
    com: '',
    phone: '',
    add: '',
    email: '',
    transferLink: [],
  });

  const [link, setLink] = useState(null);

  const ChatData = useSelector((state) => state.reducer.Chat || {});

  const { type } = ChatData;

  React.useEffect(() => {
    noScroll();
    return () => removeNoScroll();
  }, []);

  React.useEffect(() => {
    setData({
      avatar: _get(data, 'image', ''),
      name: _get(data, 'name', '') + ' ' + _get(data, 'last_name', ''),
      com: _get(data, 'company', ''),
      phone: _get(data, 'phone', ''),
      add: _get(data, 'address', ''),
      email: _get(data, 'email', ''),
      transferLink: _get(data, 'transferLink', ''),
    });
  }, [data]);

  const Close = () => SaveData({ ContactInfoPopup: null });

  useEffect(() => {
    if (ChatData.type) {
      if (ChatData.type == 'request') {
        setLink('/requests/complete');
      } else if (ChatData.type == 'order') {
        setLink('/orders/complete');
      } else {
        setLink('//');
      }
    }
  }, [ChatData]);

  return (
    <div className={cls.popup}>
      <div className={cls.card}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.close} onClick={() => Close()} />
        </Tooltip>
        <div className={cls.content}>
          <div className={cls.title}>
            Well Done! Thank you for using Smart-Turn. Here is the contact
            information of the other party.
          </div>
          <div style={{ padding: '0px 0 25px 0px' }}>
            <p>
              {typeof DATA.transferLink[0] !== 'object'
                ? 'If you are planning to street turn the empty container, you may use the emails below. Once confirmed, please upload the street turn approval receipt in the '
                : ' If you are planning to street turn the empty container, you may use the below link. Once confirmed, please upload the street turn approval receipt in the '}
              <Link
                id="forget"
                target="_blank"
                className={cls.forget}
                style={{ color: '#000000' }}
                // to='/requests/complete'
                to={link}>
                “completed”
              </Link>{' '}
              section. 
            </p>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {DATA.transferLink.map((link, index) => (
                <a
                  key={index}
                  target="_blank"
                  href={
                    typeof link !== 'object' ? `mailto: ${link}` : link.link
                  }>
                  <Button className={cls.transfer}>
                    {typeof link !== 'object' ? link : link.buttonText}
                  </Button>
                </a>
              ))}
            </div>
          </div>
          <div className={cls.row}>
            {DATA.avatar ? (
              <Avatar className={cls.icon} src={DATA.avatar} alt={DATA.name} />
            ) : (
              <AccountCircle className={cls.icon} />
            )}

            <span>{DATA.name}</span>
          </div>
          <div className={cls.row}>
            <HomeRounded className={cls.icon} />
            <span>{DATA.com}</span>
          </div>
          <div className={cls.row}>
            <CallRounded className={cls.icon} />
            <span>{DATA.phone}</span>
          </div>
          <div className={cls.row}>
            <MailRounded className={cls.icon} />
            <span>{DATA.email}</span>
          </div>
          <div className={cls.row}>
            <RoomRounded className={cls.icon} />
            <span>{DATA.add}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
