import React, { useState } from 'react';
import styles from './styles';
import AsyncSelect from 'react-select/async';
import { locationOpt } from '@/actions/Helper';
import close from '../../../assets/img/close.svg';
import { IconButton } from '@material-ui/core';

export default ({
  tableCities,
  setTableCities,
  pageContent,
  selectedCompany,
}) => {
  const cls = styles();

  const handleChangeCity = (name, value, index) => {
    const adaptedCities = [
      ...tableCities.slice(0, index),
      {
        ...tableCities[index],
        [name]: value,
      },
      ...tableCities.slice(index + 1),
    ];
    setTableCities(adaptedCities);
  };

  const handleAddNewCity = () => {
    setTableCities((prev) => {
      return [
        ...prev,
        {
          user: '',
          smart: null,
        },
      ];
    });
  };

  const handleRemoveCity = (index) => {
    const adaptedCities = [
      ...tableCities.slice(0, index),
      ...tableCities.slice(index + 1),
    ];

    setTableCities(adaptedCities);
  };

  return (
    <div className={cls.cityTable}>
      <h3 className={cls.header}>CITY NAMING</h3>
      <div style={{ width: '100%', overflow: 'auto' }}>
        <div className={cls.listContainer}>
          <table className={cls.list}>
            <thead>
              <th style={{ width: '50%' }}>USER</th>
              <th style={{ width: '40%' }}>SMART TURN</th>
              {pageContent.value !== 1 && pageContent.value !== 2 && (
                <th style={{ flex: 0.3 }}>Remove</th>
              )}
            </thead>
            <tbody>
              {tableCities.map((item, index) => (
                <tr>
                  <td style={{ flex: 0.7 }}>
                    {pageContent.value !== 1 && pageContent.value !== 2 ? (
                      <input
                        onChange={(e) =>
                          handleChangeCity(e.target.name, e.target.value, index)
                        }
                        type="text"
                        defaultValue={item.user}
                        value={item.user}
                        name="user"
                        style={{ textAlign: 'center' }}
                      />
                    ) : (
                      <span>{item.user}</span>
                    )}
                  </td>
                  <td style={{ flex: 1 }}>
                    {pageContent.value !== 1 && pageContent.value !== 2 ? (
                      <AsyncSelect
                        placeholder="Location"
                        classNamePrefix="react-select"
                        value={item.smart}
                        className={cls.loc}
                        // inputValue={item?.smart ? item?.smart : ''}
                        // displayedValue={item?.smart ? item?.smart : ''}
                        loadOptions={(e) => locationOpt(e)}
                        onChange={(itemm) =>
                          handleChangeCity('smart', itemm, index)
                        }
                        cacheOptions
                        defaultOptions
                      />
                    ) : (
                      <span>{item.smart?.label}</span>
                    )}
                  </td>
                  {pageContent.value !== 1 && pageContent.value !== 2 && (
                    <td style={{ flex: 0.3 }}>
                      <IconButton
                        onClick={() => {
                          handleRemoveCity(index);
                        }}>
                        <img alt="close_icon" src={close} />
                      </IconButton>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
          {pageContent.value !== 1 && pageContent.value !== 2 && (
            <button
              disabled={!selectedCompany}
              className={cls.addRowBtn}
              onClick={() => handleAddNewCity()}>
              Add New Row
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
