import React, { useState } from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import clsx from 'clsx';
import HubspotForm from 'react-hubspot-form';

import {
  TextField,
  FormControl,
  Button,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import { CheckBoxOutlineBlank } from '@material-ui/icons';
import * as Y from 'yup';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { Header } from '@/components/Landing';
import { LoginIcons } from '@/assets';
import { SOCIAL_URL } from '@/actions/constants';
import { oauthLogin } from '@/actions';
import $ from 'jquery';

import styles from './styles';

let RegisterSch = Y.object().shape({
  password: Y.string()
    .required('Password is required')
    .min(8, 'Your Password must be at least 8 characters'),
  password_confirmation: Y.string()
    .required('Password confirmation is required')
    .min(8, 'Password confirmation must be at least 8 characters')
    .oneOf([Y.ref('password'), null], 'Password confirmation does not match'),
  email: Y.string()
    .required('Email is required')
    .email('Your Email looks wrong.')
    .trim(),
  terms: Y.bool().oneOf([true], 'Please accept term and condition'),
});

let LoginSch = Y.object().shape({
  password: Y.string()
    .required('Password is required')
    .min(8, 'Your Password must be at least 8 characters'),
  email: Y.string()
    .required('Email is required')
    .email('Your Email looks wrong.')
    .trim(),
});

export default ({ history, ...rest }) => {
  const notify = useNotify();
  React.useEffect(() => {
    let q = qs.parse(history.location.search);
    if (q.access_token)
      oauthLogin(q.access_token).then(() => {
        history.push('/dashboard');
        notify('You logged in successfully');
        window.location.reload();
        console.log('reloaded');
      });
  }, [history, notify]);

  const [errs, setErrs] = useState([]);
  const [loginMode, setLoginMode] = useState(false);
  const [form, setFormData] = useState({
    email: '',
    password: '',
    password_confirmation: '',
    terms: false,
  });

  const setForm = (it, e) => setFormData({ ...form, [it]: e.target.value });

  const login = useLogin();
  const cls = styles({ loginMode });

  const toggleMode = () => {
    // $('#rep-pass').slideToggle();
    // $('#terms').slideToggle();
    // $('#forget').slideToggle();
    // $('#remember').slideToggle();
    $('#tips').slideToggle();
    setLoginMode(!loginMode);
    // setFormData({ ...form, password_confirmation: '' });
  };

  const Validate = () => {
    let Sch = loginMode ? LoginSch : RegisterSch;
    Sch.validate(form, { abortEarly: false })
      .then(handle)
      .catch(({ inner }) => setErrs(inner));
  };

  const handle = () => {
    login(form, '/dashboard')
      .then(() => {
        if (loginMode) notify('Logged In Successfully.');
        else notify('You are Registered Successfully.');
      })
      .catch((er = 'Invalid email or password') => notify('error', 'warning'));
  };

  const checkError = (item) => {
    if (errs.length) {
      let a = errs.find((i) => i.path === item);
      return a ? a.message : false;
    } else return false;
  };

  return (
    <div className={cls.bg}>
      <Header height={70} logoWidth={190} />
      <Notification />

      <div className={cls.card}>
        <div className={cls.row}>
          <Link
            onClick={toggleMode}
            style={{ textDecoration: 'none' }}
            className={clsx(cls.but, cls.yellow)}
            to={'/Login'}>
            <Button className={clsx(cls.but, cls.yellow)}>LOG IN</Button>
          </Link>
          <Button className={clsx(cls.but, cls.green)} onClick={toggleMode}>
            SIGN UP
          </Button>
          <span className={cls.opLine} />
        </div>
        <HubspotForm
          portalId="22601622"
          formId="07d3c62e-48f4-4d16-bc40-45ebbb022f31"
          onSubmit={() => console.log('Submit!')}
          onReady={(form) => {
            const input = document.getElementsByClassName('hs-input');
            console.log('form ready', input[0], form);
          }}
          loading={<div>Loading...</div>}
        />
        {/* <TextField
          placeholder="Email*"
          variant="outlined"
          className={cls.input}
          value={form.email}
          onChange={(e) => setForm('email', e)}
          error={checkError('email')}
          helperText={checkError('email')}
        />

        <TextField
          type="password"
          placeholder="Password*"
          variant="outlined"
          className={cls.input}
          value={form.password}
          onChange={(e) => setForm('password', e)}
          error={checkError('password')}
          helperText={checkError('password')}
        /> */}
        {/* <Link id="forget" className={cls.forget} to="/forget">
          FORGET YOUR PASSWORD?
        </Link> */}
        {/* <FormControl id="rep-pass" style={{ display: 'none' }}>
          <TextField
            type="password"
            placeholder="Confirm"
            variant="outlined"
            className={cls.input}
            value={form.password_confirmation}
            onChange={(e) => setForm('password_confirmation', e)}
            error={checkError('password_confirmation')}
            helperText={checkError('password_confirmation')}
          />
        </FormControl> */}
        {/* <FormControl className={'flip-card'}>
          <div className={clsx('flip-card-inner', loginMode && 'rot180')}>
            <Button
              className={clsx(cls.loginBut, 'flip-card-back')}
              fullWidth
              type="submit"
              color="primary"
              onClick={Validate}>
              LOG IN
            </Button>
            <Button
              className={clsx(cls.loginBut, 'flip-card-front')}
              fullWidth
              type="submit"
              color="primary"
              disabled={true}
              onClick={Validate}>
              SIGN UP
            </Button>
          </div>
        </FormControl> */}
        {/* <div id="terms" style={{ display: 'none' }}>
          <FormControlLabel
            className={cls.terms}
            control={
              <Checkbox
                color="secondary"
                icon={<CheckBoxOutlineBlank color="secondary" />}
                value={form.terms}
                onChange={(e) =>
                  setFormData({ ...form, terms: e.target.checked })
                }
              />
            }
            label="By signing up to smart-turn you agree to our Terms Of Use and Privacy Policy agreements"
          />
          {checkError('terms') && (
            <p className={cls.error}>Please accept terms and conditions</p>
          )}
        </div> */}
        {/* <FormControlLabel
          className={cls.remember}
          id="remember"
          control={
            <Checkbox
              color="secondary"
              icon={<CheckBoxOutlineBlank color="secondary" />}
              defaultChecked
            />
          }
          label="Remember Me?"
        /> */}
        {/* <div className={cls.hr} /> */}
        {/* <FormControl>
          <Button
            disabled={true}
            onClick={() => window.open(`${SOCIAL_URL}/facebook`, '_self')}
            className={cls.social}
            startIcon={
              <img alt="icon" className={cls.socIcon} src={LoginIcons.fb} />
            }>
            LOG IN WITH FACEBOOK
          </Button>
          <Button
            disabled={true}
            onClick={() => window.open(`${SOCIAL_URL}/google`, '_self')}
            className={cls.social}
            startIcon={
              <img alt="icon" className={cls.socIcon} src={LoginIcons.google} />
            }>
            LOG IN WITH GOOGLE
          </Button>
        </FormControl> */}
        <div id="tips" style={{ display: 'none' }}>
          <div className={cls.hr} />
          <div className={cls.already} onClick={() => toggleMode(true)}>
            ALREADY HAVE AN ACCOUNT
          </div>
        </div>
      </div>
    </div>
  );
};
