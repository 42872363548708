import React from 'react';
import styles from './styles';

export default ({ tablesData, setTablesData, pageContent }) => {
  const cls = styles();

  const handleChangeSSl = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setTablesData((prev) => {
      return {
        ...prev,
        ssl: {
          ...prev.ssl,
          [name]: value,
        },
      };
    });
  };

  return (
    <div>
      <h3 className={cls.header}>SSL NAMING</h3>
      <div className={cls.listContainer}>
        <table className={cls.list}>
          <thead>
            <th style={{ flex: 0.7 }}>SMART TURN</th>
            <th style={{ flex: 1 }}>SSL</th>
            <th style={{ flex: 0.7 }}>USER</th>
          </thead>
          <tbody>
            {tablesData.ssl_default &&
              Object.keys(tablesData.ssl_default).map((key, index) => (
                <tr>
                  <td style={{ flex: 1 }}>{key}</td>
                  <td style={{ flex: 0.7 }}>{tablesData.ssl_default[key]}</td>
                  <td style={{ flex: 0.7 }}>
                    {pageContent &&
                    (pageContent.value === 1 || pageContent.value === 2) ? (
                      <>{tablesData.ssl[key]}</>
                    ) : (
                      <input
                        onChange={(e) => handleChangeSSl(e)}
                        type="text"
                        defaultValue={tablesData.ssl[key]}
                        // value={tablesData.ssl_default[key]}
                        name={key}
                      />
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
