import React from 'react';
import { PlayCircleFilled } from '@material-ui/icons';
import HomeLayout from '@/components/HomeLayout';
import { AppBar } from '@/components/Landing';
import { AboutBg } from '@/assets';
import styles from './styles';

export default (props) => {
  const cls = styles();

  return (
    <HomeLayout className={cls.container}>
      <div className={cls.bg}>
        <div className={cls.title}>MORE BENEFIT WITH SMART TURN</div>
        <a className={cls.row} href="https://www.youtube.com/watch?v=WcvLZtnnQeQ">
          <PlayCircleFilled className={cls.icon} />
          <div>watch how it works</div>
        </a>
      </div>
      <div className={cls.content}>
        <div className={cls.flex}>
          <div className={cls.contentTitle}>About Us</div>
          <div className={cls.des}>
            Smart-Turn is an online marketplace that connects companies looking
            for empty containers to those that offer containers they no longer
            need. Our platform was built to avoid the unnecessary trips to the
            port for empty pickups and empty returns, saving enormous amount of
            money and time for the trucking community. The goal is to help the
            community keep the trucks on the road rather than being stuck at the
            very congested terminals.
          </div>
          <div className={cls.des}>
            Although street turns have been a on going practice, finding the
            specific empty container for the street turn transaction has always
            been a major challenge. The Smart-Turn platform will help you find
            the specific empty container needed for your street turn transaction
            through a simple smart search, linked to a database of many
            available empty containers offered in the marketplace. It is also a
            very useful tool for companies that no longer need their empty
            containers. They can simply offer them on the market place and have
            them street turned with the first company who contacts them through
            the platform, avoiding the extra trip back to the port.
          </div>
        </div>
        <div className={cls.flex}>
          <img alt="feature" src={AboutBg} className={cls.img} />
        </div>
      </div>
      <AppBar />
    </HomeLayout>
  );
};
