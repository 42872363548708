import React, { useState } from 'react';
import styles from './styles';
import { Tooltip, IconButton, Button } from '@material-ui/core';
import {
  CreateRounded,
  CloseRounded,
  CheckBox,
  CheckBoxOutlineBlank,
} from '@material-ui/icons';
import { adminApproveReferral, adminDisapproveReferral } from '@/actions';
import { fTimeDate } from '@/actions/Helper';
import EditPopup from './EditPopup';

export default ({ DATA = [], notify, getReferredCompany }) => {
  const cls = styles();
  const [editPopup, setEditPopup] = useState(null);

  const renderTableActions = (referral) => {
    let actionItems = [
      {
        icon: (
          <>
            {referral.status !== 0 ? (
              <CheckBox className={cls.checkBox} />
            ) : (
              <CheckBoxOutlineBlank className={cls.checkBox} />
            )}
          </>
        ),
        tooltip: referral.status === 0 ? 'Approve' : 'Disapprove',
        // disable: referral.status !== 0 ? true : false,
        onClick: () => {
          referral.status === 0
            ? handleApproveReferr(referral.id)
            : handleDisapproveReferr(referral.id);
        },
      },
      {
        icon: <CreateRounded className={cls.editIcon} />,
        tooltip: 'Edit',
        onClick: () => {
          setEditPopup(referral);
        },
      },
    ];
    return (
      <div className={cls.tableActionsContainer}>
        {actionItems.map((item, index) => {
          return (
            <Tooltip arrow title={item.tooltip} placement="top">
              <IconButton disabled={item.disable} onClick={item.onClick}>
                {item.icon}
              </IconButton>
            </Tooltip>
          );
        })}
      </div>
    );
  };

  const handleApproveReferr = (id) => {
    adminApproveReferral(id)
      .then((response) => {
        if (response.message === 'success') {
          notify('Successfully Approved.', { type: 'success' });
          getReferredCompany();
        }
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleDisapproveReferr = (id) => {
    adminDisapproveReferral(id)
      .then((response) => {
        if (response.message === 'success') {
          notify('Successfully Disapproved.', { type: 'success' });
          getReferredCompany();
        }
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  return (
    <div className={cls.container}>
      {/* {approvePopup && (
        <div className={cls.popup}>
          <div style={{ width: '400px' }} className={cls.popupCard}>
            <Tooltip arrow title="Close" placement="top">
              <CloseRounded
                className={cls.close}
                onClick={() => setApprovePopup(null)}
              />
            </Tooltip>
            <div>
              <p style={{ textAlign: 'center', marginBottom: '35px' }}>
                {`Are you sure that you want to ${
                  approvePopup.status === 0 ? 'Approve' : 'Disapprove '
                } it?`}
              </p>
              <div className={cls.actionContainer}>
                <button
                  className={cls.yesBtn}
                  onClick={() =>
                    approvePopup.status === 0
                      ? handleApproveReferr()
                      : handleDisapproveReferr()
                  }>
                  yes
                </button>
                <button
                  className={cls.noBtn}
                  onClick={() => setApprovePopup(null)}>
                  no
                </button>
              </div>
            </div>
          </div>
        </div>
      )} */}
      {editPopup && (
        <div className={cls.popup}>
          <div className={cls.popupCard}>
            <Tooltip arrow title="Close" placement="top">
              <CloseRounded
                className={cls.close}
                onClick={() => setEditPopup(null)}
              />
            </Tooltip>
            <EditPopup
              companyData={editPopup}
              getReferredCompany={getReferredCompany}
              setEditPopup={setEditPopup}
            />
          </div>
        </div>
      )}
      <div className={cls.tableContainer}>
        <table className={cls.list}>
          <thead className={cls.thead}>
            <th style={{ flex: 1 }}>First Name</th>
            <th style={{ flex: 1 }}>Last Name</th>
            <th style={{ flex: 1 }}>Company</th>
            <th style={{ flex: 1.7 }}>Emial</th>
            <th style={{ flex: 2.5 }}>Ref Code</th>
            <th style={{ flex: 1.3 }}>Phone Number</th>
            <th style={{ flex: 1 }}>Program</th>
            <th style={{ flex: 1 }}>Rewards</th>
            <th style={{ flex: 1.5 }}>By</th>
            <th style={{ flex: 1 }}>Date</th>
            <th style={{ flex: 1 }}>Actions</th>
          </thead>
          <tbody className={cls.tbody}>
            {DATA.map((i, idx) => {
              return (
                <tr className={cls.tableRow} key={idx}>
                  <td style={{ flex: 1 }}>{i.f_name}</td>
                  <td style={{ flex: 1 }}>{i.l_name}</td>
                  <td style={{ flex: 1 }}>{i.company}</td>
                  <td style={{ flex: 1.7 }}>{i.email}</td>
                  <td style={{ flex: 2.5 }}>{i.referral_link.code}</td>
                  <td style={{ flex: 1.3 }}>{i.phone}</td>
                  <td style={{ flex: 1 }}>{i.program.name}</td>
                  <td style={{ flex: 1 }}>{i.bonus}</td>
                  <td style={{ flex: 1.5 }}>{i.by.company}</td>
                  <td style={{ flex: 1 }}>{fTimeDate(i.created_at)}</td>
                  <td style={{ flex: 1 }}>{renderTableActions(i)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
