import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    minWidth: 260,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    margin: '10px',
  },
  header: {
    height: 33,
    backgroundColor: '#f8b100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
    '& > span': {
      fontSize: 10,
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      lineHeight: 1.8,
      color: '#303030',
      cursor: 'default',
    },
  },
  content: {
    padding: '20px 25px',
    paddingBottom: '20px !important',
  },
  hr: {
    height: 1,
    backgroundColor: '#9f9f9f',
    borderRadius: 2,
    marginTop: 15,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 10,
    fontFamily: 'Averta Bold',
    lineHeight: 1.5,
    color: '#303030',
    fontWeight: 'bold',
    cursor: 'default',
  },
  light: {
    fontFamily: 'Averta Light',
    fontWeight: '300',
    color: '#43424b',
  },
  bigText: {
    fontSize: 30,
    fontFamily: 'Averta Bold',
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: 10,
  },
  butCont: {
    display: 'flex',
    justifyContent: 'space-evenly',
    marginTop: 20,
  },
  but: {
    width: 80,
    height: 30,
    fontSize: 12,
    fontFamily: 'Averta Light',
    color: '#fff',
    fontWeight: 300,
    backgroundColor: '#f8b100',
    borderRadius: 5,
    textTransform: 'none',
    cursor: 'pointer',
  },
  red: {
    backgroundColor: '#e4464b',
  },

  bigRow: {
    alignItems: 'flex-start',
  },
}));
