import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    // minWidth: 260,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
  },
  header: {
    height: 33,
    backgroundColor: '#f8b100',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
    textTransform: 'capitalize',
    '& > span': {
      fontSize: 10,
      fontFamily: 'Averta Bold',
      fontWeight: 'bold',
      lineHeight: 1.8,
      color: '#303030',
    },
  },
  content: {
    padding: 20,
    paddingBottom: '20px !important',
  },
  hr: {
    height: 1,
    backgroundColor: '#9f9f9f',
    borderRadius: 2,
    marginTop: 4,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 10,
    fontFamily: 'Averta Bold',
    lineHeight: 1.5,
    color: '#303030',
    fontWeight: 'bold',
  },
  light: {
    fontFamily: 'Averta Light',
    fontWeight: '300',
    color: '#43424b',
  },
  blue: {
    color: '#007fff',
    margin: '15px 0',
  },
  green: {
    color: '#44a570',
    margin: '15px 0',
  },
  butCont: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25,
  },
  but: {
    width: 130,
    height: 32,
    fontSize: 10,
    fontFamily: 'Averta Light',
    lineHeight: 1.2,
    color: '#303030',
    fontWeight: 300,
    backgroundColor: '#f8b100',
    borderRadius: 5,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#ffd616',
    },
    '& .MuiButton-endIcon': {
      marginLeft: 5,
    },
  },
  launchIcon: {
    height: 20,
    width: 20,
    fill: '#303030',
  },
  but2: {
    width: 80,
    height: 32,
    fontSize: 10,
    fontFamily: 'Averta Light',
    lineHeight: 1.2,
    color: '#fff',
    fontWeight: 300,
    backgroundColor: '#e4464b',
    borderRadius: 5,
    textTransform: 'none',
    '&:hover':{
      color: '#fff',
      backgroundColor: '#e4464b',
    }
  },
  loader:{
    width:'20px !important',
    height:'20px !important',
    color:'#ffffff'
  },
  bigText: {
    fontSize: 30,
    fontFamily: 'Averta Bold',
    fontWeight: 800,
    lineHeight: 1,
    marginBottom: 10,
  },
  bigRow: {
    alignItems: 'flex-start',
  },
  link: {
    textDecoration: 'none',
  },
  progressWrapper: {
    display: 'flex',
    height: 9,
    marginTop: 10,
    '& span': {
      fontFamily: 'Averta Bold',
      fontSize: 10,
      fontWeight: 'bold',
      lineHeight: 0.8,
      color: '#9f9f9f',
    },
  },
  progressBar: {
    display: 'flex',
    borderRadius: 10,
    backgroundColor: '#dfdfdf',
    width: '100%',
    margin: '0 3px',
  },
  progress: {
    height: 9,
    backgroundColor: '#f8b100',
    borderRadius: 10,
    width: ({ progress }) => (progress ? progress * 100 + '%' : 0),
  },
  avatarCont: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
  avatar: {
    height: 40,
    width: 40,
  },

  avatarRow: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Averta Light',
    fontSize: 10,
    color: '#303030',
    '& > svg': {
      height: 20,
      width: 20,
      fill: '#f8b100',
      margin: '0 5px',
    },
  },
  row2: {
    display: 'flex',
    alignItems: 'center',
  },
  acc: {
    color: '#007fff',
    lineHeight: 1.8,
    margin: '10px 0',
  },
  acc2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 10,
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    color: '#007fff',
    lineHeight: 1.8,
    margin: '35px 0',
  },

  stat: {
    color: '#303030',
    lineHeight: 1.8,
    margin: '10px 0',
  },
}));
