// import { cacheDataProviderProxy } from 'react-admin';

import { API_URL } from '@/actions/constants';
import customRoutes from './CustomRoutes';
import authProvider from './auth';
import data from './data';
import theme from './theme';

// const dataProvider = cacheDataProviderProxy(data(API_URL));
const dataProvider = data(API_URL);

export { authProvider, customRoutes, dataProvider, theme };
