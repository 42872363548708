import React, { useEffect, useState } from 'react';
import { Modal, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import styles from '../styles';
import { useNotify } from 'react-admin';
import closeIcon from '../../../assets/img/closeIcon.svg';

export default ({
  DATA = [],
  open,
  selectedRow,
  setShowContainersModal,
  getInventoryList,
  isCompleted,
}) => {
  const cls = styles();
  const notify = useNotify();
  const [containers, setContainers] = useState(selectedRow.containers);

  const getInventoryContainers = () => {
    let inventoryContainers = [];
    for (let index = 0; index < selectedRow.containers.length; index++) {
      if (
        selectedRow.containers[index].status === 1 ||
        selectedRow.containers[index].status === 0 ||
        isCompleted
      ) {
        inventoryContainers.push({
          name: selectedRow.containers[index]
            ? selectedRow.containers[index].name
            : '',
          chases: selectedRow.containers[index]
            ? selectedRow.containers[index].chases
            : '',
          id: selectedRow.containers[index]
            ? selectedRow.containers[index].id
            : null,
          status: selectedRow.containers[index]
            ? selectedRow.containers[index].status
            : null,
        });
      }
    }
    setContainers(inventoryContainers);
  };

  useEffect(() => {
    getInventoryContainers();
  }, []);

  const gatEditContainersContent = () => {
    return (
      <table className={cls.containerTable}>
        <thead>
          <tr>
            <th style={{ flex: '0.3' }}>#</th>
            <th style={{ flex: '1' }}>Container#</th>
            <th style={{ flex: '1' }}>Chassis#</th>
          </tr>
        </thead>
        <tbody>
          {containers.map((container, index) => {
            return (
              <tr key={index}>
                <td style={{ flex: '0.3' }}>{index + 1}</td>
                <td style={{ flex: '1' }}>
                  <span>
                    {container.name ? container.name : 'Not Applicable'}
                  </span>
                </td>
                <td style={{ flex: '1' }}>
                  <span>
                    {container.chases ? container.chases : 'Not Applicable'}
                  </span>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <Modal open={open}>
        <div
          id="app_modal"
          className={cls.modal}
          onClick={(e) =>
            e.target.id === 'app_modal' && setShowContainersModal(false)
          }
          style={{ width: '100%', height: '100%' }}>
          <Box className={cls.modalBox}>
            <div
              onClick={() => setShowContainersModal(false)}
              className={cls.closeModal}>
              <img alt="close_icon" src={closeIcon} />
            </div>
            <h3 className={cls.modalTitle}>Container Availability</h3>
            <div className={cls.containersTableContainer}>
              {gatEditContainersContent()}
            </div>
            <button
              className={cls.updateContainersBtn}
              onClick={() => setShowContainersModal(false)}>
              Close
            </button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};
