import React from 'react';
import { Avatar } from '@material-ui/core';
import { AccountCircle, HomeRounded } from '@material-ui/icons';
import { fTimeDate } from '@/actions/Helper';
import styles from './styles';

const MSG = ({ msg = '' }) => msg.substring(0, 35);

export default ({ DATA = {}, Chat }) => {
  const cls = styles({ black: Boolean(!DATA.seen) });
  const image = DATA.msg.includes('https://api.smart-turn.com/storage');
  return (
    <div className={cls.card}>
      {/* <MoreVertRounded className={cls.more} /> */}
      <div className={cls.row} onClick={() => Chat(DATA)}>
        <div className={cls.f1}>
          <Avatar className={cls.avatar} src={DATA.avatar} alt={DATA.user} />
          <div>
            <div className={cls.row2}>
              <AccountCircle className={cls.icon} />
              <span className={cls.light}>{DATA.user}</span>
            </div>
            <div className={cls.row2}>
              <HomeRounded className={cls.icon} />
              <span className={cls.light}>{DATA.company}</span>
            </div>
          </div>
        </div>

        <div className={cls.f2}>
          <div className={cls.light}>{image ? '[Image]' : MSG(DATA)}</div>
          <div className={cls.seen}>
            <span>Last Seen: </span>
            <span>{fTimeDate(DATA.update)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
