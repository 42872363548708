import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: '8.5%',
    boxSizing: 'border-box',
    margin: 'auto',
    '@media (max-width:800px)': {
      padding: '10%',
    },
    },
  col: {
    display: 'flex',
    flexDirection: 'column',
  },
  hubspotContact: {
    minHeight: 426,
  },
  label: {
    fontSize: 15,
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    lineHeight: 2,
    color: '#43424b',
    marginTop: 5,
  },
  title: {
    fontSize: 30,
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    color: '#303030',
    marginBottom: 25,
  },
  input: {
    height: 50,
    borderRadius: 5,
    boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
    backgroundColor: '#fff',
    borderWidth: 0,
    outline: 'none',
    padding: '0 7px 0 15px',
    fontSize: 15,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    color: '#43424b',
  },

  textarea: {
    width: '-webkit-fill-available',
    height: 180,
    padding: 10,
    resize: 'none',
  },
  butCont: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginTop: 35,
  },
  but: {
    width: 150,
    height: 45,
    borderRadius: 5,
    border: 'solid 2px #f8b100',
    backgroundColor: 'transparent',
    fontSize: 14,
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
    color: '#f8b100',
  },
  link: {
    fontSize: 14,
    fontFamily: 'Averta Bold',
    fontWeight: 500,
    color: '#000',
  },
}));
