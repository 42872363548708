import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#303030',
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    height: 70,
    '& > span': {
      fontFamily: 'Averta Light',
      fontSize: 14,
      fontWeight: 300,
      lineHeight: 3.86,
      color: '#a6a9b7',
      cursor: 'default',
    },
  },
});

export default (props) => {
  const cls = styles();
  let year = ' ' + new Date().getFullYear() + ' ';

  return (
    <div className={cls.footer}>
      <span>© {year} All Rights Reserved</span>
    </div>
  );
};
