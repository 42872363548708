import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 182,
    borderRadius: 5,
    boxShadow: '0 0 44px 0 rgba(49, 56, 150, 0.03)',
    backgroundImage: 'linear-gradient(259deg, #6bb2f9 113%, #007fff -15%)',
    margin: '10px',
    '& a': {
      textDecoration: 'none',
    },
    boxSizing: 'border-box',
    padding: '15px 20px',
    [BP.up('xl')]: {
      padding: '20px 30px',
    },
  },

  title: {
    fontSize: '1.4rem',
    fontFamily: 'Averta Bold',
    color: '#fff',
    fontWeight: 'bold',
    lineHeight: 0.96,
    [BP.up('xl')]: {
      fontSize: 25,
    },
  },
  see: {
    fontSize: '0.7rem',
    fontFamily: 'Averta',
    color: '#fff',
    fontWeight: 600,

    [BP.up('xl')]: {
      fontSize: 15,
    },
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  row2: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 15,
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  itemTitle: {
    fontSize: '0.9rem',
    fontFamily: 'Averta',
    color: '#fff',
    fontWeight: 600,
  },
  icon: {
    '& svg': {
      height: 50,
      margin: '5px 0',
    },
  },
  itemDes: {
    fontSize: '1.2rem',
    fontFamily: 'Averta Bold',
    color: '#fff',
    fontWeight: 600,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
}));
