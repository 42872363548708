import { Tooltip, Avatar, TextField, Button } from '@material-ui/core';
import React, { useState } from 'react';
import styles from '../styles';
import { CloseRounded } from '@material-ui/icons';
import { adminAddMember, adminEditMember, SaveData } from '@/actions';
import { validateAdminAddMemberData } from '@/actions/validations';
import Form from '../Form';

export default ({
  handleAdd,
  formData,
  setFormData,
  userAvatar,
  setUserAvatar,
}) => {
  const cls = styles();

  const Close = () => SaveData({ addMemberPopup: false });

  const handleChangeInput = (name, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  return (
    <div className={cls.popup}>
      <Form
        userAvatar={userAvatar}
        formData={formData}
        Close={Close}
        setUserAvatar={setUserAvatar}
        handleSendForm={handleAdd}
        handleChangeInput={handleChangeInput}
        button="Add Member"
      />
    </div>
  );
};
