import React, { useState } from 'react';
import styles from '../styles';
import Select from 'react-select';
import clsx from 'clsx';
import { Tooltip } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import { useOutsideAlerter } from '@/hocs';

export default ({
  companies,
  children,
  Close,
  selectedCompany,
  setSelectedCompany,
  showCompanies = true,
}) => {
  const cls = styles();

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    Close();
  });

  return (
    <div className={cls.popup}>
      <div ref={wrapperRef} className={cls.popUpCard}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.close} onClick={Close} />
        </Tooltip>
        {showCompanies && (
          <Select
            placeholder="Company"
            className={clsx(cls.select, cls.ml15)}
            value={selectedCompany}
            onChange={(item) => setSelectedCompany(item)}
            options={companies}
          />
        )}
        {children}
      </div>
    </div>
  );
};
