import { makeStyles } from '@material-ui/core/styles';

export default makeStyles({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: '0 20px',
  },
  card: {
    position: 'relative',
    padding: '15px 40px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 20,
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '23px',
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 5,
    justifyContent: 'center',
    border: 'solid 1px #707070',
    marginTop: '0.5rem',
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
    '& > div': {
      fontSize: 13,
    },
  },
  select: {
    marginBottom: 20,
    fontFamily: 'Averta Light',
    '& > div': {
      border: 'solid 1px #707070',
    },
    '& > div:first-child > div': {
      padding: '0 0 0 10px',
    },
    '& > div:first-child > div > div': {
      padding: '8px 4px',
    },
    '& svg': {
      fill: '#f8b100',
    },
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 15,
    '& button': {
      color: '#ffffff',
      background: '#f8b100',
      '&:hover': { color: '#ffffff', background: '#f8b100' },
    },
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '15px',
  },
  loader: {
    width: '25 !important',
    height: '25 !important',
    margin: '0 15px',
    color: '#f8b100',
  },
  actionConatiner: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 15,
  },
  saveBtn: {
    width: 100,
    height: 35,
    color: '#ffffff',
    background: '#f8b100',
    '&:hover': {
      color: '#ffffff',
      background: '#f8b100',
    },
  },
  deleteBtn: {
    width: 100,
    height: 35,
    color: '#ffffff',
    background: '#FF0000',
    margin: '0 15px',
    '&:hover': {
      color: '#ffffff',
      background: '#FF0000',
    },
  },
  delContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  delIcon: {
    height: 20,
    width: 20,
    fill: '#e4464b',
  },
});
