import React from 'react';
import { Grid } from '@material-ui/core';
import { getCompareReport } from '@/actions';
import StatCard from './Card';

export default () => {
  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });

  React.useEffect(() => {
    getCompareReport()
      .then((DATA) => setState({ DATA, loading: false }))
      .catch(() => setState((st) => ({ ...st, loading: false })));
  }, []);

  return (
    <Grid container justify="flex-start" spacing={1}>
      <Grid item lg={3} md={6} xs={12}>
        <StatCard title="Total requests" color="yellow" data={state.DATA.req} />
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <StatCard title="Completed Deals" color="green" data={state.DATA.com} />
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <StatCard
          title="Total Offers"
          color="black"
          white
          data={state.DATA.ord}
        />
      </Grid>
      <Grid item lg={3} md={6} xs={12}>
        <StatCard
          title="Rejected Deals"
          color="red"
          white
          data={state.DATA.rej}
        />
      </Grid>
    </Grid>
  );
};
