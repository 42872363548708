// in src/MyAppBar.js
import React, { Children, cloneElement, isValidElement, useState } from 'react';
import { Button, Menu, Avatar, MenuItem } from '@material-ui/core';
import { ArrowDropDownRounded } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import styles from './styles';
import { Link } from 'react-router-dom';

export default (props) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (e) => setAnchorEl(e.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const cls = styles();
  return (
    <div className={cls.menu}>
      <Button
        onClick={handleMenu}
        className={cls.menuBtn}
        endIcon={<ArrowDropDownRounded />}>
        Menu
      </Button>
      <Menu
        elevation={0}
        keepMounted
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        getContentAnchorEl={null}
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem>
          <Link className={cls.link} to="/">
            HOME
          </Link>
        </MenuItem>
        <MenuItem>
          <Link className={cls.link} to="/how-it-works">
            HOW IT WORKS
          </Link>
        </MenuItem>
        <MenuItem>
          <Link className={cls.link} to="/about">
            ABOUT
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
};
