import React from 'react';
import { Card, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { Bar } from 'react-chartjs-2';
import { getReportList } from '@/actions';
import { styles, ChartOptions } from './styles';

export default (props) => {
  const cls = styles();
  const [state, setState] = React.useState({
    labels: [],
    ordDATA: [],
    reqDATA: [],
    loading: true,
  });

  React.useEffect(() => {
    getReportList()
      .then(({ ord, req }) => {
        let labels = Object.keys({ ...ord, ...req });
        let ordDATA = Object.values(ord);
        let reqDATA = Object.values(req);
        setState({ ordDATA, reqDATA, labels, loading: false });
      })
      .catch(() => setState((st) => ({ ...st, loading: false })));
  }, []);

  const ChartData = {
    labels: state.labels,
    datasets: [
      {
        label: 'Offers',
        barThickness: 30,
        backgroundColor: '#494949',
        // borderWidth: 2,
        // borderColor: '#f9bb05',
        // hoverBackgroundColor: '#49494955',
        data: state.ordDATA,
      },
      {
        label: 'Requests',
        barThickness: 40,
        backgroundColor: '#f9bb05',
        data: state.reqDATA,
      },
    ],
  };

  if (state.loading)
    return (
      <Card className={clsx(cls.card, cls.center)}>
        <CircularProgress />
      </Card>
    );

  return (
    <Card className={cls.card}>
      <div className={clsx(cls.row)}>
        <span className={cls.title}>Monthly Report</span>
        <Link to="/report" className={cls.see}>
          ...
        </Link>
      </div>

      <div className={cls.chartCont}>
        <Bar data={ChartData} options={ChartOptions} />
      </div>
    </Card>
  );
};
