import React from 'react';
import { Grid } from '@material-ui/core';
import { useNotify, Loading } from 'react-admin';
import { useSelector } from 'react-redux';
import { RequestCard } from '@/components/Request';
import { Load, Filter } from '@/components/Global';
import { getReq, delReq, delSmart, SaveData, delExportReq } from '@/actions';
import { ParseRequest } from '@/actions/ParseData';

import styles from './styles';

const perPage = 8;

export default (props) => {
  const cls = styles();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(true);
  const [loadingCards, setLoadingCards] = React.useState([]);
  const [DATA, setData] = React.useState([]);
  const [filter, setFilter] = React.useState({
    open: false,
    DATA: [],
  });

  const [page, setPage] = React.useState(1);
  const disableLoad = () => {
    if (page * perPage < DATA.length) return false;
    else return true;
  };
  const RefreshPage = useSelector((state) => state.reducer.refreshPage || '');
  const RefreshData = React.useCallback(() => {
    setLoading(true);
    getReq('active')
      .then((res) => {
        let DATA = ParseRequest(res);
        setData(DATA);
        setFilter({ open: false, DATA });
        setLoading(false);
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoading(false);
      });
  }, [notify]);

  const getData = React.useCallback(
    () => filter.DATA.slice(0, page * perPage),
    [filter.DATA, page]
  );

  React.useEffect(() => {
    if (RefreshPage === 'request') {
      RefreshData();
      SaveData({ refreshPage: '' });
    }
  }, [RefreshPage, RefreshData]);

  React.useEffect(() => {
    RefreshData();
  }, [RefreshData]);

  const Stop = (id, type, mode) => {
    setLoadingCards((prev) => {
      return [...prev, id];
    });
    let Fun =
      type === 'manual'
        ? mode === 'export'
          ? delExportReq
          : delReq
        : delSmart;
    Fun(id)
      .then(({ data, msg }) => {
        let adaptedLoadingCards = loadingCards.filter(
          (cardId) => cardId !== id
        );
        setLoadingCards(adaptedLoadingCards);
        RefreshData();
        setFilter({ open: false, DATA });
        notify(msg);
      })
      .catch((err) => notify(err, 'warning'));
  };

  return (
    <div className={cls.content}>
      <Filter
        title="Active Requests"
        filter={filter}
        setFilter={setFilter}
        DATA={DATA}
      />

      <div className={cls.list}>
        {loading ? (
          <Loading />
        ) : (
          <Grid container justify="flex-start" spacing={2}>
            {getData().map((i, idx) => (
              <Grid item xs={12} sm={6} md={4} lg={3} xl="auto" key={idx}>
                <RequestCard DATA={i} Stop={Stop} loadingCards={loadingCards} />
              </Grid>
            ))}
          </Grid>
        )}
      </div>
      <Load onLoad={() => setPage(page + 1)} disable={disableLoad()} />
    </div>
  );
};
