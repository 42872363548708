import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 280,
    borderRadius: 5,
    boxShadow: '0 0 44px 0 rgba(49, 56, 150, 0.03)',
    backgroundImage: 'linear-gradient(259deg, #6bb2f9 113%, #007fff -15%)',
    margin: '10px',
    boxSizing: 'border-box',
    padding: '25px 7%',
    [BP.up('xl')]: { 
      padding: '20px 30px',
    },
    [BP.down('xs')]: {
      height:'auto',
    }
  },

  content: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [BP.down('sm')]: {
      flexDirection:'column',
    },
  },

  title: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '1.8rem',
    fontFamily: 'Averta Bold',
    color: '#fff',
    fontWeight: 'bold',
    marginRight: '7%',
    [BP.up('xl')]: {
      fontSize: 25,
    },
  },
  title2: {
    marginTop: 10,
    fontSize: '2.2rem',
    [BP.up('xl')]: {
      fontSize: 28,
    },
  },

  row: {
    flex: 1,
    display: 'flex',
    [BP.down('sm')]: {
      marginTop:30,
    },
    [BP.down('xs')]: {
      flexDirection:'column',
    },
  },

  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
  },
  itemTitle: {
    fontSize: '0.9rem',
    fontFamily: 'Averta',
    color: '#fff',
    fontWeight: 600,
  },
  icon: {
    '& svg': {
      height: 50,
      margin: '5px 0',
    },
  },
  itemDes: {
    fontSize: '1.2rem',
    fontFamily: 'Averta Bold',
    color: '#fff',
    fontWeight: 600,
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },

  typeWrapper: {
    display: 'flex',
    height: 40,
    backgroundColor: '#fff',
    borderRadius: 20,
    margin: '0 auto',
  },
  type: {
    fontSize: '0.9rem',
    fontFamily: 'Averta Light',
    color: '#9f9f9f',
    fontWeight: 300,
    height: 32,
    width: 120,
    borderRadius: 20,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    transition: 'all 0.5s ease-in',
    margin: 4,
  },
  typeActive: {
    color: '#fff',
    backgroundColor: '#f8b100',
    animation: 'zoomIn 0.5s',
  },
}));
