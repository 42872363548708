import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    // minWidth: 210,
    height: '100%',
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#303030',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  uploadLabel: {
    cursor: 'pointer',
    '&:hover': {
      opacity: '0.85',
    },
  },
  header: {
    height: 33,
    // backgroundColor: '#44a570',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
    fontSize: 13,
    color: '#fff',
  },
  uploadBtn: {
    display: 'flex',
  },
  content: {
    padding: '20px',
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },

  flex: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  hr: {
    height: 1,
    backgroundColor: '#9f9f9f',
    borderRadius: 2,
    margin: '8px 0 5px 0',
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: 10,
    fontFamily: 'Averta Light',
    lineHeight: 1.5,
    color: '#fff',
    fontWeight: 300,
    '& a': {
      textDecoration: 'none',
      color: '#ffffff',
    },
  },
  butCont: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '5px',
    '& button': {
      flex: '1',
    },
    '& p': {
      flex: '1',
    },
  },
  approvalDiv: {
    marginTop: -6,
    marginBottom: 12,
  },
  but: {
    color: '#fff',
    width: '100%',
    fontSize: 10,
    height: 30,
    borderRadius: 5,
    textTransform: 'none',
    padding: '7px 5px',
    marginTop: 6,
    marginBottom: -4,
  },
  stat: {
    fontSize: 11,
    fontFamily: 'Averta Bold',
    textAlign: 'left',
    color: '#fff',
    fontWeight: 600,
  },
  statText: {
    lineHeight: 1.5,
    fontSize: 11,
    fontFamily: 'Averta light',
    color: '#007fff',
    fontWeight: 300,
  },
  file: {
    marginBottom: 25,
    color: 'rgb(68, 165, 112)',
    display: 'block',
    textAlign: 'center',
  },
  disabled: {
    color: '#ffffff',
    background: '#cacaca !important',
  },
  numText: {
    lineHeight: 1.2,
    fontSize: 14,
    fontFamily: 'Averta Bold',
    color: '#ffffff',
    fontWeight: 600,
    '& input': {
      display: 'none',
    },
    '& button': {
      marginLeft: 10,
      backgroundColor: '#007fff !important',
      color: '#fff',
      width: '65px',
      height: '27px',
      padding: '0',
      fontSize: '10px',
      fontFamily: 'Averta Light',
      fontWeight: '300',
      borderRadius: '5px',
    },
    '& button:disabled': {
      color: '#ffffff',
      background: '#cacaca !important',
    },
    '& label': {
      marginLeft: 10,
      backgroundColor: '#007fff',
      color: '#fff',
      width: '65px',
      height: '27px',
      padding: '0',
      fontSize: '10px',
      fontFamily: 'Averta Light',
      fontWeight: '300',
      borderRadius: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  actionContainer: {
    marginTop: '16px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  yesBtn: {
    color: '#ffffff',
    padding: '10px 15px',
    background: '#007FFF',
    border: 'none',
    borderRadius: '5px',
    width: 80,
    '&:hover': {
      color: '#ffffff',
      background: '#007FFF',
    },
  },
  noBtn: {
    color: '#ffffff',
    padding: '10px 15px',
    background: '#FF0000',
    marginRight: 10,
    border: 'none',
    borderRadius: '5px',
    width: 80,
    '&:hover': {
      color: '#ffffff',
      background: '#FF0000',
    },
  },
  popup: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: '#000000aa',
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  close: {
    position: 'absolute',
    top: 10,
    right: 15,
    height: 25,
    cursor: 'pointer',
    fill: 'gray',
    width: 15,
  },
  confirm_container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  confirm: {
    color: '#007fff',
    marginTop: 10,
    fontSize: 12,
  },
  statusContainer: {
    '& p': {
      padding: '10px 5px',
      borderRadius: 5,
      fontSize: 12,
      marginBottom: -8,
      marginTop: 16,
      ['@media (min-width:1870px)']: {
        width: '320px',
      },
    },
  },
  popUpCard: {
    position: 'relative',
    padding: '15px',
    boxSizing: 'border-box',
    width: 380,
    height: 'auto',
    boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: 10,
    alignItems: 'center',
    justifyContent: 'center',
    // position: 'absolute',
    // right: '9vw',
    zIndex: 10,
    // top: 110,
    '@media (max-width:500px)': {
      width: '100%',
    },
    '& p': {
      margin: '25px 0',
    },
  },
  but2: {
    backgroundColor: '#e4464b !important',
  },
  but3: {
    backgroundColor: '#3c845d',
    '&:hover': {
      backgroundColor: '#3c845d',
    },
  },
  bigText: {
    fontSize: 30,
    fontFamily: 'Averta Bold',
    fontWeight: 800,
    lineHeight: 1,
    marginRight: 3,
  },

  text: {
    lineHeight: 1.5,
    fontSize: 10,
    fontFamily: 'Averta light',
    textAlign: 'center',
    color: '#fff',
    fontWeight: 300,
  },
  margin: {
    marginBottom: 7,
  },
  bold: {
    fontFamily: 'Averta Bold',
    fontWeight: 'bold',
  },

  light: {
    fontFamily: 'Averta light',
    fontWeight: 300,
  },
  avatar: {
    height: 35,
    width: 35,
  },

  icon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    margin: '0 5px',
  },
  light2: {
    height: 12,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    color: '#fff',
  },
  row2: {
    display: 'flex',
    alignItems: 'center',
  },
  loader: {
    width: '20px !important',
    height: '20px !important',
    color: '#ffffff',
  },
}));
