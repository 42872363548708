import React from 'react';
import { useNotify } from 'react-admin';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File

const TextEditor = ({ editorState, setEditorState }) => {
  const notify = useNotify();

  const handleImageUploadBefore = (files, info, uploadHandler) => {
    // uploadHandler is a function
    if (files[0].size > 1000000) {
      notify('This file size is large. Please try another image', 'warning');
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      {console.log('editorState', typeof editorState)}
      <div>
        <SunEditor
          setOptions={{
            font: ['Avarta'],
            defaultFont: 'Avarta',
            buttonList: [
              ['font', 'fontSize', 'formatBlock'],
              [
                'bold',
                'underline',
                'italic',
                'strike',
                'subscript',
                'superscript',
              ],
              ['align', 'horizontalRule', 'list', 'table'],
              ['fontColor', 'hiliteColor'],
              ['outdent', 'indent'],
              ['undo', 'redo'],
              ['removeFormat'],
              ['outdent', 'indent'],
              ['link', 'image'],
              ['preview', 'print'],
              ['fullScreen', 'showBlocks', 'codeView'],
            ],
          }}
          onChange={(content) => setEditorState(content)}
          setContents={editorState}
          onImageUploadBefore={handleImageUploadBefore}
        />
        {/* <Editor
          toolbar={config}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
        /> */}
        {/* <textarea
          disabled
          value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
        /> */}
      </div>
    </div>
  );
};

export default TextEditor;
