import React, { useEffect, useState } from 'react';
import styles from '../styles';
import { Modal, Box } from '@material-ui/core';
import closeIcon from '../../../assets/img/closeIcon.svg';
import { useNotify } from 'react-admin';
import ShareLink from './ShareLink';
import SendMail from './SendMail';
import { useOutsideAlerter } from '@/hocs';

export default ({ shareModal, setShareModal, shareLink }) => {
  const [content, setContent] = useState(0);

  const cls = styles();
  const notify = useNotify();

  //for close chat when click outside of box
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    setContent(0);
  });

  return (
    <>
      <Box className={`${cls.shareLinkBox} ${cls.modalBox}`}>
        <div
          onClick={() => {
            setShareModal(null);
          }}
          className={cls.closeModal}>
          <img alt="close_icon" src={closeIcon} />
        </div>
        <ShareLink
          setContent={setContent}
          setShareModal={setShareModal}
          shareLink={shareLink}
          notify={notify}
        />
      </Box>
      {content === 1 && (
        <Modal open={shareModal ? true : false}>
          <div
            id="app_modal"
            className={cls.modal}
            style={{ width: '100%', height: '100%' }}>
            <Box
              style={{ minHeight: '255px' }}
              className={cls.modalBox}
              ref={wrapperRef}>
              <div
                onClick={() => {
                  setContent(0);
                }}
                className={cls.closeModal}>
                <img alt="close_icon" src={closeIcon} />
              </div>
              <SendMail
                notify={notify}
                setContent={setContent}
                shareModal={shareModal}
                setShareModal={setShareModal}
              />
            </Box>
          </div>
        </Modal>
      )}
    </>
  );
};
