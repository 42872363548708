import React from 'react';
import { ArrowRightAltRounded } from '@material-ui/icons';
import { SaveData } from '@/actions';
import styles from './styles';

export default ({ type, id }) => {
  const cls = styles();

  return (
    <div className={cls.container}>
      <span className={cls.title}>{`${type} ID: ${id}`}</span>
      <ArrowRightAltRounded
        className={cls.icon}
        onClick={() => SaveData({ Chat: null })}
      />
    </div>
  );
};
