import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  footer: {
    position: 'relative',
    flex: 1,
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: '#303030',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 100,
    padding: '6rem 0',
    fontSize: 13,
    [BP.down('sm')]: {
      flexDirection: 'column',
      padding: '4rem 0',
      alignItems: 'center',
      fontSize: 11,
    },
  },

  links: {
    display: 'flex',
    alignItems: 'center',
    '& > a': {
      color: '#fff',
      margin: '0 0.5rem',
      textDecoration: 'none',
      fontWeight: 500,
      '&:hover': {
        color: '#f8b100',
      },
    },
    [BP.down('sm')]: {
      marginTop: 20,
    },
  },
  logo: {
    display: 'flex',
    [BP.down('sm')]: {
      display: 'none',
    },
  },

  text: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Averta',
    color: '#cbd0d3',
    [BP.down('sm')]: {
      marginTop: 20,
    },
  },
  top: {
    position: 'absolute',
    top: -18,
    cursor: 'pointer',
    fontFamily: 'Averta',
    fontSize: 11,
    color: '#fff',
    fontWeight: 'bold',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: 'all .3s ease-in-out',
    '&:hover': {
      color: '#f8b100',
      animation: 'headShake 1s',
    },
  },
  icon: {
    backgroundColor: '#303030',
    height: 45,
    width: 45,
    borderRadius: 15,
    color: '#fff',
    transition: 'all .5s ease-in-out',
    '&:hover': {
      color: '#303030',
      backgroundColor: '#f8b100',
    },
  },
  item: {
    display:'flex',
    justifyContent:'center'
  }
}));
