import { makeStyles } from '@material-ui/core/styles';
import { LoginIcons } from '@/assets';

export default makeStyles(({ breakpoints: BP }) => ({
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    margin: '0 20px',
  },
  title: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '23px',
  },
  card: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    minHeight: 139,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    boxSizing: 'border-box',
    marginBottom: 25,
  },
  formDescription: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '15px',
  },
  loader: {
    width: '25 !important',
    height: '25 !important',
    color: '#f8b100',
  },
  updateActions: {
    display: 'flex',
    gap: '5px',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    '@media (max-width:600px)': {
      flexDirection: 'column',
    },
  },
  updateBtn: {
    width: 150,
    color: '#ffffff',
    background: '#f8b100',
    marginRight: 6,
    '&:hover': {
      color: '#ffffff',
      background: '#f8b100',
    },
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  tableContainer: {
    overflow: 'auto',
    padding: '0 10px',
  },
  membersMainContainer: {
    margin: '0 20px',
  },
  loading: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 400,
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '20px 0 50px',
    minWidth: 800,
  },
  thead: {
    display: 'flex',
    fontFamily: 'Averta Bold',
    fontSize: 13,
    fontWeight: 600,
    lineHeight: 1.2,
    color: '#43424b',
    textAlign: 'center',
    alignItems: 'center',
    marginBottom: '5px',
    [BP.up('lg')]: {
      fontSize: 15,
    },
    '& div': {
      whiteSpace: 'nowrap',
      padding: 5,
    },
  },
  tbody: {
    display: 'flex',
    flexDirection: 'column',
  },
  tableRow: {
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 61,
    borderRadius: 5,
    boxShadow: '2px 2px 10px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    margin: '5px 0',
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    color: '#43424b',
    textAlign: 'center',

    [BP.up('lg')]: {
      fontSize: 14,
    },
    '& div': {
      whiteSpace: 'nowrap',
      // padding: 5,
    },
  },
  // tableRow: {
  //   display: 'flex',
  //   flexDirection: 'row',
  //   alignItems: 'center',
  //   height: 61,
  //   backgroundColor: 'transparent',
  //   fontFamily: 'Averta Light',
  //   fontSize: 12,
  //   fontWeight: 300,
  //   color: '#43424b',
  //   textAlign: 'center',
  //   padding: '0 16px',
  //   borderBottom: '1px solid #CBCBCB',
  //   [BP.up('lg')]: {
  //     fontSize: 14,
  //   },
  //   '& > div': {
  //     padding: '0 10px',
  //     font: 'Roboto',
  //     weight: '400px',
  //     size: '14px',
  //     lineHeight: '16.41px',
  //     color: '#777575',
  //   },
  // },

  addMemberIconContainer: {
    width: '40px',
    height: '40px',
    background: '#EFFBFF',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    padding: '0 !important',
  },
  activeIconContainer: {
    background: '#D9D9D9 !important',
  },
  icon: {
    fill: '#007FFF',
    width: '18.34px',
    height: '20.07px',
    cursor: 'pointer',
  },
  activeIcon: {
    fill: 'rgba(0, 0, 0, 0.53)',
  },
  tableActionsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionIcon: {
    fill: '#777575',
    padding: '0 4px',
    cursor: 'pointer',
  },
  search: {
    display: 'flex',
    alignItems: 'center',
  },
  searchBox: {
    height: '38px',
    background: 'transparent',
    border: '1px solid #E0E0E0',
    flex: '1',
    display: 'flex',
    alignItems: 'center',
    padding: '5px 10px',
    boxSizing: 'border-box',
    '& input': {
      border: 'none',
      background: 'transparent',
      flex: '1',
      '&:focus-visible': {
        border: 'none !important',
        outline: 'none',
      },
    },
  },
  delIcon: {
    height: 20,
    width: 20,
    fill: '#e4464b',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  previweIcon: {
    height: 20,
    width: 20,
    fill: 'gray',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  input: {
    fontFamily: 'Averta Light',
    width: '100%',
    height: 38,
    backgroundColor: '#fff',
    borderRadius: 2,
    justifyContent: 'center',
    border: 'solid 1px #F1F1F1',
    marginTop: 10,
    '& input': {
      padding: '5px 10px',
      fontFamily: 'Averta Light',
    },
  },
  searchIcon: {
    width: '20px',
    height: '18.51px',
    fill: '#E0E0E0',
    paddingRight: '10px',
  },
  searchInput: {
    '&::placeholder': {
      color: '#E0E0E0',
    },
  },
  filter: {
    display: 'flex',
    alignItems: 'center',
  },
  filterTitle: {
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '19px',
    display: 'flex',
    alignItems: 'center',
    color: '#000000',
    padding: '0 10px',
  },
  filterIcon: {
    width: '16.53px',
    height: '16.67px',
  },
  actions: {
    display: 'flex',
  },
  addBtn: {
    color: '#ffffff',
    width: '50px',
    height: '21px',
    fontSize: '11px',
    background: '#007FFF',
    marginRight: '5px',
    borderRadius: '5px',
    border: 'none',
  },
  cancleBtn: {
    color: '#ffffff',
    width: '50px',
    height: '21px',
    fontSize: '11px',
    background: '#FF0000',
    borderRadius: '5px',
    border: 'none',
  },
  avatar: {
    marginLeft: 10,
    '& img': {
      borderRadius: '50%',
    },
  },
  addAvatar: {
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: ' #d6e8f569',
    borderRadius: '50%',
    marginLeft: 40,
  },
  selectBox: {
    width: '95px',
    color: '#000000',
    fontSize: '14px',
  },
  addAvatarInput: {
    display: 'none',
  },
  userAvatar: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
  },
  delIcon: {
    height: 20,
    width: 20,
    fill: '#e4464b',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  editIcon: {
    height: 20,
    width: 20,
    fill: '#f8b100',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  maileIcon: {
    height: 20,
    width: 20,
    fill: '#0003f87a',
    [BP.up('lg')]: {
      height: 25,
      width: 25,
    },
  },
  addMemberText: {
    paddingLeft: '15px',
    color: '#000000',
    cursor: 'pointer',
  },
  userName: {
    display: 'flex',
    alignItems: 'center',
    '& span': {
      paddingLeft: 15,
    },
  },
  bigText: {
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    lineHeight: 2.7,
    color: '#303030',
  },
  loginLoader: {
    width: '25px !important',
    height: '25px !important',
    color: '#f8b100',
  },
}));
