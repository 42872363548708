import React, { useState } from 'react';
import styles from './styles';
import { TextField, Button, CircularProgress, Grid } from '@material-ui/core';
import { useNotify } from 'react-admin';
import { adminUpdateReferredCompany } from '@/actions';

export default ({ companyData, getReferredCompany, setEditPopup }) => {
  const [formData, setFormData] = useState({
    f_name: companyData.f_name,
    l_name: companyData.l_name,
    company: companyData.company,
    phone: companyData.phone,
  });
  const [loadingForm, setLoadingForm] = useState(false);
  const cls = styles();
  const notify = useNotify();
  const formInputs = [
    {
      label: 'First Name',
      name: 'f_name',
      type: 'text',
    },
    {
      label: 'Last Name',
      name: 'l_name',
      type: 'text',
    },
    {
      label: 'Company',
      name: 'company',
      type: 'text',
    },
    {
      label: 'Phone Number',
      name: 'phone',
      type: 'text',
    },
    {
      label: 'Update',
      type: 'submit',
    },
  ];

  const changeFormData = (key, value) => {
    setFormData((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleEditReferredCompany = () => {
    let formError = false;
    Object.keys(formData).map((key) => {
      if (!formData[key]) {
        formError = true;
      }
    });
    if (formError) {
      notify('Please fill in all fields', 'warning');
    } else {
      setLoadingForm(true);
      adminUpdateReferredCompany(
        { ...formData, _method: 'patch' },
        companyData.id
      )
        .then((res) => {
          notify('Successfully Updated', 'success');
          getReferredCompany();
          setLoadingForm(false);
          setEditPopup(false);
        })
        .catch((err) => {
          notify(err, 'warning');
          setLoadingForm(false);
        });
    }
  };

  return (
    <Grid container spacing={2}>
      {formInputs.map((item, index) => {
        return item.type !== 'submit' ? (
          <Grid item xs={12} sm={6}>
            <TextField
              type={item.type}
              placeholder=""
              className={cls.input}
              InputProps={{ disableUnderline: true }}
              inputProps={{ min: 1 }}
              value={formData[item.name]}
              onChange={(ev) => changeFormData(item.name, ev.target.value)}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <span />
            <Button
              onClick={() => handleEditReferredCompany()}
              className={cls.referBtn}>
              {loadingForm ? (
                <CircularProgress color="#ffffff" className={cls.editLoader} />
              ) : (
                <>{item.label}</>
              )}
            </Button>
          </Grid>
        );
      })}
    </Grid>
  );
};
