import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  card: {
    width: 280,
    minHeight: 80,
    borderRadius: 10,
    boxShadow: '2px 2px 6px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    padding: 10,
    boxSizing: 'border-box',
    margin: '10px 0',
    cursor: 'pointer',
    display: 'flex',
  },
  row: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
  },
  row2: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  flex: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },

  title: {
    fontSize: 10,
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    lineHeight: 1.3,
    color: '#434343',
  },
  date: {
    fontSize: 10,
    fontFamily: 'Averta Bold',
    fontWeight: 600,
    lineHeight: 1.3,
    color: '#43424b',
  },
  des: {
    fontSize: 10,
    fontFamily: 'Averta light',
    fontWeight: 300,
    color: '#434343',
    marginTop: 7,
    flex: 1,
  },

  avatar: {
    height: 30,
    width: 30,
    margin: 'auto 10px auto 0',
  },
  seen: {
    fontSize: '1rem',
    alignSelf: 'flex-end',
    color: '#f8b100',
  },
  opponentName: {
    fontSize: '10.5px',
  },
}));
