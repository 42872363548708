import React, { useState } from 'react';
import { useNotify, Notification } from 'react-admin';
import {
  TextField,
  FormControl,
  Button,
  CircularProgress,
} from '@material-ui/core';
import * as Y from 'yup';
import { Link } from 'react-router-dom';
import { forgetPass } from '@/actions';
import { Header } from '@/components/Landing';

import styles from './styles';

let sch = Y.string()
  .required('Email is required')
  .email('Your Email looks wrong.')
  .trim();

export default ({ history, ...rest }) => {
  const cls = styles();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');

  const Validate = () =>
    sch
      .validate(email)
      .then(handle)
      .catch((er) => notify(er.message, 'warning'));

  const handle = () => {
    setLoading(true);
    forgetPass(email)
      .then((msg) => {
        notify(msg, 'info', { autoHideDuration: 8000 });
        history.push('/login');
      })
      .catch((msg) => {
        notify(msg, 'warning');
        setLoading(false);
      });
  };

  return (
    <div className={cls.bg}>
      <Header height={70} logoWidth={190} />
      <Notification />
      {loading ? (
        <div className={cls.loading}>
          <CircularProgress />
        </div>
      ) : (
        <div className={cls.card}>
          <FormControl>
            <TextField
              required
              label="Email"
              placeholder="email@example.com"
              InputProps={{
                disableUnderline: true,
                style: { paddingLeft: 15 },
              }}
              InputLabelProps={{ style: { paddingLeft: 15 } }}
              className={cls.inp}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormControl>
          <Button
            className={cls.but}
            fullWidth
            type="submit"
            color="primary"
            onClick={Validate}>
            Send me the link
          </Button>

          <Link className={cls.signIn} to="/login">
            or Sign In
          </Link>
        </div>
      )}
    </div>
  );
};
