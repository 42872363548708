import React, { useEffect, useState } from 'react';
import { Card, Button, CircularProgress, Tooltip } from '@material-ui/core';
import { ReactSVG } from 'react-svg';
import { fDate, SizeIcons } from '@/actions/Helper';
import { Icons } from '@/assets';
import styles from './styles';
import { ErrorOutline, Done, Close } from '@material-ui/icons';

export default ({
  DATA = {},
  req,
  num,
  loadingCards,
  handleChangeNmuber,
  index,
  isExport,
}) => {
  const cls = styles();
  const [hasContainerIcon, setHasContainerIcon] = useState();
  const hasContainer = () => {
    if (DATA.container_numbers === 1) {
      setHasContainerIcon(Icons.has_containers);
    } else {
      setHasContainerIcon('');
    }
  };
  React.useEffect(() => {
    hasContainer();
  }, []);
  // console.log('hasContainerIcon', hasContainerIcon);
  // console.log('DATA.has_containers', DATA.has_containers);

  const getProgressIcon = (DATA, type) => {
    const pool = DATA.pool_available || 0;
    const own = DATA.own_available || 0;
    const count = DATA.count_available;
    if (pool + own !== count) {
      return (
        <Tooltip
          arrow
          title={`The carrier is currently updating the  inventory list. We can not verify if the empties you are requesting are on own or pool chassis. However, please proceed with submitting your request and it will be shown in the next step.`}
          placement="top">
          <ErrorOutline className={cls.progressIcon} />
        </Tooltip>
      );
    }
  };

  return (
    <Card className={cls.card}>
      <ReactSVG src={SizeIcons(DATA.size)} className={cls.headerImg} />
      <ReactSVG
        with="50px"
        src={hasContainerIcon}
        className={cls.hasContainersIcon}
      />
      <div className={cls.row}>
        <span>SSL</span>
        <span className={cls.bold}>{DATA.ssl}</span>
      </div>
      <div className={cls.row}>
        <span>Number</span>
        <span className={`${cls.bold} ${cls.numInputContainer}`}>
          <input
            min={'1'}
            max={DATA.max_num}
            className={cls.numInput}
            type="number"
            onChange={(e) => handleChangeNmuber(e.target.value, index)}
            value={Number(DATA.num)}
          />
        </span>
      </div>
      <div className={cls.row}>
        <span>Location</span>
        <span className={cls.bold}>{DATA.loc}</span>
      </div>
      <div className={cls.row}>
        <span>Size</span>
        <span className={cls.bold}>{DATA.size}</span>
      </div>
      <div className={cls.row}>
        <span>Number of Own Chassis</span>

        <span className={cls.bold}>
          <div className={cls.chassisContainer}>
            {isExport ? (
              <>
                {DATA.wanna_own ? (
                  <Done className={cls.progressIcon} />
                ) : (
                  <Close className={cls.progressIcon} />
                )}
              </>
            ) : (
              <>
                {getProgressIcon(DATA, 'Own')}
                {DATA.own_available}
              </>
            )}
          </div>
        </span>
      </div>{' '}
      <div className={cls.row}>
        <span>Number of Pool Chassis</span>
        <span className={cls.bold}>
          <div className={cls.chassisContainer}>
            {isExport ? (
              <>
                {DATA.wanna_pool ? (
                  <Done className={cls.progressIcon} />
                ) : (
                  <Close className={cls.progressIcon} />
                )}
              </>
            ) : (
              <>
                {getProgressIcon(DATA, 'Pool')}
                {DATA.pool_available}
              </>
            )}
          </div>
        </span>
      </div>{' '}
      <div className={cls.row}>
        <span>Flip Available</span>
        <span className={cls.bold}>{DATA.flip_available ? 'Yes' : 'No'}</span>
      </div>
      <div className={cls.row}>
        <span>Last Update</span>
        <span className={cls.bold}>{fDate(DATA.date)}</span>
      </div>
      <Button className={cls.but} onClick={() => req(DATA)}>
        {loadingCards.indexOf(DATA.id) !== -1 ? (
          <CircularProgress className={cls.loader} />
        ) : (
          'Request'
        )}
      </Button>
    </Card>
  );
};
