import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  container: {
    width: '100%',
    padding: '0 4vw',
    boxSizing: 'border-box',
    [BP.down('xs')]: {
      padding: 0,
      overflow: 'hidden',
    },
  },

  title: {
    fontSize: 58,
    fontFamily: 'Averta Light',
    fontWeight: 300,
    lineHeight: 1,
    color: '#303030',
    textAlign: 'center',
    marginTop: 90,
    marginBottom: 60,
    [BP.down('xs')]: {
      fontSize: '2rem',
      marginTop: 60,
      marginBottom: 40,
    },
  },
  loading: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 390,
  },
}));
