import React, { useEffect, useState } from 'react';
import { Loading } from 'react-admin';
import { Button, TextField, Grid } from '@material-ui/core';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import DateInput from '@/components/DateInput';
import { locationOpt } from '@/actions/Helper';
import styles from './styles';
import { addRequestInventory } from '@/actions';

export default ({ notify, setData, getRequestInventoryList }) => {
  const cls = styles();
  const [loading, setLoading] = React.useState(false);
  const selectOp = useSelector((state) => state.reducer);

  const [form, setFormData] = React.useState({
    size: null,
    ssl: null,
    date: new Date(),
    count: '',
    loc: null,
    containers: [],
    chassis: {
      own: true,
      pool: true,
    },
  });

  const setForm = (it, val) => setFormData({ ...form, [it]: val });

  const Add = () => {
    alert('api not exist')
    // setLoading(true);

    // addRequestInventory({
    //   ...form,
    // })
    //   .then((res) => {
    //     notify('Added Successfully.');
    //     getRequestInventoryList();
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     notify(err, 'warning');
    //     setData([]);
    //     setLoading(false);
    //   });
  };

  const Reset = () =>
    setFormData({
      size: null,
      ssl: null,
      date: new Date(),
      count: '',
      loc: null,
      containers: [],
    });

  if (loading) return <Loading className={cls.loading} />;

  return (
    <div className={cls.card}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} lg={2}>
          <Select
            placeholder="Size"
            className={cls.select}
            value={form.size}
            onChange={(item) => setForm('size', item)}
            options={selectOp.size}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <Select
            placeholder="SSL"
            className={clsx(cls.select)}
            value={form.ssl}
            onChange={(item) => setForm('ssl', item)}
            options={selectOp.ssl}
          />
        </Grid>

        <Grid item xs={12} sm={4} lg={2}>
          <TextField
            type="number"
            placeholder="Number"
            className={clsx(cls.input)}
            value={form.count}
            onChange={(e) => setForm('count', e.target.value)}
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0 }}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <AsyncSelect
            placeholder="Location"
            className={cls.loc}
            value={form.loc}
            loadOptions={locationOpt}
            onChange={(item) => setForm('loc', item)}
            cacheOptions
            defaultOptions
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={3}>
          <DateInput
            placeholder="Available date"
            className={cls.date}
            format="MM/dd/yyyy"
            value={form.date}
            onChange={(item) => setForm('date', item)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <div className={cls.containerActions}>
            <div className={cls.containerRowRound}>
              <span
                onClick={() =>
                  setForm('chassis', {
                    own: !form.chassis.own,
                    pool:
                      !form.chassis.own === false ? true : form.chassis.pool,
                  })
                }
                className={clsx(
                  cls.chassisText,
                  form.chassis.own && cls.activeChassis
                )}>
                Own Chassis
              </span>
              <span
                onClick={() =>
                  setForm('chassis', {
                    pool: !form.chassis.pool,
                    own: !form.chassis.pool === false ? true : form.chassis.own,
                  })
                }
                className={clsx(
                  cls.chassisText,
                  form.chassis.pool && cls.activeChassis
                )}>
                Pool Chassis
              </span>
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={cls.actions}>
        <span className={cls.text}>
          Please fill the form above and click on add button. You can edit or
          delete an request later through the below list
        </span>
        <div className={cls.buttons}>
          <Button className={clsx(cls.but, cls.red)} onClick={Reset}>
            Reset
          </Button>
          <Button className={clsx(cls.but, cls.blue)} onClick={Add}>
            Add
          </Button>
        </div>
      </div>
    </div>
  );
};
