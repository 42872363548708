import React from 'react';
import { getCompareReport } from '@/actions';
import StatCard from './Card';
import styles from './styles';

export default () => {
  const cls = styles();
  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });

  React.useEffect(() => {
    getCompareReport()
      .then((DATA) => setState({ DATA, loading: false }))
      .catch(() => setState((st) => ({ ...st, loading: false })));
  }, []);

  return (
    <div className={cls.itemRow}>
      <div className={cls.itemCol}>
        <StatCard title="Total requests" color="yellow" data={state.DATA.req} />
        <StatCard
          title="Total Offers"
          color="black"
          white
          data={state.DATA.ord}
        />
      </div>
      <div className={cls.itemCol}>
        <StatCard title="Completed Deals" color="green" data={state.DATA.com} />
        <StatCard
          title="Rejected Deals"
          color="red"
          white
          data={state.DATA.rej}
        />
      </div>
    </div>
  );
};
