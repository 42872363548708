import React from 'react';
import { Link } from 'react-router-dom';
import { EmailRounded, PhoneRounded } from '@material-ui/icons';
import {
  AppBar,
  useScrollTrigger,
  Slide,
  useMediaQuery,
} from '@material-ui/core';
import { HeaderIcons } from '@/assets';
import { LoggedIn } from '@/actions/Helper';
import MobileHeader from '../MobileHeader';
import styles from './styles';

export default ({ loginBut, height, logoWidth }) => {
  const cls = styles({ height, logoWidth });
  const isMobile = useMediaQuery('(max-width:960px)');
  const trigger = useScrollTrigger({ target: window ? window : undefined });
  if (isMobile) return <MobileHeader />;

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <AppBar className={cls.header} position="sticky">
        <Link to="/">
          <img src={HeaderIcons.logo} alt="Logo" className={cls.logo} />
        </Link>
        <div className={cls.linkCont}>
          <Link to="/" className={cls.link}>
            HOME
          </Link>
          <Link to="/how-it-works" className={cls.link}>
            HOW IT WORKS
          </Link>
          <Link to="/about" className={cls.link}>
            ABOUT
          </Link>
          <Link to="/contact" className={cls.link}>
            CONTACT US
          </Link>
        </div>
        <span className={cls.spacer} />

        <a href="mailto:support@smart-turn.com" className={cls.headerItem}>
          <EmailRounded />
          support@smart-turn.com
        </a>

        <a className={cls.headerItem} href="tel:+1 714 251 0126">
          <PhoneRounded />
          (714) 251-0126
        </a>
        {loginBut &&
          (LoggedIn() ? (
            <Link to="/dashboard" className={cls.but}>
              Dashboard
            </Link>
          ) : (
            <Link to="/login" className={cls.but}>
              LOG IN
            </Link>
          ))}
      </AppBar>
    </Slide>
  );
};
