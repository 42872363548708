import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const styles = makeStyles({
  progressWrapper: {
    display: 'flex',
    height: 9,
    marginTop: 10,
    fontFamily: 'Averta Light',
    fontSize: 12,
    fontWeight: 300,
    lineHeight: 0.8,
    color: '#303030',
    cursor: 'default',
  },
  progressBar: {
    display: 'flex',
    borderRadius: 10,
    backgroundColor: '#dfdfdf',
    width: '100%',
    margin: '0 3px',
  },
  progress: {
    height: 9,
    backgroundColor: '#f8b100',
    borderRadius: 10,
    width: (props) => props.percent + '%',
  },
});

export default ({ percent, max }) => {
  const cls = styles({ percent });

  return (
    <div className={cls.progressWrapper}>
      <span>0</span>
      <div className={cls.progressBar}>
        <div className={cls.progress} />
      </div>
      <span>{max}</span>
    </div>
  );
};
