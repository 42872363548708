import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(({ breakpoints: BP }) => ({
  input: {
    background: 'transparent',
    border: '1px solid #E0E0E0',
    width:'100%',
    height:'19px',
    margin:'0 auto',
    '&::placeholder': {
        fontSize:'10px',
        color:'#E0E0E0'
    }
  },
}));
