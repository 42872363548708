import React, { useEffect, useState } from 'react';
import { Tooltip, IconButton, CircularProgress } from '@material-ui/core';
import {
  DeleteRounded,
  CreateRounded,
  Share as ShareIcon,
  Done,
  Close,
} from '@material-ui/icons';
import { fDate, fTimeDate } from '@/actions/Helper';
import styles from './styles';
// import Containers from './Containers';
// import Share from './Share/index';
import { getInventoryShareLink } from '@/actions';
import { useNotify } from 'react-admin';

export default ({ DATA = [], Del, Edit, currentPage }) => {
  const cls = styles();
  const [showContainersModal, setShowContainersModal] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [shareLink, setShareLink] = useState({
    loading: null,
    DATA: null,
  });

  const notify = useNotify();
  const handleGetShareLink = (i) => {
    setShareLink({ loading: i.id });
    getInventoryShareLink(i.id)
      .then((res) => {
        setShareModal(i);
        setShareLink({
          loading: null,
          DATA: res,
        });
      })
      .catch((err) => {
        setShareLink({ loading: null });
        notify(err, 'warning');
      });
  };

  return (
    <div className={cls.listContainer}>
      <table className={cls.list}>
        <thead className={cls.thead}>
          <th style={{ flex: 0.35 }}>#</th>
          <th style={{ flex: 1 }}>Size</th>
          <th style={{ flex: 0.7 }}>SSL</th>
          <th style={{ flex: 0.7 }}>Active</th>
          <th style={{ flex: 1.2 }}>Location</th>
          <th style={{ flex: 1 }}>Available Date</th>
          <th style={{ flex: 0.7 }}>Own Chassis</th>
          <th style={{ flex: 0.7 }}>Pool Chassis</th>
          <th style={{ flex: 1 }}>Last Update</th>
          <th style={{ flex: 1 }}>Action</th>
        </thead>
        <tbody className={cls.tbody}>
          {DATA.map((i, idx) => (
            <tr className={cls.tableRow} key={idx}>
              <td style={{ flex: 0.35 }}>{idx + 1 + (currentPage - 1) * 10}</td>
              <td style={{ flex: 1 }}>{i.size}</td>
              <td style={{ flex: 0.7 }}>{i.ssl}</td>
              <td style={{ flex: 0.7 }}>{i.active}</td>
              <td style={{ flex: 1.2 }}>{i.loc}</td>
              <td style={{ flex: 1 }}>{fDate(i.date)}</td>
              <td style={{ flex: 0.7 }}>
                {i.own_chases_count ? <Done /> : <Close />}
              </td>
              <td style={{ flex: 0.7 }}>
                {i.pool_chases_count ? <Done /> : <Close />}
              </td>
              <td style={{ flex: 1 }}>{fTimeDate(i.up)}</td>
              <td className={cls.tabActions} style={{ flex: 1 }}>
                <Tooltip arrow title="Edit" placement="top">
                  <IconButton aria-label="edit" onClick={() => Edit(i)}>
                    <CreateRounded className={cls.editIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip arrow title="Delete" placement="top">
                  <IconButton aria-label="delete" onClick={() => Del(i.id)}>
                    <DeleteRounded className={cls.delIcon} />
                  </IconButton>
                </Tooltip>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
