import { Tooltip, Avatar, TextField, Button } from '@material-ui/core';
import React from 'react';
import styles from './styles';
import { CloseRounded } from '@material-ui/icons';
import { useOutsideAlerter } from '@/hocs';

export default ({ handleDelete, closePopup }) => {
  const cls = styles();

  const handleClose = () => {
    closePopup();
  };

  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef, () => {
    handleClose()
  });

  return (
    <div className={cls.popup}>
      <div ref={wrapperRef} className={cls.card}>
        <Tooltip arrow title="Close" placement="top">
          <CloseRounded className={cls.close} onClick={() => handleClose()} />
        </Tooltip>
        <div>
          <p>Are you sure that you want to delete it?</p>
          <div className={cls.actionContainer}>
            <button className={cls.noBtn} onClick={() => handleClose()}>
              Not now
            </button>
            <button className={cls.yesBtn} onClick={() => handleDelete()}>
              Yes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
