import React, { useEffect, useState } from 'react';
import styles from './styles';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import {
  adminGetUpdatedMembers,
  adminUpdateUsersApp,
  checkUpdate,
} from '@/actions';
import UpdatedMembers from './UpdatedMembers';
import { useNotify } from 'react-admin';
import TextEditor from '@/components/TextEditor';
import { ContentState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import UpdatePopup from '@/components/UpdatePopup';
import Pagination from '@/components/pagination';

const Update = () => {
  const [loadingUpdate, setLoadingUpdate] = useState(null);
  const [showPreviwe, setShowPreviwe] = useState(false);
  const [notificationBody, setNotificationBody] = useState('');
  const [notificationTitle, setNotificationTitle] = useState(null);
  const [updatedMembers, setUpdatedMembers] = React.useState({
    loading: false,
    DATA: [],
  });
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pages: [1],
  });

  const notify = useNotify();

  const cls = styles();

  const createArrayFromNumbers = (number) => {
    let numberArray = [];
    for (let i = 1; i <= number; i++) {
      numberArray.push(i);
    }

    return numberArray;
  };

  const getUpdatedMembers = () => {
    setUpdatedMembers((prev) => {
      return {
        ...prev,
        loading: true,
      };
    });
    adminGetUpdatedMembers({ page: pagination.currentPage })
      .then((res) => {
        setUpdatedMembers((prev) => {
          return {
            loading: false,
            DATA: res.data,
          };
        });
        setPagination((prev) => {
          return {
            ...prev,
            pages: createArrayFromNumbers(Math.ceil(res.total / res.per_page)),
          };
        });
      })
      .catch((err) => {
        notify(err, 'warning');
        setUpdatedMembers((prev) => {
          return {
            ...prev,
            loading: false,
          };
        });
      });
  };

  const handleUpdateUsers = (params) => {
    setLoadingUpdate(params.update);
    let apiData = {
      ...params,
      title: notificationTitle ? notificationTitle : null,
      message: notificationBody,
    };
    adminUpdateUsersApp(apiData)
      .then(() => {
        setLoadingUpdate(null);
        notify('Updated Successfully', 'success');
        getUpdatedMembers();
      })
      .catch((err) => {
        notify(err, 'warning');
        setLoadingUpdate(null);
      });
  };

  const checkUpdateApp = () => {
    checkUpdate().then((res) => {
      if (res.notification) {
        setNotificationTitle(res.notification.title);
        const contentBlock = htmlToDraft(res.notification.message);
        if (contentBlock) {

          setNotificationBody(res.notification.message);
        }
      }
    });
  };

  useEffect(() => {
    checkUpdateApp();
    getUpdatedMembers();
  }, []);

  useEffect(() => {
    getUpdatedMembers();
  }, [notify, pagination.currentPage]);

  return (
    <div className={cls.content}>
      {showPreviwe && (
        <UpdatePopup
          updateNotification={{
            title: notificationTitle,
            message: notificationBody,
          }}
          disabledUpdate={true}
          showClose={true}
          handleClose={() => setShowPreviwe(false)}
          loadingUpdate={loadingUpdate}
        />
      )}
      <h2 style={{ padding: '0 10px' }} className={cls.title}>
        Update
      </h2>
      <div style={{ margin: '0 10px' }} className={cls.card}>
        <span
          style={{ fontSize: '20px', marginBottom: '20px' }}
          className={cls.formDescription}>
          Use the button below to apply the new update to all users
        </span>
        <div style={{ marginTop: '20px' }}>
          <span className={cls.formDescription}>Write Notification Title</span>
          <TextField
            className={cls.input}
            type="text"
            value={notificationTitle}
            onChange={(e) => setNotificationTitle(e.target.value)}
            InputProps={{ disableUnderline: true }}
            inputProps={{ min: 0 }}
          />
        </div>
        <div style={{ margin: '20px 0' }}>
          <span
            style={{ marginBottom: '10px', display: 'block' }}
            className={cls.formDescription}>
            Write Notification Body
          </span>
          <TextEditor
            editorState={notificationBody}
            setEditorState={setNotificationBody}
          />
        </div>
        <div className={cls.updateActions}>
          <Button
            onClick={() => setShowPreviwe(true)}
            className={cls.updateBtn}>
            Previwe
          </Button>
          <Button
            onClick={() => handleUpdateUsers({ update: 1 })}
            className={cls.updateBtn}>
            {loadingUpdate === 1 ? (
              <CircularProgress size={20} color="#ffffff" />
            ) : (
              'Apply Update'
            )}
          </Button>
          <Button
            onClick={() => handleUpdateUsers({ update: 0 })}
            className={cls.updateBtn}>
            {loadingUpdate === 0 ? (
              <CircularProgress size={20} color="#ffffff" />
            ) : (
              'Disable Update'
            )}
          </Button>
        </div>
      </div>
      <span
        style={{ margin: '2rem 0 1rem 0', padding: '0 10px', fontSize: '20px' }}
        className={cls.formDescription}>
        Users who have pressed the update button
      </span>
      {updatedMembers.loading ? (
        <div className={cls.loading}>
          <CircularProgress className={cls.loader} />
        </div>
      ) : (
        <div style={{ overflow: 'auto', width: '100%' }}>
          <UpdatedMembers
            getUpdatedMembers={getUpdatedMembers}
            DATA={updatedMembers.DATA}
            notify={notify}
          />
        </div>
      )}
      <Pagination pagination={pagination} setPagination={setPagination} />
    </div>
  );
};

export default Update;
