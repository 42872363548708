import {
  SaveData,
  SaveNotif,
  adminChangeWhatsappReplayStatus,
  adminEditWhatsappReplay,
  adminGetWhatsappUserList,
  adminGetWhatsappUserMessages,
} from '@/actions';
import React, { useEffect, useRef, useState } from 'react';
import List from './List';
import Messages from './Messages';
import styles from './styles';
import useMediaQuery from 'use-mediaquery';
import { useNotify } from 'react-admin';
import { useSelector } from 'react-redux';

export default () => {
  const [state, setState] = React.useState({
    DATA: [],
    loading: true,
  });
  const [editingMessage, setEditingMessage] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchingChat, setSearchingChat] = useState(null);
  const [messages, setMessages] = useState({
    loading: false,
    currentPage: 1,
    totalPage: 1,
    DATA: [],
  });

  const whatsappNewMessages = useSelector(
    (state) => state.reducer.whatsappNewMessages
  );

  const cls = styles();
  const notify = useNotify();

  const isSmallDevice = useMediaQuery('(max-width: 1000px)');

  const RefreshData = React.useCallback(() => {
    setState({
      DATA: [],
      loading: true,
    });
    adminGetWhatsappUserList()
      .then((res) =>
        setState({
          DATA: res.data.users.data,
          loading: false,
        })
      )
      .catch((err) => {
        // notify(err, 'warning');
        setState({ DATA: [], loading: false });
      });
  });

  const getMessages = (page = messages.currentPage) => {
    adminGetWhatsappUserMessages(selectedUser, page).then((res) => {
      setMessages((prev) => {
        return {
          loading: false,
          currentPage: res.data.message_groups.current_page,
          totalPage: res.data.message_groups.total,
          perPage: res.data.message_groups.per_page,
          DATA: [...res.data.message_groups.data.reverse(), ...prev.DATA],
          // prev.currentPage === 1
          //   ? res.data.message_groups.data.reverse()
          //   : [...res.data.message_groups.data, ...prev.DATA].reverse(),
        };
      });
    });
  };

  const handleSelectUser = (user_id) => {
    setSelectedUser(user_id);
  };

  const handleChangeMessageState = (message_id, token_id, key, value) => {
    setMessages((prev) => {
      return {
        loading: false,
        DATA: prev.DATA.map((message) => {
          if (message.whatsapp_message_id === message_id) {
            return {
              ...message,
              tokens: message.tokens.map((token) => {
                if (token.id === token_id) {
                  return {
                    ...token,
                    [key]: value,
                  };
                } else {
                  return token;
                }
              }),
            };
          } else {
            return message;
          }
        }),
      };
    });
  };

  const handleChangeReplyStatus = (
    status,
    whatsappAfterAiAnalyze,
    message_id,
    token_id
  ) => {
    handleChangeMessageState(
      message_id,
      token_id,
      'status',
      status === 'verify' ? 3 : 0
    );
    adminChangeWhatsappReplayStatus({ status }, whatsappAfterAiAnalyze)
      .then((data) => {
        if (data.message === 'success') {
          notify('Successfully Updated', 'success');
        } else {
          notify('please try again!', 'warning');
        }
      })
      .catch((err) => {
        notify(err, 'warning');
      });
  };

  const handleEditMessage = (newMessage, message_id, token_id) => {
    adminEditWhatsappReplay({ new_message_text: newMessage }, token_id).then(
      (res) => {
        handleChangeMessageState(
          message_id,
          token_id,
          'message_to_send',
          newMessage
        );
        setEditingMessage(null);
      }
    );
  };

  const removeNewMessageNotif = (id = selectedUser) => {
    let adaptedMessages = whatsappNewMessages.filter((item) => item.by !== id);
    SaveData({ whatsappNewMessages: adaptedMessages });
  };

  React.useEffect(() => {
    RefreshData();
    SaveNotif({ 'whatsapp-messages': false });
    return () => {
      SaveNotif({ 'whatsapp-messages': false });
    };
  }, []);

  React.useEffect(() => {
    setMessages({
      loading: false,
      currentPage: 1,
      totalPage: 1,
      DATA: [],
    });
    if (selectedUser) {
      getMessages(1);
    }
    removeNewMessageNotif(selectedUser);
  }, [selectedUser]);

  React.useEffect(() => {
    if (Array.isArray(whatsappNewMessages) && whatsappNewMessages.length > 0) {
      const lastMessage = whatsappNewMessages[whatsappNewMessages.length - 1];
      if (selectedUser && selectedUser === lastMessage.by) {
        setMessages((prev) => {
          return {
            ...prev,
            DATA: [
              ...prev.DATA,
              {
                ...JSON.parse(lastMessage.message),
                tokens:
                  JSON.parse(lastMessage.message).tokens &&
                  JSON.parse(lastMessage.message).tokens.length > 0
                    ? JSON.parse(lastMessage.message).tokens[0]
                    : [],
              },
            ],
          };
        });
        removeNewMessageNotif(lastMessage.by);
      }

      setState((prev) => {
        prev.DATA.forEach(function (item, i) {
          if (item.from === lastMessage.by) {
            prev.DATA.splice(i, 1);
            prev.DATA.unshift(item);
          }
        });

        return {
          ...prev,
          DATA: prev.DATA,
        };
      });
    }
  }, [whatsappNewMessages]);

  return (
    <div className={cls.chatContainer}>
      {(!isSmallDevice || (isSmallDevice && messages.DATA.length === 0)) && (
        <div className={cls.chatListContainer}>
          <List
            searchingChat={searchingChat}
            setSearchingChat={setSearchingChat}
            state={state}
            setState={setState}
            handleSelectUser={handleSelectUser}
          />
        </div>
      )}
      {(!isSmallDevice || (isSmallDevice && messages.DATA.length > 0)) && (
        <>
          {
            <Messages
              selectedUser={selectedUser}
              setList={setState}
              messages={messages}
              refreshChatList={() => RefreshData()}
              handleChangeReplyStatus={handleChangeReplyStatus}
              handleEditMessage={handleEditMessage}
              editingMessage={editingMessage}
              setEditingMessage={setEditingMessage}
              getMessages={getMessages}
              setMessages={setMessages}
              removeNewMessageNotif={removeNewMessageNotif}
              notify={notify}
            />
          }
        </>
      )}
    </div>
  );
};
